import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

import { IDwProgram } from './interface/program.interface';
import { IDwOperationInfoService } from './interface/operation-info-service.interface';
import { DwOperationInfoListService } from './operation-info-list.service';

@Injectable()
export class DwOperationInfoService implements IDwOperationInfoService {
  constructor(
    private dwProgramListService: DwOperationInfoListService
  ) {
  }

  /**
   * 取得作業資訊
   */
  operationInfo$(programId: string): Observable<IDwProgram> {
    const programSubject = new BehaviorSubject<IDwProgram>(null);

    let program: IDwProgram = {
      module: '',
      type: '', // category, program
      routerLink: '', // 頁面路由
      menuId: '',
      code: '',
      parameter: [] // 作業參數
    };

    this.dwProgramListService.operationListMap$.subscribe(
      list => {
        if (list !== null) {
          if (list[programId]) {
            program = list[programId];
          }
        }

        programSubject.next(program);
      },
      error => {
        programSubject.next(program);
      },
    );

    return programSubject.asObservable().pipe(
      filter(obsData => obsData !== null), // 不廣播初始值
      distinctUntilChanged() // 有改變時才廣播
    );
  }
}
