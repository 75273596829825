import { Injectable } from '@angular/core';
import { Model, ModelFactory } from '@angular-extensions/model';
import { Observable } from 'rxjs';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxMasterService } from '../../../../shared/pattern/masterdetail/fx-masterservice';
import { Fxai001S01DetailService } from './fxai001-s01-detail.service';
import { FxGridRowEditService } from 'app/implementation/shared/fx-grid/fx-grid-row-edit.service';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, ValidatorFn, ValidationErrors } from '@angular/forms';

export interface Fxai001S01MasterModel {
  id: number;           // ID
  aaaent: String;      // 企业编号
  aaastus: String;     // 状态
  aaa001: String;      // 系统分类
  aaa001_n1: String;   // 系统分类名称
  aaa001_n2: String;
  aaa001_n3: String;
  aaanote: String;     // 备注
  aaamper: String;     // 最近操作人员
  aaamper_n1: String,
  aaalock: String;      // 乐观锁
}

const initialData: Fxai001S01MasterModel[] = []; // 初始化表格为空

@Injectable()
export class Fxai001S01MasterService extends FxGridRowEditService {
  private model: Model<Fxai001S01MasterModel[]>;

  static ID = 0; // id 值
  masterDataSet$: Observable<Fxai001S01MasterModel[]>;

  constructor(
    private fb: FormBuilder,
    private modelFactory: ModelFactory<Fxai001S01MasterModel[]>,
    public http: FxDapHttpClient) {
    super(http);
    this.model = this.modelFactory.create(initialData);
    this.masterDataSet$ = this.model.data$;
  }

  // 建立group 
  initMasterRowFromGroup(data: any): any {
    const formGroup = this.fb.group({});
    Object.keys(data).forEach(key => {
      //字段同步验证器
      if (['aaa001', 'aaa001_n1'].find(element => element === key)) {
        formGroup.setControl(key, new FormControl(data[key], [Validators.required]));
      } else {
        formGroup.setControl(key, new FormControl(data[key]));
      }
    });
    formGroup.updateValueAndValidity();
    return formGroup;
  }

  //赋值对象初始值，并建立group
  public createRowFromGroup(customRowData: any): FormGroup {
    // 设置新增的默认值
    let _rowData = {
      id: this.getNextId(),
      aaaent: '',
      aaastus: '',
      aaa001: '',
      aaa001_n1: '',
      aaa001_n2: '',
      aaa001_n3: '',
      aaanote: '',
      aaamper: '',
      aaamper_n1: '',
      aaalock: ''
    };
    const detailList: Fxai001S01MasterModel[] = [];
    _rowData = Object.assign(detailList, _rowData);
    if (customRowData) {
      _rowData = Object.assign(_rowData, customRowData);
    }
    return this.initMasterRowFromGroup(_rowData);
  }

  public createFormArray(datas: any): FormArray {
    const formArray: FormArray = this.fb.array(datas.map((item, index) => this.createRowFromGroup({ id: index, ...item })));
    this.maxId = formArray.length;
    this.initIndex(formArray.value);
    return formArray;
  }

  // 从后端查询到数据，调用此initMaster给MasterGrid赋值
  initMaster(datas: any): void {

    Fxai001S01MasterService.ID = 0;  // 翻页或从后端查重询之后ID从0开始
    if (Array.isArray(datas)) {
      let masterDataSet = this.model.get();
      masterDataSet = [];
      if (datas !== undefined && datas !== null) {  // 参数检查
        datas.forEach((data, idx, array) => {
          Fxai001S01MasterService.ID++;         // 自动设定ID值
          data.id = Fxai001S01MasterService.ID;
          masterDataSet.push(data);
        });
      }
      this.model.set(masterDataSet);
    }
  }

  // 创建一个新的一行数据并给定默认值
  createNewRowData(): Fxai001S01MasterModel {

    Fxai001S01MasterService.ID++; // 自动给ID

    // 设置新增的默认值
    const defaultData: Fxai001S01MasterModel = {
      id: Fxai001S01MasterService.ID,
      aaaent: '',
      aaastus: 'T',
      aaa001: '',
      aaa001_n1: '',
      aaa001_n2: '',
      aaa001_n3: '',
      aaanote: '',
      aaamper: '',
      aaamper_n1: '',
      aaalock: '1'
    };

    return defaultData;
  }

  // 新增一笔记录
  add(data: any): void {
    const masterDataSet = this.model.get();

    // masterDataSet.push(data);
    // 在第一个位置填加
    masterDataSet.unshift(data);

    this.model.set(masterDataSet);
  }

  // 修改一笔记录
  modify(data: any): void {
    const masterDataSet = this.model.get();

    masterDataSet.splice(masterDataSet.findIndex(dataSet => dataSet.id === data.id), 1, data);

    this.model.set(masterDataSet);
  }

  // 有效多笔记录
  valid(selectedMap: any, datas: Fxai001S01MasterModel[]): void {
    const masterDataSet = this.model.get();

    masterDataSet.forEach((item, index) => {
      if (selectedMap[item.id] !== undefined && datas !== null) {

        // 更新Model并刷新Grid
        const data = datas.find(master => master.aaa001 === item.aaa001);
        if (data !== undefined) {
          data.id = item.id;
          masterDataSet.splice(index, 1, data);  // 替换对应的行
        }
      }
    });

    this.model.set(masterDataSet);
  }

  // 无效多笔记录
  invalid(selectedMap: any, datas: Fxai001S01MasterModel[]): void {
    const masterDataSet = this.model.get();

    masterDataSet.forEach((item, index) => {
      if (selectedMap[item.id] !== undefined && datas !== null) {

        // 更新Model并刷新Grid
        const data = datas.find(master => master.aaa001 === item.aaa001);
        if (data !== undefined) {
          data.id = item.id;
          masterDataSet.splice(index, 1, data);  // 替换对应的行
        }
      }
    });

    this.model.set(masterDataSet);
  }

  // 删除
  delete(selectedMap: any, datas: Fxai001S01MasterModel[]): void {
    let masterDataSet = this.model.get();

    masterDataSet = masterDataSet.filter(item => {

      if (selectedMap[item.id] !== undefined && datas !== null) {
        const data = datas.find(master => master.aaa001 === item.aaa001);
        if (data === undefined) {
          return true;  // 元素通过测试,保留该元素
        } else {
          return false;  // 不保留
        }
      } else {
        return true; // 元素通过测试,保留所有元素
      }
    });

    this.model.set(masterDataSet);
  }


  // 取消
  cancel(): void {
    const masterDataSet = this.model.get();
    this.model.set(masterDataSet);
  }

  // 1-列表查询
  public getList(searchParam: any): Observable<any> {
    return this.http.post('A/IFxai001Service/getList', { queryInfo: searchParam });
  }

  // 2-获取详情
  public getDetailInfo(detailParam: any): Observable<any> {
    return this.http.post('A/IFxai001Service/getDetailInfo', { 'param': detailParam });
  }

  // 3-新增(列表、详情)
  public addData(data: any): Observable<any> {
    return this.http.post('A/IFxai001Service/addData', { 'param': data });
  }

  // 4-修改(列表、详情)
  public updateData(data: any): Observable<any> {
    return this.http.post('A/IFxai001Service/updateData', { 'param': data });
  }

  // 5-删除【批量】
  public deleteData(data: any): Observable<any> {
    return this.http.post('A/IFxai001Service/deleteData', { 'param': data });
  }

  // 6-有效【批量】
  public auditData(data: any): Observable<any> {
    return this.http.post('A/IFxai001Service/auditData', { 'param': data });
  }

  // 7-无效【批量】
  public disAuditData(data: any): Observable<any> {
    return this.http.post('A/IFxai001Service/disAuditData', { 'param': data });
  }

  // 8-检查主键重复
  public checkPrimaryKey(param: any): Observable<any> {
    return this.http.post('A/IFxai001Service/checkPrimaryKey', { 'param': param });
  }

}

