import { Component, OnDestroy, OnInit } from '@angular/core';
import { FxAgGridValidationEditorComponent } from '../fx-ag-grid-validation-editor';
import { FormBuilder, FormControl } from '@angular/forms';
import { ICellRendererAngularComp } from '../../../../../../node_modules/ag-grid-angular';
import { type } from 'os';
import { Observable } from 'rxjs';
import { subscribeOn } from 'rxjs/operators';

@Component({
  selector: 'app-fx-ag-grid-select-cell-renderer-multi',
  templateUrl: './fx-ag-grid-select-cell-renderer-multi.component.html',
  styleUrls: ['./fx-ag-grid-select-cell-renderer-multi.component.less']
})
export class FxAgGridSelectCellRendererMultiComponent extends FxAgGridValidationEditorComponent implements ICellRendererAngularComp, OnDestroy {
  values: any;
  public formControl: FormControl;
  key;
  public value;
  label: string = '';
  columnName: string;
  params;
  className: string;
  private rowId: number;

  constructor(private fb: FormBuilder) {
    super();
  }

  agInit(params: any): any {
    this.values = params.values ? params.values : [];
    this.className = params.className ? params.className : '';
    this.params = params;
    // console.debug('-------- fx-form-cell debugging');
    this.columnName = params.column.colDef.headerName;
    // this.key = params.context.createKey(params.columnApi, params.column);
    this.key = params.node.id + '-' + params.column.colId;
    this.value = params.getValue();
    if (!Array.isArray(this.value)) {
      this.value = this.value.split(',');
    }
    if (Array.isArray(this.values)) {
      this.label = this.findValue(this.values);
    } else {
      const observable: Observable<any> = this.values;
      observable.subscribe(data => {
        this.label = this.findValue(data);
      });
    }

    this.formControl = new FormControl(this, params.validators);
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.validateForm.addControl(this.key, this.formControl);
    this.formControl.markAsDirty();

    super.init();
  }

  findValue(arr: Array<any>): string {
    var matchedItem = '';
    var valueArr = this.value;
    if (valueArr && valueArr.length) {
      valueArr.forEach((element, index) => {
        matchedItem = matchedItem ? matchedItem + ',' + (arr.find(item => item.value === element)).label : (arr.find(item => item.value === element)).label;
      });
    }
    return matchedItem;
  }

  refresh(params: any): boolean {
    // this.value = params.value;
    // if (Array.isArray(this.values)) {
    //   this.label = this.findValue(this.values);
    // } else {
    //   const observable: Observable<any> = this.values;
    //   observable.subscribe(data => {
    //     this.label = this.findValue(data);
    //   });
    // }
    return true;
  }

  labelClick(event): void {
    if (this.params && this.params.click) {
      this.params.click(this.params);
    }
  }

  debug(): void {
    console.debug('-------- fx-form-cell debugging');
    console.debug(this.formControl);
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.key);
  }
}
