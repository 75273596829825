export const programData = [
  {
    'id': 'dw-finereport',
    'type': 'fineReport',
    'routerLink': '/fr-reports'
  },
  {
    'id': 'dw-general-frame',
    'type': 'externalUrl',
    'routerLink': '/gen-reports'
  }
];
