// 功能按鈕這裡的name是為了上傳到互聯應用管理中心
export const programActionData = [
  {
    'programId': 'dw-home-setting',
    'action': [
      {
        'id': 'dw-home-setting-tenant',
        'name': '共用首頁設定'
      }
    ]
  }
];
