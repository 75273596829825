import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DwModalService } from 'ng-quicksilver';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-fx-header-renderer',
  templateUrl: './fx-header-renderer.component.html',
})
export class FxHeaderRendererComponent implements ICellRendererAngularComp {

  infoList = [];
  constructor(
  ) {
  }

  refresh(params: any): any {
    // throw new Error("Method not implemented.");
  }
  agInit(params: any): any {
  }

  public dataInit(): void {

    this.infoList = [
      { field_no: 'c_ent', field_name: '企业编号' },
      { field_no: 'c_user', field_name: '当前登录用户' },
      { field_no: 'c_per', field_name: '当前登录员工' },
      { field_no: 'c_lang', field_name: '当前语系' },
      { field_no: 'c_dept', field_name: '当前用户部门' },
      { field_no: 'c_org', field_name: '当前登录组织' },
      { field_no: 'TODAY', field_name: '当前日期' }
    ];

  }

  public change(event): void {
    console.log(event);
    if (event) {
      this.dataInit();
    }
  }



}
