import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, zip, of, Subject, ConnectableObservable} from 'rxjs';
import {filter, distinctUntilChanged, map, publishLast} from 'rxjs/operators';
import {FxDapHttpClient} from '../fx-http-client';
import {FormGroup, FormBuilder, Validators, FormControl, AsyncValidatorFn, ValidatorFn, AbstractControl, ValidationErrors, FormArray} from '@angular/forms';


// 作业隐藏字段（黑名单）
class FxFieldBlockItem {
  fieldNo: string;
  fieldName: string;

  constructor(fieldNo: string, fieldName: string) {
    this.fieldNo = fieldNo || '';
    this.fieldName = fieldName || '';
  }
}

// 作业隐藏字段集合（黑名单）
class FxFieldBlackList {
  [id: string]: Array<FxFieldBlockItem>;
}

@Injectable({
  providedIn: 'root'
})
export class FieldAuthorizedService {
  private restriction: any;
  private fieldAuthorizedSubject: BehaviorSubject<string>;
  private fieldPermissionInfo: any;
  private FAuthorizedId: string; // 作业编号
  private FDReponse: any; // 画面多语言返参
  private FHReponse: any; // 权限返参

  constructor(private http: FxDapHttpClient) {
  }

  /**
   * 設定功能權限資料
   *
   * @param dwAuthorizedId 作業權限ID
   * @param dwActionId 功能按鈕ID
   */
  public setActionAuth(dwAuthorizedId: string): void {
    this.restriction = null;
    this.fieldAuthorizedSubject = new BehaviorSubject<any>(this.restriction);
    let authorizedList: FxFieldBlackList; // 作業與功能權限
    this.getProgramHideField(dwAuthorizedId).pipe().subscribe(
      response => {
        authorizedList = <FxFieldBlackList>{'hideField': response.map(item => new FxFieldBlockItem(item.field_no, item.field_name))};
        const authorized: Array<FxFieldBlockItem> = authorizedList['hideField'];
        // && authorized.find(item => formGroup.controls[item.fieldNo] !== undefined)
        if (authorized && authorized.length > 0) {
          this.restriction = {
            style: 'hidden',
            authorized: authorized
          };
        }
        this.fieldAuthorizedSubject.next(this.restriction);
      },
      (error: any) => {
        authorizedList = {};
      }
    );
  }

  /**
   *
   * 提供訂閱功能權限資料
   */
  get fieldAuthorizedSubject$(): Observable<any> {
    return this.fieldAuthorizedSubject.asObservable().pipe(
      filter(obsData => obsData !== null), // 不廣播初始值
      distinctUntilChanged() // 有改變時才廣播
    );
  }

  getProgramHideField(programId: string): Observable<any> { // 权限
    return Observable.create(observer => {
      if (this.FAuthorizedId !== programId || !this.FDReponse) { // 不是当前作业或没有权限返参
        this.http.post('A/IACommonService/getProgramHideField', {param: {program_no: programId}}, {uiOptions: <any>{'loadMaskCfg': {spinning: false}}, headers: {'program-code': programId} }).subscribe(
          response => {
            if (response.success && response.data) {
              // observer.next({"hideField" : response.data.map(item => new FxFieldBlockItem(item.field_no, item.field_name))});
              observer.next(response.data);
            } else {
              observer.error();
            }
            observer.complete();
          }, error => {
            observer.error();
          }
        );
      } else {
        observer.next(this.FDReponse);
        observer.complete();
      }
    });
  }

  public getDisplayField(programNo: any): Observable<any> { // 画面多语言
    return this.http.post('A/IACommonService/getDisplayField', {uiOptions: <any>{'loadMaskCfg': {spinning: false}}, param: {programNo: programNo}}, { headers: {'program-code': programNo} });
  }

  /**
   * 設定表格栏位显示隐藏
   *
   * @param dwAuthorizedId 作業權限ID
   * @param columnApi
   * @param isMain  是否列表页
   */
  async setFieldAuth(dwAuthorizedId: string, columnApi: any, isMain: String): Promise<any> {
    return this.handleFieldAuth$(dwAuthorizedId, columnApi, isMain).toPromise();
  }

  private _getProgramHideField$ = (programNo: string): Observable<Array<string>> => {
    return this.http.post('A/IACommonService/getProgramHideField', {param: {program_no: programNo}}, {uiOptions: <any>{'loadMaskCfg': {spinning: false}}, headers: {'program-code': programNo} }).pipe(map(response => (
      (response.success && response.data) ? response.data.map(item => (item.field_no)) : []
    )));
  }
  private _hideFieldSubjectMap = new Map();
  private getProgramHideField$ = (programNo: string): Observable<any> => {
     if (!this._hideFieldSubjectMap.has(programNo)) {
       const _$ = (this._getProgramHideField$(programNo)).pipe(publishLast());
       (_$ as ConnectableObservable<any>).connect();
       this._hideFieldSubjectMap.set(programNo, _$);
     }
     return this._hideFieldSubjectMap.get(programNo);
  }

  private _getDisplayField$(programNo: any): Observable<Array<string>> { // 画面多语言
    return this.http.post('A/IACommonService/getDisplayField', {param: {programNo: programNo}}, {uiOptions: <any>{'loadMaskCfg': {spinning: false}}, headers: {'program-code': programNo} }).pipe(map(response => (
      (response.success && response.data) ? response.data.map(item => (item.aaf002)) : []
    )));
  }
  private _displayFieldSubjectMap = new Map();
  private getDisplayField$ = (programNo: string): Observable<any> => {
    if (!this._displayFieldSubjectMap.has(programNo)) {
      const _$ = (this._getDisplayField$(programNo)).pipe(publishLast());
      (_$ as ConnectableObservable<any>).connect();
      this._displayFieldSubjectMap.set(programNo, _$);
    }
    return this._displayFieldSubjectMap.get(programNo);
  }

  private mergeFieldAuth(dwAuthorizedId: string, isMain: String): Observable<Array<string>> {
    return zip(this.getDisplayField$(dwAuthorizedId), this.getProgramHideField$(dwAuthorizedId))
      .pipe(map(([displayField, hideField]) => ({displayField, hideField})))
      .pipe(map(data => {
        const _hideFieldMap = data.hideField.reduce((obj, item) => ({...obj, [item]: 1}), {});
        return data.displayField.filter(item => (!_hideFieldMap[item]));
      }));
  }

  private handleFieldAuth$(dwAuthorizedId: string, columnApi: any, isMain: String): Observable<Array<string>> {
    if (!columnApi) {
      return of([]);
    }
    const _allFields = columnApi.columnController.columnDefs.filter(item => (item.field !== 'operation')).map(item => item.field);
    const _displayedFields = columnApi.columnController.columnDefs.filter(item => (!item.hide && item.field !== 'operation')).map(item => item.field);
    return new Observable((observer): void => {
      if (isMain === 'Y') {
        this.mergeFieldAuth(dwAuthorizedId, isMain).subscribe((availableFields: Array<string>) => {
          // 取到权限信息，显示有权限栏位
          const _mapAvailableFields = availableFields.reduce((obj, item) => ({...obj, [item]: 1}), {});
          // 可以显示的条件： AvailableFields 中有或者 field 是数字
          _displayedFields.forEach(item => (((!_mapAvailableFields[item]) && isNaN(item * 1))
            ? columnApi.setColumnVisible(item, false)
            : columnApi.setColumnVisible(item, true)));
          observer.next(availableFields);
          observer.complete();
        }, err => {
          // 没取到权限信息隐藏全部
          _displayedFields.forEach(item => columnApi.setColumnVisible(item, false));
          observer.next([]);
          observer.complete();
        });
      } else {
        this.getProgramHideField$(dwAuthorizedId).subscribe(hideFields => {
          const _hideFieldMap = hideFields.reduce((obj, item) => ({...obj, [item]: 1}), {});
          const availableFields = _allFields.filter(item => (!_hideFieldMap[item]));
          hideFields.forEach(item => columnApi.setColumnVisible(item, false));
          // availableFields.forEach(item => columnApi.setColumnVisible(item, true));
          observer.next(availableFields);
          observer.complete();
        }, err => {
          _displayedFields.forEach(item => columnApi.setColumnVisible(item, false));
          observer.next([]);
          observer.complete();
        });
      }

    }); // return new Observable
  } // private handleFieldAuth$
}
