import { Observable } from 'rxjs';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxBaseModel } from '../../fxbase/fx-base.model';

export abstract class FxMasterService extends FxBaseModel {

   // getDisplayField
   public getDisplayField( programNo: any): Observable<any> {
      return this.http.post('A/IACommonService/getDisplayField', {param: {programNo: programNo}});
    }

    constructor(public http: FxDapHttpClient) {
       super(http);
    }

}
