import {EventEmitter, Injectable, Optional} from '@angular/core';
import {Observable, Subject } from 'rxjs';

export interface IAgGridEditorEvent {
  gridId: string;
  colId: string;
  rowIndex: number;
  id: string;
  value: any;
  controlName: string;
  dataId?: any;
}

export interface IAgGridEditorEventHandler {
  // valueSet: EventEmitter<{ gridId: string, colId: string, value: any, message?: string}>;
  valueSet: Subject<{gridId: string, colId: string, value: any, message?: string, id?: string}>;
  beforeField: EventEmitter<IAgGridEditorEvent>;
  afterField: EventEmitter<IAgGridEditorEvent>;
  changeField: EventEmitter<IAgGridEditorEvent>;
  openButtonClicked: EventEmitter<IAgGridEditorEvent>;
  modifyButtonClicked: EventEmitter<IAgGridEditorEvent>;
  deleteButtonClicked: EventEmitter<IAgGridEditorEvent>;
  cancelButtonClicked: EventEmitter<IAgGridEditorEvent>;
  saveButtonClicked: EventEmitter<IAgGridEditorEvent>;
  upButtonClicked: EventEmitter<IAgGridEditorEvent>;
  downButtonClicked: EventEmitter<IAgGridEditorEvent>;
  detailButtonClicked: EventEmitter<IAgGridEditorEvent>;
}

@Injectable()
export class FxAgGridEventHandlerService implements IAgGridEditorEventHandler {

  constructor() {
    console.log('建立新的AgGridEditorEventHandlerService');
  }

  /**
   * 由編輯元件訂閱賦值的事件 (EventEmitter改為Subject也行，只是用EventEmitter更符合該行為)
   */
  // valueSet: EventEmitter<{gridId: string, colId: string, value: any, message?: string}> = new EventEmitter<{gridId: string, colId: string, value: any, message?: string}>();


  valueSet: Subject<{gridId: string, colId: string, value: any, message?: string, id?: string}> = new Subject<{gridId: string, colId: string, value: any, message?: string, id?: string}>();

  /**
   * 作業中訂閱離開輸入字段的事件 (EventEmitter改為Subject也行，只是用EventEmitter更符合該行為)
   */
  afterField: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();

  /**
   * 作業中訂閱進入輸入字段的事件 (EventEmitter改為Subject也行，只是用EventEmitter更符合該行為)
   */
  beforeField: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();

/**
   * 作业中订阅选项发生变更的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   */
  changeField: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();


/**
   * 作业中订阅开窗发生变更的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   * @type {EventEmitter<IAgGridEditorEvent>}
   * @memberof FxAgGridEventHandlerService
   */
  openButtonClicked: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();

/**
   * 作业中修改的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   * @type {EventEmitter<IAgGridEditorEvent>}
   * @memberof FxAgGridEventHandlerService
   */
  modifyButtonClicked: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();

/**
   * 作业中删除的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   * @type {EventEmitter<IAgGridEditorEvent>}
   * @memberof FxAgGridEventHandlerService
   */
  deleteButtonClicked: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();

/**
   * 作业中取消的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   * @type {EventEmitter<IAgGridEditorEvent>}
   * @memberof FxAgGridEventHandlerService
   */
  cancelButtonClicked: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();

/**
   * 作业中保存的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   * @type {EventEmitter<IAgGridEditorEvent>}
   * @memberof FxAgGridEventHandlerService
   */
  saveButtonClicked: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();

/**
   * 作业中增加按钮的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   * @type {EventEmitter<IAgGridEditorEvent>}
   * @memberof FxAgGridEventHandlerService
   */
  upButtonClicked: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();

/**
   * 作业中减少按钮的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   * @type {EventEmitter<IAgGridEditorEvent>}
   * @memberof FxAgGridEventHandlerService
   */
  downButtonClicked: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();


/**
   * 作业中详情按钮的事件 (EventEmitter改为Subject也行，只是用EventEmitter更符合该行为)
   * @type {EventEmitter<IAgGridEditorEvent>}
   * @memberof FxAgGridEventHandlerService
   */
  detailButtonClicked: EventEmitter<IAgGridEditorEvent> = new EventEmitter<IAgGridEditorEvent>();
  /**
   * 作業利用此方法將值給輸入元件
   */
  setValue(gridId: string, colId: string, value: any,  message?: string,  id?: string ): void {
    // this.valueSet.emit({gridId, colId, value, message});
    this.valueSet.next({gridId, colId, value, message, id});
  }

  /**
   * 輸入元件利用此方法發送事件
   */
  onAfterField(event: IAgGridEditorEvent): void {
    setTimeout(() => {
      this.afterField.emit(event);
    }, 0);
  }

  /**
   * 輸入元件利用此方法發送事件
   */
  onBeforeField(event: IAgGridEditorEvent): void {
    this.beforeField.emit(event);
  }
  /**
   * 选择元件利用此方法发送变更事件
   */
  onChangeField(event: IAgGridEditorEvent): void {
    setTimeout(() => {
      this.changeField.emit(event);
    }, 0);
  }

  /**
   * 开窗元件利用此方法发送开窗事件
   */
  onClickOpenButton(event: IAgGridEditorEvent): void {
    this.openButtonClicked.emit(event);
  }

  /**
   * 操作元件利用此方法发送修改事件
  */
  onClickModifyButton(event: IAgGridEditorEvent): void {
    this.modifyButtonClicked.emit(event);
  }

 /**
   * 操作元件利用此方法发送删除事件
  */
  onClickDeleteButton(event: IAgGridEditorEvent): void {
    this.deleteButtonClicked.emit(event);
  }

 /**
   * 操作元件利用此方法发送取消事件
  */
 onClickCancelButton(event: IAgGridEditorEvent): void {
  this.cancelButtonClicked.emit(event);
}

 /**
   * 操作元件利用此方法发送保存事件
  */
 onClickSaveButton(event: IAgGridEditorEvent): void {
  this.saveButtonClicked.emit(event);
}

 /**
   * 操作元件利用此方法发送增加事件
  */
 onClickUpButton(event: IAgGridEditorEvent): void {
  this.upButtonClicked.emit(event);
}

 /**
   * 操作元件利用此方法发送减少事件
  */
 onClickDownButton(event: IAgGridEditorEvent): void {
  this.downButtonClicked.emit(event);
}

/**
   * 操作元件利用此方法发送事件
  */
 onClickDetailButton(event: IAgGridEditorEvent): void {
  this.detailButtonClicked.emit(event);
}

  /**
   * 作業在ngOnDestroy方法中調用此方法，終止所有訂閱
   */
  destroy(): void {
    this.valueSet.complete();
    this.beforeField.complete();
    this.afterField.complete();
    this.changeField.complete();
    this.openButtonClicked.complete();
    this.modifyButtonClicked.complete();
    this.deleteButtonClicked.complete();
    this.cancelButtonClicked.complete();
    this.saveButtonClicked.complete();
  }

}
