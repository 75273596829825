import { Component, ElementRef, OnDestroy, ViewChild , Optional, NgModule} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormBuilder, FormControl } from '@angular/forms';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';
import {Subscription} from 'rxjs';
import { Observable, of, ConnectableObservable } from 'rxjs';
import { concatMap, publishLast } from 'rxjs/operators';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import {fxButtonEditFilterPipe} from 'app/implementation/shared/components/pipe/fx-button-edit-filter.pipe';
import { DOWN_ARROW, ENTER, ESCAPE, TAB, SPACE, UP_ARROW } from '@angular/cdk/keycodes';

@Component({
  selector: 'fx-country-button-editor',
  template: `
    <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
         [dwTitle]="errorContent" [dwVisible]="errorVisible"
         *ngIf="errorVisible"></div>
    <ng-template #errorContent>
      <div *ngFor="let msg of validationMessages">{{ message }} {{ msg.key | translate:msg.params }}</div>
    </ng-template>
    <form [formGroup]="validateForm">
      <dw-form-control>
        <dw-input-group dwSearch [dwSuffix]="suffix_2">
          <input [ngStyle]="input2Style" [formControl]="formControl" (keydown)="onKeyDown($event)" (ngModelChange)="filterData()" dw-input placeholder="" [attr.disabled]="dwDisabled?'':null" #cellInput  [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option [hidden]="params.disableCheck || !matOptionShow" *ngFor="let option of options | async | fxButtonEditFilter: formControl.value" [value]="option.id" [ngClass]="{'active': option.id == activeId}" (mouseenter)="onMouseEnter(option, $event)" (mousedown)="setData(option)">
                {{option.id | appendName: option.name }}
            </mat-option>
          </mat-autocomplete>
        </dw-input-group>
      </dw-form-control>
    </form>
    <ng-template #suffix_2>
      <button *ngIf="!params.isBtnHide" dw-button type="button" dwType="primary" dwSearch [ngStyle]="btn2Style" (click)="openModal()">
        <i class="anticon anticon-search"></i>
      </button>
      <button *ngIf="params.isBtn2Show" dw-button type="button" style="margin-left:0px;" (click)="openModal2()">
        <i class="anticon anticon-down-circle-o"></i>
      </button>
    </ng-template>
    
  `,
  styles: [
      `.ant-input {
      //padding: 4px 11px 0 11px;
    }

    .ant-input-affix-wrapper {
      top: -5px;
    }
    .mat-option {
      line-height: normal !important;
      height: auto !important;
      min-height: 32px;
      white-space: normal !important;
      padding: 3px 16px !important;
      background-color: #fff !important;
    }
    .mat-option.active {
      background-color: #d9d9d9 !important;
    }
    `
  ],
  providers: [fxButtonEditFilterPipe]
})
export class FxCellButtonEditorComponent extends FxAgGridValidationEditorComponent
  implements ICellEditorAngularComp, OnDestroy {
  colId;
  name: string;
  editParams: any;
  modalService;
  multiSelection = false;
  btn2Style: any;
  input2Style: any;
  @ViewChild('cellInput') cellInput: ElementRef;
  params: ICellEditorParams;  // 存放cellEditorParams   Added By David Lee 18Feb2019
  valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  message: string;

  dwDisabled: boolean = false;  // add by canon

  matOptionShow: boolean = false;  // add by quwj
  options: Observable<any>;
  filteredOptions: Array<any>;
  isReGetDropDownData: boolean = true; // 是否重新获取开窗下拉的值，默认true
  activeId: any; // 记录鼠标移动到的数据id
  isBackendPaging: Boolean = false; // 是否后端分页，默认非后端分页

  constructor(private fb: FormBuilder, @Optional() private eventHandler: FxAgGridEventHandlerService,public http: FxDapHttpClient,public fxModalService: FxModalService, public fxButtonEditFilterPipe: fxButtonEditFilterPipe) {
    super();
  }

  onKeyDown(event): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38 
      || event.keyCode === 39 || event.keyCode === 40) {
        event.stopPropagation();
    }

    if (event.keyCode === ENTER || event.keyCode === TAB) {
      if (this.activeId && this.matOptionShow && this.filteredOptions && !this.params['disableCheck'] && !this.isBackendPaging) {
        if (this.activeId !== this.formControl.value) {
          this.formControl.setValue(this.activeId);
        }
      }
      this.cellInput.nativeElement.onchange();
    }
    // 绑定上下键事件
    if (event.keyCode === DOWN_ARROW || event.keyCode === UP_ARROW) {
      if (!this.activeId) {
        this.options.subscribe(data => {
          if (data.length) {
            this.onMouseEnter(data[0]);
          }
        });
      } else {
        this.options.subscribe(data => {
          if (data.length) {
            const idx = data.findIndex(item => {
              return item.id === this.activeId;
            });
            if (idx !== -1) {
              const newIdx = event.keyCode === DOWN_ARROW ? (idx + 1) : (idx - 1)
              if (newIdx <= (data.length -1) && newIdx >= 0) {
                this.onMouseEnter(data[newIdx]);
                this.scrollIntoView(newIdx);
              } else if (newIdx <= (data.length -1) && newIdx < 0){
                this.onMouseEnter(data[data.length -1]);
                this.scrollIntoView(data.length -1);
              } else {
                this.onMouseEnter(data[0]);
                this.scrollIntoView(0);
              }
            }else {
              this.onMouseEnter(data[0]);
              this.scrollIntoView(0);
            } 
          }
        });
      }
    }
  }

  // 滚动条位置
  scrollIntoView(Idx) {
    var height = 32; // 下拉每条数据的高度
    if (document.querySelector('.mat-autocomplete-panel')) {
      document.querySelector('.mat-autocomplete-panel').scrollTop = (height * Idx); 
    }
  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.params = params;  // 把cellEditorParams参数赋给params Added By David Lee 18Feb2019
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    const currentValue = this.valueTransfer(params.value);
    this.colId = params.column.colId;
    this.editParams = params;
    this.modalService = params['modalService'];
    this.formControl = new FormControl(currentValue, params.validators);
    this.validateForm = params.form;
    this.dwDisabled = params.dwDisabled;
    if (this.params['isBtn2Show'] && !this.params['isBtnHide']) {
      this.btn2Style = {"border-left": "1px solid #d9d9d9"};
      this.input2Style = {"padding-right": "57px"};
    }
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    // 變更開窗的設定
    // if (this.modalService) {
    //   this.modalService.config.tableMultiSelect = !!params.tableMultiSelect;
    //   this.modalService.config.tableIdField = 'name';
    //   this.multiSelection = this.modalService.config ? this.modalService.config.tableMultiSelect : false;
    // }

    this.name = this.params.node.id + '-' + params.column.colId;

    if ( this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);
    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }


    this.formControl.markAsDirty();

    // 监听赋值 Add David lee 25 Feb 2019
    this.valueSetSubscription = this.eventHandler.valueSet.subscribe(event => {
      if ( (event.colId === this.colId) && (event.gridId === params.context.id) && (!event.id || (event.id && event.id === params.node.id))) {
        // if ( (event.colId === this.colId) && (event.gridId === params.context.id) ) {
          this.formControl.setValue(event.value);
          this.activeId = event.value;
          this.message = event.message;
          this.formControl.markAsDirty();
      }
    });


      // 通过订阅消息回传onFocus事件
  this.cellInput.nativeElement.onfocus = (e: any): void => {
    if (this.eventHandler) {
      this.eventHandler.onBeforeField({
        gridId: this.params.context.id,
        value: this.formControl.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
      });
    }
    this.getDropDownList();
    this.matOptionShow = true;
};

// 通过订阅消息回传onChange事件
this.cellInput.nativeElement.onchange = (event): void => {  
  if (this.eventHandler) {
    this.activeId = this.formControl.value;
    this.eventHandler.onChangeField ({
      gridId: this.params.context.id,
      value: this.formControl.value,
      rowIndex: this.params.node.rowIndex,
      id: this.params.node.id,
      colId: this.colId,
      controlName: this.name
      });
  }
  this.getDropDownList();
};

// 通过订阅消息回传onBlur事件
  this.cellInput.nativeElement.onblur = (e: any): void => {
    if (this.eventHandler) {
      this.eventHandler.onAfterField({
        gridId: this.params.context.id,
        value: this.formControl.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
      });
    }
    this.matOptionShow = false;
};

    super.init();
  }

  openModal(): void {
    if (this.eventHandler) {
      this.eventHandler.onBeforeField({
        gridId: this.params.context.id,
        value: this.formControl.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
      });
      this.eventHandler.onClickOpenButton({
        gridId: this.params.context.id,
        value: this.formControl.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
      });
    }

    // if (this.modalService) {
    //   if (this.eventHandler) {
    //     this.eventHandler.onBeforeField({
    //       value:  this.formControl.value,
    //       rowIndex: this.params.node.rowIndex,
    //       columnId: this.columnId
    //       });
    //   }
    //   // this.modalService.open(this.formControl.value).subscribe(
    //   this.modalService.searchFx({
    //     modalID: 'qry_aah001',     // (必传) 开窗的编号
    //     postParam: []               // (必传) 查询参数，注意是数组
    //   }).subscribe(
    //     (result) => {
    //       this.setSelectedValue(result[0].aah001);
    //       this.eventHandler.onChangeField({
    //         value:  this.formControl.value,
    //         rowIndex: this.params.node.rowIndex,
    //         columnId: this.columnId
    //         });
    //       this.eventHandler.onAfterField({
    //           value:  this.formControl.value,
    //           rowIndex: this.params.node.rowIndex,
    //           columnId: this.columnId
    //           });
    //     }
    //   );
    // }
  }

  openModal2(): void {
    this.params['clickBtn2']({
      gridId: this.params.context.id,
      value: this.formControl.value,
      rowIndex: this.params.node.rowIndex,
      id: this.params.node.id,
      colId: this.colId,
      controlName: this.name
    });
  }
  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    if (this.cellInput && !this.isFullRowEdit()) {
      this.cellInput.nativeElement.focus();
    }
  }

  private valueTransfer(value: any): any {
    let currentValue = value;
    if (this.multiSelection) {
      if (typeof value === 'string') {
        currentValue = value.split(',').map(
          v => v.trim()
        );
      }
    } else {
      if (Array.isArray(value)) {
        currentValue = value.join();
      }
    }
    return currentValue;
  }

  getSelectedValue(): any {
    return this.formControl.value;
  }

  setSelectedValue(value: any): any {
    this.formControl.setValue(value);
  }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
    this.cellInput.nativeElement.focus();
    // if (this.eventHandler) {
    //  this.eventHandler.onBeforeField({
    //    gridId: this.params.context.id,
    //    value: this.formControl.value,
    //    rowIndex: this.params.node.rowIndex,
    //    id: this.params.node.id,
    //    colId: this.colId,
    //    controlName: this.name
    //  });
    // }
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    this.cellInput.nativeElement.blur();
    // if (this.eventHandler) {
    //  this.eventHandler.onAfterField({
    //    gridId: this.params.context.id,
    //    value: this.formControl.value,
    //    rowIndex: this.params.node.rowIndex,
    //    id: this.params.node.id,
    //    colId: this.colId,
    //    controlName: this.name
    //  });
    // }
  }

  // 输入框变动则获取过滤的数据
  filterData(): void {
    setTimeout(() => {
      if (this.filteredOptions && this.filteredOptions.length) {
        const filterList = this.fxButtonEditFilterPipe.transform(this.filteredOptions, this.formControl['_pendingValue']);
        if (filterList && filterList.length) {
          this.activeId = filterList[0].id;
        }
       }
    }, 500);     
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.getSelectedValue();
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.name);
    // 当销毁时取消所有订阅
    if (this.valueSetSubscription) {
      this.valueSetSubscription.unsubscribe();
    }
  }

  // 获取下拉获取数据
  getData(): Observable<any> {
    return Observable.create(observer => {
      this.fxModalService.searchFx(this.params['param']['dropDownParam']).subscribe(
        (data) => {
          const result = data.map(item => {
            item.id = item[this.params['param']['id']];
            item.name = item[this.params['param']['name']];
            return item;
          });
          this.filteredOptions = JSON.parse(JSON.stringify(result));
          // 后端分页则不用下拉否则使用下拉
          if (result[0].isBackendPaging) {
            this.isBackendPaging = true;
            observer.next([]);
          } else {
            observer.next(result);
          }
          observer.complete();
        },
        (error) => {
          observer.next([]);
          observer.complete();
        }
      );
    });
  }

  // 设置开窗下拉列表数据
  getDropDownList(): void {
    // 不设置pram则不会有下拉
    if(!this.params['param']){
      return;
    }
    if ((!this.isReGetDropDownData && !this.params['param']['reGetData']) || this.isBackendPaging) {
      return;
    }
    this.isReGetDropDownData = false;
    if (!this.params['param']['customMethod']) {
      this.options = this.getData().pipe(publishLast());
      (this.options as ConnectableObservable<any>).connect();     
    } else {
      this.options = this.http.post(this.params['param']['customMethod'], this.params['param']['customParam'])
        .pipe(concatMap(res => {
          var data;  // 存放服务返回的数据
         
          data = res.data || [];          
          const result = data.map(item => {
            item.id = item[this.params['param']['id']];
            item.name = item[this.params['param']['name']];
            return item;
          });
          this.filteredOptions = JSON.parse(JSON.stringify(result));
          return of(result);
        })).pipe(
          publishLast()
        );
      (this.options as ConnectableObservable<any>).connect();      
    }
  };

  // 鼠标移入设置activeId
  onMouseEnter(item: any, event?: any): void {
    this.activeId = item.id;
  };

  // 点击下拉框的数据给input赋值
  setData(option: any): void {
    if(this.formControl.value !== option.id) {
      this.setSelectedValue(option.id);
      this.cellInput.nativeElement.onchange();
    }
    
  };

}
