import { Injectable,EventEmitter } from '@angular/core';
import { Observable,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MinLogoService {


  public eventEmit: any;

  constructor() { 
    // 定义发射事件
    this.eventEmit = new EventEmitter();
  }
}
