import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DwModalService } from 'ng-quicksilver';

import { DwAuthService, DwUserService } from '../../../auth';
import { APP_DEFAULT, DW_MULTI_TENANT, DW_USING_TAB } from '../../../config/system.config';
import { DwLanguageService, DwLanguageListService, IDwLanguageList } from '../../language';
import { LocalStorage } from '../../../storage/local-storage';



@Component({
  selector: 'dw-login-block',
  templateUrl: './login-block.component.html',
  styleUrls: ['./login-block.component.css']
})

export class DwLoginBlockComponent implements OnInit {
  language: string; // 语言
  remember_me: boolean; // 记住我
  languageList: IDwLanguageList[]; // 取可用語言清單
  user = { tenantId: '', userId: '', password: '' }; // 使用者应输入
  validateForm: FormGroup;
  returnUrl: string;
  public isDisabled: boolean = false; // 是否允許按送出.

  @Input() showRemember: boolean;
  @Input() showLanguage: boolean;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fBuilder: FormBuilder,
    private dwModalService: DwModalService,
    private authService: DwAuthService,
    private userService: DwUserService,
    private storage: LocalStorage,
    private languageListService: DwLanguageListService,
    private languageService: DwLanguageService,
    @Inject(APP_DEFAULT) private defaultApp: any,
    @Inject(DW_USING_TAB) private _usingTab: boolean,
    @Inject(DW_MULTI_TENANT) public dwMultiTenant: boolean
  ) {

  }

  // public logining(): Observable<boolean> {
  //   return Observable.create((observer) => {
  //     setTimeout(() => {
  //     // observer.next(false);
  //     observer.next(true);
  //     observer.complete();
  //     }, 1000);
  //   });
  // }

  /**
   * form 验证按钮
  */
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(i)) {
        this.validateForm.controls[i].markAsDirty();
      }
    }
  }


  /**
   * 登入
   */
  login(): void {
    this.user = {
      tenantId: this.validateForm.get('tenantId').value,
      userId: this.validateForm.get('userId').value,
      password: this.validateForm.get('password').value
    };

    // this.authService.registerEvent(this);
    this.isDisabled = true;

    this.authService.login(this.user).subscribe(
      data => {
        // this.authService.unregisterEvent(this);
        this.isDisabled = false;

        if (!data.success) {
          this.dwModalService.error({
            dwContent: data.description,
          });
          return;
        }

        this.afterLogin();

        // // 在開出登入事件接口前，暫時的FineReport SSO 代碼
        // if (this.usingFrSSO) {
        //   const url = `${this.frUrl}?op=fs_load&cmd=sso&fr_username=${this.user.userId}&fr_password=${data.token}`;
        //   this.http.jsonp(url, 'callback').subscribe();
        // }
      }
    );
  }

  /**
   * 没有使用 iam api 时取 mock 资料
   * 有其他登入链结应修正 loginservice 中的 getUserInfo()
   */
  public afterLogin(): void {
    const user_id = this.validateForm.get('userId').value;
    const ent = this.validateForm.get('tenantId').value;
    this.userService.read(user_id, ent).subscribe(
      data => {
        if (!data['success']) {
          this.dwModalService.error({
            dwContent: data['description'],
          });
          return;
        }

        const remember = this.validateForm.get('remember').value;

        const tenantId = (remember) ? this.validateForm.get('tenantId').value : '';
        const userId = (remember) ? this.validateForm.get('userId').value : '';
        const userName = (remember) ? data['userInfo']['userName'] : '';

        this.storage.set('rememberLogin', remember);
        this.storage.set('tenantId', tenantId);
        this.storage.set('userId', userId);
        this.storage.set('userName', userName);

        // FineReport 的 SSO login, 因為需要傳 tenantId, 所以只能寫在 getUserInfo/read 之後.
        this.authService.fineReportlogin();

        // login 後的導頁.
        this.loginedForwardUrl(this.returnUrl);

      }, error => {
        console.log(error);
      }
    );
  }

  /**
   * todo 目前寫在 2 個地方, 應該集中到 dw-tab-routing 去.
   * [登入後]的要導頁的 url.
   *
   * param {string} returnUrl: 導頁的 url.
   */
  public loginedForwardUrl(returnUrl: string): void {
    if (!this._usingTab) {
      this.router.navigateByUrl(returnUrl);
      return;
    }

    if (!returnUrl || returnUrl === '/') {
      this.router.navigateByUrl('/dwTabRouting');
      return;
    }

    if (returnUrl.search('/dwTabRouting') === -1) {
      if (returnUrl.substr(0, 1) !== '/') {
        returnUrl = '/' + returnUrl;
      }
      returnUrl = '/dwTabRouting' + returnUrl;
      this.router.navigateByUrl(returnUrl);
      return;
    }

    this.router.navigateByUrl(returnUrl);
  }



  /**
   * 翻译 function
   *
   */
  public switchLanguage(language: string): void {
    this.languageService.setUp(language);
  }


  ngOnInit(): void {
    // 存在 storage 可能為字串, 需轉型成 boolean;
    const remember = JSON.parse(this.storage.get('rememberLogin')) ? JSON.parse(this.storage.get('rememberLogin')) : false;
    const language = this.languageService.currentLanguage;
    const sTenantId = this.storage.get('tenantId') ? this.storage.get('tenantId') : '';
    const sAccount = this.storage.get('userId') ? this.storage.get('userId') : '';
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || this.defaultApp;

    // tenantId 的驗證.
    const tenantIdValidators = (this.dwMultiTenant) ? [Validators.required] : [];

    this.validateForm = this.fBuilder.group({
      tenantId: [sTenantId, tenantIdValidators],
      userId: [sAccount, [Validators.required]],
      password: [null, [Validators.required]],
      language: [language, [Validators.required]],
      remember: [remember]
    });

    // 顯示下拉語系.
    this.languageListService.getLanguagesList().subscribe(
      (list: IDwLanguageList[]) => {
        this.languageList = list;
      }
    );

    // 切換語系.
    this.validateForm.controls.language.valueChanges.subscribe(
      (value: string) => {
        this.switchLanguage(value);
      }
    );
    // 取得最新語言別
    this.languageService.language$.subscribe(
      lang => {
        this.language = lang;
      }
    );

  }

  // 忘記密碼.
  forget(): void {
    this.router.navigateByUrl('/forget');
  }
}
