import { Component, OnInit, Input } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'text-modal',
  template: 
  `
  <div class="fx_modal_container" appModalDrag style="margin: 15px;">
    <dw-form-textarea [(ngModel)]="textvalue" rows="10">
    </dw-form-textarea>
  </div>
  `,
  styles: [
    `
    `
  ]
})
export class textModalComponent implements OnInit  {
  @Input() textvalue: any;

  constructor(private modalSubject: DwModalRef, private fb: FormBuilder) {}

  ngOnInit(): void {
    console.log(this.textvalue);
  }

}