import { FormArray, AbstractControl, ValidatorFn, AsyncValidatorFn, FormBuilder } from '@angular/forms';
import { DwDataRow } from './data-row';


export class DwDataTable extends FormArray {
  constructor(datasource: any) {
    const controls: AbstractControl[] = DwDataTable.parseToControl(datasource);
    super(controls);
  }

  get rows(): DwDataRow[] {
    return (this.controls as DwDataRow[]);
  }

  insertRow (index: number, data: object): void {
    const control: DwDataRow = new DwDataRow(data);
    control.markAsCreate();
    this.insert(index, control);
  }

  pushRow(data: object): void {
    const control: DwDataRow = new DwDataRow(data);
    control.markAsCreate();
    this.push(control);
  }

  updateRow (index: number, data: object): void {
    const control: DwDataRow = new DwDataRow(data);
    this.controls[index] = control;
    control.markAsUpdate();
  }

  deleteRow (index: number): void {
    if (this.controls[index]) {
      (this.controls[index] as DwDataRow).markAsDelete();
    }
  }

  static parseToControl(data: any): AbstractControl[] {
    const controls: AbstractControl[] = [];
    for (const rowdata of data) {
      const row = new DwDataRow(rowdata);
      controls.push(row);
    }

    return controls;
  }
}
