import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FxDapHttpClient } from '../../fx-http-client';
import { ActivatedRoute } from '@angular/router';
import { DwRoutingMessageService } from '@webdpt/framework';
import { UploadFile, UploadXHRArgs } from 'ng-quicksilver';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';


@Component({
  selector: 'fx-import',
  templateUrl: './fx-import.component.html',
  styleUrls: ['./fx-import.component.less']
})
export class FxImportComponent implements OnInit {
  @Input() importData: any; // 模板下载出资料
  @Input() warningMsg: any; // 导入后提示信息
  @Input() btnData: any;      // 按钮资料
  @Input() isMoreBtn: any;    // 判断是否是更多按钮
  @Output() researchData = new EventEmitter();     // 按钮资料
  public programId: string = '';   // 作业编号
  public importList: any = [];  // 模板下载列表
  public getImportTemplateListFinish = false; // 是否已取模板下载清单
  public fileList: UploadFile[] = [];
  public templateCode: string = '';   // 记录上传的模板编号


  constructor(
    public http: FxDapHttpClient,
    private translateService: TranslateService,
    protected route: ActivatedRoute,
    public dwMessage: DwRoutingMessageService,
    public fxModalService: FxModalService
  ) {
  }

  ngOnInit() {
    // 通过路由获取作业编号
    this.programId = this.route.snapshot['_routerState'].url.split(';')[0].replace('/dwTabRouting/', '');

    // 获取模板下载列表
    this.getImportList();
  }

  // 模板下载
  public getImportList() {

    // 获取数据模板下载
    this.importList = this.btnData.importTemplateList ? this.btnData.importTemplateList : [];
    this.importList.forEach(importItem => {
      importItem.name = importItem.abx001;
    });
    if (this.importList.length === 0) {
      this.templateCode = '';
    } else if (this.importList.length === 1) {
      this.templateCode = this.importList[0].abxdocno;
    } else if (this.importList.length > 1) {
      this.templateCode = '';
    }
  };

  // 导入文件
  importFile = (item: UploadXHRArgs) => {
    // 判断模板编号是否为空
    if (!this.templateCode) {
      this.dwMessage.errorNew(this.translateService.instant('com-templat-error'), this.translateService.instant('com-templat-error'), "", "");
      return;
    }
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    formData.append('attach', item.file as any);
    // 获取当前导入按钮是否有对应自定义模板
    let importItem = [];
    if (this.importData && this.importData.length) {
      importItem = this.importData.filter(obj => {
        return obj.id === this.btnData.id;
      })
    }

    this.http.post('A/IAttachFileService/uploadFile', formData).subscribe(response => {
      if (response.successValue) {
        item.onSuccess!(response.data, item.file!, '');
        var url = response.data[0].url;
        var param = {
          url: url, // 上传文件的url--必填
          importToDb: (importItem && importItem.length && importItem[0].importToDb) ? importItem[0].importToDb : 'Y',  // 是否需要导入到数据库,默认导入
          // api: (importItem && importItem.length && importItem[0].api) ? importItem[0].api : '',  //(自定义模板必填)
          params: (importItem && importItem.length && importItem[0].params) ? importItem[0].params : {},  // (自定义模板必填,为自定义导入的参数)
          templateCode: this.templateCode  // （标准模板必填）
        };
        this.http.post('A/IStandardImportExcelService/importFileToDB', { param: param }).subscribe(response => {
          if (this.warningMsg) {
            this.researchData.emit(response);
          } else {
            this.fxModalService.batch({
              batchResult: response.collector.batchResult,
              errorInfos: response.collector.errorInfos,
              okFn: (data: any): void => {
                this.researchData.emit(true);
              },
              cancelFn: (): void => {
                this.researchData.emit(true);
              }
            });
          }
        });
      } else {
        this.dwMessage.errorNew(response.message, response.isRemind, response.programNo, response.programName);
      }
    });
  }

  // 点击导入按钮
  clickImportBtn(data?: any): void {
    if (this.importList.length) { // 已获取模板下载清单
      if (data) {
        this.templateCode = data.abxdocno;
      }
      if (this.btnData.beforeCheck) { // 作业添加导入前检查方法
        this.btnData.beforeCheck().subscribe(response => {
          if (response) {
            const uploadId = this.btnData.uploadId ? this.btnData.uploadId : "fx_import_upload";
            document.getElementById(uploadId).click();
          }
        })
      } else {
        const uploadId = this.btnData.uploadId ? this.btnData.uploadId : "fx_import_upload";
        document.getElementById(uploadId).click();
      }
    } else {
      this.dwMessage.errorNew(this.translateService.instant('com-templat-get'), this.translateService.instant('com-templat-get'), "", "");
    }
  };

}
