import {Observable} from 'rxjs';

export class CfcTextRendererComponent {
  private eGui;

  constructor() { }

  public init(params: any): void {
    this.eGui = document.createElement('span');
    this.eGui.style.display = 'flex';
    this.eGui.style.height = '100%';
    this.eGui.style.margin = '0 0';
    // todo: 这种做法有问题，只有 Subject 订阅早于 grid init 才可以... 吧
    if (params.values as Observable<any>|Array<any>) {
      if (Array.isArray(params.values)) {
        this.eGui.innerHTML = (params.values.find(item => item.value === params.value) || {}).label || '';
      } else {
        // 传入 Observable 对象，此时
        try {
          params.values.subscribe(items => {
            this.eGui.innerHTML = (items.find(item => item.value === params.value) || {}).label || '';
          });
        } catch (e) {
          console.error(e);
        }
      }
    } else if ((undefined !== params.value) && (null !== params.value)) {
      this.eGui.innerHTML = params.value;
    }
    // if (params.vstatus) {
    //   const _valueStatus = ((typeof params.vstatus === 'function'
    //     ? params.vstatus(params)
    //     : params.vstatus) || {}).status as string || '';
    //   if (_valueStatus.toLowerCase() === 'invalid') {
    //     this.eGui.style.background = 'red';
    //   } else if (_valueStatus.toLowerCase() === 'pending') {
    //     this.eGui.style.background = 'blue';
    //   }
    // }
    if (params.context.formArrayId && params.context.serviceObj) {
      const id = [params.context.formArrayId, params.context.serviceObj.indexMap.get(params.data.id), params.column.colId].join('@');
      const vformControl = params.context.serviceObj.invalidFormControls.get(id);
      if (vformControl && vformControl.status.toLowerCase() === 'invalid') {
        // this.eGui.style.background = '#fae4e4';
        this.eGui.style.border = '1px solid red';
      }
    }
  }

  public getGui() {
    return this.eGui;
  }
}
