import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateParser } from '@ngx-translate/core';
import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxAgGridEventHandlerService, IAgGridEditorEvent } from '../../ag-grid/fx-ag-grid-event-handler.service';
import { FxBaseComponent } from '../../fxbase/fx-base.component';
import { FxListService } from './fx-listservice';
import { GridOptions, GridApi, ColumnApi, Column, RowNode, RowNodeTransaction } from 'ag-grid-community';

@Component({
  selector: 'fx-list-base',
  template: '',
  styles: [``],
})
export class FxListBaseComponent extends FxBaseComponent {

  // 此函数中写List中公共的函数与功能

  private listService: FxListService;  // 列表服务


  constructor(public baseInjector: Injector, listService: FxListService) {
    super(baseInjector);
    this.listService = listService;
  }

  // 控制Grid栏位显示与隐藏
  getGridDisplayField(gridColumnApi: ColumnApi, programNo: string): void {
    let data: any = [];
    // 表格单头列显示控制
    if (gridColumnApi) {
      this.listService.getDisplayField(programNo).subscribe(response => {
        console.log('order-list response:', response);
        if (response.data.length > 0) {
          data = response.data;
          data.forEach(obj => {
            gridColumnApi.setColumnVisible(obj.aaf002, true); // 动态设置操作列显示隐藏
          });
        } else {
          // 通过 masterGridColumnApi 来设定，设定之前先Ag-Grid 显示判断
          // 未做控制或请求出错，所有Ag-grid 列均显示（ToDo: 此处存在数据安全隐患）
          const columns: Column[] = gridColumnApi.getAllGridColumns();
          columns.forEach(column => {
            if (!column.isVisible()) {
              gridColumnApi.setColumnVisible(column, true); // 动态设置操作列显示隐藏
            }
          });
        }
      });
    }
  }


  public stateControl(state: string, masterGridOptions: any): void {
    // for Test
    console.log('state:', state);

    switch (state) {
      case 'browse':  // 列表为浏览状态
        masterGridOptions.suppressClickEdit = false; // 不抑制点击编辑
        masterGridOptions.singleClickEdit = false;    // 不允许单击编辑
        masterGridOptions.suppressRowClickSelection = false; // 不抑制行选择
        masterGridOptions.suppressCellSelection = false;     // 不抑制单元格选择

        break;
      case 'edit':
        // 列表页为编辑状态
        masterGridOptions.suppressClickEdit = true; // 抑制点击编辑
        masterGridOptions.singleClickEdit = false; // 不允许单击编辑
        masterGridOptions.suppressRowClickSelection = true; // 抑制行选择
        masterGridOptions.suppressCellSelection = true; // 抑制单元格选择

        break;
      case 'add':
        // 列表为新增状态
        masterGridOptions.suppressClickEdit = false; // 不抑制点击编辑
        masterGridOptions.singleClickEdit = false; // 不允许单击编辑
        masterGridOptions.suppressRowClickSelection = false; // 不抑制行选择
        masterGridOptions.suppressCellSelection = false; // 不抑制单元格选择

        break;

      default:
        masterGridOptions.suppressClickEdit = false; // 不抑制点击编辑
        masterGridOptions.singleClickEdit = true;    // 单击编辑
        masterGridOptions.suppressRowClickSelection = false; // 不抑制行选择
        masterGridOptions.suppressCellSelection = false;     // 不抑制单元格选择

    }
  }

  getPageInfo(programId: string, searchParam: any): void {
    //获取页码信息
    var searchSession = sessionStorage.getItem(programId);
    if (searchSession !== null && searchSession !== undefined && searchSession !== 'null' && searchSession !== '') {
      searchParam.pageNumber = JSON.parse(searchSession).pageNumber;
      searchParam.pageSize = JSON.parse(searchSession).pageSize;
    }
  };

  // 设定RowNodeID
  getRowNodeId(data: any): number {
    return data.id;
  }

}
