import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxToolPanelComponent } from './fx-tool-panel.component';
import {DwDocumentModule, FrameworkUIModule} from '@webdpt/framework';

@NgModule({
  declarations: [
    FxToolPanelComponent
  ],
  imports: [
    CommonModule,
    FrameworkUIModule,
    DwDocumentModule,
  ],
  exports: [
    FxToolPanelComponent
  ],
  entryComponents: [
    FxToolPanelComponent
  ]
})
export class FxToolPanelModule { }
