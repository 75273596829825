import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { GridApi } from 'ag-grid-community';
import { ColumnApi } from 'ag-grid-community';
import { FX_GRID_COMPONENTS, FX_GRID_FRAMEWORK_COMPONENTS } from 'app/implementation/shared/fx-grid/cell-component/cell-component.module';
import { TranslateService } from '@ngx-translate/core';
import { FxUserInfoService } from '../../../auth/fx-auth/fx-user-info.service';
import { RxWaitRepetService } from 'app/implementation/shared/services/rx-wait-repet.service';
import { takeUntil } from 'rxjs/operators';
import { FormStatusAnalyzeFlatService } from 'lib/form-status-analyze-flat.service';
import { Subject } from 'rxjs/internal/Subject';
import { DwRoutingMessageService } from '@webdpt/framework';
import { OrderByMasterService } from 'app/implementation/shared/search-condition/modal/set-orderby.service';

@Component({
  selector: 'set-orderby',
  template: 
  `
  <div class="fx_modal_container" appModalDrag>
    <form dw-form [formGroup]="myform" class="fx_form_content">
    <div class="fx_form_group">
      <dw-row class="fx_grid_space">
        <a class="dw-f-link-more" (click)="MasterAdd()">
          <i class="anticon anticon-plus-circle"></i>{{ 'com-add' | translate }}
        </a>
      </dw-row>
      <div class="fx_table">
        <ag-grid-angular #masterGrid style="width: 100%;height: 314px;overflow-y: auto;" class="ag-theme-balham"  defaultColDef="{ width: 100 }"
        [gridOptions]="masterGridOptions" [context]="masterContext"
        [columnDefs]="masterColumnDefs" [components]="components" [frameworkComponents]="frameworkComponents" 
        [getRowNodeId]="getRowNodeId" [deltaRowDataMode]="false"
        [enableSorting]="false" [editType]="'fullRow'" [enableFilter]="false" [floatingFilter]="false"
        [enableColResize]="true"
        [localeTextFunc]="localTextFunc"
        [rowBuffer]="10" [suppressColumnVirtualisation]="true"
        [suppressClickEdit]="true" [singleClickEdit]="false" 
        (gridReady)="onMasterGridReady($event)" 
        (rowClicked)="onMasterRowClicked($event)"
        (rowEditingStarted)="onMasterRowEditingStarted($event)"
        (rowEditingStopped)="onMasterRowEditingStopped($event)">
        </ag-grid-angular>
      </div>
    </div>
    </form>
  </div>
  `,
  styles: []
})
 // <dw-row style="padding-top: 0px;" dwType="flex" dwAlign="middle">
 // </dw-row>
export class SetOrderByComponent implements OnInit, OnDestroy {
  @Input() orderByParam;

  public myform: FormGroup;
  private destroy$ = new Subject();
  public orderFileds = [];

  
  public masterGridOptions: GridOptions; // 列表ag-grid
  public masterContext = {}; 
  public masterColumnDefs = []; // 列表列定义
  public masterList = [];
  public masterGridApi: GridApi; // 列表GridApi
  public masterGridColumnApi: ColumnApi; // 列表GridColumnApi
  public components = {
    ...FX_GRID_COMPONENTS //  注入JS组件
  };
  public frameworkComponents = {
    ...FX_GRID_FRAMEWORK_COMPONENTS  //  注入cfcgrid组件
  };
  public currentEditingMasterRowIndex = -1; //当前修改行
  public flagClickRow = false; //grid双击
  constructor(private modalSubject: DwModalRef, 
    private fb: FormBuilder,
    private translateService: TranslateService,
    private fxUserService: FxUserInfoService,
    public MasterService: OrderByMasterService,
    private waitService: RxWaitRepetService, // 定时器
    public dwMessage: DwRoutingMessageService
  ) {}

  ngOnInit(): void {
    this.myform = this.fb.group({
      acj_f_list: new FormArray([])
    });
    this.myform.statusChanges.pipe(takeUntil(this.destroy$)).forEach((value) => { //当form的status变化时，更新xService.invalidFormControls（验证失败的formcontrol）
      // 统计整个表单fromgroup中的error的fromcontrol 
      const fsaf = new FormStatusAnalyzeFlatService({ redFormGroupErrors: true });
      this.MasterService.invalidFormControls = fsaf.getInvalidData(this.myform);
    });
    this.orderFileds = this.orderByParam.orderByListInit.map(obj =>{return {label: obj.acj002, value: obj.acj002}});
    this.masterGridInit();
  }

  masterGridInit(): void{
    this.masterGridOptions = <GridOptions>{
      suppressEnterKeyDown: true // 抑制回车结束编辑
    };
    this.masterColumnDefs = [
      { //排序字段
        headerName: this.translateService.instant('acj002'),
        field: 'acj002',
        width: 100,
        editable: true,
        cellEditor: 'cfcSelectEditor',
        cellEditorParams: {
          dwShowSearch: false,
          dwAllowClear: true,
          onChange: (value) => {
            this.changeAcj002(value)
          },
          values: this.orderFileds
        },
        cellRenderer: 'cfcSelectRenderer',
        cellRendererParams: {
          values: this.orderFileds
        }
      },
      { //排序字段名称
        headerName: this.translateService.instant('acj002_n1'),
        field: 'acj002_n1',
        width: 150,
        editable: true,
        cellEditor: 'cfcReadonlyTextEditor'
      }, 
      { //排序方式
        headerName: this.translateService.instant('acj003'),
        field: 'acj003',
        width: 150,
        editable: true,
        cellEditor: 'cfcSelectEditor',
        cellEditorParams: {
          values: this.fxUserService.getSysEnumById$('294')
        },
        cellRenderer: 'cfcSelectRenderer',
        cellRendererParams: {
          values: this.fxUserService.getSysEnumById$('294')
        }
      },
      {
        headerName: this.translateService.instant('comm-operation'),
        width: 70,
        field: 'operation',
        pinned: 'right',
        cellRenderer: 'cfcOperationButtonRenderer', // ==  grid优化  ==  begin  ==
        cellRendererParams: {
          isShowMod: false,
          isShowDel: true,
          delete: (value: any) => {
            console.log('删除=>dwdewd', value);
            this.MasterDelete(value);
          },
        }
      }
    ];
    this.masterContext = {
      componentParent: this, // 指定父组件
      serviceObj: this.MasterService,  //指定数据模型，留给元件访问
      rowCachedFormGroup: this.MasterService.rowCachedFormGroup, //指定行表单，留给元件访问
      id: 'masterGrid',   // 给ag-Grid指定ID
      formArrayId: 'acj_f_list' // grid's formArrayy's id 指定grid表单的id
    };
  }
  // 过滤条件多语系
  localTextFunc = (key: string): any => {
    // grid多语系
    return this.translateService.instant(key);
  }

  // 设定RowNodeID
  getRowNodeId(data: any): number {
    return data.id;
  }
  onMasterGridReady(params: any): void {
    this.masterGridApi = params.api;
    this.masterGridColumnApi = params.columnApi;
    // this.masterGridApi.setRowData([]);
    this.updateMasterGrid(this.orderByParam.orderByList || []); // 更新grid
  }

  // 更新单身数据，不保留现有数据
  private updateMasterGrid = (data): void => {
    // 为 ag-grid 维护 id 字段
    this.currentEditingMasterRowIndex = -1;//覆盖数据之后当前点击行清空
    this.MasterService.rowCachedFormGroup = null;
    // 每行数据转成 formGroup
    const formArray = this.MasterService.createFormArray(data);
    setTimeout(() => {
      this.myform.setControl('acj_f_list', formArray);
      // 这里的 updateValueAndValidity 不是为了触发 controls 的验证方法
      // 目的是为了触发 statusChange
      this.myform.updateValueAndValidity();
      this.masterGridApi.setRowData(this.myform.get('acj_f_list').value);
    }, 50);
  }

  //  mastergrid点击事件（此处功能点击mastergrid中某一行，关联更新detailgrid中显示的数据）
  onMasterRowClicked = (params) => {
    // 防止双击
    if (this.flagClickRow) {
      return;
    } else {
      this.flagClickRow = true;
    }
    // 检查是否是同行
    const index = this.MasterService.indexMap.get(params.node.data.id); // 获取 formArray Index
    if (this.currentEditingMasterRowIndex === index) {
      this.flagClickRow = false;
      return;
    }
    // 延时 2s 执行
    const _timer = this.waitService.repet(i => {
      if (!this.MasterService.rowCachedFormGroup || this.MasterService.rowCachedFormGroup.status.toUpperCase() !== 'PENDING') {//判断校验状态
        if (this.MasterService.rowCachedFormGroup) {
          this.onMasterRowEditingStopped({}); //停止正在编辑行
          this.MasterService.rowCachedFormGroup = null;
          this.masterGridApi.stopEditing(false);
        }
        // 更新当前行 index
        this.currentEditingMasterRowIndex = index;
        //讲当前行数据初始化到RowCachedFormGroup
        this.MasterService.initRowCachedFormGroup((this.myform.get('acj_f_list') as FormArray).at(index) as FormGroup, this.destroy$);
        if (this.masterGridApi) {
          setTimeout(() => {
            this.masterGridOptions.suppressClickEdit = false;
            this.masterGridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: 'operation' }); // index行更改为编辑状态，colKey必须存在
            this.masterGridOptions.suppressClickEdit = true;
          }, 50);
        }
        this.flagClickRow = false;
        _timer.unsubscribe();
      }
    }, () => {
      _timer.unsubscribe();
      this.flagClickRow = false;
      this.dwMessage.error(this.translateService.instant('com-formcheck'));
    });
  }

  onMasterRowEditingStarted(params: any): void { //  mastergrid行编辑模式，在当前行进入编辑状态之前会都会执行
  }
  //  mastergrid行编辑模式，退出当前行编辑状态或离开当前行时，在离开之后会执行，并且每个单元格的CellEditingStarted/CellEditingStop将被激发，此事件仅对应于整行编辑
  onMasterRowEditingStopped(params: any): void {
    if ((!this.MasterService.rowCachedFormGroup) || (params.node && this.MasterService.rowCachedFormGroup.get('id').value !== params.node.data.id)) {
      return;
    }
    const index = this.MasterService.indexMap.get(this.MasterService.rowCachedFormGroup.get('id').value);
    if (index === undefined) {
      // 在编辑状态下，点行删除。这时候 index 会是 undefined
      return;
    }
    // 编辑表单数据提交到formarray
    (this.myform.get('acj_f_list') as FormArray).at(index).patchValue(this.MasterService.rowCachedFormGroup.getRawValue());
    this.masterGridApi.setRowData(this.myform.get('acj_f_list').value);
    this.currentEditingMasterRowIndex = -1; // 停止编辑则当前修改行为-1
  }

  //新增排序
  public MasterAdd(): void{
    const _timer = this.waitService.repet(i => {
      if (!this.MasterService.rowCachedFormGroup || this.MasterService.rowCachedFormGroup.status.toUpperCase() !== 'PENDING') {//判断是否有正在校验
        if (this.MasterService.rowCachedFormGroup) {
          this.onMasterRowEditingStopped({}); //编辑行停止编辑
          this.MasterService.rowCachedFormGroup = null;
          this.masterGridApi.stopEditing(false);
        }
        // 设置新增的默认值
        const newRowFormGroup = this.MasterService.createRowFromGroup({});
        (this.myform.get('acj_f_list') as FormArray).insert(this.myform.get('acj_f_list').value.length, newRowFormGroup); //最后一行插入数据
        this.MasterService.initIndex(this.myform.get('acj_f_list').value); // 刷新index id映射
        this.masterGridApi.setRowData(this.myform.get('acj_f_list').value); // 赋值grid数据
        // 把当前新增的第一笔设定成编辑模式
        this.MasterService.initRowCachedFormGroup(newRowFormGroup, this.destroy$);
        if (this.masterGridApi) {
          setTimeout(() => {
            this.currentEditingMasterRowIndex = this.myform.get('acj_f_list').value.length - 1;
            this.masterGridOptions.suppressClickEdit = false;
            this.masterGridApi.startEditingCell({ rowIndex: this.currentEditingMasterRowIndex, colKey: 'operation' });
            this.masterGridOptions.suppressClickEdit = true;
            // this.setGridMaxSize(0);
          }, 50);
        }
        _timer.unsubscribe();
      }
    });
  }

  //修改排序字段
  public changeAcj002(param): void {
    if (param.value) {
      var allData = this.MasterService.getDataWithEditingRow(this.myform.get('acj_f_list').value);
      if (allData.filter(obj => {return obj.acj002 == param.value && obj.id != param.id}).length > 0) {
        this.dwMessage.error(this.translateService.instant('com-repeat'));
        this.MasterService.rowCachedFormGroup.get('acj002').setValue('');
        return;
      }
      var filterData = this.orderByParam.orderByListInit.filter(obj => {return obj.acj002 == param.value});
      this.MasterService.rowCachedFormGroup.patchValue({
        acj002_n1: filterData[0].acj002_n1 || '',
        acj003: filterData[0].acj003 || 'ACS',
        acj005: filterData[0].acj005 || '',
      })
    } else {
      this.MasterService.rowCachedFormGroup.patchValue({
        acj002_n1: '',
        acj003: 'ACS',
        acj005: '',
      })
    }
  }
  
  //删除排序
  public MasterDelete(param): void{
    this.masterGridApi.stopEditing();
    const selectedRow = this.masterGridApi.getRowNode(param.id);
    //删除当前商品
    var index = this.MasterService.indexMap.get(selectedRow.data.id);
    (this.myform.get('acj_f_list') as FormArray).removeAt(index);
    this.MasterService.initIndex(this.myform.get('acj_f_list').value); // 更新 grid ui 
    this.masterGridApi.setRowData(this.myform.get('acj_f_list').value);
    // this.setGridMaxSize(0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
 
