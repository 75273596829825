// 可用語言清單靜態設定檔
export const languageList = [
  {
    'value': 'en_US',
    'label': 'English'
  },
  {
    'value': 'zh_CN',
    'label': '简体'
  },
  {
    'value': 'zh_TW',
    'label': '繁體'
  }
];
