import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-cfc-textarea-editor',
  templateUrl: './cfc-textarea-editor.component.html',
  styleUrls: ['./cfc-textarea-editor.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CfcTextareaEditorComponent {

  formGroup: FormGroup;
  disabledExpr: Function;
  key;
  rows = 1;
  placeholder = '';
  // private value;
  columnName: string;
  private rowId: number;
  fParam: any;

  agInit(params: any) {
    this.columnName = params.column.colDef.headerName;
    // this.key = params.context.createKey(params.columnApi, params.column);
    this.key = params.column.colId;
    // this.value = params.value;
    this.rowId = params.node.id;
    this.fParam = params;
    this.placeholder = params.placeholder || '';
    // const gridFormArray = params?.context.gridFormArray;
    // const rowFromGroup = gridFormArray.at(params.rowIndex);

    // const rowFromGroup = params.context.rowCachedFormGroup;

    const rowFromGroup = params.context.serviceObj.rowCachedFormGroup;
    // const cellFormControl = rowFromGroup.get(this.key);
    this.formGroup = rowFromGroup;

    this.disabledExpr = params.disabledExpr
      ? ((param: any): string => (params.disabledExpr(param) ? 'disabled' : null))
      : ((param: any): string => null);
  }

  // refresh(params: any): boolean {
  //   console.log('* * * form cell refresh fn');
  //   const gridFormArray = params?.context.gridFormArray;
  //   const rowFromGroup = gridFormArray.at(params.rowIndex);
  //   const cellFormControl = rowFromGroup.get(this.key);
  //   // this.formGroup = params.context.formGroup.controls[this.rowId];
  //   this.formGroup = rowFromGroup;
  //   // this could also be done in GridComponent.createFormControls, but the cell component could be doing something with
  //   // the value, so it feels more natural that the control value be set here
  //   // this.formGroup.at(this.key).patchValue(this.value);
  //   // this.formGroup.get(this.key).patchValue(this.value);
  //   return true;
  // }

  getValue(): any {
    // return this.value;
    return this.formGroup.get(this.key).value;
  }

  public onBlur = (event: any): any => {
    console.log(event);
    console.log(this.formGroup);
    try {
      if ((this.fParam as any).onBlurFn) {
        (this.fParam as any).onBlurFn({
          value: this.formGroup.get(this.key).value,
          id: this.rowId,
        });
      }
      
    } catch (ex) {
      console.error(ex);
    }
  }

  public onKeyDown(event: KeyboardEvent): void { //键盘事件
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
      || event.keyCode === 39 || event.keyCode === 40) {
      event.stopPropagation();
    }
  }
}
