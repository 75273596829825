import {Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation, Optional} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {IAfterGuiAttachedParams, ICellEditorParams} from 'ag-grid-community';
import {FxAgGridEventHandlerService} from './fx-ag-grid-event-handler.service';
import {Subscription} from 'rxjs';
import {DwCheckboxComponent} from 'ng-quicksilver';
import {FxAgGridValidationEditorComponent} from './fx-ag-grid-validation-editor';

@Component({
  selector: 'fx-ag-color-renderer',
  template: `
  <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
  		     [dwTitle]="errorContent" [dwVisible]="errorVisible"
  		     *ngIf="errorVisible"></div>
  		<ng-template #errorContent>
  			<div *ngFor="let msg of validationMessages">{{ msg.key | translate:msg.params }}</div>
  		</ng-template>
		<form [formGroup]="validateForm" [ngClass]="{'invalid-form': formControl.invalid}">
			<dw-form-control>
        <dw-input-group>
          <span [ngStyle]="{'width': '20px', 'height': '20px', 'display': 'inline-block', 'vertical-align': 'middle', 'margin-right': '5px', 'background-color': value}"></span>
					<span [ngClass]="{'cell-overlay' : errorVisible}">{{value}}</span>
				</dw-input-group>
			</dw-form-control>
		</form>
  `
})

export class FxAgColorRendererComponent extends FxAgGridValidationEditorComponent implements ICellRendererAngularComp, OnDestroy {
  private params: ICellRendererParams;
  value: boolean = false;
  validateForm: FormGroup;  // Added by David Lee 1Mar2019
  name: string;

  constructor(private fb: FormBuilder) {
    super();
    // console.warn('创建FormCellRenderer:');
  }

  agInit(params: any): any {

    this.params = params;

    this.value = this.params.getValue();

    this.formControl = new FormControl(this, params.validators);
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }

    this.name = this.params.node.id + '-' + params.column.colId;

    // console.warn('初始化FormCellRenderer:', this.name);

    if (this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);
    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }

    this.formControl.markAsDirty();


    super.init();
    
  }

  refresh(): boolean {
    // console.warn('刷新FormCellRenderer:', this.name);
    return false;
  }


  ngOnDestroy(): void {
    // console.warn('销毁FormCellRenderer:', this.name);
    this.validateForm.removeControl(this.name);
  }
}
