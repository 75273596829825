import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShowMenuService {
    public emiter: EventEmitter<string>

    constructor() { 
        this.emiter = new EventEmitter();
    }

  //收缩菜单
    toggleSidebar() {
        this.emiter.emit('toggle')
    }
}