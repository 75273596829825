import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxNumberFormatComponent } from './fx-number-format.component';
import {DwDocumentModule, FrameworkUIModule} from '@webdpt/framework';
import {FxDapModule} from '../../fx-http-client';
import {PipesModule} from '../pipe/pipes.module';
import {MatAutocompleteModule} from '@angular/material';

@NgModule({
  declarations: [FxNumberFormatComponent],
  imports: [
    CommonModule,
    FrameworkUIModule,
    FxDapModule,
    DwDocumentModule,
    PipesModule,
    MatAutocompleteModule
  ],
  exports: [
    FxNumberFormatComponent
  ],
  entryComponents: [
    FxNumberFormatComponent
  ]
})
export class FxNumberFormatModule { }
