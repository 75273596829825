import {Component, Input, Optional} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {observableToBeFn} from 'rxjs/internal/testing/TestScheduler';
import {GridApi} from 'ag-grid-community';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FxAgGridEventHandlerService } from '../fx-ag-grid-event-handler.service';

@Component({
  selector: 'app-fx-grid-operation-button-editor',
  templateUrl: './fx-grid-operation-button-editor.component.html',
  styleUrls: ['./fx-grid-operation-button-editor.component.less']
})
export class FxGridOperationButtonEditorComponent implements ICellRendererAngularComp {
  private params: any;
  value: any;
  @Input() disabelDefultSave: boolean = false;

  public editMode: boolean = false;
  validateForm: FormGroup;  // Added by David Lee 1Mar2019

  constructor(private fb: FormBuilder, @Optional() private eventHandler: FxAgGridEventHandlerService ) {

  }

  agInit(params: any): any {
    this.params = params;  // 传入参数
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }

    this.disabelDefultSave = params.disabelDefultSave;
  }

  public save(): void {

    if (this.eventHandler) {
      this.eventHandler.onClickSaveButton({
        gridId: this.params.context.id,
        value: this.params.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.params.column.colId,
        controlName: ''
      });
    }

    // if (!this.disabelDefultSave) {
    //  this.params.api.stopEditing(false);
    // }

    if (this.params.save) {
      this.params.save(this.params);
    }
  }

  public cancel(): void {
    // this.params.api.stopEditing(true);

    if (this.eventHandler) {
      this.eventHandler.onClickCancelButton({
        gridId: this.params.context.id,
        value: this.params.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.params.column.colId,
        controlName: ''
      });
    }

    if (this.params.cancel) {
      this.params.cancel(this.params);
    }
  }

  refresh(): boolean {
    return true;
  }
}
