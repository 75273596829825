// import {isNull} from "@angular/compiler/src/output/output_ast";

export class CfcCheckboxRendererComponent {
  private eGui;

  constructor() { }

  public init(params): void {
    this.eGui = document.createElement('input');
    this.eGui.type = 'checkbox';
    this.eGui.disabled = true;

    // if (isNull(params.value)) {
      this.eGui.checked = params.value === true || params.value === 'Y' || params.value === 'y'|| params.value === params.dwTrueVal;
    // }
  }

  public getGui() {
    return this.eGui;
  }
}
