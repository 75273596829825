import { Component, forwardRef, Input, Output, Optional, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DwFormControlComponent } from 'ng-quicksilver';
import { AbstractDwFormItemComponent } from '../../../../../../projects/webdpt/framework/components/form-field/abstract-dw-form-item-component';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { TranslateParser } from '@ngx-translate/core';

@Component({
  selector: 'dw-number-format',
  template: `
    <dw-form-item-panel [dwFlex]="dwFlex"
                        [dwRequired]="dwRequired"
                        [dwFor]="dwFor"
                        [dwLabelOffset]="dwLabelOffset"
                        [dwLabelOrder]="dwLabelOrder"
                        [dwLabelPull]="dwLabelPull"
                        [dwLabelPush]="dwLabelPush"
                        [dwLabelSpan]="dwLabelSpan"
                        [dwLabelXs]="dwLabelXs"
                        [dwLabelSm]="dwLabelSm"
                        [dwLabelMd]="dwLabelMd"
                        [dwLabelLg]="dwLabelLg"
                        [dwLabelXl]="dwLabelXl"
                        [dwLabelXXl]="dwLabelXXl"
                        [dwHasFeedback]="dwHasFeedback"
                        [dwInputOffset]="dwInputOffset"
                        [dwInputOrder]="dwInputOrder"
                        [dwInputPull]="dwInputPull"
                        [dwInputPush]="dwInputPush"
                        [dwInputSpan]="dwInputSpan"
                        [dwInputXs]="dwInputXs"
                        [dwInputSm]="dwInputSm"
                        [dwInputMd]="dwInputMd"
                        [dwInputLg]="dwInputLg"
                        [dwInputXl]="dwInputXl"
                        [dwInputXXl]="dwInputXXl"
                        [validationMessages]="validationMessages"
                        [dwValidateStatus]="control"
                        [dwLabel]="dwLabel">
        <input dw-input
               [dwSize]="dwSize"
               (change)="change($event)"
               [(ngModel)]="newDwValue"              
               [disabled]="dwDisabled || isDisabled"
               (blur)="blur($event)"
               (click)="click($event)"
               [attr.placeholder]="dwPlaceHolder">
    </dw-form-item-panel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FxNumberFormatComponent),
      multi: true
    }
  ]
})
export class FxNumberFormatComponent extends AbstractDwFormItemComponent {

  @Input() dwPlaceHolder: string;
  @Input() dwDisabled: boolean = false;
  @Input() dwAddOnAfter: string | TemplateRef<void>;
  @Input() dwAddOnAfterIcon: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() dwAddOnBefore: string | TemplateRef<void>;
  @Input() dwAddOnBeforeIcon: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() dwPrefix: string | TemplateRef<void>;
  @Input() dwPrefixIcon: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() dwSuffix: string | TemplateRef<void>;
  @Input() dwSuffixIcon: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() dwCompact: boolean = false;
  @Input() dwSearch: boolean = false;
  @Input() dwSize: string;
  @Input() dwType: string = 'default'; // 小数位数类别
  @Input() dwCode: string = 'default'; // 小数位代码
  @Input() dwMin: number;          // 最小值
  @Input() dwMax: number;          // 最大值
  @Input() dwPositiveNum: number;   // 正数的小数点位数
  @Input() dwNegtiveNum: number;   // 负数的小数点位数
  @Input() dwHasZero: boolean = false; // 是否允许输入00开头的数值
  // @Output() dwValueChange = new EventEmitter<any>();
  @Output() dwBlur = new EventEmitter<any>();
  @Output() dwClick = new EventEmitter<any>();
  defaultDecimal = null;
  decimal = null;
  defaultRoundType = null;
  roundType = null;
  oldDwValue: number = null; // 记录旧值
  newDwValue: any;
  oldDwCode: string = 'default'; // 小数位代码--记录初始化进来的值，解决动态dwCode问题
  constructor(@Optional() protected _fg: ControlContainer,
    @Optional() protected _ts: TranslateParser,
    private fxUserService: FxUserInfoService) {
    super(_fg, _ts);
  }

  afterContentInit(): void { }

  change(event: any): void {
    this.ngModelChange(event, true);
    this.onChange(this.newDwValue);
  }

  ngModelChange($event, isSetNewValue): void {
    // 重新获取最新值，解决后期dwCode值改变问题
    if (this.oldDwCode != this.dwCode && this.dwCode) {
      this.getInitData();
    }
    // 验证内容是否正确
    if (this.newDwValue === undefined || this.newDwValue === null || this.newDwValue.toString() === '') {      
      this.newDwValue = null;
      this.oldDwValue = null;
      return;
    }
    var reg0 = /^(\-|\+)?\d+(\.\d+)?$/;
    // if (!reg0.test(this.newDwValue.toString())) {
    if (parseFloat(this.newDwValue.toString()).toString() == "NaN") {
      this.newDwValue = this.oldDwValue;
      return;
    } else {
      if (this.newDwValue !== undefined && this.newDwValue !== null && this.newDwValue !== '') {
        this.newDwValue = parseFloat(this.newDwValue).toString();
      }
      // this.newDwValue = this.newDwValue;
    }
    if (this.newDwValue == 0) {
      this.newDwValue = 0;
      return;
    }
    var reg01 = /^\d+(\.\d+)?$/;
    if (reg01.test(this.newDwValue.toString())) { // 正数
      if (this.dwPositiveNum != undefined && this.dwPositiveNum != null && this.dwPositiveNum >= 0) { // 设置了正数小数位数
        this.roundType = 2;        // 使用作业自定义小数位数默认采用舍入类型为四舍六入五成双
        this.defaultRoundType = 2;
        this.decimal = this.dwPositiveNum;  // 使用作业自定义小数位数
        this.defaultDecimal = this.dwPositiveNum;
      }
    } else {
      if (this.dwNegtiveNum != undefined && this.dwNegtiveNum != null && this.dwNegtiveNum >= 0) { // 设置了负数小数位数
        this.roundType = 2;        // 使用作业自定义小数位数默认采用舍入类型为四舍六入五成双
        this.defaultRoundType = 2;
        this.decimal = this.dwNegtiveNum;  // 使用作业自定义小数位数
        this.defaultDecimal = this.dwNegtiveNum;
      }
    }
    if (this.dwHasZero) {
      this.newDwValue = Number(this.newDwValue.toString().replace(/\D/g, ''));
      this.oldDwValue = this.newDwValue;
    } else {
      if (this.decimal != undefined && this.decimal != null && this.decimal >= 0) {
        this.newDwValue = this.roundFormat(this.decimal, Number(this.newDwValue), this.roundType); // 舍入和自动补0
        if ((this.dwMin != undefined && Number(this.newDwValue) < this.dwMin) || (this.dwMax != undefined && Number(this.newDwValue) > this.dwMax)) {
          this.newDwValue = this.oldDwValue;
        } else {
          this.oldDwValue = this.newDwValue;
        }
      } else {
        this.newDwValue = this.oldDwValue;
      }
    }
    // this.newDwValueChange.emit(this.newDwValue);
  }

  /**
   * Decimal adjustment of a number.
   *
   * @param	{String}	type	The type of adjustment.
   * @param	{Number}	value	The number.
   * @param	{Integer}	exp		The exponent (the 10 logarithm of the adjustment base).
   * @returns	{Number}			The adjusted value.
   */
  decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  // Decimal round
  round10 = function(value, exp) {
      return this.decimalAdjust('round', value, exp);
  };
  // Decimal floor
  floor10 = function(value, exp) {
      return this.decimalAdjust('floor', value, exp);
  };
  // Decimal ceil
  ceil10 = function(value, exp) {
      return this.decimalAdjust('ceil', value, exp);
  };

  roundFormat(decimal: any, num: any, roundType: any): number {
    if (decimal != null) {
      if (roundType == '1') {  // 舍入类型为四舍五入
        num = this.round10(num,  -1 * decimal);
      } else if (roundType == '2') {  // 舍入类型为四舍六入五成双
        num = num.toFixed(decimal);
      } else if (roundType == '3') {  // 舍入类型为无条件舍弃
        num = this.floor10(num,  -1 * decimal);
      } else if (roundType == '4') {  // 舍入类型为无条件进位
        num = this.ceil10(num,  -1 * decimal);
      }
      // 修改整数的时候不会显示0的问题
      if (num.toString().indexOf('.') == -1 && decimal != 0) {
        num += '.';
        for (var i = 0; i < decimal; i++) {
          num += '0'
        }
      }

      // 数值小数点后缺位补0
      if (num && num.toString().indexOf('.') != -1 && decimal != 0) {
        var numLength = Number((num.toString().split('.')[1]).length);
        if (numLength < decimal) {
          for (var i = numLength; i < decimal; i++) {
            num += '0'
          }
        }
      }
    }
    return num;
  }

  blur($event): void {
    // this.ngModelChange($event, true)
    this.dwBlur.emit($event);
  }

  click($event): void {
    this.dwClick.emit($event);
  }
  get value(): any {
    if (isNaN(Number(this.newDwValue))) {
      return null;
    } else {
      return this.newDwValue;
    }

  }

  set value(value: any) {
    if (isNaN(Number(value))) {
      this.newDwValue = null;
    } else {
      this.newDwValue = value;
    }
  }

  // 主要解决直接代码赋值（禁用状态下）不会触发值检查的问题
  writeValue(value: any): void {
    // (this.dwDisabled || this.isDisabled)
    if (value !== undefined && value !== null && value !== '') {
      this.newDwValue = value;
      this.ngModelChange('', '');
    } else {
      this.newDwValue = null
    }
  }

  onInit(): void {
    if (this.dwType == 'unit') {
      this.defaultDecimal = (this.fxUserService.getEntParamById('c_E067') === null || this.fxUserService.getEntParamById('c_E067') === undefined || this.fxUserService.getEntParamById('c_E067') === '') ? 2 : this.fxUserService.getEntParamById('c_E067');
      this.defaultRoundType = (this.fxUserService.getEntParamById('c_E068') === null || this.fxUserService.getEntParamById('c_E068') === undefined || this.fxUserService.getEntParamById('c_E068') === '') ? 2 : this.fxUserService.getEntParamById('c_E068');
    } else if ((['unitPriceDecimal', 'priceDecimal']).indexOf(this.dwType) != -1) {
      let defaultCode = this.fxUserService.userInfo['c_curr'];
      let defaultItems = this.fxUserService.getCurrencyParam([]);
      let defaultItem =  defaultItems ? defaultItems.filter(x => x.baacurr == defaultCode)[0] : null;
      if (defaultItem) {
        if (this.dwType == 'unitPriceDecimal') {
          this.defaultDecimal = defaultItem.baa001;
        } else {
          this.defaultDecimal = defaultItem.baa002;
        }
        this.defaultRoundType = defaultItem ? (defaultItem.baa003 ? defaultItem.baa003 : 2) : 2;
      } else {
        this.defaultDecimal = 4;
        this.defaultRoundType = 2;
      }
    }
    // 获取初始化的值
    this.getInitData();
  }

  // 获取初始化的值
  async getInitData(): Promise<any> {
    var items = [];
    var defaultCurr;
    this.oldDwCode = this.dwCode;
    if (this.dwType == 'unit') {
      if (this.dwCode == 'default' || !this.dwCode) { // dwCode传的default或空，取集团参数里设置的单位默认小数位数和舍入方式
        this.decimal = (this.fxUserService.getEntParamById('c_E067') === null || this.fxUserService.getEntParamById('c_E067') === undefined || this.fxUserService.getEntParamById('c_E067') === '') ? this.defaultDecimal : this.fxUserService.getEntParamById('c_E067');
        this.roundType = (this.fxUserService.getEntParamById('c_E068') === null || this.fxUserService.getEntParamById('c_E068') === undefined || this.fxUserService.getEntParamById('c_E068') === '') ? this.defaultRoundType : this.fxUserService.getEntParamById('c_E068');
      } else {
        items = this.fxUserService.getUnitParam([]);
        defaultCurr = items ? items.filter(x => x.bacunit == this.dwCode)[0] : null;
        if (!defaultCurr) {
          this.decimal = this.defaultDecimal;
          this.roundType = this.defaultRoundType;
        } else {
          this.decimal = defaultCurr.bac002;
          this.roundType = defaultCurr.bac003 ? defaultCurr.bac003 : this.defaultRoundType;
        }
      }
    } else if ((['unitPriceDecimal', 'priceDecimal']).indexOf(this.dwType) != -1) {
      if (this.dwCode == 'default' || !this.dwCode) {// dwCode传的default或空，取缓存里的当前登录组织设置的主币别
        this.dwCode = this.fxUserService.userInfo['c_curr'];
      }
      items = this.fxUserService.getCurrencyParam([]);
      defaultCurr = items ? items.filter(x => x.baacurr == this.fxUserService.userInfo['c_curr'])[0] : null;
      if (!defaultCurr) {
        this.decimal = this.defaultDecimal;
        this.roundType = this.defaultRoundType;
      } else {
        this.roundType = defaultCurr.baa003 ? defaultCurr.baa003 : this.defaultRoundType;
        switch (this.dwType) {
          case 'unitPriceDecimal':
            this.decimal = defaultCurr.baa001;
            break;

          case 'priceDecimal':
            this.decimal = defaultCurr.baa002;
            break;

          default:
            break;
        }
      }
    }

    if (this.decimal == null) this.decimal = this.defaultDecimal;
    if (this.roundType == null) this.roundType = this.defaultRoundType;
  }

}
