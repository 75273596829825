import { Injectable } from '@angular/core';
import { Observable, timer, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RxWaitRepetService {
  private readonly _dueTime: number = 50;
  private readonly _maxRetry: number = 20;
  private readonly _period: number = 100;

  constructor() { }

  public repet = (nextFn: (value: any) => void, timeoutFn?: () => void, options?: {dueTime: number, maxRetry: number, period: number}): Subscription => {
    const opt = options || {
      dueTime: this._dueTime,
      maxRetry: this._maxRetry,
      period: this._period
    };
    const completeFn =  timeoutFn || ((): void => {
      _timer.unsubscribe();
      console.error('WaitService > time out!');
    });
    const _timer = timer(opt.dueTime, opt.period)
      .pipe(take(opt.maxRetry))
      .subscribe(nextFn, error => { }, completeFn);
    return _timer;
  }
}
