import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DwUserStorage } from './user-storage';


@Injectable()
export class DwUserService {

  /**
  * 取用即時更改內容(存入名稱 = 取出鍵名)
  */
  public userInfo = {};
  public overAllParam: any = {};
  public ent: number;
  public org: string;
  public positiveMin: number = 0.00000001;// 正数最小值
  public negativeMin: number = -0.00000001;// 负数最小值
  constructor(protected userStorage: DwUserStorage) {
    // reload 時, 可以取出已經儲存的資料.
    const userInfo = this.userStorage.get('DwUserInfo');
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
    }
    const overAllParam = this.userStorage.get('DwSysInfo');
    if (overAllParam) {
      this.overAllParam = JSON.parse(overAllParam);
    }
  }

  /**
   * 儲存內容
   * string 須用 {id: key, value: data}傳入
   */
  public setUser(userInfo: any): void {
    if (!userInfo.id) {
      return;
    }
    if (userInfo.id.indexOf('overAll') !== -1) {
      this.overAllParam[userInfo.id] = userInfo.value;
      this.userStorage.set('DwSysInfo', JSON.stringify(this.overAllParam));
    } else {
      this.userInfo[userInfo.id] = userInfo.value;
      this.userStorage.set('DwUserInfo', JSON.stringify(this.userInfo));
    }
  }


  /**
   * 獲取儲存內容(對應id下資料)
   */
  public getUser(key: string): any {
    if (!key) {
      return;
    }

    return this.userInfo[key];
  }

  /**
   * 清除所有儲存內容(只能清除屬於user的資料).
   */
  public clearUser(): void {
    this.userStorage.remove('DwUserInfo');
    this.userInfo = {};
    this.userStorage.remove('DwSysInfo');
    this.overAllParam = {};
  }


  /**
   * 取得使用者資訊.
   * return 返回Observable
   */
  public read(userId: string, ent: number): Observable<object> {
    const info = {
      success: true,
      description: '', // 取值失敗時, 顯示.
      userInfo: null // 取得的回傳值.
    };

    const userInfo = {
      userId: userId,
      userName: userId
    };

    info.userInfo = userInfo;

    this.setUserInfo(userInfo);

    return Observable.create((observer) => {
      observer.next(info);
      observer.complete(); // 在 create 裡, 需要 complete.
    });
  }

  /**
   * 批次儲存使用者資訊.
   *
   * param {*} userInfo
   */
  public setUserInfo(userInfo: any): void {
    for (const field of Object.keys(userInfo)) {
      this.setUser({ id: field, value: userInfo[field] });
    }

    let userId = { id: 'userId', value: userInfo.c_user };
    this.setUser(userId);
  }

  // 添加空方法

  public getAllEntParam(): void { }

  public getAllSysParam(): void { }

  public getAllOrgParam(): void { }

  public getAllUnitParam(): void { }

  public getAllCurrencyParam(): void { }

  public getEntParam(aat001: Array<any>): Array<any> { return [] }

  public getEntParamById(aat001: string): string { return '' }
  public getSysParam(aas001: Array<any>): Array<any> { return [] }

  public getSysParamById(key: string): string { return '' }
  public getOrgParam(aau001: Array<any>): Array<any> { return [] }
  public getOrgParamById(key: string): string { return '' }
  public getUnitParam(bacunit: Array<any>): Array<any> { return [] }
  public getUnitParamById(key: string): Array<any> { return [] }
  public getCurrencyParam(baacurr: Array<any>): Array<any> { return [] }
  public getCurrencyParamById(key: string): Array<any> { return [] }

  // private searchAAA(key: string): Observable<Array<any>> {
  //   return Observable.create(observer => {
  //     observer.next([]);
  //     observer.complete();
  //   });
  // }

  public getSysEnumById$(key: string): Observable<any> {
    return Observable.create(observer => {
      observer.next([]);
      observer.complete();
    });
  }

  // private searchProgramParam(key: string): Observable<Array<any>> {
  //   return Observable.create(observer => {
  //     observer.next([]);
  //     observer.complete();
  //   });
  // }
  public getProgramParamById$(key: string): Observable<any> {
    return Observable.create(observer => {
      observer.next([]);
      observer.complete();
    });
  }

  public clearUserInfo(): boolean {
    return true;
  }

  setAuthedProgram(programs: Array<string>): boolean {
    return true;
  }

  checkProgramAuthed(programId: string): boolean {
    return true;
  }




}

