import { DwNewRouteKeyService, DwRoutingMessageService } from '@webdpt/framework';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DwModalService } from 'ng-quicksilver';
import { ICellRendererAngularComp } from 'ag-grid-angular';
// import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-fx-modal-cell-renderer',
  templateUrl: './fx-modal-cell-renderer.component.html',
})
export class FxModalCellRendererComponent implements ICellRendererAngularComp {

  @Input() params: any;
  // validateForm: FormGroup;
  constructor(
    // private fb: FormBuilder,
    public router: DwNewRouteKeyService,
    public dwMessage: DwRoutingMessageService,
    public http: FxDapHttpClient,
    public fxModalService: FxModalService,
    private translateService: TranslateService,
    public dwModalService: DwModalService
  ) {
  }
  
  refresh(params: any): any {
    console.log(params); 
  }

  agInit(params: any): any {
    this.params = params;
    // this.validateForm = this.params.form;
    // if (!params.form) {
    //   this.validateForm = this.fb.group({});
    // }

  }

  public openCellModal(): void{ 
    this.params.openModal(this.params, this.params.scope, this);  
  }

}
