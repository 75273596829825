import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxAddressPickerComponent } from './fx-address-picker.component';
import {NgxAddressModule, AddressService} from './ngx-address';

@NgModule({
  declarations: [FxAddressPickerComponent],
  imports: [
    CommonModule,
    NgxAddressModule
  ],
  exports: [
    FxAddressPickerComponent,
    NgxAddressModule
  ],
  entryComponents: [FxAddressPickerComponent],
  providers: [AddressService]
})
export class FxAddressPickerModule { }
