import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FxAgGridValidationEditorComponent} from '../../../ag-grid/fx-ag-grid-validation-editor';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DwSelectComponent} from 'ng-quicksilver';
import {of, Observable} from 'rxjs';
import {DOWN_ARROW, ENTER, TAB, UP_ARROW} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-cfc-select-editor',
  templateUrl: './cfc-select-editor.component.html',
  styleUrls: ['./cfc-select-editor.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CfcSelectEditorComponent extends FxAgGridValidationEditorComponent
  implements ICellEditorAngularComp, OnDestroy {

  formGroup: FormGroup;
  key;
  label;
  columnName: string;
  private rowId: number;

  // 下拉清单
  options;
  // 禁用属性表达式
  disabledExpr: Function;

  dwShowSearch: boolean;
  dwAllowClear: boolean;
  // dwDisabled: boolean;
  @ViewChild('cellInput') cellInput: DwSelectComponent;

  message: string;
  params: any;

  constructor(private fb: FormBuilder) {
    super();
  }

  onKeyDown(event): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
      || event.keyCode === 39 || event.keyCode === 40) {
      event.stopPropagation();
    }
    if (event.keyCode === ENTER || event.keyCode === TAB) {
      // if (this.activeId && this.activeId !== this.rowFormGroup.get(this.formControlName).value && this.matOptionShow && !(this.editorParams['param'](this.rowFormGroup))['disableCheck'] && !this.isBackendPaging) {
      //   this.rowFormGroup.get(this.formControlName).patchValue(this.activeId);
      // }
      this.cellInput.blur();
    }

  }

  // gets called once after the editor is created
  agInit(params: any): void {
    this.params = params;
    this.columnName = params.column.colDef.headerName;
    this.key = params.column.colId;
    this.rowId = params.node.id;
    const rowFromGroup = params.context.serviceObj.rowCachedFormGroup;
    this.formGroup = rowFromGroup;

    this.disabledExpr = params.disabledExpr || ((params: any) => { return false; });

    this.options = Array.isArray(params.values)
      ? of(params.values)
      : params.values;
    this.dwShowSearch = !!params.dwShowSearch;
    this.dwAllowClear = !!params.dwAllowClear;
    if (this.disabledExpr) {
      this.formGroup.get(this.key).setValue(params.value);
      if (Array.isArray(this.options)) {
        this.label = this.findValue(this.options);
      } else {
        const observable: Observable<any> = this.options;
        observable.subscribe(data => {
          this.label = this.findValue(data);
        });
      }
    }

    

    super.init();
  }
  findValue(arr: Array<any>): string {
    const matchedItem = arr.find(item => item.value === this.formGroup.get(this.key).value);
    return matchedItem ? matchedItem.label : '';
  }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    // 通过订阅消息回传onChange事件
    // if (this.cellInput) {
    //   this.cellInput.onChange = (event): void => {
    //     try {
    //       if ((this.params as any).onChangeFn) {
    //         (this.params as any).onChangeFn({
    //           value: event
    //         });
    //       }
    //     } catch (ex) {
    //       console.error(ex);
    //     }
    //   };
    // }
  }
  onChange(): any {
    if (this.params && this.params.onChange) {
      this.params.onChange({
        gridId: '',
        value: this.getValue(),
        rowIndex: '',
        id: this.rowId,
        colId: '',
        controlName: ''
      });
    }
  }


  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.formGroup.get(this.key).value;
  }

  focusIn(): void {
    this.cellInput.focus();
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    this.cellInput.blur();
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
    // return false;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {}
}

