import { DwNewRouteKeyService, DwRoutingMessageService } from '@webdpt/framework';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DwModalService } from 'ng-quicksilver';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-fx-note-cell-renderer',
  templateUrl: './fx-note-cell-renderer.component.html',
})
export class FxNoteCellRendererComponent implements ICellRendererAngularComp {

  @Input() params: any;
  public infoOption: any;
  public noteLabel: any;
  public noteValue: any;
  public keyObj = {};
  infoList = [];
  public type: any;
  constructor(
    public router: DwNewRouteKeyService,
    public dwMessage: DwRoutingMessageService,
    public http: FxDapHttpClient,
    public fxModalService: FxModalService,
    private translateService: TranslateService,
    public dwModalService: DwModalService
  ) {
  }

  // refresh(params: any): boolean {
  //   throw new Error("Method not implemented.");
  // }   //原fresh

  refresh(params: any): any {
    // throw new Error("Method not implemented.");
  }//by dinghao 0704
  agInit(params: any): any {
    this.params = params;
    this.type = this.params.type;
    this.noteLabel = [this.params.field[0], this.params.field[1], this.params.field[2], this.params.field[3]];   
    this.noteValue = [this.params.data[this.params.field[0]], this.params.data[this.params.field[1]], this.params.data[this.params.field[2]]];
    this.params.key.forEach((item) => {
      Object.assign(this.keyObj, { [item]: this.params.data[item] });
    });
  }

  public dataInit(): void {

    // this.infoOption = {
    //   tableName: this.params.tableName,                // 类型：String  必有字段  备注：表编号
    //   keyValue: this.keyObj
    // };
    // console.log('参数不全', this._infoOption);
    // if (!this.infoOption.tableName || !this.infoOption.keyValue) {
    //   console.log('参数不全');
    //   return;
    // }
    // this.http.post<any>('A/IACommonService/getDynamicInfo', { param: this.infoOption }).subscribe(result => {
    //   console.log('异动资讯', result);
    //   this.infoList = result.data;
    // });

    this.infoOption = {
      azd001: this.noteValue[0],              
      azdtype: '2'
    };

    this.http.post<any>('A/IFxai900Service/getAzdInfo', { param: this.infoOption }).subscribe(result => {
      console.log('日志资讯', result);
      this.infoList = result.data;
    });

  }

  public change(event): void {
    console.log(event);
    if (event) {
      this.dataInit();
    }
  }

  public clickBtn(method): void {
    if (method == 'edit') { // 编辑
      this.type = 'edit';
    } else {
      if (method == 'save') { // 保存
        this.type = 'browse';
        // this.visible = false;
        this.params.save(this, this.params.scope);
      } else { // 取消
        this.type = 'browse';
        this.agInit(this.params);
        this.dataInit();
      }
    }
  }


}
