import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FxDapHttpClient } from '../../fx-http-client';
import { ActivatedRoute } from '@angular/router';
import { DwRoutingMessageService } from '@webdpt/framework';


@Component({
  selector: 'fx-download',
  templateUrl: './fx-download.component.html',
  styleUrls: ['./fx-download.component.less']
})
export class FxDownloadComponent implements OnInit {
  @Input() downloadData: any; // 模板下载出资料
  @Input() btnData: any;      // 按钮资料
  @Input() isMoreBtn: any;    // 判断是否是更多按钮
  public programId: string = '';   // 作业编号
  public downloadList: any = [];  // 模板下载列表
  public getDownloadListFinish = false; // 是否已取模板下载清单



  constructor(
    public http: FxDapHttpClient,
    private translateService: TranslateService,
    protected route: ActivatedRoute,
    public dwMessage: DwRoutingMessageService
  ) {
  }

  ngOnInit() {
    // 通过路由获取作业编号
    this.programId = this.route.snapshot['_routerState'].url.split(';')[0].replace('/dwTabRouting/', '');

    // 获取模板下载列表
    this.getDownloadList();
  }

  // 模板下载
  public getDownloadList() {

    // 获取数据模板下载
    const downloadParam = {
      'program_no': this.downloadData && this.downloadData.program_no ? this.downloadData.program_no : this.programId  // 作业编号
    }
    this.http.post('A/IFxai014Service/getTemplateList', { param: downloadParam }, { headers: {'program-code': this.programId} }).subscribe(
      response => {
        this.getDownloadListFinish = true;
        if (response.data) {
          this.downloadList = response.data;
          this.downloadList.forEach(downloadItem => {
            downloadItem.name = downloadItem.abx001;
          });
        }
        // this.downloadList.forEach(thisTpl => {
        //   var extension = (/[.]/.exec(thisTpl.url)) ? /[^.]+$/.exec(thisTpl.url)[0] : '';
        //   if (extension !== '') {
        //     extension = '.' + extension;
        //   }
        //   thisTpl.newName = thisTpl.templateName + extension; // 模板名称.xlsx
        // });
      });
  };

  // 模板下载
  templateDownload(data?: any): void {
    if (this.getDownloadListFinish) { // 已获取模板下载清单
      if (this.downloadList.length === 1) {  // 获取到模板个数等于1，点击直接下载
        if (this.downloadList[0].abx006) {
          window.open(this.downloadList[0].abx006);
        } else {
          // url获取有误  
          this.dwMessage.errorNew(this.translateService.instant('com-template-error'), this.translateService.instant('com-template-error'), "", "");        
        }
      } else if (this.downloadList.length > 1) {  // 获取到模板个数大于1
        if (data.abx006) {
          window.open(data.abx006);
        } else {
          // url获取有误  
          this.dwMessage.errorNew(this.translateService.instant('com-template-error'), this.translateService.instant('com-template-error'), "", "");        
        }
      } else {  // 未获取到模板
        this.dwMessage.errorNew(this.translateService.instant('com-set-template'), this.translateService.instant('com-set-template'), "", "");
      }
    } else {
      this.dwMessage.errorNew(this.translateService.instant('com-templat-get'), this.translateService.instant('com-templat-get'), "", "");
    }
  };

}
