import {Component, OnDestroy, OnInit} from '@angular/core';
import {FxAgGridValidationEditorComponent} from '../fx-ag-grid-validation-editor';
import {FormBuilder, FormControl} from '@angular/forms';
import {ICellRendererAngularComp} from '../../../../../../node_modules/ag-grid-angular';
import {type} from 'os';
import {Observable} from 'rxjs';
import {subscribeOn} from 'rxjs/operators';

@Component({
  selector: 'app-fx-ag-grid-select-cell-renderer',
  templateUrl: './fx-ag-grid-select-cell-renderer.component.html',
  styleUrls: ['./fx-ag-grid-select-cell-renderer.component.less']
})
export class FxAgGridSelectCellRendererComponent extends FxAgGridValidationEditorComponent implements  ICellRendererAngularComp, OnDestroy {
  values: any;
  public formControl: FormControl;
  key;
  public value;
  label: string = '';
  columnName: string;
  params;
  className: string;
  private rowId: number;

  constructor(private fb: FormBuilder) {
    super();
  }

  agInit(params: any): any {
    this.values = params.values ? params.values : [];
    this.className = params.className ? params.className : '';
    this.params = params;
    // console.debug('-------- fx-form-cell debugging');
    this.columnName = params.column.colDef.headerName;
    // this.key = params.context.createKey(params.columnApi, params.column);
    this.key = params.node.id + '-' + params.column.colId;
    this.value = params.getValue();
    if (Array.isArray(this.values)) {
      this.label = this.findValue(this.values);
    } else {
      const observable: Observable<any> = this.values;
      observable.subscribe(data => {
        this.label = this.findValue(data);
      });
    }

    this.formControl = new FormControl(this, params.validators);
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.validateForm.addControl(this.key, this.formControl);
    this.formControl.markAsDirty();

    super.init();
  }

  findValue(arr: Array<any>): string {
    const matchedItem = arr.find(item => item.value === this.value);
    return matchedItem ? matchedItem.label : '';
  }

  refresh(params: any): boolean {
    // this.formGroup = params.context.formGroup.controls[this.rowId].get(this.key);
    //
    // // this could also be done in GridComponent.createFormControls, but the cell component could be doing something with
    // // the value, so it feels more natural that the control value be set here
    // // this.formGroup.at(this.key).patchValue(this.value);
    // this.formGroup.patchValue(this.value);
    this.value = params.value;
    if (Array.isArray(this.values)) {
      this.label = this.findValue(this.values);
    } else {
      const observable: Observable<any> = this.values;
      observable.subscribe(data => {
        this.label = this.findValue(data);
      });
    }
    return true;
  }

  labelClick(event): void {
    if (this.params && this.params.click) {
      this.params.click(this.params);
    }
  }

  debug(): void {
    console.debug('-------- fx-form-cell debugging');
    console.debug(this.formControl);
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.key);
  }
}
