import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FxDapHttpClient} from '../../fx-http-client';
import {FxBaseModel} from '../../fxbase/fx-base.model';
import {ColumnApi, GridApi} from 'ag-grid-community';

@Injectable()
export class FxListViewService extends FxBaseModel {

  // 此基类中写List中公共的函数与功能

  // getDisplayField
  public getDisplayField(programNo: any): Observable<any> {
    return this.http.post('A/IACommonService/getDisplayField', {param: {programNo: programNo}});
  }

  public async updateGridDisplayFields(programNo: string, columnApi: any): Promise<any> {
    try {
      const response = await this.getDisplayField(programNo).toPromise();
      const columns = (response.data.length > 0)
        ? response.data
        : columnApi.columnController.columnDefs;

      columns.forEach(obj => {
        columnApi.setColumnVisible(obj.aaf002, true); // 动态设置操作列显示隐藏
      });
    } catch (e) {
      console.warn(e);
    }
  }

  constructor(public http: FxDapHttpClient) {
    super(http);
  }
}
