/**
 * 废弃，使用FxAgCheckboxEditorComponent
 */
import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation, Optional} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { DwAgGridValidationEditorComponent } from '../../../../../projects/webdpt/framework/components/ag-grid-editors/ag-grid-validation-editor';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';
import {Subscription} from 'rxjs';
import { DwCheckboxComponent } from 'ng-quicksilver';

@Component({
  selector: 'app-checkbox-cell-editor',
  template: `
    <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
         [dwTitle]="errorContent" [dwVisible]="errorVisible"
         *ngIf="errorVisible"></div>
    <ng-template #errorContent>
      <div *ngFor="let msg of validationMessages">{{ msg.key | translate:msg.params }}</div>
    </ng-template>
    <form [formGroup]="validateForm">
      <dw-form-control>
        <dw-input-group>
          <label class="edit-checkbox" [formControl]="formControl" dw-checkbox [dwDisabled]="isDisabled" [(ngModel)] = "value"
          #cellInput></label>
        </dw-input-group>
      </dw-form-control>
    </form>
  `,
  styles: [`
    .edit-checkbox {
      padding-left: 11px;
    }
  `]
})
export class CheckboxEditorComponent extends DwAgGridValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {
  modalService;
  colId;
  value: boolean = false;
  isDisabled: boolean = false;
  dwTrueVal: string = 'Y';
  dwFalseVal: string = 'N';
  params: ICellEditorParams;  // Added by David Lee 25Feb2019
  valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  validateForm: FormGroup;  // Added by David Lee 1Mar2019

  // @ViewChild('cellInput') cellInput: ElementRef;
  @ViewChild('cellInput') cellInput: DwCheckboxComponent;

  constructor(private fb: FormBuilder, @Optional() private eventHandler: FxAgGridEventHandlerService) {
    super();
  }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    if (this.cellInput && !this.isFullRowEdit()) {
      // this.cellInput.nativeElement.focus();
      // this.cellInput.nativeElement.select();
      this.cellInput.focus();
    }


    const valueChange = (value: boolean): boolean => {

      this.formControl.setValue(value);
      // 通过订阅消息回传onChange事件


        if (this.eventHandler) {
          this.eventHandler.onBeforeField({
            gridId: this.params.context.id,
            value: this.formControl.value,
            rowIndex: this.params.node.rowIndex,
            id: this.params.node.id,
            colId: this.colId,
            controlName: ''
          });
        }

        this.formControl.setValue(value);
        this.formControl.markAsDirty({onlySelf: true});

        if (this.eventHandler) {
          this.eventHandler.onChangeField ({
            gridId: this.params.context.id,
            value: this.formControl.value,
            rowIndex: this.params.node.rowIndex,
            id: this.params.node.id,
            colId: this.colId,
            controlName: ''
        });
      }


      return true;

    };
    this.cellInput.registerOnChange( valueChange ) ;  // Added by David Lee 注册OnChange事件


  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.params = params;
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    this.colId = params.column.colId;
    this.params = params;
    if (params.dwTrueVal) {
      this.dwTrueVal = params.dwTrueVal;
    }
    if (params.dwFalseVal) {
        this.dwFalseVal = params.dwFalseVal;
    }
    if (params.value === this.dwTrueVal) { this.value = true; }
    if (params.value === this.dwFalseVal) { this.value = false; }
    if (params.disable) {
        this.isDisabled = params.disable;
    }
    this.formControl = new FormControl(this.value, params.validators);
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.validateForm.addControl(this.colId, this.formControl);
    this.formControl.markAsDirty();

  /* // 通过订阅消息回传onClick事件
  this.cellInput.onClick = (e: any): void => {
    if (this.eventHandler) {
      this.eventHandler.onBeforeField({
        // value: this.formControl.value,
        value : e.resultVal,
        rowIndex: this.params.node.rowIndex,
        columnId: this.columnId
      });
      this.value = e.resultVal;
      this.formControl.markAsDirty();

    }
}; */

// 通过订阅消息回传onBlur事件
  this.cellInput.onBlur = (): void => {
    if (this.eventHandler) {
      this.eventHandler.onAfterField({
        gridId: this.params.context.id,
        value: this.validateForm.controls[this.colId].value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: ''
      });
    }
};

    super.init();
  }

  // changechecked(): void {
  //  console.log('cell change!');
  //  if (this.params != undefined) {
  //      console.log('wdew', this.params)
  //    this.params.onChange(this.params, this.getValue());
  //  }
  // }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
    // this.cellInput.focus();
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    // this.cellInput.blur();
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    let resultVal = 'Y';
    if (this.formControl.value) {
      // this.formControl.setValue(this.dwTrueVal);
      resultVal = this.dwTrueVal;
    } else {
      // this.formControl.setValue(this.dwFalseVal);
      resultVal = this.dwFalseVal;
    }
    return resultVal;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.colId);
        // 当销毁时取消所有订阅
        if (this.valueSetSubscription) {
          this.valueSetSubscription.unsubscribe();
        }
  }


}
