import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FxHotkeyHelpService} from './fx-hotkey-help.service';
import {FxHotkeyCommunicateService} from './fx-hotkey-communicate.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  providers: [
    FxHotkeyHelpService,
    FxHotkeyCommunicateService
  ]
})
export class FxHotkeyModule {
  static forRoot(components: any): ModuleWithProviders {
    return {
      ngModule: FxHotkeyModule,
      providers: [
        FxHotkeyHelpService,
        FxHotkeyCommunicateService
      ]
    };
  }
}
