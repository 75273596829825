import {Inject, Injectable} from '@angular/core';
import {DW_MENU_JSON, DwAuthService, DwMenuAttributeService, IDwMenu, IDwMenuConfigMap, IDwMenuService} from '@webdpt/framework';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {FxDapHttpClient} from '../../fx-http-client';
import {FxUserInfoService} from 'app/implementation/auth/fx-auth/fx-user-info.service';

@Injectable({
  providedIn: 'root'
})
export class FxMenuService implements IDwMenuService {
  private menu$: BehaviorSubject<IDwMenu[]>; // 選單資料
  private menuConfigMap$: BehaviorSubject<any>; // 選單設定對應表
  private menuList: IDwMenu[];
  private menuConfigMapList: IDwMenuConfigMap;

  constructor(
    @Inject(DW_MENU_JSON) private dwMenuJson: IDwMenu[], // Menu靜態設定檔
    private http: FxDapHttpClient,
    private dwMenuAttributeService: DwMenuAttributeService,
    private authService: DwAuthService,
    // private fxAuthGuardService: FxAuthGuardService
    // private fxUserService: FxUserInfoService
  ) {
    this.menuList = null;
    this.menuConfigMapList = null;
    this.menu$ = new BehaviorSubject<IDwMenu[]>(this.menuList);
    this.menuConfigMap$ = new BehaviorSubject<any>(this.menuConfigMapList);
    let isInit = false;

    this.authService.isLoggedIn$.subscribe(
      value => {
        if (!value) {
          isInit = false;
          this.menuList = [];
          this.menuConfigMapList = <IDwMenuConfigMap>null;
          this.menu$.next(this.menuList);
          this.menuConfigMap$.next(this.menuConfigMapList);
        } else if (!isInit) {
          isInit = true;

          try {
            this.shearchMenu().subscribe(res => {
              // const authedPrograms = [];
              const menuJson = this.getMenuTree(res, []);

              // this.fxAuthGuardService.setAuthedProgram(authedPrograms);
              if (Array.isArray(menuJson)) {
                this.menuList = menuJson;
              } else {
                this.menuList = [];
              }

              this.menuConfigMapList = <IDwMenuConfigMap>{};
              this.menuList = this.menuInit(this.menuList, this.menuConfigMapList, 1);
              this.menu$.next(this.menuList);
              this.menuConfigMap$.next(Object.assign({}, this.menuConfigMapList));
            });
            // const menuJson = JSON.parse(JSON.stringify(dwMenuJson));
            // if (Array.isArray(menuJson)) {
            //   this.menuList = menuJson;
            // } else {
            //   this.menuList = [];
            // }
            //
            // this.menuConfigMapList = <IDwMenuConfigMap>{};
            // this.menuList = this.menuInit(this.menuList, this.menuConfigMapList, 1);
            // this.menu$.next(this.menuList);
            // this.menuConfigMap$.next(Object.assign({}, this.menuConfigMapList));
          } catch (error) {
            console.error(error);
            this.menu$.next(this.menuList);
            this.menuConfigMap$.next(Object.assign({}, this.menuConfigMapList));
          }
        }
      }
    );
  }

  private shearchMenu(): Observable<any> {
    return Observable.create(observer => {
      this.http.post('A/IACommonService/getMenuTreeByUser', {param: {}}).subscribe(
        response => {
          response.success && response.data
            ? observer.next(response.data)
            : observer.next([]);
          observer.complete();
        }, error => {
          observer.next([]);
          observer.complete();
        }
      );
    });
  }

  private getMenuTree(lstData: Array<any>, authedPrograms): Array<any> {
    const result = [];
    lstData.forEach(item => {
      let child = [];
      if (item.child && item.child.length > 0) {
        child = this.getMenuTree(item.child, authedPrograms);
      }
      result.push({
        id: item.name,
        programId: item.id,
        programIdQuery:item.id_program,
        type: ('2' === item.type) ? 'program' : 'category',
        iconClass: item.iconclass || 'anticon anticon-appstore',
        child: child
      });
      if ('2' === item.type) {
        authedPrograms.push(item.id);
      }
    });
    return result;
  }

  public getMenu(): Observable<IDwMenu[]> {
    // return this.menu$.asObservable().pipe(
    //   filter(obsData => {
    //     return obsData !== null
    //   }), // 不廣播初始值
    //   distinctUntilChanged() // 有改變時才廣播
    // );

    return Observable.create(observer => {
      this.shearchMenu().subscribe(res => {
        const menuJson = this.getMenuTree(res, []);

        if (Array.isArray(menuJson)) {
          this.menuList = menuJson;
        } else {
          this.menuList = [];
        }

        this.menuConfigMapList = <IDwMenuConfigMap>{};
        this.menuList = this.menuInit(this.menuList, this.menuConfigMapList, 1);
        observer.next(this.menuList);
        observer.complete();
      });
    });
  }

  public getMenuConfigMap(): Observable<IDwMenuConfigMap> {
    return this.menuConfigMap$.asObservable().pipe(
      distinctUntilChanged() // 有改變時才廣播
    );
  }

  private menuInit(menuDataSource: IDwMenu[], menuConfigMapList: IDwMenuConfigMap, level: number): IDwMenu[] {
    const menuList: IDwMenu[] = [];
    const len = menuDataSource.length;

    for (let i = 0; i < len; i++) {
      const menuItem: IDwMenu = JSON.parse(JSON.stringify(menuDataSource[i]));
      this.dwMenuAttributeService.default(menuItem, level);

      if (menuItem.type === 'fineReport' || (menuItem.type === 'externalUrl' && menuItem.openMode === 'iframe')) {
        menuItem.programId = menuItem.id;
      }

      menuConfigMapList[menuItem.id] = {
        // type: obj.type, // 類型
        // iconClass: obj.iconClass,
        // level: obj.level, // 節點層級，從1開始
        // programId: obj.programId, // 作業編號
        // url: obj.url, // 連結網址
        // parameter: obj.parameter // 作業參數

        type: menuItem.type, // 類型. 目錄='category', 作業='program', 'fineReport':報表, 外部網頁(另開)='externalUrl'
        iconClass: menuItem.iconClass, // 圖示樣式
        open: menuItem.open, // 是否展開。預設false
        disabled: menuItem.disabled, // 是否禁用。預設false
        selected: menuItem.selected, // 是否被選中。預設false
        programId: menuItem.programId, // 作業編號。type='program'時設定。
        programIdQuery: menuItem.programIdQuery, // 作業編號。type='program'時設定。为自定义查询作业跳转参数，作业参数设置c_P006
        code: menuItem.programId,
        url: menuItem.url ? menuItem.url : '', // 連結網址。type='externalUrl'時，設定外部網頁網址
        openMode: menuItem.openMode ? menuItem.openMode : '',
        parameter: menuItem.parameter,
        level: menuItem.level
      };

      // this.propertyRegulate(menuItem);

      if (menuItem.child.length > 0) {
        menuItem.child = this.menuInit(menuItem.child, menuConfigMapList, level + 1);
      }

      menuList.push(menuItem);
    }

    return menuList;
  }

  /**
   * 屬性校正，刪除多餘的屬性
   //  */
  // private propertyRegulate(item: any): void {
  //   delete item['parameter'];
  //   delete item['action'];
  //   delete item['page'];
  //   delete item['programBase'];
  // }
}
