import { AfterViewInit, Component, ElementRef, HostListener,
  OnDestroy, Optional, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { DwInputNumberComponent } from 'ng-quicksilver';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';
import { TranslateParser } from '@ngx-translate/core';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';  // Added by David Lee 25Feb2019
import {Subscription} from 'rxjs';   // Added by David Lee 25Feb2019

@Component({
  selector: 'fx-ag-number-cell-editor',
  template: `
    <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
         [dwTitle]="errorContent" [dwVisible]="errorVisible"
         *ngIf="errorVisible"></div>
    <ng-template #errorContent>
      <div *ngFor="let msg of validationMessages">{{ message }} {{ msg.key | translate:msg.params }}</div>
    </ng-template>
    <form [formGroup]="validateForm">
      <dw-form-control>
        <dw-input-group>
          <dw-input-number dw-input
                           [dwMin]="dwMin"
                           [dwMax]="dwMax"
                           [dwStep]="dwStep"
                           [formControl]="formControl"
                           placeholder=""
                           #cellInput (keydown)="onKeyDown($event)"></dw-input-number>
        </dw-input-group>
      </dw-form-control>
    </form>
  `
})
export class FxAgNumberCellEditorComponent extends FxAgGridValidationEditorComponent
  implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  colId;
  dwMin;
  dwMax;
  dwStep;
  value;
  params: ICellEditorParams;  // Added by David Lee 25Feb2019
  name: string;
  valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  message: string;
  @ViewChild('cellInput') cellInput: DwInputNumberComponent;

  constructor(private fb: FormBuilder, @Optional() private eventHandler: FxAgGridEventHandlerService) {
    super();
  }

  onKeyDown(event): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38 
      || event.keyCode === 39 || event.keyCode === 40) {
        event.stopPropagation();
    }
  }

  ngAfterViewInit(): void {

    const valueChange = (value: number): void => {
    this.formControl.setValue(value);
    this.formControl.markAsDirty({onlySelf: true}); // David Lee 触发更新
    };

    this.cellInput.registerOnChange(valueChange);

    this.cellInput.inputElement.nativeElement.addEventListener('keyup', (event: any) => {
      valueChange(event.target.value);
    });
  }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
     if (this.cellInput && !this.isFullRowEdit()) {
        this.cellInput.dwAutoFocus = true;
     }

      // 通过订阅消息回传onFocus事件
      this.cellInput.onFocus = (e: any): void => {

        if (this.eventHandler) {
          this.eventHandler.onBeforeField({
            gridId: this.params.context.id,
            value: this.formControl.value,
            rowIndex: this.params.node.rowIndex,
            id: this.params.node.id,
            colId: this.colId,
            controlName: this.name
          });
        }
    };

      // 通过订阅消息回传onBlur事件
      this.cellInput.onBlur = (e: any): void => {
        if (this.eventHandler) {
          this.eventHandler.onAfterField({
            gridId: this.params.context.id,
            value: this.formControl.value,
            rowIndex: this.params.node.rowIndex,
            id: this.params.node.id,
            colId: this.colId,
            controlName: this.name
          });
        }
    };

    // 通过订阅消息回传up事件
    this.cellInput.up = (e: any): void => {

      if (this.eventHandler) {
        this.eventHandler.onClickUpButton({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }
    };

    // 通过订阅消息回传down事件
    this.cellInput.down = (e: any): void => {

      if (this.eventHandler) {
        this.eventHandler.onClickDownButton({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }
    };

    // 可以自己扩展其它事件

    // }
  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.params = params;  // 传入参数
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    this.colId = params.column.getColId();
    this.formControl = new FormControl(params.value, params.validators);
    this.validateForm = params.form;
    this.dwMin = params.dwMin;
    this.dwMax = params.dwMax;
    this.dwStep = params.dwStep;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }

    this.name = this.params.node.id + '-' + params.column.colId;

    if ( this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);
    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }

    this.formControl.markAsDirty();

    // 监听赋值 Add David lee 25 Feb 2019
    this.valueSetSubscription = this.eventHandler.valueSet.subscribe(event => {
      if ( (event.colId === this.colId) && (event.gridId === params.context.id) ) {
        this.formControl.setValue(event.value);
        this.message = event.message;
        this.formControl.markAsDirty();
      }
    });

    super.init();
  }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
      this.cellInput.focus();
      // if (this.eventHandler) {
      //  this.eventHandler.onBeforeField({
      //    gridId: this.params.context.id,
      //    value: this.formControl.value,
      //    rowIndex: this.params.node.rowIndex,
      //    id: this.params.node.id,
      //    colId: this.colId,
      //    controlName: this.name
      //  });
      // }
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
      this.cellInput.blur();
      // if (this.eventHandler) {
      //  this.eventHandler.onAfterField({
      //    gridId: this.params.context.id,
      //    value: this.formControl.value,
      //    rowIndex: this.params.node.rowIndex,
      //    id: this.params.node.id,
      //    colId: this.colId,
      //    controlName: this.name
      //  });
      // }
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.formControl.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.name);

    // 当销毁时取消所有订阅
    if (this.valueSetSubscription) {
      this.valueSetSubscription.unsubscribe();
    }
  }

}
