import { Component, OnInit, Input, Output, EventEmitter,ViewChild } from '@angular/core';
import { F, UploadFile, UploadXHRArgs } from 'ng-quicksilver';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { DwRoutingMessageService } from '@webdpt/framework';
import { UploadFilter } from 'ng-quicksilver';
import { ParamObj } from '../../programs/a/fxai009/model/data.model';
import { Subject ,Observable } from 'rxjs';
import Viewer from 'viewerjs';
import {FxUserInfoService} from '../../auth/fx-auth/fx-user-info.service';

@Component({
  selector: 'app-img-upload',
  template:
  `
  <div class="img-upload-all">
    <dw-upload class="img-upload"
      dwListType="picture-card"
      [(dwFileList)]="_imgList"
      [dwPreview]="handlePreview"
      [dwRemove]="handleRemove"
      [dwCustomRequest]="customReqImg"
      (dwChange)="changeImg($event)"
      [dwBeforeUpload]= "beforeUpload"
      [dwDisabled] = "_disabled"
      [dwMultiple] = true
      [dwShowButton] = "_showButton"
      [dwShowUploadList] ="showUploadList"
      [dwFilter]="filters"
      [dwSize]="filters"
      >
        <i class="anticon anticon-plus" ></i>
        <div class="ant-upload-text">{{ 'com-upload' | translate }}</div>
    </dw-upload>
    <dw-modal
      dwWrapClassName = "img-upload-video-view "
        [dwVisible]="previewVisible"
        [dwContent]="modalContent"
        [dwFooter]="null"
        (dwOnCancel)="previewVisible = false">
        <ng-template #modalContent>
          <video width="320" height="320" controls class="img-upload-video" *ngIf="nowImgitem">
            <source [src]="nowImgitem" type="video/mp4">
            <source [src]="nowImgitem" type="video/ogg">
            <source [src]="nowImgitem" type="video/webm">
             您的浏览器不支持Video标签。
          </video>
        </ng-template>
    </dw-modal>
    <ul id="img-upload-view" class="img-view{{_picFileOption.aay003}}">
      <li class="img-view-item" *ngFor="let imgitem of _imgList" >
        <div class="img-view-info">
          <span class="img-upload-actions">
            <i *ngIf="showUploadList.showDefaultIcon"  class="anticon anticon-star" title="{{ 'com-设置默认' | translate }}"
            (click)="handleDefault(imgitem, $event)" [style.color]="imgitem.ischecked === 'Y' ? 'red' : '#fff'"
            style="cursor: pointer"></i>
            <i *ngIf="showUploadList.showPreviewIcon" (click)="handlePreview(imgitem, $event)" class="anticon  anticon-eye-o"
            title="{{ 'com-预览' | translate  }}"></i>
            <i *ngIf="showUploadList.showRemoveIcon" (click)="handleRemove(imgitem, $event)" class="anticon anticon-delete"
            title="{{ 'com-删除' | translate  }}"></i>
          </span>

          <img [src]="imgitem.url" *ngIf="imgitem.fileType != 'mp4' && imgitem.fileType != 'MOV'" >
          <img src="assets/img/home/v_player.png" *ngIf="imgitem.fileType == 'mp4' || imgitem.fileType == 'MOV'" >
          </div>
      </li>
    </ul>
    <!--<div >
        <canvas id="myCanvas" #myCanvas ></canvas>
    </div>
    <div #photo class="photo"></div>-->
  </div>
  `,
  styles: [
    `
    :host ::ng-deep .customize-footer {
      border-top: 1px solid #e9e9e9;
      padding: 10px 18px 0 10px;
      text-align: right;
      border-radius: 0 0 0px 0px;
      margin: 15px -16px -5px -16px;
    }
    i.anticon.anticon-star {
      z-index: 10;
      transition: all .3s;
      cursor: pointer;
      font-size: 16px;
      width: 16px;
      color: rgba(255, 255, 255, 0.85);
  }


    `
  ]
})
export class ImgUploadComponent implements OnInit  {
  //style="display: none;"
  // <i nz-icon nzType="youtube" nzTheme="outline"></i>
  // <img [src]="../../assets/home/v_player.png" *ngIf="imgitem.fileType == 'mp4'|| imgitem.fileType == 'ogg' || imgitem.fileType == 'webm'" >

  metaData: any;

  //@ViewChild('myCanvas') myCanvas: any;
  //@ViewChild('photo') photo: any;
  @Output() open: EventEmitter<any> = new EventEmitter();

  _imgList = [];
  // previewImage = '';
  previewVisible = false;
  _disabled = false;
  _isgetData = true;
  _showButton = true;
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    showDefaultIcon: false
  }
  ischecked = true;
  nowImgitem = '';
  // nowImgitem ='http://fx.digiwin.com:8880/group1/M00/00/17/rBBlB15wm9yAKOBHABsepY_1WwM036.mp4?filename=wx_camera_15711487196232.mp4&ts=1584438237&token=57aed7ad22af9bb23cdad7e47ba19522'; //点击视频信息
  public beforeUpload;
  public imagesView;
  @Input() limit: number = 0; //限制上传图片个数
  public _picFileOption: any;
  @Input() set picFileOption(_picFileOption: any) {
    this._picFileOption = _picFileOption;
  }
  //是否自动获取数据库数据
  @Input() set isgetData(value: boolean) {
    this._isgetData = value;
  }
  // 图片列表
  @Input() set imgList(value: any) {
    this.imgListChange.emit(value);
    this._imgList = value;
  }
  @Output() imgListChange = new EventEmitter();

  @Input() set disabled(disabled: boolean) { //禁用图片编辑
    this._disabled = disabled;
  }
  // 是否显示上传按钮
  @Input() set showButton(value: boolean) {
    this._showButton = value;
    this.showUploadList.showRemoveIcon = this._showButton;
  }
  @Input() disDefault = false;


  constructor(
    public http: FxDapHttpClient,
    public fxUserService: FxUserInfoService,
    public dwMessage: DwRoutingMessageService,) {
    }

  ngOnInit(): void {
    this.showUploadList.showDefaultIcon = this.disDefault;
    if (this._isgetData) {
      this.getImgListdata();
    }
    setTimeout(() => {
      if (this.limit > 0 ) {
        this._disabled = this._imgList.length == this.limit &&this.limit !== 0 ? true : false;
      }
    }, 1000);

  }

  getImgListdata(): void {
    if (this._picFileOption.aay001 && this._picFileOption.aay002 && this._picFileOption.aay003) {
      this.http.post('A/IACommonService/pub_get_aay', { param: this._picFileOption}).subscribe(
        response => {
          if (response.success && response.data) {
            console.log('图片输出', response)
            this._imgList = response.data;
            this.imgList = this._imgList.filter(item => item.aay010 == '1');
          }
        });
    } else {
      this._imgList = [];
      this.imgList = this._imgList;
    }
  }

  public handlePreview = (file: UploadFile) => {
    // console.log('图片预览')
    // this.previewImage = file.url || file.thumbUrl;
    // this.filetype = file.type;
    // this.previewVisible = true;
    if (!file.fileType) {
      return;
    }
    var index = this._imgList.findIndex(function (obj) {
      return obj.uid == file.uid;
    });
    var imgIndex = 0; //图片展示下标
    if (file.fileType == 'svg' || file.fileType == 'mp4'|| file.fileType == 'ogg' || file.fileType == 'webm' || file.fileType == 'MOV') {
      this.previewVisible = true;
      this.nowImgitem = file.url;

      // let player = document.getElementsByClassName('img-upload-video');
      // player.src = "新的地址" ''
      // player.play()
    } else {
      var filterData = this._imgList.filter(function (obj) {
        return  obj.fileType != 'mp4' && obj.fileType != 'ogg' && obj.fileType != 'webm' && obj.fileType != 'MOV' && obj.url !='assets/img/home/v_player.png';
      });
      imgIndex = filterData.findIndex(function (obj) {
        return obj.uid == file.uid;
      });
      // if (this.imagesView) {
      //   this.imagesView.update();
      //   // this.imagesView.show();
      //   this.imagesView.view(imgIndex);
      // } else {
        var eleName = this._picFileOption.aay003 ?'img-view'+ this._picFileOption.aay003 : 'img-view';
        this.imagesView = new Viewer(document.getElementsByClassName(eleName)[0]
        , {
          // backdrop: false,
          filter(image) {
            // console.log('图片信息', image, image.src.split('?')[0].substring(image.src.split('?')[0].lastIndexOf(".") + 1).toLowerCase());
            var type = image.src.split('?')[0].substring(image.src.split('?')[0].lastIndexOf(".") + 1).toLowerCase();
            return  type != 'mp4' && type != 'ogg' && type != 'webm' && type != 'MOV' && !image.src.endsWith('assets/img/home/v_player.png');
          },
          hidden() {
            console.log('hidden');
            this.viewer.destroy();
          }
        });
        this.imagesView.view(imgIndex);
      // }
    }
  }

  public handleRemove(file): boolean{
    console.log('删除')
    const flag = true;
    var index = this._imgList.findIndex(function (obj) {
      return obj.uid == file.uid;
    });
    this._imgList.splice(index,1);
    this.imgList = this._imgList.map(function (obj) {
      return Object.assign({response: [], thumbUrl: ''}, obj);
    })
    setTimeout(() => {
      if (this.limit > 0 ) {
        this._disabled = this._imgList.length == this.limit &&this.limit !== 0 ? true : false;
      }
    }, 1000);
    return flag;
  }

  public handleDefault(file): boolean{
    if (!this._showButton) {
      return;
    }
    var checkstatus = file.ischecked === 'Y' ? 'N' : 'Y';
    for (var idx = 0; idx < this._imgList.length; idx++) {
      this._imgList[idx].ischecked = 'N';
      this._imgList[idx].aay009 = 'N';
    }
    file.status = 'ischecking';
    file.ischecked = checkstatus;
    file.aay009 = file.ischecked;
    this.imgList = this._imgList.map(function (obj) {
      return Object.assign({response: [], thumbUrl: ''}, obj);
    })
    // this.changeImg(file);
    return true;
  }



  //上传图片
  customReqImg = (item: UploadXHRArgs) => {
    console.log('[item]',item);
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    formData.append('attach', item.file as any);
    this.http.post('A/IAttachFileService/uploadFile', formData).subscribe(response => {
      if (response.successValue) {
        console.log('response',response);
        console.log('img_list',this._imgList);
        item.onSuccess!(response.data, item.file!, '');
      } else {
        this.dwMessage.errorNew(response.message, response.isRemind, response.programNo, response.programName);
      }
      console.log(this._imgList.length);
    });
  }


  //上传图片
  customReqImgAI = (item: UploadXHRArgs) => {
    console.log('[item]',item);
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    formData.append('attach', item.file as any);
    this.http.post('AI/IBaiduAIService/uploadFile', formData).subscribe(response => {
      if (response.successValue) {
        console.log('response',response);
        console.log('img_list',this._imgList);
        item.onSuccess!(response.data, item.file!, '');
        if(Array.isArray(response.data)&&response.data.length>0){
           if(response.data[0].hasMeta){

            console.log('meta',response.data[0].meta);

            this.metaData=response.data[0].meta;
            //this.onCreateCanvas(item.file);
            this.open.emit(this.metaData);

           }
        }

      } else {
        this.dwMessage.errorNew(response.message, response.isRemind, response.programNo, response.programName);
      }
      console.log(this._imgList.length);
    });
  }


  /*
  onCreateCanvas(file): boolean{
    console.log('AI');
    const flag = true;
    var index = this._imgList.findIndex(function (obj) {
      return obj.uid == file.uid;
    });
  // console.log(img1.naturalWidth, img1.naturalHeight) 可获取图片的原始宽度和高度
  //1706 × 1279
  let canvasWidth = 1279 ;// 这里设置固定值
  let canvasHeight = 1706 ;
  let canvas = this.myCanvas.nativeElement;

  let context = canvas.getContext('2d');


  canvas.width = canvasWidth; // canvas画布宽度
  canvas.height = canvasHeight ;// canvas画布高度
  context.fillStyle = '#ffffff';
  context.fillRect(0, 0, canvas.width, canvas.height);//给canvas设置背景白色

  context.fillStyle = 'black' // 设置文字的填充颜色
  context.font = '15px Georgia' // 设置文字的填充样式


  if(Array.isArray(this.metaData.words_result)){

    var num:number = this.metaData.words_result.length;
    var i:number;

    for (i=0 ; i< num;i++ ) {


        if(this.metaData.words_result[i].words!=null&&this.metaData.words_result[i].location!=null){

          context.fillText(this.metaData.words_result[i].words.toString(), this.metaData.words_result[i].location.top, this.metaData.words_result[i].location.left) // 在画布中添加文字

        }

    }
  }



  //context.fillText(this.metaData.toString(), 100, 440) // 在画布中添加文字

  //context.fillStyle = 'black' // 设置文字的填充颜色
  //context.font = '15px Georgia' // 设置文字的填充样式
  //context.fillText(this.metaData, 245, 500) // 在画布中添加文字

  let newImg = new Image()
  newImg.src = canvas.toDataURL('image/png') // canvas画布导出图片

this.photo.nativeElement.append(newImg) // 将图片插入div中展示出来

return flag;
}

*/
  //格式化图片信息
  changeImg(file): void{
    if(file.type == 'success'){
      file.file.status = 'done';
      for(var i= 0;i< file.fileList.length;i++){
        if(file.fileList[i].uid == file.file.uid){
          file.fileList[i].id = file.file.response[0].id;
          file.fileList[i].url = file.file.response[0].url;
          file.fileList[i].fileSize = file.file.response[0].fileSize;
          file.fileList[i].fileType = file.file.response[0].fileType;
          file.fileList[i].name = file.file.response[0].fileName;
          file.fileList[i].uploadDate = file.file.response[0].uploadDate;
          file.fileList[i].status = 'done';
          file.fileList[i].aay009 = file.file.ischecked;
          file.fileList[i].aaystus = 'N';
          file.fileList[i].aay010 = '1';
        }
      }
    } else if (file.type == 'ischecking') {
      for(var i= 0;i< file.fileList.length;i++){
        file.fileList[i].aay009 = file.fileList[i].ischecked;
      }
    }
    console.log('==图片变化', this._imgList, file);
    // this._imgList = file.fileList;
    this.imgList = this._imgList.map(function (obj) {
      return Object.assign({response: [], thumbUrl: ''}, obj);
    })
    setTimeout(() => {
      if (this.limit > 0 ) {
        this._disabled = this._imgList.length == this.limit &&this.limit !== 0 ? true : false;
      }
    }, 1000);
  }
  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: UploadFile[]) => {
        const filterFiles = fileList.filter(w => ~['image/png','image/jpeg','image/gif','image/bmp', 'video/mp4', 'image/svg+xml'].indexOf(w.type));
        if (filterFiles.length !== fileList.length) {
          this.dwMessage.error(`包含文件格式不正确，只支持 png/jpg/jpeg/gif/bmp/mp4 格式`);
          return filterFiles;
        }
        return fileList;
      }
    },
    {
      name: 'size',
      fn: (fileList: UploadFile[]) => {
        const c_S015: string= this.fxUserService.getSysParamById('c_S015');
        let c_S015_M:number= Number(c_S015);
        let c_S015_Byte:number= 0;
        //若配置数值不的0~500范围之内或带单位
        if(c_S015_M>=0&&c_S015_M<=500){
          c_S015_Byte=c_S015_M*1024*1024
        }else{
          //默认100M
          c_S015_M=100;
          c_S015_Byte=104857600;
        }
        const filterFiles = fileList.filter(w => Number(w.size)<=c_S015_Byte);//1M=1024kb
        if (filterFiles.length !== fileList.length) {
          this.dwMessage.error('图片或文件上传大小，单个照片或文件不能超过'+c_S015+'M！');
          return filterFiles;
        }
        return fileList;
      }
    }
    // , //异步验证器
    // {
    //   name: 'async',
    //   fn: (fileList: UploadFile[]) => {
    //     return new Observable((observer: Observer<UploadFile[]>) => {
    //       // doing
    //       observer.next(fileList);
    //       observer.complete();
    //     });
    //   }
    // }
  ];
  public getImgList(): any {
    this._imgList.forEach(element => {
      element.response = [];
      element.thumbUrl = '';
    });
    this.imgList = this._imgList.map(function (obj) {
      return Object.assign({response: [], thumbUrl: ''}, obj);
    })
    return this._imgList;
  }

}
