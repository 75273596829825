import { Component, EventEmitter, forwardRef, Input, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateParser } from '@ngx-translate/core';
import { AbstractDwFormPickComponent } from './abstract-form-picker.component';
import { FunctionProp } from 'ng-quicksilver/core/types/common-wrap';
import { DwDatePickerComponent } from 'ng-quicksilver';
import { toBoolean } from '../../../utils/convert';
import { DatePipe } from '@angular/common';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';

@Component({
  selector: 'dw-form-date-picker',
  template: `
    <dw-form-item-panel [dwFlex]="dwFlex"
                        [dwRequired]="dwRequired"
                        [dwFor]="dwFor"
                        [dwLabelOffset]="dwLabelOffset"
                        [dwLabelOrder]="dwLabelOrder"
                        [dwLabelPull]="dwLabelPull"
                        [dwLabelPush]="dwLabelPush"
                        [dwLabelSpan]="dwLabelSpan"
                        [dwLabelXs]="dwLabelXs"
                        [dwLabelSm]="dwLabelSm"
                        [dwLabelMd]="dwLabelMd"
                        [dwLabelLg]="dwLabelLg"
                        [dwLabelXl]="dwLabelXl"
                        [dwLabelXXl]="dwLabelXXl"
                        [dwHasFeedback]="dwHasFeedback"
                        [dwInputOffset]="dwInputOffset"
                        [dwInputOrder]="dwInputOrder"
                        [dwInputPull]="dwInputPull"
                        [dwInputPush]="dwInputPush"
                        [dwInputSpan]="dwInputSpan"
                        [dwInputXs]="dwInputXs"
                        [dwInputSm]="dwInputSm"
                        [dwInputMd]="dwInputMd"
                        [dwInputLg]="dwInputLg"
                        [dwInputXl]="dwInputXl"
                        [dwInputXXl]="dwInputXXl"
                        [validationMessages]="validationMessages"
                        [dwValidateStatus]="control"
                        [dwLabel]="dwLabel">
      <dw-date-picker #dwDatePicker style="width: 100%;"
        [(ngModel)]="dwValue"
        (ngModelChange)="change($event)"
        [dwAllowClear]="dwAllowClear"
        [dwAutoFocus]="dwAutoFocus"
        [dwClassName]="dwClassName"
        [dwDisabled]="dwDisabled"
        [dwDisabledDate]="dwDisabledDate"
        [dwLocale]="dwLocale"
        [dwPlaceHolder]="dwPlaceHolder"
        [dwPopupStyle]="dwPopupStyle"
        [dwDropdownClassName]="dwDropdownClassName"
        [dwSize]="dwSize"
        [dwStyle]="dwStyle"
        (dwOnOpenChange)="dwOnOpenChange.emit()"
        [dwDisabledTime]="dwDisabledTime"
        [dwFormat]="_dwFormat"
        [dwRenderExtraFooter]="dwRenderExtraFooter"
        [dwShowTime]="dwShowTime"
        [dwShowToday]="dwShowToday"
        (dwOnOk)="dwOnOk.emit()"
      >
      </dw-date-picker>
    </dw-form-item-panel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DwFormDatePickerComponent),
      multi: true
    }
  ]
})
export class DwFormDatePickerComponent extends AbstractDwFormPickComponent {

  @Input() dwDisabledTime: (current: Date, partial?: 'start' | 'end') => { nzDisabledHours, nzDisabledMinutes, nzDisabledSeconds };
  @Input() dwFormat: string;
  @Input() dwRenderExtraFooter: FunctionProp<TemplateRef<void> | string>;
  @Input() dwconType: string; //start 开始  end 结束
  private _showTime: object | boolean;
  public _dwFormat: string;


  @Input()
  get dwShowTime(): object | boolean { return this._showTime; }
  set dwShowTime(value: object | boolean) {
    this._showTime = typeof value === 'object' ? value : toBoolean(value);
    if (this.dwFormat) {
      this._dwFormat = this.dwFormat;
    } else {
      this._dwFormat = this._showTime ? this.userinfoService.getEntParamById('c_E031') + ' HH:mm:ss' : this.userinfoService.getEntParamById('c_E031');
    }
  }

  @Input() dwShowToday = true;
  @Output() dwOnOk: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @ViewChild('dwDatePicker') dwDatePicker: DwDatePickerComponent;

  private _open;
  @Input()
  set dwOpen(value: boolean) {
    if (this.dwDatePicker) {
      this.dwDatePicker.dwOpen = value;
    }
  }
  get dwOpen(): boolean {
    return this.dwDatePicker ? this.dwDatePicker.dwOpen : false;
  }

  constructor(@Optional() protected _fg: ControlContainer,
    @Optional() protected _ts: TranslateParser,
    private datePipe: DatePipe,
    public userinfoService: FxUserInfoService) {
    super(_fg, _ts);
    // this._dwFormat = this.userinfoService.getEntParamById('c_E031');
  }

  afterContentInit(): void {
    if (this._open !== undefined) {
      this.dwDatePicker.dwOpen = this._open;
    }
  }

  change(event: any): void {
    this.onChange(event);
    // this._dwFormat = this._showTime ? this.userinfoService.getEntParamById('c_E031') + ' HH:mm:ss' : this.userinfoService.getEntParamById('c_E031');

    if (this.dwValue === 'Invalid Date' || !this.dwValue) {
      this.dwValue = null
    } else {
      this.dwValue = this.datePipe.transform(this.dwValue, this._dwFormat);
    }
    this.dateChange.emit(this.dwValue);
  }

  onInit(): void {
    if (this.dwFormat) {
      this._dwFormat = this.dwFormat;
    } else {
      this._dwFormat = this._showTime ? this.userinfoService.getEntParamById('c_E031') + ' HH:mm:ss' : this.userinfoService.getEntParamById('c_E031');
    }
    if (this.control) {
      if (this._showTime && !this.control.value) { //日期控件时间给初始值
        if (this.dwconType == 'start') {
          var startFormat = this._dwFormat.replace('HH', '00').replace('mm', '00').replace('ss', '01');
          this.control.setValue(this.datePipe.transform(new Date(), startFormat));
        } else if (this.dwconType == 'end') {
          var endFormat = this._dwFormat.replace('HH', '23').replace('mm', '59').replace('ss', '59');
          this.control.setValue(this.datePipe.transform(new Date(), endFormat));
        } else {
          this.control.setValue(this.datePipe.transform(this.control.value || null, this._dwFormat));
        }
      } else {
        this.control.setValue(this.datePipe.transform(this.control.value || null, this._dwFormat));
      }
    }
  }

}
