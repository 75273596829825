import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { IDwLanguageList } from './interface/language.interface';
import { LocalStorage } from '../../storage/local-storage';
import { DwLanguageListService } from './service/language-list.service';
import { DwLanguageService } from './service/language.service';


@Component({
  selector: 'dw-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class DwLanguageComponent implements OnInit {
  languageOption: string;
  index: any = 0;
  languageList: IDwLanguageList[];

  constructor(
    private storage: LocalStorage,
    private languageListService: DwLanguageListService,
    private languageService: DwLanguageService
  ) {
    this.languageListService.getLanguagesList().subscribe(
      (list: IDwLanguageList[]) => {
        this.languageList = list;
      }
    );
  }

  @Input() template: string;

  // 翻译
  switchLanguage(language: string): void {
    this.languageService.setUp(language);
  }
  // 上一个
  previous(): void {
    this.index--;
    if (this.index < 0) {
      this.index = Object.keys(this.languageList).length - 1;
    }
    this.languageOption = this.languageList[this.index].value;
    this.switchLanguage(this.languageList[this.index].value);
  }
  // 下一个
  next(): void {
    this.index++;
    if (this.index >= Object.keys(this.languageList).length) {
      this.index = 0;
    }
    this.languageOption = this.languageList[this.index].value;
    this.switchLanguage(this.languageList[this.index].value);
  }
  ngOnInit(): void {
    if (this.storage.get('fx.language')) {
      this.switchLanguage(this.storage.get('fx.language'));
      this.languageOption = this.storage.get('fx.language');
      this.index = this.languageList.findIndex(language => language.value === this.languageOption);
    } else {
      this.languageService.language$.subscribe(
        lang => {
          this.switchLanguage(lang);
          this.languageOption = lang;
        }
      );
    }

    // 20181203-storage跟選項跟著storage跑.
    this.languageService.language$.subscribe(
      (currLang: any) => {
        this.languageOption = currLang;
      }
    );

  }

}
