import { Injectable } from '@angular/core';

@Injectable()
export class DwHttpRequestUrlService {

  constructor() {
  }

  public getUrl(apiUrl: string, requestUrl: string): string {
    let url = apiUrl + requestUrl;

    /* David Li 30Aug2023 分销URL(https://datatracker.ietf.org/doc/html/rfc3986#section-3.3)
     * 1. Absolute URI: 场景为ExternalURL: http://、https://、double slash ("//") 
     * 2. relative-reference: path-abempty、path-absolute  
     */

    //绝对URL
    const isExternalURL = (requestUrl.startsWith('http://') || requestUrl.startsWith('https://') || requestUrl.startsWith('//'));

    //后端采用绝对UR配置（外部URL与旧版本分销均采用此种配置）
    const isAbsoluteURL:boolean = (apiUrl.startsWith('http://') || apiUrl.startsWith('https://') || apiUrl.startsWith('//'));

    //后端采用绝对路径配置方式配置 begins with "/" but not "//" 规定表示apiUrl后端配置URL为绝对路径例如: /restful/service/
    const isPathAbsolute:boolean = (apiUrl.startsWith('/')&&!(apiUrl.startsWith('//')));

    //资源文件(从前端加载)
    const isAssets:boolean= url.startsWith('assets/');


    let baseURI:string =  document.baseURI;

    if(baseURI.charAt(baseURI.length-1) === "/") {
      baseURI = baseURI.slice(0, -1);
    }

    //子路径方式构建
    let isBaseURI:boolean=false;

    //通过以下方式判断是否baseURI是否包含子路径
    const pattern: RegExp =/^(https?:\/\/)/;
    let baseurl=baseURI.replace(pattern,'');
  
    if(-1!=baseurl.indexOf('\/')){
  
      isBaseURI=true; 
  
    }

    //非绝对URL
    if(!isExternalURL){
      //若后端采用绝对UR配置
      if(isAbsoluteURL){
        //apiUrl为绝对URl，直接拼接
        url= apiUrl + requestUrl;
      }else if(isPathAbsolute){   //后端采用绝对路径配置方式配置 begins with "/" but not "//" 规定表示apiUrl后端配置URL为绝对路径例如: /restful/service/
        //若apiUrl为相对URl
        if(isBaseURI){
        //若以子路径方式构建，拼接绝对路径
          url= baseURI+apiUrl+ requestUrl;
        }else{
          //非子目录方式构建，直接拼接
          url= apiUrl+ requestUrl;
        }
      }else{
        //相对URL之path-abempty：begins with "/" or is empty（is empty 也表示相对路径）
        if(isBaseURI){
          //子路径方式构建
          url= baseURI+'/' + apiUrl+ requestUrl;
        }else{
          if(typeof　apiUrl === 'undefined'||apiUrl===''){
            url= '/'+ requestUrl;
          }else{
            url= apiUrl+ requestUrl;
          }
        }
      }
  }else{
    //绝对URL
    url=requestUrl;

  }

    return url;
  }
}
