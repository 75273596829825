import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation, Optional} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ICellRendererAngularComp} from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';
import {Subscription} from 'rxjs';
import { DwCheckboxComponent } from 'ng-quicksilver';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';

@Component({
  selector: 'fx-checkbox-cell-renderer',
  template: `
  <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
       [dwTitle]="errorContent" [dwVisible]="errorVisible"
       *ngIf="errorVisible"></div>
  <ng-template #errorContent>
    <div *ngFor="let msg of validationMessages">{{ msg.key | translate:msg.params }}</div>
  </ng-template>
  <form [formGroup]="validateForm">
    <dw-form-control>
      <dw-input-group>
      <label\n dw-checkbox\n  dw-checkbox\n  [formControl]="formControl" [(ngModel)]="checked"  [(dwDisabled)]="disabled"></label>
      </dw-input-group>
    </dw-form-control>
  </form>
  `,
  styleUrls: ['../../style/conponents/checkbox.less'],
  encapsulation: ViewEncapsulation.None
})
export class FxAgCheckboxRendererComponent extends FxAgGridValidationEditorComponent implements ICellRendererAngularComp, OnDestroy {
  private params: ICellRendererParams;
  checked: boolean = false;
  disabled: boolean = false;
  dwTrueVal: string = 'Y';
  dwFalseVal: string = 'N';
  validateForm: FormGroup;  // Added by David Lee 1Mar2019
  name: string;

  constructor(private fb: FormBuilder ) {
    super();
  }

  agInit(params: any): any {

    this.params = params;


    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwTrueVal) {
        this.dwTrueVal = this.params.colDef.cellRendererParams.dwTrueVal;
    }

    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwFalseVal) {
      this.dwFalseVal = this.params.colDef.cellRendererParams.dwFalseVal;
    }

    if ( this.params.getValue() ===  this.dwTrueVal ) {
          this.checked = true;
    } else if ( this.params.getValue() === this.dwFalseVal ) {
          this.checked = false;
    } else {
          // console.log('Value is Error!');
    }

    if (this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.dwDisabled) {
        this.disabled = this.params.colDef.cellRendererParams.dwDisabled;
    }

    this.formControl = new FormControl(this, params.validators);
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.name = this.params.node.id + '-' + params.column.colId;

    if ( this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);
    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }

    this.formControl.markAsDirty();


    super.init();
  }

  refresh(): boolean {
    return false;
  }


  ngOnDestroy(): void {
    this.validateForm.removeControl(this.name);
  }
}
