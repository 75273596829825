import { Directive, ElementRef, HostListener, Input, AfterViewInit, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, takeUntil, concatAll, withLatestFrom, debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appModalDrag]'
})
export class FxModalDragDirective implements AfterViewInit {
  public modalBody: any; //拖动节点
  public diffX: any;
  public diffY: any;
  public eventName = '';

  constructor(
    private el: ElementRef,
    private render2: Renderer2
  ) { }

  @HostListener('mousedown', ['$event', '$event.target']) onmousedown(e, dom) {
    // console.log(e,dom.className,'[HostListener]')
    this.eventName = dom.className;
    const full = dom.querySelector('.anticon-arrows-alt');
    //console.log(full,'full')
  }



  ngAfterViewInit() {
    setTimeout(() => {
      const box = this.el.nativeElement.offsetParent.parentNode;
      this.drag(box)
    }, 200);

  }

  drag(box) {
    const mouseDown = fromEvent(box, "mousedown");
    const mouseMove = fromEvent(document, "mousemove");
    const mouseUp = fromEvent(document, "mouseup");
    
    mouseDown.pipe(
      map((e: MouseEvent) => {
        console.log((e.target as HTMLInputElement).className != 'fx_le_title' || (e.target as HTMLInputElement).className.indexOf('ant-modal-header') != 0)
        if((e.target as HTMLInputElement).className != 'fx_le_title' && (e.target as HTMLInputElement).className.indexOf('ant-modal-header') != 0) {
          let a,b;
          return {a,b};
        }
        const { left, top } = (e.target as HTMLInputElement).getBoundingClientRect();
        const clickOffsetX = e.clientX - left;
        const clickOffsetY = e.clientY - top;
        return {
          clickOffsetX,
          clickOffsetY
        };
      }),
      map(({ clickOffsetX, clickOffsetY }) => {
        // console.log(clickOffsetX, clickOffsetY,'[mouseMove]')
        return mouseMove.pipe(
          takeUntil(mouseUp),
          map((moveEvent: MouseEvent) => ({
            x: moveEvent.clientX - clickOffsetX,
            y: moveEvent.clientY - clickOffsetY
          })),
          takeUntil(mouseDown)
        );
      }),
      concatAll(),
      
    )
      .subscribe(({ x, y }) => {
        box.style.left = `${x}px`;
        box.style.top = `${y}px`;
      });
  }
  // ngAfterViewChecked() {
  //   //调用框架开窗拖动方法
  //   if(this.el.nativeElement.offsetParent === null) {
  //     return
  //   }
  //   const getAutoDom = this.el.nativeElement.offsetParent.offsetParent;
  //   let modalBody = this.el.nativeElement.querySelector(".fx_modal_content");
  //   const hasFull = this.el.nativeElement.querySelector('.anticon-arrows-alt');
  //   const hasFooter = this.el.nativeElement.querySelector('.anticon-arrows-alt');

  //   // //判断是否有最大化按钮
  //   // if(hasFull == null) {
  //   //   modalBody.style.height = 'auto';
  //   // } else {
  //   //   modalBody.style['max-height'] = 'calc(100vh - 140px)';
  //   // }
  //   //有放大按钮
  //   getAutoDom.onmousedown = (e) => {
  //     var e = e || window.event; //兼容ie浏览器 
  //     let diffX = e.clientX - getAutoDom.offsetLeft; //鼠标点击物体那一刻相对于物体左侧边框的距离=点击时的位置相对于浏览器最左边的距离-物体左边框相对于浏览器最左边的距离 
  //     let diffY = e.clientY - getAutoDom.offsetTop;

  //     //放大|还原
  //     if(e.target.className.indexOf('anticon-arrows-alt') >= 0){
  //       //console.log(modalBody.clientHeight)
  //       //记录是否出发最大化
  //       let sum = 1;
  //       if(getAutoDom.clientWidth < window.innerWidth) {
  //         //存储宽、高、left、top
  //         if(sum == 1) {
  //           localStorage.setItem('firstW',getAutoDom.clientWidth)
  //           localStorage.setItem('firstH',getAutoDom.clientHeight)
  //           localStorage.setItem('firstL',getAutoDom.offsetLeft)
  //           localStorage.setItem('firstT',getAutoDom.offsetTop)
  //           localStorage.setItem('firstB',modalBody.clientHeight)
  //         }
  //         getAutoDom.style.width = window.innerWidth + 'px';
  //         getAutoDom.style.height = 100 + 'vh';
  //         getAutoDom.style.left = 0;
  //         getAutoDom.style.top = 0;
  //         modalBody.style.height = 'calc(100vh - 90px)';
  //         modalBody.style['max-height'] = 'calc(100vh - 90px)';
  //         sum += sum;
  //         return;
  //       }else if(getAutoDom.clientWidth == window.innerWidth) {
  //         getAutoDom.style.width = localStorage.getItem('firstW') + 'px';
  //         getAutoDom.style.height = localStorage.getItem('firstH') + 'px';
  //         getAutoDom.style.left = localStorage.getItem('firstL') + 'px';
  //         getAutoDom.style.top = localStorage.getItem('firstT') + 'px';
  //         modalBody.style.height = localStorage.getItem('firstB') + 'px';
  //         modalBody.style['max-height'] = '80vh';
  //         sum = 1;
  //         return
  //       }
  //     }


  //     if(e.target.className != 'ant-modal-header ng-star-inserted') {
  //       return
  //     }
  //     //禁止超出
  //     // if (typeof getAutoDom.setCapture != 'undefined') {
  //     //   getAutoDom.setCapture();
  //     // }

  //     getAutoDom.onmousemove = function (e) {
  //       debugger
  //       var e = e || window.event; //兼容ie浏览器 
  //       let left = e.clientX - diffX;
  //       let top = e.clientY - diffY;
  //       let dragBox = this;
  //       // console.log(e, diffX, dragBox, left, top, e.path[6], 'getAutoDom++++')
  //       //控制拖拽物体的范围只能在浏览器视窗内，不允许出现滚动条 
  //       // if (left < 0) {
  //       //   left = 0;
  //       // } else if (left > window.innerWidth - dragBox.clientWidth) {
  //       //   left = window.innerWidth - dragBox.clientWidth;
  //       // }
  //       // if (top < 0) {
  //       //   top = 0;
  //       // } else if (top > window.innerHeight - dragBox.clientHeight) {
  //       //   top = window.innerHeight - dragBox.clientHeight;
  //       // }
  //       //移动时重新得到物体的距离，解决拖动时出现晃动的现象 
  //       dragBox.style.left = left + 'px';
  //       dragBox.style.top = top + 'px';
  //     };
  //     getAutoDom.onmouseup = function (e) { //当鼠标弹起来的时候不再移动 
  //       debugger
  //       this.onmousemove = null;
  //       this.onmouseup = null; //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动） 
  //       //修复低版本ie bug 
  //       if (typeof this.releaseCapture != 'undefined') {
  //         this.releaseCapture();
  //       }
  //     };
  //   }

  // }

  setResize() {
    fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(event => {
        console.log('setResize')
        const getAutoDom = this.el.nativeElement.offsetParent.offsetParent;
        let getbounld = this.el.nativeElement.offsetParent.offsetParent.parentNode;
        console.log(window.innerWidth, this.el, getbounld)
        if (window.innerWidth <= this.el.nativeElement.clientWidth) {
          getAutoDom.style.position = 'absolute';
          getbounld.style.overflow = 'auto';
        } else {
          getAutoDom.style.position = 'fixed';
          getbounld.style.overflow = 'hidden';
        }
      })
  }
}
