import { NgQuicksilverModule } from 'ng-quicksilver';
import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule, ViewContainerRef} from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FrameworkModule } from '@webdpt/framework';
import { SystemModule } from './config/system.module';
import { ImplementationModule } from './implementation/implementation.module';
import { SYSTEM_CONFIG } from './config/system-config';
import {FxHotkeyModule} from './implementation/shared/components/fx-hotkey/fx-hotkey.module';
import {HotkeyModule} from 'angular2-hotkeys-d';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularSplitModule } from 'angular-split';
import {AngularDraggableModule} from 'angular2-draggable';
import { FormsModule } from '@angular/forms';
import {GlobalErrorHandler} from './implementation/shared/global-event-handler.service';
//import { FxTabRoutingModule } from './implementation/shared/layout/dw-tab-routing/dw-tab-routing.module'

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgQuicksilverModule.forRoot(), // 暂时于appmodule使用 - limingwei
    FormsModule,
    /*************** 端平台 ***************/
    FrameworkModule.forRoot([], SYSTEM_CONFIG),
    SystemModule.forRoot([]),
    ImplementationModule.forRoot([]),
    /*************** Canon test ***************/
    AngularDraggableModule,
    FxHotkeyModule.forRoot([]),
    HotkeyModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularSplitModule.forRoot()
    /***************************************** */
    //FxTabRoutingModule.forRoot()
  ],
  declarations: [
    AppComponent
  ],
  // providers: [
  //   {
  //     provide: ErrorHandler, useClass: GlobalErrorHandler
  //   }
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
