import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

const ANT_ICONS = [
  {
    type: '方向性图标',
    isSvg2: true,
    iconList: [
      {id: 'step-backward', class: 'anticon anticon-step-backward'},
      {id: 'step-forward', class: 'anticon anticon-step-forward'},
      {id: 'fast-backward', class: 'anticon anticon-fast-backward'},
      {id: 'fast-forward', class: 'anticon anticon-fast-forward'},
      {id: 'shrink', class: 'anticon anticon-shrink'},
      {id: 'arrows-alt', class: 'anticon anticon-arrows-alt'},
      {id: 'down', class: 'anticon anticon-down'},
      {id: 'up', class: 'anticon anticon-up'},
      {id: 'left', class: 'anticon anticon-left'},
      {id: 'right', class: 'anticon anticon-right'},
      {id: 'caret-up', class: 'anticon anticon-caret-up'},
      {id: 'caret-down', class: 'anticon anticon-caret-down'},
      {id: 'caret-left', class: 'anticon anticon-caret-left'},
      {id: 'caret-right', class: 'anticon anticon-caret-right'},
      {id: 'up-circle', class: 'anticon anticon-up-circle'},
      {id: 'down-circle', class: 'anticon anticon-down-circle'},
      {id: 'left-circle', class: 'anticon anticon-left-circle'},
      {id: 'right-circle', class: 'anticon anticon-right-circle'},
      {id: 'up-circle-o', class: 'anticon anticon-up-circle-o'},
      {id: 'down-circle-o', class: 'anticon anticon-down-circle-o'},
      {id: 'right-circle-o', class: 'anticon anticon-right-circle-o'},
      {id: 'left-circle-o', class: 'anticon anticon-left-circle-o'},
      {id: 'double-right', class: 'anticon anticon-double-right'},
      {id: 'double-left', class: 'anticon anticon-double-left'},
      {id: 'verticle-left', class: 'anticon anticon-verticle-left'},
      {id: 'verticle-right', class: 'anticon anticon-verticle-right'},
      {id: 'forward', class: 'anticon anticon-forward'},
      {id: 'backward', class: 'anticon anticon-backward'},
      {id: 'rollback', class: 'anticon anticon-rollback'},
      {id: 'enter', class: 'anticon anticon-enter'},
      {id: 'retweet', class: 'anticon anticon-retweet'},
      {id: 'swap', class: 'anticon anticon-swap'},
      {id: 'swap-left', class: 'anticon anticon-swap-left'},
      {id: 'swap-right', class: 'anticon anticon-swap-right'},
      {id: 'arrow-up', class: 'anticon anticon-arrow-up'},
      {id: 'arrow-down', class: 'anticon anticon-arrow-down'},
      {id: 'arrow-left', class: 'anticon anticon-arrow-left'},
      {id: 'arrow-right', class: 'anticon anticon-arrow-right'},
      {id: 'play-circle', class: 'anticon anticon-play-circle'},
      {id: 'play-circle-o', class: 'anticon anticon-play-circle-o'},
      {id: 'up-square', class: 'anticon anticon-up-square'},
      {id: 'down-square', class: 'anticon anticon-down-square'},
      {id: 'left-square', class: 'anticon anticon-left-square'},
      {id: 'right-square', class: 'anticon anticon-right-square'},
      {id: 'up-square-o', class: 'anticon anticon-up-square-o'},
      {id: 'down-square-o', class: 'anticon anticon-down-square-o'},
      {id: 'left-square-o', class: 'anticon anticon-left-square-o'},
      {id: 'right-square-o', class: 'anticon anticon-right-square-o'},
      {id: 'login', class: 'anticon anticon-login'},
      {id: 'logout', class: 'anticon anticon-logout'}
    ]
  },
  {
    type: '提示建议性图标',
    isSvg2: true,
    iconList: [
      {id: 'menu-fold', class: 'anticon anticon-menu-fold'},
      {id: 'menu-unfold', class: 'anticon anticon-menu-unfold'},
      {id: 'question', class: 'anticon anticon-question'},
      {id: 'question-circle-o', class: 'anticon anticon-question-circle-o'},
      {id: 'question-circle', class: 'anticon anticon-question-circle'},
      {id: 'plus', class: 'anticon anticon-plus'},
      {id: 'plus-circle-o', class: 'anticon anticon-plus-circle-o'},
      {id: 'plus-circle', class: 'anticon anticon-plus-circle'},
      {id: 'pause', class: 'anticon anticon-pause'},
      {id: 'pause-circle-o', class: 'anticon anticon-pause-circle-o'},
      {id: 'pause-circle', class: 'anticon anticon-pause-circle'},
      {id: 'minus', class: 'anticon anticon-minus'},
      {id: 'minus-circle-o', class: 'anticon anticon-minus-circle-o'},
      {id: 'minus-circle', class: 'anticon anticon-minus-circle'},
      {id: 'plus-square', class: 'anticon anticon-plus-square'},
      {id: 'plus-square-o', class: 'anticon anticon-plus-square-o'},
      {id: 'minus-square', class: 'anticon anticon-minus-square'},
      {id: 'minus-square-o', class: 'anticon anticon-minus-square-o'},
      {id: 'info', class: 'anticon anticon-info'},
      {id: 'info-circle-o', class: 'anticon anticon-info-circle-o'},
      {id: 'info-circle', class: 'anticon anticon-info-circle'},
      {id: 'exclamation', class: 'anticon anticon-exclamation'},
      {id: 'exclamation-circle-o', class: 'anticon anticon-exclamation-circle-o'},
      {id: 'exclamation-circle', class: 'anticon anticon-exclamation-circle'},
      {id: 'close', class: 'anticon anticon-close'},
      {id: 'close-circle', class: 'anticon anticon-close-circle'},
      {id: 'close-circle-o', class: 'anticon anticon-close-circle-o'},
      {id: 'close-square', class: 'anticon anticon-close-square'},
      {id: 'close-square-o', class: 'anticon anticon-close-square-o'},
      {id: 'check', class: 'anticon anticon-check'},
      {id: 'check-circle', class: 'anticon anticon-check-circle'},
      {id: 'check-circle-o', class: 'anticon anticon-check-circle-o'},
      {id: 'check-square', class: 'anticon anticon-check-square'},
      {id: 'check-square-o', class: 'anticon anticon-check-square-o'},
      {id: 'clock-circle-o', class: 'anticon anticon-clock-circle-o'},
      {id: 'clock-circle', class: 'anticon anticon-clock-circle'},
      {id: 'warning', class: 'anticon anticon-warning'}
    ]
  },
  {
    type: '网站通用图标',
    isSvg2: true,
    iconList: [
      {id: 'lock', class: 'anticon anticon-lock'},
      {id: 'unlock', class: 'anticon anticon-unlock'},
      {id: 'area-chart', class: 'anticon anticon-area-chart'},
      {id: 'pie-chart', class: 'anticon anticon-pie-chart'},
      {id: 'bar-chart', class: 'anticon anticon-bar-chart'},
      {id: 'dot-chart', class: 'anticon anticon-dot-chart'},
      {id: 'bars', class: 'anticon anticon-bars'},
      {id: 'book', class: 'anticon anticon-book'},
      {id: 'calendar', class: 'anticon anticon-calendar'},
      {id: 'cloud', class: 'anticon anticon-cloud'},
      {id: 'cloud-download', class: 'anticon anticon-cloud-download'},
      {id: 'code', class: 'anticon anticon-code'},
      {id: 'code-o', class: 'anticon anticon-code-o'},
      {id: 'copy', class: 'anticon anticon-copy'},
      {id: 'credit-card', class: 'anticon anticon-credit-card'},
      {id: 'delete', class: 'anticon anticon-delete'},
      {id: 'desktop', class: 'anticon anticon-desktop'},
      {id: 'download', class: 'anticon anticon-download'},
      {id: 'edit', class: 'anticon anticon-edit'},
      {id: 'ellipsis', class: 'anticon anticon-ellipsis'},
      {id: 'file', class: 'anticon anticon-file'},
      {id: 'file-text', class: 'anticon anticon-file-text'},
      {id: 'file-unknown', class: 'anticon anticon-file-unknown'},
      {id: 'file-pdf', class: 'anticon anticon-file-pdf'},
      {id: 'file-word', class: 'anticon anticon-file-word'},
      {id: 'file-excel', class: 'anticon anticon-file-excel'},
      {id: 'file-jpg', class: 'anticon anticon-file-jpg'},
      {id: 'file-ppt', class: 'anticon anticon-file-ppt'},
      {id: 'file-markdown', class: 'anticon anticon-file-markdown'},
      {id: 'file-add', class: 'anticon anticon-file-add'},
      {id: 'folder', class: 'anticon anticon-folder'},
      {id: 'folder-open', class: 'anticon anticon-folder-open'},
      {id: 'folder-add', class: 'anticon anticon-folder-add'},
      {id: 'hdd', class: 'anticon anticon-hdd'},
      {id: 'frown', class: 'anticon anticon-frown'},
      {id: 'frown-o', class: 'anticon anticon-frown-o'},
      {id: 'meh', class: 'anticon anticon-meh'},
      {id: 'meh-o', class: 'anticon anticon-meh-o'},
      {id: 'smile', class: 'anticon anticon-smile'},
      {id: 'smile-o', class: 'anticon anticon-smile-o'},
      {id: 'inbox', class: 'anticon anticon-inbox'},
      {id: 'laptop', class: 'anticon anticon-laptop'},
      {id: 'appstore-o', class: 'anticon anticon-appstore-o'},
      {id: 'appstore', class: 'anticon anticon-appstore'},
      {id: 'line-chart', class: 'anticon anticon-line-chart'},
      {id: 'link', class: 'anticon anticon-link'},
      {id: 'mail', class: 'anticon anticon-mail'},
      {id: 'mobile', class: 'anticon anticon-mobile'},
      {id: 'notification', class: 'anticon anticon-notification'},
      {id: 'paper-clip', class: 'anticon anticon-paper-clip'},
      {id: 'picture', class: 'anticon anticon-picture'},
      {id: 'poweroff', class: 'anticon anticon-poweroff'},
      {id: 'reload', class: 'anticon anticon-reload'},
      {id: 'search', class: 'anticon anticon-search'},
      {id: 'setting', class: 'anticon anticon-setting'},
      {id: 'share-alt', class: 'anticon anticon-share-alt'},
      {id: 'shopping-cart', class: 'anticon anticon-shopping-cart'},
      {id: 'tablet', class: 'anticon anticon-tablet'},
      {id: 'tag', class: 'anticon anticon-tag'},
      {id: 'tag-o', class: 'anticon anticon-tag-o'},
      {id: 'tags', class: 'anticon anticon-tags'},
      {id: 'tags-o', class: 'anticon anticon-tags-o'},
      {id: 'to-top', class: 'anticon anticon-to-top'},
      {id: 'upload', class: 'anticon anticon-upload'},
      {id: 'user', class: 'anticon anticon-user'},
      {id: 'video-camera', class: 'anticon anticon-video-camera'},
      {id: 'home', class: 'anticon anticon-home'},
      {id: 'loading', class: 'anticon anticon-loading'},
      {id: 'loading-3-quarters', class: 'anticon anticon-loading-3-quarters'},
      {id: 'cloud-upload-o', class: 'anticon anticon-cloud-upload-o'},
      {id: 'cloud-download-o', class: 'anticon anticon-cloud-download-o'},
      {id: 'cloud-upload', class: 'anticon anticon-cloud-upload'},
      {id: 'cloud-o', class: 'anticon anticon-cloud-o'},
      {id: 'star-o', class: 'anticon anticon-star-o'},
      {id: 'star', class: 'anticon anticon-star'},
      {id: 'heart-o', class: 'anticon anticon-heart-o'},
      {id: 'heart', class: 'anticon anticon-heart'},
      {id: 'environment', class: 'anticon anticon-environment'},
      {id: 'environment-o', class: 'anticon anticon-environment-o'},
      {id: 'eye', class: 'anticon anticon-eye'},
      {id: 'eye-o', class: 'anticon anticon-eye-o'},
      {id: 'camera', class: 'anticon anticon-camera'},
      {id: 'camera-o', class: 'anticon anticon-camera-o'},
      {id: 'save', class: 'anticon anticon-save'},
      {id: 'team', class: 'anticon anticon-team'},
      {id: 'solution', class: 'anticon anticon-solution'},
      {id: 'phone', class: 'anticon anticon-phone'},
      {id: 'filter', class: 'anticon anticon-filter'},
      {id: 'exception', class: 'anticon anticon-exception'},
      {id: 'export', class: 'anticon anticon-export'},
      {id: 'customer-service', class: 'anticon anticon-customer-service'},
      {id: 'qrcode', class: 'anticon anticon-qrcode'},
      {id: 'scan', class: 'anticon anticon-scan'},
      {id: 'like', class: 'anticon anticon-like'},
      {id: 'like-o', class: 'anticon anticon-like-o'},
      {id: 'dislike', class: 'anticon anticon-dislike'},
      {id: 'dislike-o', class: 'anticon anticon-dislike-o'},
      {id: 'message', class: 'anticon anticon-message'},
      {id: 'pay-circle', class: 'anticon anticon-pay-circle'},
      {id: 'pay-circle-o', class: 'anticon anticon-pay-circle-o'},
      {id: 'calculator', class: 'anticon anticon-calculator'},
      {id: 'pushpin', class: 'anticon anticon-pushpin'},
      {id: 'pushpin-o', class: 'anticon anticon-pushpin-o'},
      {id: 'bulb', class: 'anticon anticon-bulb'},
      {id: 'select', class: 'anticon anticon-select'},
      {id: 'switcher', class: 'anticon anticon-switcher'},
      {id: 'rocket', class: 'anticon anticon-rocket'},
      {id: 'bell', class: 'anticon anticon-bell'},
      {id: 'disconnect', class: 'anticon anticon-disconnect'},
      {id: 'database', class: 'anticon anticon-database'},
      {id: 'compass', class: 'anticon anticon-compass'},
      {id: 'barcode', class: 'anticon anticon-barcode'},
      {id: 'hourglass', class: 'anticon anticon-hourglass'},
      {id: 'key', class: 'anticon anticon-key'},
      {id: 'flag', class: 'anticon anticon-flag'},
      {id: 'layout', class: 'anticon anticon-layout'},
      {id: 'printer', class: 'anticon anticon-printer'},
      {id: 'sound', class: 'anticon anticon-sound'},
      {id: 'usb', class: 'anticon anticon-usb'},
      {id: 'skin', class: 'anticon anticon-skin'},
      {id: 'tool', class: 'anticon anticon-tool'},
      {id: 'sync', class: 'anticon anticon-sync'},
      {id: 'wifi', class: 'anticon anticon-wifi'},
      {id: 'car', class: 'anticon anticon-car'},
      {id: 'schedule', class: 'anticon anticon-schedule'},
      {id: 'user-add', class: 'anticon anticon-user-add'},
      {id: 'user-delete', class: 'anticon anticon-user-delete'},
      {id: 'usergroup-add', class: 'anticon anticon-usergroup-add'},
      {id: 'usergroup-delete', class: 'anticon anticon-usergroup-delete'},
      {id: 'man', class: 'anticon anticon-man'},
      {id: 'woman', class: 'anticon anticon-woman'},
      {id: 'shop', class: 'anticon anticon-shop'},
      {id: 'gift', class: 'anticon anticon-gift'},
      {id: 'idcard', class: 'anticon anticon-idcard'},
      {id: 'medicine-box', class: 'anticon anticon-medicine-box'},
      {id: 'red-envelope', class: 'anticon anticon-red-envelope'},
      {id: 'coffee', class: 'anticon anticon-coffee'},
      {id: 'copyright', class: 'anticon anticon-copyright'},
      {id: 'trademark', class: 'anticon anticon-trademark'},
      {id: 'safety', class: 'anticon anticon-safety'},
      {id: 'wallet', class: 'anticon anticon-wallet'},
      {id: 'bank', class: 'anticon anticon-bank'},
      {id: 'trophy', class: 'anticon anticon-trophy'},
      {id: 'contacts', class: 'anticon anticon-contacts'},
      {id: 'global', class: 'anticon anticon-global'},
      {id: 'shake', class: 'anticon anticon-shake'},
      {id: 'api', class: 'anticon anticon-api'},
      {id: 'fork', class: 'anticon anticon-fork'},
      {id: 'dashboard', class: 'anticon anticon-dashboard'},
      {id: 'form', class: 'anticon anticon-form'},
      {id: 'table', class: 'anticon anticon-table'},
      {id: 'profile', class: 'anticon anticon-profile'}
    ]
  },
  {
    type: '品牌和标识',
    isSvg2: true,
    iconList: [
      {id: 'android', class: 'anticon anticon-android'},
      {id: 'android-o', class: 'anticon anticon-android-o'},
      {id: 'apple', class: 'anticon anticon-apple'},
      {id: 'apple-o', class: 'anticon anticon-apple-o'},
      {id: 'windows', class: 'anticon anticon-windows'},
      {id: 'windows-o', class: 'anticon anticon-windows-o'},
      {id: 'ie', class: 'anticon anticon-ie'},
      {id: 'chrome', class: 'anticon anticon-chrome'},
      {id: 'github', class: 'anticon anticon-github'},
      {id: 'aliwangwang', class: 'anticon anticon-aliwangwang'},
      {id: 'aliwangwang-o', class: 'anticon anticon-aliwangwang-o'},
      {id: 'dingding', class: 'anticon anticon-dingding'},
      {id: 'dingding-o', class: 'anticon anticon-dingding-o'},
      {id: 'weibo-square', class: 'anticon anticon-weibo-square'},
      {id: 'weibo-circle', class: 'anticon anticon-weibo-circle'},
      {id: 'taobao-circle', class: 'anticon anticon-taobao-circle'},
      {id: 'html5', class: 'anticon anticon-html5'},
      {id: 'weibo', class: 'anticon anticon-weibo'},
      {id: 'twitter', class: 'anticon anticon-twitter'},
      {id: 'wechat', class: 'anticon anticon-wechat'},
      {id: 'youtube', class: 'anticon anticon-youtube'},
      {id: 'alipay-circle', class: 'anticon anticon-alipay-circle'},
      {id: 'taobao', class: 'anticon anticon-taobao'},
      {id: 'skype', class: 'anticon anticon-skype'},
      {id: 'qq', class: 'anticon anticon-qq'},
      {id: 'medium-workmark', class: 'anticon anticon-medium-workmark'},
      {id: 'gitlab', class: 'anticon anticon-gitlab'},
      {id: 'medium', class: 'anticon anticon-medium'},
      {id: 'linkedin', class: 'anticon anticon-linkedin'},
      {id: 'google-plus', class: 'anticon anticon-google-plus'},
      {id: 'dropbox', class: 'anticon anticon-dropbox'},
      {id: 'facebook', class: 'anticon anticon-facebook'},
      {id: 'codepen', class: 'anticon anticon-codepen'},
      {id: 'amazon', class: 'anticon anticon-amazon'},
      {id: 'google', class: 'anticon anticon-google'},
      {id: 'codepen-circle', class: 'anticon anticon-codepen-circle'},
      {id: 'alipay', class: 'anticon anticon-alipay'},
      {id: 'ant-design', class: 'anticon anticon-ant-design'},
      {id: 'aliyun', class: 'anticon anticon-aliyun'},
      {id: 'zhihu', class: 'anticon anticon-zhihu'},
      {id: 'slack', class: 'anticon anticon-slack'},
      {id: 'slack-square', class: 'anticon anticon-slack-square'},
      {id: 'behance', class: 'anticon anticon-behance'},
      {id: 'behance-square', class: 'anticon anticon-behance-square'},
      {id: 'dribbble', class: 'anticon anticon-dribbble'},
      {id: 'dribbble-square', class: 'anticon anticon-dribbble-square'},
      {id: 'instagram', class: 'anticon anticon-instagram'},
      {id: 'yuque', class: 'anticon anticon-yuque'}
    ]
  }
];
const MATERIAL_ICONS = [
  {
    type: 'action',
    isSvg2: true,
    iconList: [
      {id: '3d_rotation', class: 'material-icons mc_3d_rotation',isSvg2: true},
      {id: 'accessibility', class: 'material-icons mc_accessibility',isSvg2: true},
      {id: 'accessible', class: 'material-icons mc_accessible',isSvg2: true},
      {id: 'account_balance', class: 'material-icons mc_account_balance',isSvg2: true},
      {id: 'account_balance_wallet', class: 'material-icons mc_account_balance_wallet',isSvg2: true},
      {id: 'account_box', class: 'material-icons mc_account_box',isSvg2: true},
      {id: 'account_circle', class: 'material-icons mc_account_circle',isSvg2: true},
      {id: 'add_shopping_cart', class: 'material-icons mc_add_shopping_cart'},
      {id: 'alarm', class: 'material-icons mc_alarm'},
      {id: 'alarm_add', class: 'material-icons mc_alarm_add'},
      {id: 'alarm_off', class: 'material-icons mc_alarm_off'},
      {id: 'alarm_on', class: 'material-icons mc_alarm_on'},
      {id: 'all_out', class: 'material-icons mc_all_out'},
      {id: 'android', class: 'material-icons mc_android'},
      {id: 'announcement', class: 'material-icons mc_announcement'},
      {id: 'aspect_ratio', class: 'material-icons mc_aspect_ratio'},
      {id: 'assessment', class: 'material-icons mc_assessment'},
      {id: 'assignment', class: 'material-icons mc_assignment'},
      {id: 'assignment_ind', class: 'material-icons mc_assignment_ind'},
      {id: 'assignment_late', class: 'material-icons mc_assignment_late'},
      {id: 'assignment_return', class: 'material-icons mc_assignment_return'},
      {id: 'assignment_returned', class: 'material-icons mc_assignment_returned'},
      {id: 'assignment_turned_in', class: 'material-icons mc_assignment_turned_in'},
      {id: 'autorenew', class: 'material-icons mc_autorenew'},
      {id: 'backup', class: 'material-icons mc_backup'},
      {id: 'book', class: 'material-icons mc_book'},
      {id: 'bookmark', class: 'material-icons mc_bookmark'},
      {id: 'bookmark_border', class: 'material-icons mc_bookmark_border'},
      {id: 'bug_report', class: 'material-icons mc_bug_report'},
      {id: 'build', class: 'material-icons mc_build'},
      {id: 'cached', class: 'material-icons mc_cached'},
      {id: 'camera_enhance', class: 'material-icons mc_camera_enhance'},
      {id: 'card_giftcard', class: 'material-icons mc_card_giftcard'},
      {id: 'card_membership', class: 'material-icons mc_card_membership'},
      {id: 'card_travel', class: 'material-icons mc_card_travel'},
      {id: 'change_history', class: 'material-icons mc_change_history'},
      {id: 'check_circle', class: 'material-icons mc_check_circle'},
      {id: 'chrome_reader_mode', class: 'material-icons mc_chrome_reader_mode'},
      {id: 'class', class: 'material-icons mc_class'},
      {id: 'code', class: 'material-icons mc_code'},
      {id: 'compare_arrows', class: 'material-icons mc_compare_arrows'},
      {id: 'copyright', class: 'material-icons mc_copyright'},
      {id: 'credit_card', class: 'material-icons mc_credit_card'},
      {id: 'dashboard', class: 'material-icons mc_dashboard'},
      {id: 'date_range', class: 'material-icons mc_date_range'},
      {id: 'delete', class: 'material-icons mc_delete'},
      {id: 'delete_forever', class: 'material-icons mc_delete_forever'},
      {id: 'description', class: 'material-icons mc_description'},
      {id: 'dns', class: 'material-icons mc_dns'},
      {id: 'done', class: 'material-icons mc_done'},
      {id: 'done_all', class: 'material-icons mc_done_all'},
      {id: 'donut_large', class: 'material-icons mc_donut_large'},
      {id: 'donut_small', class: 'material-icons mc_donut_small'},
      {id: 'eject', class: 'material-icons mc_eject'},
      {id: 'euro_symbol', class: 'material-icons mc_euro_symbol'},
      {id: 'event', class: 'material-icons mc_event'},
      {id: 'event_seat', class: 'material-icons mc_event_seat'},
      {id: 'exit_to_app', class: 'material-icons mc_exit_to_app'},
      {id: 'explore', class: 'material-icons mc_explore'},
      {id: 'extension', class: 'material-icons mc_extension'},
      {id: 'face', class: 'material-icons mc_face'},
      {id: 'favorite', class: 'material-icons mc_favorite'},
      {id: 'favorite_border', class: 'material-icons mc_favorite_border'},
      {id: 'feedback', class: 'material-icons mc_feedback'},
      {id: 'find_in_page', class: 'material-icons mc_find_in_page'},
      {id: 'find_replace', class: 'material-icons mc_find_replace'},
      {id: 'fingerprint', class: 'material-icons mc_fingerprint'},
      {id: 'flight_land', class: 'material-icons mc_flight_land'},
      {id: 'flight_takeoff', class: 'material-icons mc_flight_takeoff'},
      {id: 'flip_to_back', class: 'material-icons mc_flip_to_back'},
      {id: 'flip_to_front', class: 'material-icons mc_flip_to_front'},
      {id: 'g_translate', class: 'material-icons mc_g_translate'},
      {id: 'gavel', class: 'material-icons mc_gavel'},
      {id: 'get_app', class: 'material-icons mc_get_app'},
      {id: 'gif', class: 'material-icons mc_gif'},
      {id: 'grade', class: 'material-icons mc_grade'},
      {id: 'group_work', class: 'material-icons mc_group_work'},
      {id: 'help', class: 'material-icons mc_help'},
      {id: 'help_outline', class: 'material-icons mc_help_outline'},
      {id: 'highlight_off', class: 'material-icons mc_highlight_off'},
      {id: 'history', class: 'material-icons mc_history'},
      {id: 'home', class: 'material-icons mc_home'},
      {id: 'hourglass_empty', class: 'material-icons mc_hourglass_empty'},
      {id: 'hourglass_full', class: 'material-icons mc_hourglass_full'},
      {id: 'http', class: 'material-icons mc_http'},
      {id: 'https', class: 'material-icons mc_https'},
      {id: 'important_devices', class: 'material-icons mc_important_devices'},
      {id: 'info', class: 'material-icons mc_info'},
      {id: 'info_outline', class: 'material-icons mc_info_outline'},
      {id: 'input', class: 'material-icons mc_input'},
      {id: 'invert_colors', class: 'material-icons mc_invert_colors'},
      {id: 'label', class: 'material-icons mc_label'},
      {id: 'label_outline', class: 'material-icons mc_label_outline'},
      {id: 'language', class: 'material-icons mc_language'},
      {id: 'launch', class: 'material-icons mc_launch'},
      {id: 'lightbulb_outline', class: 'material-icons mc_lightbulb_outline'},
      {id: 'line_style', class: 'material-icons mc_line_style'},
      {id: 'line_weight', class: 'material-icons mc_line_weight'},
      {id: 'list', class: 'material-icons mc_list'},
      {id: 'lock', class: 'material-icons mc_lock'},
      {id: 'lock_open', class: 'material-icons mc_lock_open'},
      {id: 'lock_outline', class: 'material-icons mc_lock_outline'},
      {id: 'loyalty', class: 'material-icons mc_loyalty'},
      {id: 'markunread_mailbox', class: 'material-icons mc_markunread_mailbox'},
      {id: 'motorcycle', class: 'material-icons mc_motorcycle'},
      {id: 'note_add', class: 'material-icons mc_note_add'},
      {id: 'offline_pin', class: 'material-icons mc_offline_pin'},
      {id: 'opacity', class: 'material-icons mc_opacity'},
      {id: 'open_in_browser', class: 'material-icons mc_open_in_browser'},
      {id: 'open_in_new', class: 'material-icons mc_open_in_new'},
      {id: 'open_with', class: 'material-icons mc_open_with'},
      {id: 'pageview', class: 'material-icons mc_pageview'},
      {id: 'pan_tool', class: 'material-icons mc_pan_tool'},
      {id: 'payment', class: 'material-icons mc_payment'},
      {id: 'perm_camera_mic', class: 'material-icons mc_perm_camera_mic'},
      {id: 'perm_contact_calendar', class: 'material-icons mc_perm_contact_calendar'},
      {id: 'perm_data_setting', class: 'material-icons mc_perm_data_setting'},
      {id: 'perm_device_information', class: 'material-icons mc_perm_device_information'},
      {id: 'perm_identity', class: 'material-icons mc_perm_identity'},
      {id: 'perm_media', class: 'material-icons mc_perm_media'},
      {id: 'perm_phone_msg', class: 'material-icons mc_perm_phone_msg'},
      {id: 'perm_scan_wifi', class: 'material-icons mc_perm_scan_wifi'},
      {id: 'pets', class: 'material-icons mc_pets'},
      {id: 'picture_in_picture', class: 'material-icons mc_picture_in_picture'},
      {id: 'picture_in_picture_alt', class: 'material-icons mc_picture_in_picture_alt'},
      {id: 'play_for_work', class: 'material-icons mc_play_for_work'},
      {id: 'polymer', class: 'material-icons mc_polymer'},
      {id: 'power_settings_new', class: 'material-icons mc_power_settings_new'},
      {id: 'pregnant_woman', class: 'material-icons mc_pregnant_woman'},
      {id: 'print', class: 'material-icons mc_print'},
      {id: 'query_builder', class: 'material-icons mc_query_builder'},
      {id: 'question_answer', class: 'material-icons mc_question_answer'},
      {id: 'receipt', class: 'material-icons mc_receipt'},
      {id: 'record_voice_over', class: 'material-icons mc_record_voice_over'},
      {id: 'redeem', class: 'material-icons mc_redeem'},
      {id: 'remove_shopping_cart', class: 'material-icons mc_remove_shopping_cart'},
      {id: 'reorder', class: 'material-icons mc_reorder'},
      {id: 'report_problem', class: 'material-icons mc_report_problem'},
      {id: 'restore', class: 'material-icons mc_restore'},
      {id: 'restore_page', class: 'material-icons mc_restore_page'},
      {id: 'room', class: 'material-icons mc_room'},
      {id: 'rounded_corner', class: 'material-icons mc_rounded_corner'},
      {id: 'rowing', class: 'material-icons mc_rowing'},
      {id: 'schedule', class: 'material-icons mc_schedule'},
      {id: 'search', class: 'material-icons mc_search'},
      {id: 'settings', class: 'material-icons mc_settings'},
      {id: 'settings_applications', class: 'material-icons mc_settings_applications'},
      {id: 'settings_backup_restore', class: 'material-icons mc_settings_backup_restore'},
      {id: 'settings_bluetooth', class: 'material-icons mc_settings_bluetooth'},
      {id: 'settings_brightness', class: 'material-icons mc_settings_brightness'},
      {id: 'settings_cell', class: 'material-icons mc_settings_cell'},
      {id: 'settings_ethernet', class: 'material-icons mc_settings_ethernet'},
      {id: 'settings_input_antenna', class: 'material-icons mc_settings_input_antenna'},
      {id: 'settings_input_component', class: 'material-icons mc_settings_input_component'},
      {id: 'settings_input_composite', class: 'material-icons mc_settings_input_composite'},
      {id: 'settings_input_hdmi', class: 'material-icons mc_settings_input_hdmi'},
      {id: 'settings_input_svideo', class: 'material-icons mc_settings_input_svideo'},
      {id: 'settings_overscan', class: 'material-icons mc_settings_overscan'},
      {id: 'settings_phone', class: 'material-icons mc_settings_phone'},
      {id: 'settings_power', class: 'material-icons mc_settings_power'},
      {id: 'settings_remote', class: 'material-icons mc_settings_remote'},
      {id: 'settings_voice', class: 'material-icons mc_settings_voice'},
      {id: 'shop', class: 'material-icons mc_shop'},
      {id: 'shop_two', class: 'material-icons mc_shop_two'},
      {id: 'shopping_basket', class: 'material-icons mc_shopping_basket'},
      {id: 'shopping_cart', class: 'material-icons mc_shopping_cart'},
      {id: 'speaker_notes', class: 'material-icons mc_speaker_notes'},
      {id: 'speaker_notes_off', class: 'material-icons mc_speaker_notes_off'},
      {id: 'spellcheck', class: 'material-icons mc_spellcheck'},
      // {id: 'star_rate', class: 'material-icons mc_star_rate'},
      {id: 'stars', class: 'material-icons mc_stars'},
      {id: 'store', class: 'material-icons mc_store'},
      {id: 'subject', class: 'material-icons mc_subject'},
      {id: 'supervisor_account', class: 'material-icons mc_supervisor_account'},
      {id: 'swap_horiz', class: 'material-icons mc_swap_horiz'},
      {id: 'swap_vert', class: 'material-icons mc_swap_vert'},
      {id: 'swap_vertical_circle', class: 'material-icons mc_swap_vertical_circle'},
      {id: 'system_update_alt', class: 'material-icons mc_system_update_alt'},
      {id: 'tab', class: 'material-icons mc_tab'},
      {id: 'tab_unselected', class: 'material-icons mc_tab_unselected'},
      {id: 'theaters', class: 'material-icons mc_theaters'},
      {id: 'thumb_down', class: 'material-icons mc_thumb_down'},
      {id: 'thumb_up', class: 'material-icons mc_thumb_up'},
      {id: 'thumbs_up_down', class: 'material-icons mc_thumbs_up_down'},
      {id: 'timeline', class: 'material-icons mc_timeline'},
      {id: 'toc', class: 'material-icons mc_toc'},
      {id: 'today', class: 'material-icons mc_today'},
      {id: 'toll', class: 'material-icons mc_toll'},
      {id: 'touch_app', class: 'material-icons mc_touch_app'},
      {id: 'track_changes', class: 'material-icons mc_track_changes'},
      {id: 'translate', class: 'material-icons mc_translate'},
      {id: 'trending_down', class: 'material-icons mc_trending_down'},
      {id: 'trending_flat', class: 'material-icons mc_trending_flat'},
      {id: 'trending_up', class: 'material-icons mc_trending_up'},
      {id: 'turned_in', class: 'material-icons mc_turned_in'},
      {id: 'turned_in_not', class: 'material-icons mc_turned_in_not'},
      {id: 'update', class: 'material-icons mc_update'},
      {id: 'verified_user', class: 'material-icons mc_verified_user'},
      {id: 'view_agenda', class: 'material-icons mc_view_agenda'},
      {id: 'view_array', class: 'material-icons mc_view_array'},
      {id: 'view_carousel', class: 'material-icons mc_view_carousel'},
      {id: 'view_column', class: 'material-icons mc_view_column'},
      {id: 'view_day', class: 'material-icons mc_view_day'},
      {id: 'view_headline', class: 'material-icons mc_view_headline'},
      {id: 'view_list', class: 'material-icons mc_view_list'},
      {id: 'view_module', class: 'material-icons mc_view_module'},
      {id: 'view_quilt', class: 'material-icons mc_view_quilt'},
      {id: 'view_stream', class: 'material-icons mc_view_stream'},
      {id: 'view_week', class: 'material-icons mc_view_week'},
      {id: 'visibility', class: 'material-icons mc_visibility'},
      {id: 'visibility_off', class: 'material-icons mc_visibility_off'},
      {id: 'watch_later', class: 'material-icons mc_watch_later'},
      {id: 'work', class: 'material-icons mc_work'},
      {id: 'youtube_searched_for', class: 'material-icons mc_youtube_searched_for'},
      {id: 'zoom_in', class: 'material-icons mc_zoom_in'},
      {id: 'zoom_out', class: 'material-icons mc_zoom_out'}
    ]
  },
  {
    type: 'alert',
    isSvg2: true,
    iconList: [
      {id: 'add_alert', class: 'material-icons mc_add_alert'},
      {id: 'error', class: 'material-icons mc_error'},
      {id: 'error_outline', class: 'material-icons mc_error_outline'},
      // {id: 'notification_important', class: 'material-icons mc_notification_important'},
      {id: 'warning', class: 'material-icons mc_warning'},
    ]
  },
  {
    type: 'av',
    isSvg2: true,
    iconList: [
      {id: 'add_to_queue', class: 'material-icons mc_add_to_queue'},
      {id: 'airplay', class: 'material-icons mc_airplay'},
      {id: 'album', class: 'material-icons mc_album'},
      {id: 'art_track', class: 'material-icons mc_art_track'},
      {id: 'av_timer', class: 'material-icons mc_av_timer'},
      {id: 'branding_watermark', class: 'material-icons mc_branding_watermark'},
      {id: 'call_to_action', class: 'material-icons mc_call_to_action'},
      {id: 'closed_caption', class: 'material-icons mc_closed_caption'},
      {id: 'equalizer', class: 'material-icons mc_equalizer'},
      {id: 'explicit', class: 'material-icons mc_explicit'},
      {id: 'fast_forward', class: 'material-icons mc_fast_forward'},
      {id: 'fast_rewind', class: 'material-icons mc_fast_rewind'},
      {id: 'featured_play_list', class: 'material-icons mc_featured_play_list'},
      {id: 'featured_video', class: 'material-icons mc_featured_video'},
      {id: 'fiber_dvr', class: 'material-icons mc_fiber_dvr'},
      {id: 'fiber_manual_record', class: 'material-icons mc_fiber_manual_record'},
      {id: 'fiber_new', class: 'material-icons mc_fiber_new'},
      {id: 'fiber_pin', class: 'material-icons mc_fiber_pin'},
      {id: 'fiber_smart_record', class: 'material-icons mc_fiber_smart_record'},
      {id: 'forward_10', class: 'material-icons mc_forward_10'},
      {id: 'forward_30', class: 'material-icons mc_forward_30'},
      {id: 'forward_5', class: 'material-icons mc_forward_5'},
      {id: 'games', class: 'material-icons mc_games'},
      {id: 'hd', class: 'material-icons mc_hd'},
      {id: 'hearing', class: 'material-icons mc_hearing'},
      {id: 'high_quality', class: 'material-icons mc_high_quality'},
      {id: 'library_add', class: 'material-icons mc_library_add'},
      {id: 'library_books', class: 'material-icons mc_library_books'},
      {id: 'library_music', class: 'material-icons mc_library_music'},
      {id: 'loop', class: 'material-icons mc_loop'},
      {id: 'mic', class: 'material-icons mc_mic'},
      {id: 'mic_none', class: 'material-icons mc_mic_none'},
      {id: 'mic_off', class: 'material-icons mc_mic_off'},
      {id: 'movie', class: 'material-icons mc_movie'},
      {id: 'music_video', class: 'material-icons mc_music_video'},
      {id: 'new_releases', class: 'material-icons mc_new_releases'},
      {id: 'not_interested', class: 'material-icons mc_not_interested'},
      {id: 'note', class: 'material-icons mc_note'},
      {id: 'pause', class: 'material-icons mc_pause'},
      {id: 'pause_circle_filled', class: 'material-icons mc_pause_circle_filled'},
      {id: 'pause_circle_outline', class: 'material-icons mc_pause_circle_outline'},
      {id: 'play_arrow', class: 'material-icons mc_play_arrow'},
      {id: 'play_circle_filled', class: 'material-icons mc_play_circle_filled'},
      // {id: 'play_circle_filled_white', class: 'material-icons mc_play_circle_filled_white'},
      {id: 'play_circle_outline', class: 'material-icons mc_play_circle_outline'},
      {id: 'playlist_add', class: 'material-icons mc_playlist_add'},
      {id: 'playlist_add_check', class: 'material-icons mc_playlist_add_check'},
      {id: 'playlist_play', class: 'material-icons mc_playlist_play'},
      {id: 'queue', class: 'material-icons mc_queue'},
      {id: 'queue_music', class: 'material-icons mc_queue_music'},
      {id: 'queue_play_next', class: 'material-icons mc_queue_play_next'},
      {id: 'radio', class: 'material-icons mc_radio'},
      {id: 'recent_actors', class: 'material-icons mc_recent_actors'},
      {id: 'remove_from_queue', class: 'material-icons mc_remove_from_queue'},
      {id: 'repeat', class: 'material-icons mc_repeat'},
      {id: 'repeat_one', class: 'material-icons mc_repeat_one'},
      {id: 'replay_10', class: 'material-icons mc_replay_10'},
      {id: 'replay', class: 'material-icons mc_replay'},
      {id: 'replay_30', class: 'material-icons mc_replay_30'},
      {id: 'replay', class: 'material-icons mc_replay'},
      {id: 'replay_5', class: 'material-icons mc_replay_5'},
      {id: 'shuffle', class: 'material-icons mc_shuffle'},
      {id: 'skip_next', class: 'material-icons mc_skip_next'},
      {id: 'skip_previous', class: 'material-icons mc_skip_previous'},
      {id: 'slow_motion_video', class: 'material-icons mc_slow_motion_video'},
      {id: 'snooze', class: 'material-icons mc_snooze'},
      {id: 'sort_by_alpha', class: 'material-icons mc_sort_by_alpha'},
      {id: 'stop', class: 'material-icons mc_stop'},
      {id: 'subscriptions', class: 'material-icons mc_subscriptions'},
      {id: 'subtitles', class: 'material-icons mc_subtitles'},
      {id: 'surround_sound', class: 'material-icons mc_surround_sound'},
      {id: 'video_call', class: 'material-icons mc_video_call'},
      {id: 'video_label', class: 'material-icons mc_video_label'},
      {id: 'video_library', class: 'material-icons mc_video_library'},
      {id: 'videocam', class: 'material-icons mc_videocam'},
      {id: 'videocam_off', class: 'material-icons mc_videocam_off'},
      {id: 'volume_down', class: 'material-icons mc_volume_down'},
      {id: 'volume_mute', class: 'material-icons mc_volume_mute'},
      {id: 'volume_off', class: 'material-icons mc_volume_off'},
      {id: 'volume_up', class: 'material-icons mc_volume_up'},
      {id: 'web', class: 'material-icons mc_web'},
      {id: 'web_asset', class: 'material-icons mc_web_asset'}
    ]
  },
  {
    type: 'communication',
    isSvg2: true,
    iconList: [
      {id: 'business', class: 'material-icons mc_business'},
      {id: 'call', class: 'material-icons mc_call'},
      {id: 'call_end', class: 'material-icons mc_call_end'},
      {id: 'call_made', class: 'material-icons mc_call_made'},
      {id: 'call_merge', class: 'material-icons mc_call_merge'},
      {id: 'call_missed', class: 'material-icons mc_call_missed'},
      {id: 'call_missed_outgoing', class: 'material-icons mc_call_missed_outgoing'},
      {id: 'call_received', class: 'material-icons mc_call_received'},
      {id: 'call_split', class: 'material-icons mc_call_split'},
      {id: 'chat', class: 'material-icons mc_chat'},
      {id: 'chat_bubble', class: 'material-icons mc_chat_bubble'},
      {id: 'chat_bubble_outline', class: 'material-icons mc_chat_bubble_outline'},
      {id: 'clear_all', class: 'material-icons mc_clear_all'},
      {id: 'comment', class: 'material-icons mc_comment'},
      {id: 'contact_mail', class: 'material-icons mc_contact_mail'},
      {id: 'contact_phone', class: 'material-icons mc_contact_phone'},
      {id: 'contacts', class: 'material-icons mc_contacts'},
      {id: 'dialer_sip', class: 'material-icons mc_dialer_sip'},
      {id: 'dialpad', class: 'material-icons mc_dialpad'},
      {id: 'email', class: 'material-icons mc_email'},
      {id: 'forum', class: 'material-icons mc_forum'},
      {id: 'import_contacts', class: 'material-icons mc_import_contacts'},
      {id: 'import_export', class: 'material-icons mc_import_export'},
      {id: 'invert_colors_off', class: 'material-icons mc_invert_colors_off'},
      {id: 'live_help', class: 'material-icons mc_live_help'},
      {id: 'location_off', class: 'material-icons mc_location_off'},
      {id: 'location_on', class: 'material-icons mc_location_on'},
      {id: 'mail_outline', class: 'material-icons mc_mail_outline'},
      {id: 'message', class: 'material-icons mc_message'},
      {id: 'no_sim', class: 'material-icons mc_no_sim'},
      {id: 'phone', class: 'material-icons mc_phone'},
      {id: 'phonelink_erase', class: 'material-icons mc_phonelink_erase'},
      {id: 'phonelink_lock', class: 'material-icons mc_phonelink_lock'},
      {id: 'phonelink_ring', class: 'material-icons mc_phonelink_ring'},
      {id: 'phonelink_setup', class: 'material-icons mc_phonelink_setup'},
      {id: 'portable_wifi_off', class: 'material-icons mc_portable_wifi_off'},
      {id: 'present_to_all', class: 'material-icons mc_present_to_all'},
      {id: 'ring_volume', class: 'material-icons mc_ring_volume'},
      {id: 'rss_feed', class: 'material-icons mc_rss_feed'},
      {id: 'screen_share', class: 'material-icons mc_screen_share'},
      {id: 'speaker_phone', class: 'material-icons mc_speaker_phone'},
      {id: 'stay_current_landscape', class: 'material-icons mc_stay_current_landscape'},
      {id: 'stay_current_portrait', class: 'material-icons mc_stay_current_portrait'},
      {id: 'stay_primary_landscape', class: 'material-icons mc_stay_primary_landscape'},
      {id: 'stay_primary_portrait', class: 'material-icons mc_stay_primary_portrait'},
      {id: 'stop_screen_share', class: 'material-icons mc_stop_screen_share'},
      {id: 'swap_calls', class: 'material-icons mc_swap_calls'},
      {id: 'textsms', class: 'material-icons mc_textsms'},
      {id: 'voicemail', class: 'material-icons mc_voicemail'},
      {id: 'vpn_key', class: 'material-icons mc_vpn_key'}
    ]
  },
  {
    type: 'content',
    isSvg2: true,
    iconList: [
      {id: 'add', class: 'material-icons mc_add'},
      {id: 'add_box', class: 'material-icons mc_add_box'},
      {id: 'add_circle', class: 'material-icons mc_add_circle'},
      {id: 'add_circle_outline', class: 'material-icons mc_add_circle_outline'},
      {id: 'archive', class: 'material-icons mc_archive'},
      {id: 'backspace', class: 'material-icons mc_backspace'},
      {id: 'block', class: 'material-icons mc_block'},
      {id: 'clear', class: 'material-icons mc_clear'},
      {id: 'content_copy', class: 'material-icons mc_content_copy'},
      {id: 'content_cut', class: 'material-icons mc_content_cut'},
      {id: 'content_paste', class: 'material-icons mc_content_paste'},
      {id: 'create', class: 'material-icons mc_create'},
      {id: 'delete_sweep', class: 'material-icons mc_delete_sweep'},
      {id: 'drafts', class: 'material-icons mc_drafts'},
      {id: 'filter_list', class: 'material-icons mc_filter_list'},
      {id: 'flag', class: 'material-icons mc_flag'},
      {id: 'font_download', class: 'material-icons mc_font_download'},
      {id: 'forward', class: 'material-icons mc_forward'},
      {id: 'gesture', class: 'material-icons mc_gesture'},
      {id: 'inbox', class: 'material-icons mc_inbox'},
      {id: 'link', class: 'material-icons mc_link'},
      {id: 'low_priority', class: 'material-icons mc_low_priority'},
      {id: 'mail', class: 'material-icons mc_mail'},
      {id: 'markunread', class: 'material-icons mc_markunread'},
      {id: 'move_to_inbox', class: 'material-icons mc_move_to_inbox'},
      {id: 'next_week', class: 'material-icons mc_next_week'},
      {id: 'redo', class: 'material-icons mc_redo'},
      {id: 'remove', class: 'material-icons mc_remove'},
      {id: 'remove_circle', class: 'material-icons mc_remove_circle'},
      {id: 'remove_circle_outline', class: 'material-icons mc_remove_circle_outline'},
      {id: 'reply', class: 'material-icons mc_reply'},
      {id: 'reply_all', class: 'material-icons mc_reply_all'},
      {id: 'report', class: 'material-icons mc_report'},
      {id: 'save', class: 'material-icons mc_save'},
      {id: 'select_all', class: 'material-icons mc_select_all'},
      {id: 'send', class: 'material-icons mc_send'},
      {id: 'sort', class: 'material-icons mc_sort'},
      {id: 'text_format', class: 'material-icons mc_text_format'},
      {id: 'unarchive', class: 'material-icons mc_unarchive'},
      {id: 'undo', class: 'material-icons mc_undo'},
      {id: 'weekend', class: 'material-icons mc_weekend'}
    ]
  },
  {
    type: 'device',
    isSvg2: true,
    iconList: [
      {id: 'attach_file', class: 'material-icons mc_attach_file'},
      {id: 'attach_money', class: 'material-icons mc_attach_money'},
      {id: 'border_all', class: 'material-icons mc_border_all'},
      {id: 'border_bottom', class: 'material-icons mc_border_bottom'},
      {id: 'border_clear', class: 'material-icons mc_border_clear'},
      {id: 'border_color', class: 'material-icons mc_border_color'},
      {id: 'border_horizontal', class: 'material-icons mc_border_horizontal'},
      {id: 'border_inner', class: 'material-icons mc_border_inner'},
      {id: 'border_left', class: 'material-icons mc_border_left'},
      {id: 'border_outer', class: 'material-icons mc_border_outer'},
      {id: 'border_right', class: 'material-icons mc_border_right'},
      {id: 'border_style', class: 'material-icons mc_border_style'},
      {id: 'border_top', class: 'material-icons mc_border_top'},
      {id: 'border_vertical', class: 'material-icons mc_border_vertical'},
      {id: 'bubble_chart', class: 'material-icons mc_bubble_chart'},
      {id: 'drag_handle', class: 'material-icons mc_drag_handle'},
      {id: 'format_align_center', class: 'material-icons mc_format_align_center'},
      {id: 'format_align_justify', class: 'material-icons mc_format_align_justify'},
      {id: 'format_align_left', class: 'material-icons mc_format_align_left'},
      {id: 'format_align_right', class: 'material-icons mc_format_align_right'},
      {id: 'format_bold', class: 'material-icons mc_format_bold'},
      {id: 'format_clear', class: 'material-icons mc_format_clear'},
      {id: 'format_color_fill', class: 'material-icons mc_format_color_fill'},
      {id: 'format_color_reset', class: 'material-icons mc_format_color_reset'},
      {id: 'format_color_text', class: 'material-icons mc_format_color_text'},
      {id: 'format_indent_decrease', class: 'material-icons mc_format_indent_decrease'},
      {id: 'format_indent_increase', class: 'material-icons mc_format_indent_increase'},
      {id: 'format_italic', class: 'material-icons mc_format_italic'},
      {id: 'format_line_spacing', class: 'material-icons mc_format_line_spacing'},
      {id: 'format_list_bulleted', class: 'material-icons mc_format_list_bulleted'},
      {id: 'format_list_numbered', class: 'material-icons mc_format_list_numbered'},
      {id: 'format_paint', class: 'material-icons mc_format_paint'},
      {id: 'format_quote', class: 'material-icons mc_format_quote'},
      {id: 'format_shapes', class: 'material-icons mc_format_shapes'},
      {id: 'format_size', class: 'material-icons mc_format_size'},
      {id: 'format_strikethrough', class: 'material-icons mc_format_strikethrough'},
      {id: 'format_textdirection_l_to_r', class: 'material-icons mc_format_textdirection_l_to_r'},
      {id: 'format_textdirection_r_to_l', class: 'material-icons mc_format_textdirection_r_to_l'},
      {id: 'format_underlined', class: 'material-icons mc_format_underlined'},
      {id: 'functions', class: 'material-icons mc_functions'},
      {id: 'highlight', class: 'material-icons mc_highlight'},
      {id: 'insert_chart', class: 'material-icons mc_insert_chart'},
      {id: 'insert_comment', class: 'material-icons mc_insert_comment'},
      {id: 'insert_drive_file', class: 'material-icons mc_insert_drive_file'},
      {id: 'insert_emoticon', class: 'material-icons mc_insert_emoticon'},
      {id: 'insert_invitation', class: 'material-icons mc_insert_invitation'},
      {id: 'insert_link', class: 'material-icons mc_insert_link'},
      {id: 'insert_photo', class: 'material-icons mc_insert_photo'},
      {id: 'linear_scale', class: 'material-icons mc_linear_scale'},
      {id: 'merge_type', class: 'material-icons mc_merge_type'},
      {id: 'mode_comment', class: 'material-icons mc_mode_comment'},
      {id: 'mode_edit', class: 'material-icons mc_mode_edit'},
      {id: 'monetization_on', class: 'material-icons mc_monetization_on'},
      {id: 'money_off', class: 'material-icons mc_money_off'},
      {id: 'multiline_chart', class: 'material-icons mc_multiline_chart'},
      {id: 'pie_chart', class: 'material-icons mc_pie_chart'},
      {id: 'pie_chart_outlined', class: 'material-icons mc_pie_chart_outlined'},
      {id: 'publish', class: 'material-icons mc_publish'},
      {id: 'short_text', class: 'material-icons mc_short_text'},
      {id: 'show_chart', class: 'material-icons mc_show_chart'},
      {id: 'space_bar', class: 'material-icons mc_space_bar'},
      {id: 'strikethrough_s', class: 'material-icons mc_strikethrough_s'},
      {id: 'text_fields', class: 'material-icons mc_text_fields'},
      {id: 'title', class: 'material-icons mc_title'},
      {id: 'vertical_align_bottom', class: 'material-icons mc_vertical_align_bottom'},
      {id: 'vertical_align_center', class: 'material-icons mc_vertical_align_center'},
      {id: 'vertical_align_top', class: 'material-icons mc_vertical_align_top'},
      {id: 'wrap_text', class: 'material-icons mc_wrap_text'},
      {id: 'access_alarm', class: 'material-icons mc_access_alarm'},
      {id: 'access_alarms', class: 'material-icons mc_access_alarms'},
      {id: 'access_time', class: 'material-icons mc_access_time'},
      {id: 'add_alarm', class: 'material-icons mc_add_alarm'},
      {id: 'airplanemode_active', class: 'material-icons mc_airplanemode_active'},
      {id: 'airplanemode_inactive', class: 'material-icons mc_airplanemode_inactive'},
      // {id: 'battery_20', class: 'material-icons mc_battery_20'},
      // {id: 'battery_30', class: 'material-icons mc_battery_30'},
      // {id: 'battery_50', class: 'material-icons mc_battery_50'},
      // {id: 'battery_60', class: 'material-icons mc_battery_60'},
      // {id: 'battery_80', class: 'material-icons mc_battery_80'},
      // {id: 'battery_90', class: 'material-icons mc_battery_90'},
      {id: 'battery_alert', class: 'material-icons mc_battery_alert'},
      // {id: 'battery_charging_20', class: 'material-icons mc_battery_charging_20'},
      // {id: 'battery_charging_30', class: 'material-icons mc_battery_charging_30'},
      // {id: 'battery_charging_50', class: 'material-icons mc_battery_charging_50'},
      // {id: 'battery_charging_60', class: 'material-icons mc_battery_charging_60'},
      // {id: 'battery_charging_80', class: 'material-icons mc_battery_charging_80'},
      // {id: 'battery_charging_90', class: 'material-icons mc_battery_charging_90'},
      {id: 'battery_charging_full', class: 'material-icons mc_battery_charging_full'},
      {id: 'battery_full', class: 'material-icons mc_battery_full'},
      {id: 'battery_std', class: 'material-icons mc_battery_std'},
      {id: 'battery_unknown', class: 'material-icons mc_battery_unknown'},
      {id: 'bluetooth', class: 'material-icons mc_bluetooth'},
      {id: 'bluetooth_connected', class: 'material-icons mc_bluetooth_connected'},
      {id: 'bluetooth_disabled', class: 'material-icons mc_bluetooth_disabled'},
      {id: 'bluetooth_searching', class: 'material-icons mc_bluetooth_searching'},
      {id: 'brightness_auto', class: 'material-icons mc_brightness_auto'},
      {id: 'brightness_high', class: 'material-icons mc_brightness_high'},
      {id: 'brightness_low', class: 'material-icons mc_brightness_low'},
      {id: 'brightness_medium', class: 'material-icons mc_brightness_medium'},
      {id: 'data_usage', class: 'material-icons mc_data_usage'},
      {id: 'developer_mode', class: 'material-icons mc_developer_mode'},
      {id: 'devices', class: 'material-icons mc_devices'},
      {id: 'dvr', class: 'material-icons mc_dvr'},
      {id: 'gps_fixed', class: 'material-icons mc_gps_fixed'},
      {id: 'gps_not_fixed', class: 'material-icons mc_gps_not_fixed'},
      {id: 'gps_off', class: 'material-icons mc_gps_off'},
      {id: 'graphic_eq', class: 'material-icons mc_graphic_eq'},
      {id: 'location_disabled', class: 'material-icons mc_location_disabled'},
      {id: 'location_searching', class: 'material-icons mc_location_searching'},
      {id: 'network_cell', class: 'material-icons mc_network_cell'},
      {id: 'network_wifi', class: 'material-icons mc_network_wifi'},
      {id: 'nfc', class: 'material-icons mc_nfc'},
      {id: 'screen_lock_landscape', class: 'material-icons mc_screen_lock_landscape'},
      {id: 'screen_lock_portrait', class: 'material-icons mc_screen_lock_portrait'},
      {id: 'screen_lock_rotation', class: 'material-icons mc_screen_lock_rotation'},
      {id: 'screen_rotation', class: 'material-icons mc_screen_rotation'},
      {id: 'sd_storage', class: 'material-icons mc_sd_storage'},
      {id: 'settings_system_daydream', class: 'material-icons mc_settings_system_daydream'},
      // {id: 'signal_cellular_0_bar', class: 'material-icons mc_signal_cellular_0_bar'},
      // {id: 'signal_cellular_1_bar', class: 'material-icons mc_signal_cellular_1_bar'},
      // {id: 'signal_cellular_2_bar', class: 'material-icons mc_signal_cellular_2_bar'},
      // {id: 'signal_cellular_3_bar', class: 'material-icons mc_signal_cellular_3_bar'},
      {id: 'signal_cellular_4_bar', class: 'material-icons mc_signal_cellular_4_bar'},
      // {id: 'signal_cellular_connected_no_internet_0_bar', class: 'material-icons mc_signal_cellular_connected_no_internet_0_bar'},
      // {id: 'signal_cellular_connected_no_internet_1_bar', class: 'material-icons mc_signal_cellular_connected_no_internet_1_bar'},
      // {id: 'signal_cellular_connected_no_internet_2_bar', class: 'material-icons mc_signal_cellular_connected_no_internet_2_bar'},
      // {id: 'signal_cellular_connected_no_internet_3_bar', class: 'material-icons mc_signal_cellular_connected_no_internet_3_bar'},
      // {id: 'signal_cellular_connected_no_internet_4_bar', class: 'material-icons mc_signal_cellular_connected_no_internet_4_bar'},
      // {id: 'signal_cellular_no_sim', class: 'material-icons mc_signal_cellular_no_sim'},
      {id: 'signal_cellular_null', class: 'material-icons mc_signal_cellular_null'},
      {id: 'signal_cellular_off', class: 'material-icons mc_signal_cellular_off'},
      // {id: 'signal_wifi_0_bar', class: 'material-icons mc_signal_wifi_0_bar'},
      // {id: 'signal_wifi_1_bar', class: 'material-icons mc_signal_wifi_1_bar'},
      // {id: 'signal_wifi_1_bar_lock', class: 'material-icons mc_signal_wifi_1_bar_lock'},
      // {id: 'signal_wifi_2_bar', class: 'material-icons mc_signal_wifi_2_bar'},
      // {id: 'signal_wifi_2_bar_lock', class: 'material-icons mc_signal_wifi_2_bar_lock'},
      // {id: 'signal_wifi_3_bar', class: 'material-icons mc_signal_wifi_3_bar'},
      // {id: 'signal_wifi_3_bar_lock', class: 'material-icons mc_signal_wifi_3_bar_lock'},
      // {id: 'signal_wifi_4_bar', class: 'material-icons mc_signal_wifi_4_bar'},
      // {id: 'signal_wifi_4_bar_lock', class: 'material-icons mc_signal_wifi_4_bar_lock'},
      {id: 'signal_wifi_off', class: 'material-icons mc_signal_wifi_off'},
      // {id: 'signal_wifi_statusbar_1_bar', class: 'material-icons mc_signal_wifi_statusbar_1_bar'},
      // {id: 'signal_wifi_statusbar_2_bar', class: 'material-icons mc_signal_wifi_statusbar_2_bar'},
      // {id: 'signal_wifi_statusbar_3_bar', class: 'material-icons mc_signal_wifi_statusbar_3_bar'},
      // {id: 'signal_wifi_statusbar_4_bar', class: 'material-icons mc_signal_wifi_statusbar_4_bar'},
      // {id: 'signal_wifi_statusbar_connected_no_internet_1', class: 'material-icons mc_signal_wifi_statusbar_connected_no_internet_1'},
      // {id: 'signal_wifi_statusbar_connected_no_internet_2', class: 'material-icons mc_signal_wifi_statusbar_connected_no_internet_2'},
      // {id: 'signal_wifi_statusbar_connected_no_internet', class: 'material-icons mc_signal_wifi_statusbar_connected_no_internet'},
      // {id: 'signal_wifi_statusbar_connected_no_internet_3', class: 'material-icons mc_signal_wifi_statusbar_connected_no_internet_3'},
      // {id: 'signal_wifi_statusbar_connected_no_internet_4', class: 'material-icons mc_signal_wifi_statusbar_connected_no_internet_4'},
      // {id: 'signal_wifi_statusbar_not_connected', class: 'material-icons mc_signal_wifi_statusbar_not_connected'},
      // {id: 'signal_wifi_statusbar_null', class: 'material-icons mc_signal_wifi_statusbar_null'},
      {id: 'storage', class: 'material-icons mc_storage'},
      {id: 'usb', class: 'material-icons mc_usb'},
      {id: 'wallpaper', class: 'material-icons mc_wallpaper'},
      {id: 'widgets', class: 'material-icons mc_widgets'},
      {id: 'wifi_lock', class: 'material-icons mc_wifi_lock'},
      {id: 'wifi_tethering', class: 'material-icons mc_wifi_tethering'}
    ]
  },
  {
    type: 'editor',
    isSvg2: true,
    iconList: [
      {id: 'attach_file', class: 'material-icons mc_attach_file'},
      {id: 'attach_money', class: 'material-icons mc_attach_money'},
      {id: 'border_all', class: 'material-icons mc_border_all'},
      {id: 'border_bottom', class: 'material-icons mc_border_bottom'},
      {id: 'border_clear', class: 'material-icons mc_border_clear'},
      {id: 'border_color', class: 'material-icons mc_border_color'},
      {id: 'border_horizontal', class: 'material-icons mc_border_horizontal'},
      {id: 'border_inner', class: 'material-icons mc_border_inner'},
      {id: 'border_left', class: 'material-icons mc_border_left'},
      {id: 'border_outer', class: 'material-icons mc_border_outer'},
      {id: 'border_right', class: 'material-icons mc_border_right'},
      {id: 'border_style', class: 'material-icons mc_border_style'},
      {id: 'border_top', class: 'material-icons mc_border_top'},
      {id: 'border_vertical', class: 'material-icons mc_border_vertical'},
      {id: 'bubble_chart', class: 'material-icons mc_bubble_chart'},
      {id: 'drag_handle', class: 'material-icons mc_drag_handle'},
      {id: 'format_align_center', class: 'material-icons mc_format_align_center'},
      {id: 'format_align_justify', class: 'material-icons mc_format_align_justify'},
      {id: 'format_align_left', class: 'material-icons mc_format_align_left'},
      {id: 'format_align_right', class: 'material-icons mc_format_align_right'},
      {id: 'format_bold', class: 'material-icons mc_format_bold'},
      {id: 'format_clear', class: 'material-icons mc_format_clear'},
      {id: 'format_color_fill', class: 'material-icons mc_format_color_fill'},
      {id: 'format_color_reset', class: 'material-icons mc_format_color_reset'},
      {id: 'format_color_text', class: 'material-icons mc_format_color_text'},
      {id: 'format_indent_decrease', class: 'material-icons mc_format_indent_decrease'},
      {id: 'format_indent_increase', class: 'material-icons mc_format_indent_increase'},
      {id: 'format_italic', class: 'material-icons mc_format_italic'},
      {id: 'format_line_spacing', class: 'material-icons mc_format_line_spacing'},
      {id: 'format_list_bulleted', class: 'material-icons mc_format_list_bulleted'},
      {id: 'format_list_numbered', class: 'material-icons mc_format_list_numbered'},
      {id: 'format_paint', class: 'material-icons mc_format_paint'},
      {id: 'format_quote', class: 'material-icons mc_format_quote'},
      {id: 'format_shapes', class: 'material-icons mc_format_shapes'},
      {id: 'format_size', class: 'material-icons mc_format_size'},
      {id: 'format_strikethrough', class: 'material-icons mc_format_strikethrough'},
      {id: 'format_textdirection_l_to_r', class: 'material-icons mc_format_textdirection_l_to_r'},
      {id: 'format_textdirection_r_to_l', class: 'material-icons mc_format_textdirection_r_to_l'},
      {id: 'format_underlined', class: 'material-icons mc_format_underlined'},
      {id: 'functions', class: 'material-icons mc_functions'},
      {id: 'highlight', class: 'material-icons mc_highlight'},
      {id: 'insert_chart', class: 'material-icons mc_insert_chart'},
      {id: 'insert_comment', class: 'material-icons mc_insert_comment'},
      {id: 'insert_drive_file', class: 'material-icons mc_insert_drive_file'},
      {id: 'insert_emoticon', class: 'material-icons mc_insert_emoticon'},
      {id: 'insert_invitation', class: 'material-icons mc_insert_invitation'},
      {id: 'insert_link', class: 'material-icons mc_insert_link'},
      {id: 'insert_photo', class: 'material-icons mc_insert_photo'},
      {id: 'linear_scale', class: 'material-icons mc_linear_scale'},
      {id: 'merge_type', class: 'material-icons mc_merge_type'},
      {id: 'mode_comment', class: 'material-icons mc_mode_comment'},
      {id: 'mode_edit', class: 'material-icons mc_mode_edit'},
      {id: 'monetization_on', class: 'material-icons mc_monetization_on'},
      {id: 'money_off', class: 'material-icons mc_money_off'},
      {id: 'multiline_chart', class: 'material-icons mc_multiline_chart'},
      {id: 'pie_chart', class: 'material-icons mc_pie_chart'},
      {id: 'pie_chart_outlined', class: 'material-icons mc_pie_chart_outlined'},
      {id: 'publish', class: 'material-icons mc_publish'},
      {id: 'short_text', class: 'material-icons mc_short_text'},
      {id: 'show_chart', class: 'material-icons mc_show_chart'},
      {id: 'space_bar', class: 'material-icons mc_space_bar'},
      {id: 'strikethrough_s', class: 'material-icons mc_strikethrough_s'},
      {id: 'text_fields', class: 'material-icons mc_text_fields'},
      {id: 'title', class: 'material-icons mc_title'},
      {id: 'vertical_align_bottom', class: 'material-icons mc_vertical_align_bottom'},
      {id: 'vertical_align_center', class: 'material-icons mc_vertical_align_center'},
      {id: 'vertical_align_top', class: 'material-icons mc_vertical_align_top'},
      {id: 'wrap_text', class: 'material-icons mc_wrap_text'}
    ]
  },
  {
    type: 'file',
    isSvg2: true,
    iconList: [
      {id: 'attachment', class: 'material-icons mc_attachment'},
      {id: 'cloud', class: 'material-icons mc_cloud'},
      {id: 'cloud_circle', class: 'material-icons mc_cloud_circle'},
      {id: 'cloud_done', class: 'material-icons mc_cloud_done'},
      {id: 'cloud_download', class: 'material-icons mc_cloud_download'},
      {id: 'cloud_off', class: 'material-icons mc_cloud_off'},
      {id: 'cloud_queue', class: 'material-icons mc_cloud_queue'},
      {id: 'cloud_upload', class: 'material-icons mc_cloud_upload'},
      {id: 'create_new_folder', class: 'material-icons mc_create_new_folder'},
      {id: 'file_download', class: 'material-icons mc_file_download'},
      {id: 'file_upload', class: 'material-icons mc_file_upload'},
      {id: 'folder', class: 'material-icons mc_folder'},
      {id: 'folder_open', class: 'material-icons mc_folder_open'},
      {id: 'folder_shared', class: 'material-icons mc_folder_shared'}
    ]
  },
  {
    type: 'hardware',
    isSvg2: true,
    iconList: [
      {id: 'cast', class: 'material-icons mc_cast'},
      {id: 'cast_connected', class: 'material-icons mc_cast_connected'},
      {id: 'computer', class: 'material-icons mc_computer'},
      {id: 'desktop_mac', class: 'material-icons mc_desktop_mac'},
      {id: 'desktop_windows', class: 'material-icons mc_desktop_windows'},
      {id: 'developer_board', class: 'material-icons mc_developer_board'},
      {id: 'device_hub', class: 'material-icons mc_device_hub'},
      {id: 'devices_other', class: 'material-icons mc_devices_other'},
      {id: 'dock', class: 'material-icons mc_dock'},
      {id: 'gamepad', class: 'material-icons mc_gamepad'},
      {id: 'headset', class: 'material-icons mc_headset'},
      {id: 'headset_mic', class: 'material-icons mc_headset_mic'},
      {id: 'keyboard', class: 'material-icons mc_keyboard'},
      {id: 'keyboard_arrow_down', class: 'material-icons mc_keyboard_arrow_down'},
      {id: 'keyboard_arrow_left', class: 'material-icons mc_keyboard_arrow_left'},
      {id: 'keyboard_arrow_right', class: 'material-icons mc_keyboard_arrow_right'},
      {id: 'keyboard_arrow_up', class: 'material-icons mc_keyboard_arrow_up'},
      {id: 'keyboard_backspace', class: 'material-icons mc_keyboard_backspace'},
      {id: 'keyboard_capslock', class: 'material-icons mc_keyboard_capslock'},
      {id: 'keyboard_hide', class: 'material-icons mc_keyboard_hide'},
      {id: 'keyboard_return', class: 'material-icons mc_keyboard_return'},
      {id: 'keyboard_tab', class: 'material-icons mc_keyboard_tab'},
      {id: 'keyboard_voice', class: 'material-icons mc_keyboard_voice'},
      {id: 'laptop', class: 'material-icons mc_laptop'},
      {id: 'laptop_chromebook', class: 'material-icons mc_laptop_chromebook'},
      {id: 'laptop_mac', class: 'material-icons mc_laptop_mac'},
      {id: 'laptop_windows', class: 'material-icons mc_laptop_windows'},
      {id: 'memory', class: 'material-icons mc_memory'},
      {id: 'mouse', class: 'material-icons mc_mouse'},
      {id: 'phone_android', class: 'material-icons mc_phone_android'},
      {id: 'phone_iphone', class: 'material-icons mc_phone_iphone'},
      {id: 'phonelink', class: 'material-icons mc_phonelink'},
      {id: 'phonelink_off', class: 'material-icons mc_phonelink_off'},
      {id: 'power_input', class: 'material-icons mc_power_input'},
      {id: 'router', class: 'material-icons mc_router'},
      {id: 'scanner', class: 'material-icons mc_scanner'},
      {id: 'security', class: 'material-icons mc_security'},
      {id: 'sim_card', class: 'material-icons mc_sim_card'},
      {id: 'smartphone', class: 'material-icons mc_smartphone'},
      {id: 'speaker', class: 'material-icons mc_speaker'},
      {id: 'speaker_group', class: 'material-icons mc_speaker_group'},
      {id: 'tablet', class: 'material-icons mc_tablet'},
      {id: 'tablet_android', class: 'material-icons mc_tablet_android'},
      {id: 'tablet_mac', class: 'material-icons mc_tablet_mac'},
      {id: 'toys', class: 'material-icons mc_toys'},
      {id: 'tv', class: 'material-icons mc_tv'},
      {id: 'videogame_asset', class: 'material-icons mc_videogame_asset'},
      {id: 'watch', class: 'material-icons mc_watch'}
    ]
  },
  {
    type: 'image',
    isSvg2: true,
    iconList: [
      {id: 'add_a_photo', class: 'material-icons mc_add_a_photo'},
      {id: 'add_to_photos', class: 'material-icons mc_add_to_photos'},
      {id: 'adjust', class: 'material-icons mc_adjust'},
      {id: 'assistant', class: 'material-icons mc_assistant'},
      {id: 'assistant_photo', class: 'material-icons mc_assistant_photo'},
      {id: 'audiotrack', class: 'material-icons mc_audiotrack'},
      {id: 'blur_circular', class: 'material-icons mc_blur_circular'},
      {id: 'blur_linear', class: 'material-icons mc_blur_linear'},
      {id: 'blur_off', class: 'material-icons mc_blur_off'},
      {id: 'blur_on', class: 'material-icons mc_blur_on'},
      {id: 'brightness_1', class: 'material-icons mc_brightness_1'},
      {id: 'brightness_2', class: 'material-icons mc_brightness_2'},
      {id: 'brightness_3', class: 'material-icons mc_brightness_3'},
      {id: 'brightness_4', class: 'material-icons mc_brightness_4'},
      {id: 'brightness_5', class: 'material-icons mc_brightness_5'},
      {id: 'brightness_6', class: 'material-icons mc_brightness_6'},
      {id: 'brightness_7', class: 'material-icons mc_brightness_7'},
      {id: 'broken_image', class: 'material-icons mc_broken_image'},
      {id: 'brush', class: 'material-icons mc_brush'},
      {id: 'burst_mode', class: 'material-icons mc_burst_mode'},
      {id: 'camera', class: 'material-icons mc_camera'},
      {id: 'camera_alt', class: 'material-icons mc_camera_alt'},
      {id: 'camera_front', class: 'material-icons mc_camera_front'},
      {id: 'camera_rear', class: 'material-icons mc_camera_rear'},
      {id: 'camera_roll', class: 'material-icons mc_camera_roll'},
      {id: 'center_focus_strong', class: 'material-icons mc_center_focus_strong'},
      {id: 'center_focus_weak', class: 'material-icons mc_center_focus_weak'},
      {id: 'collections', class: 'material-icons mc_collections'},
      {id: 'collections_bookmark', class: 'material-icons mc_collections_bookmark'},
      {id: 'color_lens', class: 'material-icons mc_color_lens'},
      {id: 'colorize', class: 'material-icons mc_colorize'},
      {id: 'compare', class: 'material-icons mc_compare'},
      {id: 'control_point', class: 'material-icons mc_control_point'},
      {id: 'control_point_duplicate', class: 'material-icons mc_control_point_duplicate'},
      {id: 'crop_16_9', class: 'material-icons mc_crop_16_9'},
      {id: 'crop', class: 'material-icons mc_crop'},
      {id: 'crop_3_2', class: 'material-icons mc_crop_3_2'},
      {id: 'crop', class: 'material-icons mc_crop'},
      {id: 'crop_5_4', class: 'material-icons mc_crop_5_4'},
      {id: 'crop_7_5', class: 'material-icons mc_crop_7_5'},
      {id: 'crop_din', class: 'material-icons mc_crop_din'},
      {id: 'crop_free', class: 'material-icons mc_crop_free'},
      {id: 'crop_landscape', class: 'material-icons mc_crop_landscape'},
      {id: 'crop_original', class: 'material-icons mc_crop_original'},
      {id: 'crop_portrait', class: 'material-icons mc_crop_portrait'},
      {id: 'crop_rotate', class: 'material-icons mc_crop_rotate'},
      {id: 'crop_square', class: 'material-icons mc_crop_square'},
      {id: 'dehaze', class: 'material-icons mc_dehaze'},
      {id: 'details', class: 'material-icons mc_details'},
      {id: 'edit', class: 'material-icons mc_edit'},
      {id: 'exposure', class: 'material-icons mc_exposure'},
      {id: 'exposure_neg_1', class: 'material-icons mc_exposure_neg_1'},
      {id: 'exposure_neg_2', class: 'material-icons mc_exposure_neg_2'},
      {id: 'exposure_plus_1', class: 'material-icons mc_exposure_plus_1'},
      {id: 'exposure_plus_2', class: 'material-icons mc_exposure_plus_2'},
      {id: 'exposure_zero', class: 'material-icons mc_exposure_zero'},
      {id: 'filter_1', class: 'material-icons mc_filter_1'},
      {id: 'filter_2', class: 'material-icons mc_filter_2'},
      {id: 'filter', class: 'material-icons mc_filter'},
      {id: 'filter_3', class: 'material-icons mc_filter_3'},
      {id: 'filter_4', class: 'material-icons mc_filter_4'},
      {id: 'filter', class: 'material-icons mc_filter'},
      {id: 'filter_5', class: 'material-icons mc_filter_5'},
      {id: 'filter_6', class: 'material-icons mc_filter_6'},
      {id: 'filter_7', class: 'material-icons mc_filter_7'},
      {id: 'filter_8', class: 'material-icons mc_filter_8'},
      {id: 'filter_9', class: 'material-icons mc_filter_9'},
      {id: 'filter_9_plus', class: 'material-icons mc_filter_9_plus'},
      {id: 'filter_b_and_w', class: 'material-icons mc_filter_b_and_w'},
      {id: 'filter_center_focus', class: 'material-icons mc_filter_center_focus'},
      {id: 'filter_drama', class: 'material-icons mc_filter_drama'},
      {id: 'filter_frames', class: 'material-icons mc_filter_frames'},
      {id: 'filter_hdr', class: 'material-icons mc_filter_hdr'},
      {id: 'filter_none', class: 'material-icons mc_filter_none'},
      {id: 'filter_tilt_shift', class: 'material-icons mc_filter_tilt_shift'},
      {id: 'filter_vintage', class: 'material-icons mc_filter_vintage'},
      {id: 'flare', class: 'material-icons mc_flare'},
      {id: 'flash_auto', class: 'material-icons mc_flash_auto'},
      {id: 'flash_off', class: 'material-icons mc_flash_off'},
      {id: 'flash_on', class: 'material-icons mc_flash_on'},
      {id: 'flip', class: 'material-icons mc_flip'},
      {id: 'gradient', class: 'material-icons mc_gradient'},
      {id: 'grain', class: 'material-icons mc_grain'},
      {id: 'grid_off', class: 'material-icons mc_grid_off'},
      {id: 'grid_on', class: 'material-icons mc_grid_on'},
      {id: 'hdr_off', class: 'material-icons mc_hdr_off'},
      {id: 'hdr_on', class: 'material-icons mc_hdr_on'},
      {id: 'hdr_strong', class: 'material-icons mc_hdr_strong'},
      {id: 'hdr_weak', class: 'material-icons mc_hdr_weak'},
      {id: 'healing', class: 'material-icons mc_healing'},
      {id: 'image', class: 'material-icons mc_image'},
      {id: 'image_aspect_ratio', class: 'material-icons mc_image_aspect_ratio'},
      {id: 'iso', class: 'material-icons mc_iso'},
      {id: 'landscape', class: 'material-icons mc_landscape'},
      {id: 'leak_add', class: 'material-icons mc_leak_add'},
      {id: 'leak_remove', class: 'material-icons mc_leak_remove'},
      {id: 'lens', class: 'material-icons mc_lens'},
      {id: 'linked_camera', class: 'material-icons mc_linked_camera'},
      {id: 'looks', class: 'material-icons mc_looks'},
      {id: 'looks_3', class: 'material-icons mc_looks_3'},
      {id: 'looks_4', class: 'material-icons mc_looks_4'},
      {id: 'looks', class: 'material-icons mc_looks'},
      {id: 'looks_5', class: 'material-icons mc_looks_5'},
      {id: 'looks_6', class: 'material-icons mc_looks_6'},
      {id: 'looks_one', class: 'material-icons mc_looks_one'},
      {id: 'looks_two', class: 'material-icons mc_looks_two'},
      {id: 'loupe', class: 'material-icons mc_loupe'},
      {id: 'monochrome_photos', class: 'material-icons mc_monochrome_photos'},
      {id: 'movie_creation', class: 'material-icons mc_movie_creation'},
      {id: 'movie_filter', class: 'material-icons mc_movie_filter'},
      {id: 'music_note', class: 'material-icons mc_music_note'},
      {id: 'nature', class: 'material-icons mc_nature'},
      {id: 'nature_people', class: 'material-icons mc_nature_people'},
      {id: 'navigate_before', class: 'material-icons mc_navigate_before'},
      {id: 'navigate_next', class: 'material-icons mc_navigate_next'},
      {id: 'palette', class: 'material-icons mc_palette'},
      {id: 'panorama', class: 'material-icons mc_panorama'},
      {id: 'panorama_fish_eye', class: 'material-icons mc_panorama_fish_eye'},
      {id: 'panorama_horizontal', class: 'material-icons mc_panorama_horizontal'},
      {id: 'panorama_vertical', class: 'material-icons mc_panorama_vertical'},
      {id: 'panorama_wide_angle', class: 'material-icons mc_panorama_wide_angle'},
      {id: 'photo', class: 'material-icons mc_photo'},
      {id: 'photo_album', class: 'material-icons mc_photo_album'},
      {id: 'photo_camera', class: 'material-icons mc_photo_camera'},
      {id: 'photo_filter', class: 'material-icons mc_photo_filter'},
      {id: 'photo_library', class: 'material-icons mc_photo_library'},
      {id: 'photo_size_select_actual', class: 'material-icons mc_photo_size_select_actual'},
      {id: 'photo_size_select_large', class: 'material-icons mc_photo_size_select_large'},
      {id: 'photo_size_select_small', class: 'material-icons mc_photo_size_select_small'},
      {id: 'picture_as_pdf', class: 'material-icons mc_picture_as_pdf'},
      {id: 'portrait', class: 'material-icons mc_portrait'},
      {id: 'remove_red_eye', class: 'material-icons mc_remove_red_eye'},
      {id: 'rotate_90_degrees_ccw', class: 'material-icons mc_rotate_90_degrees_ccw'},
      {id: 'rotate_left', class: 'material-icons mc_rotate_left'},
      {id: 'rotate_right', class: 'material-icons mc_rotate_right'},
      {id: 'slideshow', class: 'material-icons mc_slideshow'},
      {id: 'straighten', class: 'material-icons mc_straighten'},
      {id: 'style', class: 'material-icons mc_style'},
      {id: 'switch_camera', class: 'material-icons mc_switch_camera'},
      {id: 'switch_video', class: 'material-icons mc_switch_video'},
      {id: 'tag_faces', class: 'material-icons mc_tag_faces'},
      {id: 'texture', class: 'material-icons mc_texture'},
      {id: 'timelapse', class: 'material-icons mc_timelapse'},
      {id: 'timer_10', class: 'material-icons mc_timer_10'},
      {id: 'timer', class: 'material-icons mc_timer'},
      {id: 'timer_3', class: 'material-icons mc_timer_3'},
      {id: 'timer', class: 'material-icons mc_timer'},
      {id: 'timer_off', class: 'material-icons mc_timer_off'},
      {id: 'tonality', class: 'material-icons mc_tonality'},
      {id: 'transform', class: 'material-icons mc_transform'},
      {id: 'tune', class: 'material-icons mc_tune'},
      {id: 'view_comfy', class: 'material-icons mc_view_comfy'},
      {id: 'view_compact', class: 'material-icons mc_view_compact'},
      {id: 'vignette', class: 'material-icons mc_vignette'},
      {id: 'wb_auto', class: 'material-icons mc_wb_auto'},
      {id: 'wb_cloudy', class: 'material-icons mc_wb_cloudy'},
      {id: 'wb_incandescent', class: 'material-icons mc_wb_incandescent'},
      {id: 'wb_iridescent', class: 'material-icons mc_wb_iridescent'},
      {id: 'wb_sunny', class: 'material-icons mc_wb_sunny'}
    ]
  },
  {
    type: 'maps',
    isSvg2: true,
    iconList: [
      {id: 'add_location', class: 'material-icons mc_add_location'},
      {id: 'beenhere', class: 'material-icons mc_beenhere'},
      {id: 'directions', class: 'material-icons mc_directions'},
      {id: 'directions_bike', class: 'material-icons mc_directions_bike'},
      {id: 'directions_boat', class: 'material-icons mc_directions_boat'},
      {id: 'directions_bus', class: 'material-icons mc_directions_bus'},
      {id: 'directions_car', class: 'material-icons mc_directions_car'},
      {id: 'directions_railway', class: 'material-icons mc_directions_railway'},
      {id: 'directions_run', class: 'material-icons mc_directions_run'},
      {id: 'directions_subway', class: 'material-icons mc_directions_subway'},
      {id: 'directions_transit', class: 'material-icons mc_directions_transit'},
      {id: 'directions_walk', class: 'material-icons mc_directions_walk'},
      {id: 'edit_location', class: 'material-icons mc_edit_location'},
      {id: 'ev_station', class: 'material-icons mc_ev_station'},
      {id: 'flight', class: 'material-icons mc_flight'},
      {id: 'hotel', class: 'material-icons mc_hotel'},
      {id: 'layers', class: 'material-icons mc_layers'},
      {id: 'layers_clear', class: 'material-icons mc_layers_clear'},
      {id: 'local_activity', class: 'material-icons mc_local_activity'},
      {id: 'local_airport', class: 'material-icons mc_local_airport'},
      {id: 'local_atm', class: 'material-icons mc_local_atm'},
      {id: 'local_bar', class: 'material-icons mc_local_bar'},
      {id: 'local_cafe', class: 'material-icons mc_local_cafe'},
      {id: 'local_car_wash', class: 'material-icons mc_local_car_wash'},
      {id: 'local_convenience_store', class: 'material-icons mc_local_convenience_store'},
      {id: 'local_dining', class: 'material-icons mc_local_dining'},
      {id: 'local_drink', class: 'material-icons mc_local_drink'},
      {id: 'local_florist', class: 'material-icons mc_local_florist'},
      {id: 'local_gas_station', class: 'material-icons mc_local_gas_station'},
      {id: 'local_grocery_store', class: 'material-icons mc_local_grocery_store'},
      {id: 'local_hospital', class: 'material-icons mc_local_hospital'},
      {id: 'local_hotel', class: 'material-icons mc_local_hotel'},
      {id: 'local_laundry_service', class: 'material-icons mc_local_laundry_service'},
      {id: 'local_library', class: 'material-icons mc_local_library'},
      {id: 'local_mall', class: 'material-icons mc_local_mall'},
      {id: 'local_movies', class: 'material-icons mc_local_movies'},
      {id: 'local_offer', class: 'material-icons mc_local_offer'},
      {id: 'local_parking', class: 'material-icons mc_local_parking'},
      {id: 'local_pharmacy', class: 'material-icons mc_local_pharmacy'},
      {id: 'local_phone', class: 'material-icons mc_local_phone'},
      {id: 'local_pizza', class: 'material-icons mc_local_pizza'},
      {id: 'local_play', class: 'material-icons mc_local_play'},
      {id: 'local_post_office', class: 'material-icons mc_local_post_office'},
      {id: 'local_printshop', class: 'material-icons mc_local_printshop'},
      {id: 'local_see', class: 'material-icons mc_local_see'},
      {id: 'local_shipping', class: 'material-icons mc_local_shipping'},
      {id: 'local_taxi', class: 'material-icons mc_local_taxi'},
      {id: 'map', class: 'material-icons mc_map'},
      {id: 'my_location', class: 'material-icons mc_my_location'},
      {id: 'navigation', class: 'material-icons mc_navigation'},
      {id: 'near_me', class: 'material-icons mc_near_me'},
      {id: 'person_pin', class: 'material-icons mc_person_pin'},
      {id: 'person_pin_circle', class: 'material-icons mc_person_pin_circle'},
      {id: 'pin_drop', class: 'material-icons mc_pin_drop'},
      {id: 'place', class: 'material-icons mc_place'},
      {id: 'rate_review', class: 'material-icons mc_rate_review'},
      {id: 'restaurant', class: 'material-icons mc_restaurant'},
      {id: 'restaurant_menu', class: 'material-icons mc_restaurant_menu'},
      {id: 'satellite', class: 'material-icons mc_satellite'},
      {id: 'store_mall_directory', class: 'material-icons mc_store_mall_directory'},
      {id: 'streetview', class: 'material-icons mc_streetview'},
      {id: 'subway', class: 'material-icons mc_subway'},
      {id: 'terrain', class: 'material-icons mc_terrain'},
      {id: 'traffic', class: 'material-icons mc_traffic'},
      {id: 'train', class: 'material-icons mc_train'},
      {id: 'tram', class: 'material-icons mc_tram'},
      {id: 'transfer_within_a_station', class: 'material-icons mc_transfer_within_a_station'},
      {id: 'zoom_out_map', class: 'material-icons mc_zoom_out_map'}
    ]
  },
  {
    type: 'navigation',
    isSvg2: true,
    iconList: [
      {id: 'apps', class: 'material-icons mc_apps'},
      {id: 'arrow_back', class: 'material-icons mc_arrow_back'},
      {id: 'arrow_downward', class: 'material-icons mc_arrow_downward'},
      {id: 'arrow_drop_down', class: 'material-icons mc_arrow_drop_down'},
      {id: 'arrow_drop_down_circle', class: 'material-icons mc_arrow_drop_down_circle'},
      {id: 'arrow_drop_up', class: 'material-icons mc_arrow_drop_up'},
      {id: 'arrow_forward', class: 'material-icons mc_arrow_forward'},
      {id: 'arrow_upward', class: 'material-icons mc_arrow_upward'},
      {id: 'cancel', class: 'material-icons mc_cancel'},
      {id: 'check', class: 'material-icons mc_check'},
      {id: 'chevron_left', class: 'material-icons mc_chevron_left'},
      {id: 'chevron_right', class: 'material-icons mc_chevron_right'},
      {id: 'close', class: 'material-icons mc_close'},
      {id: 'expand_less', class: 'material-icons mc_expand_less'},
      {id: 'expand_more', class: 'material-icons mc_expand_more'},
      {id: 'first_page', class: 'material-icons mc_first_page'},
      {id: 'fullscreen', class: 'material-icons mc_fullscreen'},
      {id: 'fullscreen_exit', class: 'material-icons mc_fullscreen_exit'},
      {id: 'last_page', class: 'material-icons mc_last_page'},
      {id: 'menu', class: 'material-icons mc_menu'},
      {id: 'more_horiz', class: 'material-icons mc_more_horiz'},
      {id: 'more_vert', class: 'material-icons mc_more_vert'},
      {id: 'refresh', class: 'material-icons mc_refresh'},
      {id: 'subdirectory_arrow_left', class: 'material-icons mc_subdirectory_arrow_left'},
      {id: 'subdirectory_arrow_right', class: 'material-icons mc_subdirectory_arrow_right'},
      {id: 'unfold_less', class: 'material-icons mc_unfold_less'},
      {id: 'unfold_more', class: 'material-icons mc_unfold_more'}
    ]
  },
  {
    type: 'notification',
    isSvg2: true,
    iconList: [
      {id: 'adb', class: 'material-icons mc_adb'},
      {id: 'airline_seat_flat', class: 'material-icons mc_airline_seat_flat'},
      {id: 'airline_seat_flat_angled', class: 'material-icons mc_airline_seat_flat_angled'},
      {id: 'airline_seat_individual_suite', class: 'material-icons mc_airline_seat_individual_suite'},
      {id: 'airline_seat_legroom_extra', class: 'material-icons mc_airline_seat_legroom_extra'},
      {id: 'airline_seat_legroom_normal', class: 'material-icons mc_airline_seat_legroom_normal'},
      {id: 'airline_seat_legroom_reduced', class: 'material-icons mc_airline_seat_legroom_reduced'},
      {id: 'airline_seat_recline_extra', class: 'material-icons mc_airline_seat_recline_extra'},
      {id: 'airline_seat_recline_normal', class: 'material-icons mc_airline_seat_recline_normal'},
      {id: 'bluetooth_audio', class: 'material-icons mc_bluetooth_audio'},
      {id: 'confirmation_number', class: 'material-icons mc_confirmation_number'},
      {id: 'disc_full', class: 'material-icons mc_disc_full'},
      {id: 'do_not_disturb', class: 'material-icons mc_do_not_disturb'},
      {id: 'do_not_disturb_alt', class: 'material-icons mc_do_not_disturb_alt'},
      {id: 'do_not_disturb_off', class: 'material-icons mc_do_not_disturb_off'},
      {id: 'do_not_disturb_on', class: 'material-icons mc_do_not_disturb_on'},
      {id: 'drive_eta', class: 'material-icons mc_drive_eta'},
      {id: 'enhanced_encryption', class: 'material-icons mc_enhanced_encryption'},
      {id: 'event_available', class: 'material-icons mc_event_available'},
      {id: 'event_busy', class: 'material-icons mc_event_busy'},
      {id: 'event_note', class: 'material-icons mc_event_note'},
      {id: 'folder_special', class: 'material-icons mc_folder_special'},
      {id: 'live_tv', class: 'material-icons mc_live_tv'},
      {id: 'mms', class: 'material-icons mc_mms'},
      {id: 'more', class: 'material-icons mc_more'},
      {id: 'network_check', class: 'material-icons mc_network_check'},
      {id: 'network_locked', class: 'material-icons mc_network_locked'},
      {id: 'no_encryption', class: 'material-icons mc_no_encryption'},
      {id: 'ondemand_video', class: 'material-icons mc_ondemand_video'},
      {id: 'personal_video', class: 'material-icons mc_personal_video'},
      {id: 'phone_bluetooth_speaker', class: 'material-icons mc_phone_bluetooth_speaker'},
      {id: 'phone_forwarded', class: 'material-icons mc_phone_forwarded'},
      {id: 'phone_in_talk', class: 'material-icons mc_phone_in_talk'},
      {id: 'phone_locked', class: 'material-icons mc_phone_locked'},
      {id: 'phone_missed', class: 'material-icons mc_phone_missed'},
      {id: 'phone_paused', class: 'material-icons mc_phone_paused'},
      {id: 'power', class: 'material-icons mc_power'},
      {id: 'priority_high', class: 'material-icons mc_priority_high'},
      {id: 'rv_hookup', class: 'material-icons mc_rv_hookup'},
      {id: 'sd_card', class: 'material-icons mc_sd_card'},
      {id: 'sim_card_alert', class: 'material-icons mc_sim_card_alert'},
      {id: 'sms', class: 'material-icons mc_sms'},
      {id: 'sms_failed', class: 'material-icons mc_sms_failed'},
      {id: 'sync', class: 'material-icons mc_sync'},
      {id: 'sync_disabled', class: 'material-icons mc_sync_disabled'},
      {id: 'sync_problem', class: 'material-icons mc_sync_problem'},
      {id: 'system_update', class: 'material-icons mc_system_update'},
      {id: 'tap_and_play', class: 'material-icons mc_tap_and_play'},
      {id: 'time_to_leave', class: 'material-icons mc_time_to_leave'},
      {id: 'vibration', class: 'material-icons mc_vibration'},
      {id: 'voice_chat', class: 'material-icons mc_voice_chat'},
      {id: 'vpn_lock', class: 'material-icons mc_vpn_lock'},
      {id: 'wc', class: 'material-icons mc_wc'},
      {id: 'wifi', class: 'material-icons mc_wifi'}
    ]
  },
  {
    type: 'places',
    isSvg2: true,
    iconList: [
      {id: 'ac_unit', class: 'material-icons mc_ac_unit'},
      {id: 'airport_shuttle', class: 'material-icons mc_airport_shuttle'},
      {id: 'all_inclusive', class: 'material-icons mc_all_inclusive'},
      {id: 'beach_access', class: 'material-icons mc_beach_access'},
      {id: 'business_center', class: 'material-icons mc_business_center'},
      {id: 'casino', class: 'material-icons mc_casino'},
      {id: 'child_care', class: 'material-icons mc_child_care'},
      {id: 'child_friendly', class: 'material-icons mc_child_friendly'},
      {id: 'fitness_center', class: 'material-icons mc_fitness_center'},
      {id: 'free_breakfast', class: 'material-icons mc_free_breakfast'},
      {id: 'golf_course', class: 'material-icons mc_golf_course'},
      {id: 'hot_tub', class: 'material-icons mc_hot_tub'},
      {id: 'kitchen', class: 'material-icons mc_kitchen'},
      {id: 'pool', class: 'material-icons mc_pool'},
      {id: 'room_service', class: 'material-icons mc_room_service'},
      {id: 'rv_hookup', class: 'material-icons mc_rv_hookup'},
      {id: 'smoke_free', class: 'material-icons mc_smoke_free'},
      {id: 'smoking_rooms', class: 'material-icons mc_smoking_rooms'},
      {id: 'spa', class: 'material-icons mc_spa'}
    ]
  },
  {
    type: 'social',
    isSvg2: true,
    iconList: [
      {id: 'cake', class: 'material-icons mc_cake'},
      {id: 'domain', class: 'material-icons mc_domain'},
      {id: 'group', class: 'material-icons mc_group'},
      {id: 'group_add', class: 'material-icons mc_group_add'},
      {id: 'location_city', class: 'material-icons mc_location_city'},
      {id: 'mood', class: 'material-icons mc_mood'},
      {id: 'mood_bad', class: 'material-icons mc_mood_bad'},
      {id: 'notifications', class: 'material-icons mc_notifications'},
      {id: 'notifications_active', class: 'material-icons mc_notifications_active'},
      {id: 'notifications_none', class: 'material-icons mc_notifications_none'},
      {id: 'notifications_off', class: 'material-icons mc_notifications_off'},
      {id: 'notifications_paused', class: 'material-icons mc_notifications_paused'},
      {id: 'pages', class: 'material-icons mc_pages'},
      {id: 'party_mode', class: 'material-icons mc_party_mode'},
      {id: 'people', class: 'material-icons mc_people'},
      {id: 'people_outline', class: 'material-icons mc_people_outline'},
      {id: 'person', class: 'material-icons mc_person'},
      {id: 'person_add', class: 'material-icons mc_person_add'},
      {id: 'person_outline', class: 'material-icons mc_person_outline'},
      {id: 'plus_one', class: 'material-icons mc_plus_one'},
      {id: 'poll', class: 'material-icons mc_poll'},
      {id: 'public', class: 'material-icons mc_public'},
      {id: 'school', class: 'material-icons mc_school'},
      {id: 'sentiment_dissatisfied', class: 'material-icons mc_sentiment_dissatisfied'},
      {id: 'sentiment_neutral', class: 'material-icons mc_sentiment_neutral'},
      {id: 'sentiment_satisfied', class: 'material-icons mc_sentiment_satisfied'},
      {id: 'sentiment_very_dissatisfied', class: 'material-icons mc_sentiment_very_dissatisfied'},
      {id: 'sentiment_very_satisfied', class: 'material-icons mc_sentiment_very_satisfied'},
      {id: 'share', class: 'material-icons mc_share'},
      {id: 'whatshot', class: 'material-icons mc_whatshot'}
    ]
  },
  {
    type: 'toggle',
    isSvg2: true,
    iconList: [
      {id: 'check_box', class: 'material-icons mc_check_box'},
      {id: 'check_box_outline_blank', class: 'material-icons mc_check_box_outline_blank'},
      {id: 'indeterminate_check_box', class: 'material-icons mc_indeterminate_check_box'},
      {id: 'radio_button_checked', class: 'material-icons mc_radio_button_checked'},
      {id: 'radio_button_unchecked', class: 'material-icons mc_radio_button_unchecked'},
      {id: 'star', class: 'material-icons mc_star'},
      {id: 'star_border', class: 'material-icons mc_star_border'},
      {id: 'star_half', class: 'material-icons mc_star_half'}
    ]
  },
];
const ICONFONT_ICON = [
  {
    type: 'wx',
    isSvg: true,
    iconList: [
      {id: 'icon-12', class: "icon-12",isSvg: true},
      {id: 'icon-0014', class: "iconfont icon-0014",isSvg: true},
      {id: 'icon-07', class: "iconfont icon-07",isSvg: true},
      {id: 'icon-011', class: "iconfont icon-011",isSvg: true},
      {id: 'icon-05', class: "iconfont icon-05",isSvg: true},
      {id: 'icon-0015', class: "iconfont icon-0015",isSvg: true},
      {id: 'icon-20', class: "iconfont icon-20",isSvg: true},
      {id: 'icon-21', class: "iconfont icon-21",isSvg: true},
      {id: 'icon-0016', class: "iconfont icon-0016",isSvg: true},
      {id: 'icon-17', class: "iconfont icon-17",isSvg: true},
      {id: 'icon-0017', class: "iconfont icon-0017",isSvg: true},
      {id: 'icon-16', class: "iconfont icon-16",isSvg: true},
      {id: 'icon-0011', class: "iconfont icon-0011",isSvg: true},
      {id: 'icon-02', class: "iconfont icon-02",isSvg: true},
      {id: 'icon-007', class: "iconfont icon-007",isSvg: true},
      {id: 'icon-061', class: "iconfont icon-061",isSvg: true},
      {id: 'icon-15', class: "iconfont icon-15",isSvg: true},
      {id: 'icon-0013', class: "iconfont icon-0013",isSvg: true},
      {id: 'icon-0018', class: "iconfont icon-0018",isSvg: true},
      {id: 'icon-004', class: "iconfont icon-004",isSvg: true},
      {id: 'icon-06', class: "iconfont icon-06",isSvg: true},
      {id: 'icon-0019', class: "iconfont icon-0019",isSvg: true},
      {id: 'icon-009', class: "iconfont icon-009",isSvg: true},
      {id: 'icon-0021', class: "iconfont icon-0021",isSvg: true},
      {id: 'icon-002', class: "iconfont icon-002",isSvg: true},
      {id: 'icon-001', class: "iconfont icon-001",isSvg: true},
      {id: 'icon-003', class: "iconfont icon-003",isSvg: true},
      {id: 'icon-14', class: "iconfont icon-14",isSvg: true},
      {id: 'icon-0022', class: "iconfont icon-0022",isSvg: true},
      {id: 'icon-0012', class: "iconfont icon-0012",isSvg: true},
      {id: 'icon-03', class: "iconfont icon-03",isSvg: true},
      {id: 'icon-0023', class: "iconfont icon-0023",isSvg: true},
      {id: 'icon-0024', class: "iconfont icon-0024",isSvg: true},
      {id: 'icon-005', class: "iconfont icon-005",isSvg: true},
      {id: 'icon-006', class: "iconfont icon-006",isSvg: true},
      {id: 'icon-0025', class: "iconfont icon-0025",isSvg: true},
      {id: 'icon-0010', class: "iconfont icon-0010",isSvg: true},
      {id: 'icon-008', class: "iconfont icon-008",isSvg: true},
      {id: 'icon-0026', class: "iconfont icon-0026",isSvg: true},
      {id: 'icon-0027', class: "iconfont icon-0027",isSvg: true},
      {id: 'icon-04', class: "iconfont icon-04",isSvg: true},
      {id: 'icon-0028', class: "iconfont icon-0028",isSvg: true},
      {id: 'icon-0029', class: "iconfont icon-0029",isSvg: true},
      {id: 'icon-23', class: "iconfont icon-23",isSvg: true},
      {id: 'icon-24', class: "iconfont icon-24",isSvg: true},
      {id: 'icon-25', class: "iconfont icon-25",isSvg: true},
      {id: 'icon-26', class: "iconfont icon-26",isSvg: true},
      {id: 'icon-0030', class: "iconfont icon-0030",isSvg: true},
      {id: 'icon-01', class: "iconfont icon-01",isSvg: true},
      {id: 'icon-13', class: "iconfont icon-13",isSvg: true},
      {id: 'icon-0039', class: "iconfont icon-0039",isSvg: true},
      {id: 'icon-18', class: "iconfont icon-18",isSvg: true},
      {id: 'icon-09', class: "iconfont icon-09",isSvg: true},
      {id: 'icon-19', class: "iconfont icon-19",isSvg: true},
      {id: 'icon-11', class: "iconfont icon-11",isSvg: true},
      {id: 'icon-10', class: "iconfont icon-10",isSvg: true},
      {id: 'icon-0040', class: "iconfont icon-0040",isSvg: true},
      {id: 'icon-0031', class: "iconfont icon-0031",isSvg: true},
      {id: 'icon-0032', class: "iconfont icon-0032",isSvg: true},
      {id: 'icon-0033', class: "iconfont icon-0033",isSvg: true},
      {id: 'icon-0034', class: "iconfont icon-0034",isSvg: true},
      {id: 'icon-0035', class: "iconfont icon-0035",isSvg: true},
      {id: 'icon-27', class: "iconfont icon-27",isSvg: true},
      {id: 'icon-28', class: "iconfont icon-28",isSvg: true},
      {id: 'icon-29', class: "iconfont icon-29",isSvg: true},
      {id: 'icon-30', class: "iconfont icon-30",isSvg: true},
      {id: 'icon-31', class: "iconfont icon-31",isSvg: true},
      {id: 'icon-32', class: "iconfont icon-32",isSvg: true},
      {id: 'icon-33', class: "iconfont icon-33",isSvg: true},
      {id: 'icon-34', class: "iconfont icon-34",isSvg: true},
      {id: 'icon-35', class: "iconfont icon-35",isSvg: true},
      {id: 'icon-36', class: "iconfont icon-36",isSvg: true},
      {id: 'icon-37', class: "iconfont icon-37",isSvg: true},
      {id: 'icon-38', class: "iconfont icon-38",isSvg: true},
      {id: 'icon-39', class: "iconfont icon-39",isSvg: true},
      {id: 'icon-40', class: "iconfont icon-40",isSvg: true},
      {id: 'icon-41', class: "iconfont icon-41",isSvg: true},
      {id: 'icon-42', class: "iconfont icon-42",isSvg: true},
      {id: 'icon-43', class: "iconfont icon-43",isSvg: true},
      {id: 'icon-44', class: "iconfont icon-44",isSvg: true},
      {id: 'icon-45', class: "iconfont icon-45",isSvg: true},
      {id: 'icon-46', class: "iconfont icon-46",isSvg: true},
      {id: 'icon-47', class: "iconfont icon-47",isSvg: true},
      {id: 'icon-48', class: "iconfont icon-48",isSvg: true},
      {id: 'icon-49', class: "iconfont icon-49",isSvg: true},
      {id: 'icon-50', class: "iconfont icon-50",isSvg: true},
      {id: 'icon-51', class: "iconfont icon-51",isSvg: true},
      {id: 'icon-52', class: "iconfont icon-52",isSvg: true}
    ]
  }
];

@Injectable({
  providedIn: 'root'
})
export class IconDataService {
  public isSvg:boolean;
  public isSvg2:boolean;
  constructor() {
  }
  public getIcons(library: string): Array<any> {
    if ('anticon' === library) {
      return ANT_ICONS;
    } else if ('material-icons' === library) {
      return MATERIAL_ICONS;
    }else if ('iconfont' === library) {
      
      return ICONFONT_ICON;
    } else {
      return [];
    }
  }
}
