/**
 * 废弃，使用FxAgCheckboxRendererComponent
 */
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-checkbox-cell-renderer',
  // template: '<label dw-checkbox [(ngModel)]="value"  [dwDisabled]="isDisabled" (dwCheckedChange)="checkedChange($event)"></label>'
  template: '<label dw-checkbox [(ngModel)]="value"  [dwDisabled]="true"></label>'
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  private params: any;
  value: boolean = false;
  isDisabled: boolean = false;
  dwTrueVal: string = 'Y'
  dwFalseVal: string = 'N'

  agInit(params: any): any {
    console.log('参数', params)
    this.params = params;
    if (this.params.colDef.cellRendererParams && 
        this.params.colDef.cellRendererParams.dwTrueVal) {
          this.dwTrueVal = this.params.colDef.cellRendererParams.dwTrueVal;
    }
    if (this.params.colDef.cellRendererParams && 
      this.params.colDef.cellRendererParams.dwFalseVal) {
        this.dwFalseVal = this.params.colDef.cellRendererParams.dwFalseVal;
    }
    if (params.getValue() == this.dwTrueVal) { this.value = true; }
    if (params.getValue() == this.dwFalseVal) { this.value = false; }
    if (this.params.colDef.cellRendererParams && 
      this.params.colDef.cellRendererParams.disable) {
        this.isDisabled = this.params.colDef.cellRendererParams.disable;
    }
  }

  checkedChange(): void {
    console.log('cell clicked!');
    // if (
    //   this.params.colDef.cellRendererParams && 
    //   this.params.colDef.cellRendererParams.onClick) {
    //   this.params.colDef.cellRendererParams.onClick(this.value);
    // }
  }
  refresh(): boolean {
    return false;
  }

}
