import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DwModalRef} from 'ng-quicksilver';
import {TranslateService} from '@ngx-translate/core';
import {IconDataService} from './icon-data.service';

const ANT_ICONS = [
  {
    type: '方向性图标',
    iconList: [
      {class: 'step-backward'},
      {class: 'step-forward'},
      {class: 'fast-backward'},
      {class: 'fast-forward'},
      {class: 'shrink'},
      {class: 'arrows-alt'},
      {class: 'down'},
      {class: 'up'},
      {class: 'left'},
      {class: 'right'},
      {class: 'caret-up'},
      {class: 'caret-down'},
      {class: 'caret-left'},
      {class: 'caret-right'},
      {class: 'up-circle'},
      {class: 'down-circle'},
      {class: 'left-circle'},
      {class: 'right-circle'},
      {class: 'up-circle-o'},
      {class: 'down-circle-o'},
      {class: 'right-circle-o'},
      {class: 'left-circle-o'},
      {class: 'double-right'},
      {class: 'double-left'},
      {class: 'verticle-left'},
      {class: 'verticle-right'},
      {class: 'forward'},
      {class: 'backward'},
      {class: 'rollback'},
      {class: 'enter'},
      {class: 'retweet'},
      {class: 'swap'},
      {class: 'swap-left'},
      {class: 'swap-right'},
      {class: 'arrow-up'},
      {class: 'arrow-down'},
      {class: 'arrow-left'},
      {class: 'arrow-right'},
      {class: 'play-circle'},
      {class: 'play-circle-o'},
      {class: 'up-square'},
      {class: 'down-square'},
      {class: 'left-square'},
      {class: 'right-square'},
      {class: 'up-square-o'},
      {class: 'down-square-o'},
      {class: 'left-square-o'},
      {class: 'right-square-o'},
      {class: 'login'},
      {class: 'logout'}
    ]
  },
  {
    type: '提示建议性图标',
    iconList: [
      {class: 'menu-fold'},
      {class: 'menu-unfold'},
      {class: 'question'},
      {class: 'question-circle-o'},
      {class: 'question-circle'},
      {class: 'plus'},
      {class: 'plus-circle-o'},
      {class: 'plus-circle'},
      {class: 'pause'},
      {class: 'pause-circle-o'},
      {class: 'pause-circle'},
      {class: 'minus'},
      {class: 'minus-circle-o'},
      {class: 'minus-circle'},
      {class: 'plus-square'},
      {class: 'plus-square-o'},
      {class: 'minus-square'},
      {class: 'minus-square-o'},
      {class: 'info'},
      {class: 'info-circle-o'},
      {class: 'info-circle'},
      {class: 'exclamation'},
      {class: 'exclamation-circle-o'},
      {class: 'exclamation-circle'},
      {class: 'close'},
      {class: 'close-circle'},
      {class: 'close-circle-o'},
      {class: 'close-square'},
      {class: 'close-square-o'},
      {class: 'check'},
      {class: 'check-circle'},
      {class: 'check-circle-o'},
      {class: 'check-square'},
      {class: 'check-square-o'},
      {class: 'clock-circle-o'},
      {class: 'clock-circle'},
      {class: 'warning'}
    ]
  },
  {
    type: '网站通用图标',
    iconList: [
      {class: 'lock'},
      {class: 'unlock'},
      {class: 'area-chart'},
      {class: 'pie-chart'},
      {class: 'bar-chart'},
      {class: 'dot-chart'},
      {class: 'bars'},
      {class: 'book'},
      {class: 'calendar'},
      {class: 'cloud'},
      {class: 'cloud-download'},
      {class: 'code'},
      {class: 'code-o'},
      {class: 'copy'},
      {class: 'credit-card'},
      {class: 'delete'},
      {class: 'desktop'},
      {class: 'download'},
      {class: 'edit'},
      {class: 'ellipsis'},
      {class: 'file'},
      {class: 'file-text'},
      {class: 'file-unknown'},
      {class: 'file-pdf'},
      {class: 'file-word'},
      {class: 'file-excel'},
      {class: 'file-jpg'},
      {class: 'file-ppt'},
      {class: 'file-markdown'},
      {class: 'file-add'},
      {class: 'folder'},
      {class: 'folder-open'},
      {class: 'folder-add'},
      {class: 'hdd'},
      {class: 'frown'},
      {class: 'frown-o'},
      {class: 'meh'},
      {class: 'meh-o'},
      {class: 'smile'},
      {class: 'smile-o'},
      {class: 'inbox'},
      {class: 'laptop'},
      {class: 'appstore-o'},
      {class: 'appstore'},
      {class: 'line-chart'},
      {class: 'link'},
      {class: 'mail'},
      {class: 'mobile'},
      {class: 'notification'},
      {class: 'paper-clip'},
      {class: 'picture'},
      {class: 'poweroff'},
      {class: 'reload'},
      {class: 'search'},
      {class: 'setting'},
      {class: 'share-alt'},
      {class: 'shopping-cart'},
      {class: 'tablet'},
      {class: 'tag'},
      {class: 'tag-o'},
      {class: 'tags'},
      {class: 'tags-o'},
      {class: 'to-top'},
      {class: 'upload'},
      {class: 'user'},
      {class: 'video-camera'},
      {class: 'home'},
      {class: 'loading'},
      {class: 'loading-3-quarters'},
      {class: 'cloud-upload-o'},
      {class: 'cloud-download-o'},
      {class: 'cloud-upload'},
      {class: 'cloud-o'},
      {class: 'star-o'},
      {class: 'star'},
      {class: 'heart-o'},
      {class: 'heart'},
      {class: 'environment'},
      {class: 'environment-o'},
      {class: 'eye'},
      {class: 'eye-o'},
      {class: 'camera'},
      {class: 'camera-o'},
      {class: 'save'},
      {class: 'team'},
      {class: 'solution'},
      {class: 'phone'},
      {class: 'filter'},
      {class: 'exception'},
      {class: 'export'},
      {class: 'customer-service'},
      {class: 'qrcode'},
      {class: 'scan'},
      {class: 'like'},
      {class: 'like-o'},
      {class: 'dislike'},
      {class: 'dislike-o'},
      {class: 'message'},
      {class: 'pay-circle'},
      {class: 'pay-circle-o'},
      {class: 'calculator'},
      {class: 'pushpin'},
      {class: 'pushpin-o'},
      {class: 'bulb'},
      {class: 'select'},
      {class: 'switcher'},
      {class: 'rocket'},
      {class: 'bell'},
      {class: 'disconnect'},
      {class: 'database'},
      {class: 'compass'},
      {class: 'barcode'},
      {class: 'hourglass'},
      {class: 'key'},
      {class: 'flag'},
      {class: 'layout'},
      {class: 'printer'},
      {class: 'sound'},
      {class: 'usb'},
      {class: 'skin'},
      {class: 'tool'},
      {class: 'sync'},
      {class: 'wifi'},
      {class: 'car'},
      {class: 'schedule'},
      {class: 'user-add'},
      {class: 'user-delete'},
      {class: 'usergroup-add'},
      {class: 'usergroup-delete'},
      {class: 'man'},
      {class: 'woman'},
      {class: 'shop'},
      {class: 'gift'},
      {class: 'idcard'},
      {class: 'medicine-box'},
      {class: 'red-envelope'},
      {class: 'coffee'},
      {class: 'copyright'},
      {class: 'trademark'},
      {class: 'safety'},
      {class: 'wallet'},
      {class: 'bank'},
      {class: 'trophy'},
      {class: 'contacts'},
      {class: 'global'},
      {class: 'shake'},
      {class: 'api'},
      {class: 'fork'},
      {class: 'dashboard'},
      {class: 'form'},
      {class: 'table'},
      {class: 'profile'}
    ]
  },
  {
    type: '品牌和标识',
    iconList: [
      {class: 'android'},
      {class: 'android-o'},
      {class: 'apple'},
      {class: 'apple-o'},
      {class: 'windows'},
      {class: 'windows-o'},
      {class: 'ie'},
      {class: 'chrome'},
      {class: 'github'},
      {class: 'aliwangwang'},
      {class: 'aliwangwang-o'},
      {class: 'dingding'},
      {class: 'dingding-o'},
      {class: 'weibo-square'},
      {class: 'weibo-circle'},
      {class: 'taobao-circle'},
      {class: 'html5'},
      {class: 'weibo'},
      {class: 'twitter'},
      {class: 'wechat'},
      {class: 'youtube'},
      {class: 'alipay-circle'},
      {class: 'taobao'},
      {class: 'skype'},
      {class: 'qq'},
      {class: 'medium-workmark'},
      {class: 'gitlab'},
      {class: 'medium'},
      {class: 'linkedin'},
      {class: 'google-plus'},
      {class: 'dropbox'},
      {class: 'facebook'},
      {class: 'codepen'},
      {class: 'amazon'},
      {class: 'google'},
      {class: 'codepen-circle'},
      {class: 'alipay'},
      {class: 'ant-design'},
      {class: 'aliyun'},
      {class: 'zhihu'},
      {class: 'slack'},
      {class: 'slack-square'},
      {class: 'behance'},
      {class: 'behance-square'},
      {class: 'dribbble'},
      {class: 'dribbble-square'},
      {class: 'instagram'},
      {class: 'yuque'}
    ]
  }
];

@Component({
  selector: 'app-fx-icon-picker-modal',
  templateUrl: './fx-icon-picker-modal.component.html',
  styleUrls: ['./fx-icon-picker-modal.component.less'],
  providers: [IconDataService],
  encapsulation: ViewEncapsulation.None
})
export class FxIconPickerModalComponent implements OnInit {
  @Input() title: string = '选择图标';
  @Input() iconLib: string = 'anticon';
  @Input() namespace: string = 'anticon';
  selectedIcon: any = {};

  icons: Array<any> = [];
  draggable: true;

  constructor(private modal: DwModalRef, private translateService: TranslateService, private iconData: IconDataService) {
  }

  ngOnInit(): void {
    // this.icons = this.iconData.getIcons('anticon');
    // this.icons = this.iconData.getIcons('material-icons');
    this.getIcons(this.namespace);
  }

  getIcons(namespace: string): void {
    this.icons = this.iconData.getIcons(namespace);
  }

  onClickOK(): void {
    this.modal.triggerOk();
  }

  onClickCancel(): void {
    this.modal.triggerCancel();
  }
  fxCloseModal(): void {
    this.modal.triggerCancel();
  }
}
