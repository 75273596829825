import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fx-ag-icon-renderer',
  templateUrl: './fx-ag-icon-renderer.component.html',
  styleUrls: ['./fx-ag-icon-renderer.component.less']
})
export class FxAgIconRendererComponent {
  iconID: string;

  constructor() { }

  agInit(params: any): void {
    if (params.value) {
      this.iconID = params.value;
    }
  }
}
