/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
/**
 * @record
 */
export function DwTreeNodeOptions() {}
export class DwTreeNode {
  /**
   * @param {?} option
   * @param {?=} parent
   */
  constructor(option, parent = null) {
    this.level = 0;
    this.title = option.name || '---';
    this.key = option.id || null;
    this.isLeaf = (option.child !== undefined && option.child.length > 0) ? false : true;
    // this.isLeaf = option.isLeaf || false;
    this.origin = option;
    this.children = [];
    this.parentNode = parent;
    this.topNode = option;
    this.type = option.type;
    this.icon = option.iconClass;
    // option params
    this.isChecked = option.checked || false;
    this.isSelectable = option.disabled || (option.selectable === false ? false : true);
    this.isDisabled = option.disabled || false;
    this.isDisableCheckbox = option.disableCheckbox || false;
    this.isExpanded = option.isLeaf ? false : (option.expanded || false);
    this.isAllChecked = option.checked || false;
    this.isHalfChecked = false;
    this.isSelected = (!option.disabled && option.selected) || false;
    this.isLoading = false;
    this.isMatched = false;
    /**
     * parent's checked status will affect children while initializing
     */
    if (parent) {
      this.level = parent.level + 1;
      this.topNode = parent.topNode;
    } else {
      this.level = 0;
      this.topNode = option;
    }
    if (typeof (option.child) !== 'undefined' && option.child !== null) {
      option.child.forEach((nodeOptions) => {
        if (option.checked && !option.disabled && !nodeOptions.disabled && !nodeOptions.disableCheckbox) {
          nodeOptions.checked = option.checked;
        }
        this.children.push(new DwTreeNode(nodeOptions, this));
      });
    }
  }
  /**
   * @param {?=} checked
   * @param {?=} halfChecked
   * @return {?}
   */
  setChecked(checked = false, halfChecked = false) {
    this.origin.checked = checked;
    this.isChecked = checked;
    this.isAllChecked = checked;
    this.isHalfChecked = halfChecked;
  }
  /**
   * @param {?} value
   * @return {?}
   */
  setExpanded(value) {
    this.origin.expanded = value;
    this.isExpanded = value;
  }
  /**
   * @param {?} value
   * @return {?}
   */
  setSelected(value) {
    this.origin.selected = value;
    this.isSelected = value;
  }
  /**
   * @return {?}
   */
  getParentNode() {
    return this.parentNode;
  }
  /**
   * @return {?}
   */
  getTopNode() {
    return this.topNode;
  }
  /**
   * @return {?}
   */
  getChildren() {
    return this.children;
  }
  /**
   * 支持按索引位置插入,叶子节点不可添加
   * @param {?} children
   * @param {?=} childPos
   * @return {?}
   */
  addChildren(children, childPos = -1) {
    if (!this.isLeaf) {
      children.forEach((node) => {
        /** @type {?} */
        const refreshLevel = (n) => {
          n.getChildren().forEach(c => {
            c.level = c.getParentNode().level + 1;
            // flush origin
            c.origin.level = c.level;
            refreshLevel(c);
          });
        };
        /** @type {?} */
        let child = node;
        if (child instanceof DwTreeNode) {
          child.parentNode = this;
        } else {
          child = new DwTreeNode(node, this);
        }
        child.level = this.level + 1;
        child.origin.level = child.level;
        refreshLevel(child);
        try {
          childPos === -1 ? this.children.push(child) : this.children.splice(childPos, 0, child);
          // flush origin
        } catch (e) {}
      });
      this.origin.children = this.getChildren().map(v => v.origin);
      // remove loading state
      this.isLoading = false;
    }
  }
  /**
   * @return {?}
   */
  clearChildren() {
    this.children = [];
  }
}
