import {DwNewRouteKeyService, DwRoutingMessageService} from '@webdpt/framework';
import {FxDapHttpClient} from 'app/implementation/shared/fx-http-client';
import {FxModalService} from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import {Component, Input, Output, OnInit, ViewEncapsulation, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DwModalService} from 'ng-quicksilver';
import { Observable, Subject } from 'rxjs';
import {publishLast, delay, debounceTime, switchMap} from 'rxjs/operators';
import {GridOptions, GridApi, ColumnApi} from 'ag-grid-community';
import {DwTreeNode, DwTreeComponent} from 'app/implementation/shared/tree/dw-tree-node';
import * as _ from 'lodash';

@Component({
  selector: 'fx-tool-panel-sider',
  templateUrl: './fx-tool-panel.component.html',
  styleUrls: ['./fx-tool-panel.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FxToolPanelComponent implements OnInit {
  @Input() columnDefs: Array<any>;   // 作业当中grid设定的columnDefs
  @Input() gridApi: GridApi;   // gridApi
  @Input() gridColumnApi: ColumnApi;   // ColumnApi
  @Input() id: string;   // 记录grid的唯一性，命名规则是页面编号 + gridApi
  @Input() state: any;   // 记录grid状态：浏览/编辑
  @Input() columnTreeData: DwTreeNode[] = null; // 树结构的columnDefs
  @Input() disableAutoSize: any; // 是否禁用自适应
  @Input() availableColumns: Array<string>; // 可用栏位 (经过权限校验)
  @Output() saveColumnState: EventEmitter<any> = new EventEmitter();
  @Output() restColumnState: EventEmitter<any> = new EventEmitter();

  public columnDefsInit: Array<any>; // 备份columnDefs
  public maxWidth: number = 200; // 最大宽度

  constructor(
    public router: DwNewRouteKeyService,
    public dwMessage: DwRoutingMessageService,
    public http: FxDapHttpClient,
    public fxModalService: FxModalService,
    private translateService: TranslateService,
    public dwModalService: DwModalService
  ) {

  }

  ngOnInit(): any {
    // 获取数据有时间差待解决
    setTimeout(() => {
      // 监听column变化保存columnState
      this.gridApi.addEventListener('columnPinned', this.saveState);
      this.gridApi.addEventListener('columnResized', this.saveState);
      this.gridApi.addEventListener('columnMoved', this.saveState);
      this.gridApi.addEventListener('newColumnsLoaded', this.saveState);
      this.gridApi.addEventListener('gridColumnsChanged', this.saveState);
      this.gridApi.addEventListener('displayedColumnsChanged', this.saveState);
      // this.gridApi.addEventListener('virtualColumnsChanged', this.saveState);
    }, 1000);
  }

  private saveState = (event: any): void => {
    // const _id = 'fxbi001-s01-master';
    const _id = event.api.gridOptionsWrapper.gridOptions.context.gridId;
    this.saveColumnState.emit({gridId: _id, columnApi: event.columnApi});
  }

  // 初始化column树结构
  setParamTreeData(data: any): void {
    const _mapAvailableColumns = this.availableColumns.reduce((obj, item) => ({...obj, [item]: 1}), {});
    const _availableColumn = data.filter(col => (
      // grid 只读时，会设置 operation 隐藏。因此 operation 栏位是否可见不只由 gridColDef 决定。
      // 为简化逻辑，改为这里不显示 operation 控制。
      col.field !== 'operation' && (col.field === undefined || _mapAvailableColumns[col.field] || !isNaN(col.colId * 1))
      // (col.field === undefined || col.field === 'operation' || _mapAvailableColumns[col.field] || !isNaN(col.colId * 1))
    ));
    this.columnTreeData = _.cloneDeep(_availableColumn);
    // this.columnTreeData = _.cloneDeep(data);
    // 查询列的状态
    const columnState = this.gridColumnApi.getColumnState();
    this.setTreeProperty(this.columnTreeData, columnState);
    this.setParentNodeStus(this.columnTreeData);
    console.log(this.columnTreeData);
  }

  // 更新树的属性
  setTreeProperty(arr, columnState): void {
    arr.forEach(item => {
      item.title = item.headerName;
      item.key = item.field;
      // 根据缓存的记录重新赋值
      const idx = columnState.findIndex(col => {
        return item.key == col.colId;
      });
      if (idx !== -1) {
        item.hide = columnState[idx].hide;
        item.pinned = columnState[idx].pinned;
      }
      if (item.children && item.children.length) {
        this.setTreeProperty(item.children, columnState);
      } else {
        item.isLeaf = true; // 判断是否是叶子节点
      }
    });
  }

  // 更新非叶子节点hide和pinned状态
  setParentNodeStus(data) {
    data.forEach(col => {
      if (col.isLeaf != true && col.children && col.children.length) {
        // 非叶子节点hide状态
        const hideArr = col.children.filter(child => {
          return child.hide == false;
        });
        if (hideArr.length == col.children.length) {
          col.hide = false;
        } else {
          col.hide = true;
        }
        // 叶子节点pinned状态-left
        const pinnedLeftArr = col.children.filter(child => {
          return child.pinned == 'left';
        });
        // 叶子节点pinned状态-right
        const pinnedRightArr = col.children.filter(child => {
          return child.pinned == 'right';
        });
        if (pinnedLeftArr.length == col.children.length) {
          col.pinned = 'left';
          return;
        }
        if (pinnedRightArr.length == col.children.length) {
          col.pinned = 'right';
          return;
        }
        col.pinned = '';
      }
    });
  }

  // 栏位根据内容设定自动宽度，且不能超过集团参数设定最大宽度，若超过则使用集团参数设定最大宽度
  public setAutoSize() {
    // 根据作业设定是否禁用自动宽度
    if (this.disableAutoSize) {
      return;
    }
    // const columnList = this.gridColumnApi.getAllDisplayedColumns(); // 获取当前显示的列
    // this.gridColumnApi.autoSizeColumns(columnList) // 设置当前显示的列为自动宽度
  }

  // 菜单显示状态改变时调用
  visibleChange(event) {
    if (event === true) {
      this.setParamTreeData(this.gridColumnApi['columnController'].columnDefs);
    }
  }

  // 还原设定
  public restoreColumnDefs() {
    this.restColumnState.emit({gridId: this.id, gridApi: this.gridApi, availableFields: this.availableColumns});
  }

  // 判断操作的column是否是叶子节点--双层标题待优化
  isChangeParaentColumn(node, property, value) {
    if (node.parentNode) {
      const arr = node.parentNode.children.filter(col => {
        return col.origin[property] === value;
      });
      if (arr.length === node.parentNode.children.length) {
        // if (property == 'hide') {
        //   node.parentNode.origin[property] == value;
        // } else {
        node.parentNode.origin[property] = value;
        // }
      } else {
        node.parentNode.origin[property] = (property === 'hide' ? true : '');
      }
    }
  }

  // 设置column左固定/右固定
  public setColumnPinned(pinnedStatus, column, node) {
    if (column.pinned === pinnedStatus) {
      column.pinned = '';
      pinnedStatus = '';
    } else {
      column.pinned = pinnedStatus;
    }
    this.gridColumnApi.setColumnPinned(column.key, pinnedStatus);
    this.setNodePinned(column, pinnedStatus);
    // 判断是否要更新父节点
    this.isChangeParaentColumn(node, 'pinned', pinnedStatus);
    this.saveColumnDefs();
  }

  // 递归当前节点及字节的设置column左固定/右固定
  setNodePinned(column, pinnedStatus) {
    if (column.children && column.children.length) {
      column.children.forEach(item => {
        item.pinned = pinnedStatus;
        this.gridColumnApi.setColumnPinned(item.key, pinnedStatus);
        this.setNodePinned(item, pinnedStatus);
      });
    }
  }

  // 设置column显示/隐藏
  public setColumnVisiable(column, node) {
    console.log(node);
    const hideStatus = column.hide;
    column.hide = !hideStatus;
    this.gridColumnApi.setColumnVisible(column.key, hideStatus);
    this.gridColumnApi.autoSizeColumn(column.key); // 设置当前显示的列为自动宽度
    this.setNodeVisiable(column, hideStatus);
    // 判断是否要更新父节点
    this.isChangeParaentColumn(node, 'hide', !hideStatus);
  }

  // 递归当前节点及字节的设置column显示/隐藏
  setNodeVisiable(column, hideStatus) {
    if (column.children && column.children.length) {
      column.children.forEach(item => {
        item.hide = !hideStatus;
        this.gridColumnApi.setColumnVisible(item.key, hideStatus);
        this.setNodeVisiable(item, hideStatus);
      });
    }
  }

  // 保存操作后的columnDefs状态
  public saveColumnDefs() {
    // // 查询列的状态
    // const columnState = this.gridColumnApi.getColumnState();
    // // 保存列的状态
    // if (columnState) {
    //   sessionStorage.setItem(this.id, '');
    //   sessionStorage.setItem(this.id, JSON.stringify(columnState));
    // }
  }

  // 查询缓存的columnDefs状态
  public getColumnDefs() {
    const columnState = sessionStorage.getItem(this.id);
    if (columnState) {
      // 保存列的状态
      this.gridColumnApi.setColumnState(JSON.parse(columnState));
    }
  }


}
