import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DwLanguageI18nRepository } from '../repository';
import * as en_US from '../../../assets/i18n/en_US/dw-basic';
import * as zh_CN from '../../../assets/i18n/zh_CN/dw-basic';
import * as zh_TW from '../../../assets/i18n/zh_TW/dw-basic';

@Injectable()
export class DwInitialLangLoaderService {
  constructor(
    private translateService: TranslateService,
    private languageI18nRepository: DwLanguageI18nRepository
  ) {
  }

  /**
   * 初始翻譯檔載入器
   */
  getTranslation(lang: string, programId?: string): Observable<any> {
    this.translateService.setTranslation(lang, this.dwI18nBasic(lang), true);
    return this.languageI18nRepository.basic(lang);
  }

  /**
   * 平台全域多語言
   */
  private dwI18nBasic(lang: string): any {
    switch (lang) {
      case 'en_US':
        return en_US.dwI18nBasic;
      case 'zh_CN':
        return zh_CN.dwI18nBasic;
      case 'zh_TW':
        return zh_TW.dwI18nBasic;
      default:
        return {};
    }
  }
}
