export const menuData = [
    {
      'id': '首页',
      'type': 'category', // 菜单
      'iconClass': 'anticon anticon-appstore',
      'programId': 'home'
    },
    {
        'id': '系统管理',
        'type': 'category', // 菜单
        'iconClass': 'anticon anticon-appstore',
        'child': [
          {
            'id': '系统资料设置',
            'type': 'program', // 作业
            'iconClass': 'anticon anticon-chrome',
            //'programId': 'fxb-i01', // 对应作业ID
            'child': [
              {
                'id': '币种维护作业',
                'type': 'program', // 作业
                'programId': 'fxb-i01', // 对应作业ID
              },
              {
                'id': '表结构申请作业',
                'type': 'program', // 作业
                'programId': 'fxad003', // 对应作业ID
              },
              {
                'id': '测试作业',
                'type': 'program', // 作业
                'programId': 'fx-666', // 对应作业ID
              },
              {
                'id': 'grid测试作业',
                'type': 'program', // 作业
                'programId': 'fxgrid', // 对应作业ID

              },
              {
                'id': '表结构查询',
                'type': 'program', // 作业
                'programId': 'fxaq003', // 对应作业ID

              },
              {
                'id': '在线用户查询',
                'type': 'program', // 作业
                'programId': 'fxaq033', // 对应作业ID

              },
              {
                'id': '系统分类设置',
                'type': 'program', // 作业
                'programId': 'fxai001', // 对应作业ID

              },
              {
                'id': '常用字段缩写设置',
                'type': 'program', // 作业
                'programId': 'fxai002', // 对应作业ID

              },
              {
                'id': '| 表结构申请单',
                'type': 'program', // 作业

              },
              {
                'id': '| 表结构查询',
                'type': 'program', // 作业

              },
              {
                'id': '画面多语言设置',
                'type': 'program', // 作业
                'programId': 'fxai004', // 对应作业ID

              },
              {
                'id': '系统信息设置',
                'type': 'program', // 作业
                'programId': 'fxai005', // 对应作业ID

              },
              {
                'id': '作业注册设置',
                'type': 'program', // 作业
                'programId': 'fxai006', // 对应作业ID

              },
              {
                'id': '自定义查询配置',
                'type': 'program', // 作业
                'programId': 'fxai017', // 对应作业ID

              },
              {
                'id': '| 常用查询条件设置',
                'type': 'program', // 作业

              },
              {
                'id': '开窗设置',
                'type': 'program', // 作业
                'programId': 'fxai007', // 对应作业ID

              },
              {
                'id': '需求',
                'type': 'program', // 作业
                'programId': 'fxai900', // 对应作业ID

              },
              {
                'id': '| 服务注册设置',
                'type': 'program', // 作业

              },
              {
                'id': '| EXCEL导入导出设置',
                'type': 'program', // 作业

              },
              {
                'id': '| 企业编号设置',
                'type': 'program', // 作业
			        	'programId': 'fxai010', // 对应作业ID
              },
              {
                'id': '| 费用编号设置',
                'type': 'program', // 作业
                'programId': 'fxgi001', // 对应作业ID
              },
              {
                'id': '自动编码设置',
                'type': 'program', // 作业
                'programId': 'fxai031', // 对应作业ID

              },
              {
                'id': '| 公告设置',
                'type': 'program', // 作业

              },
              {
                'id': '| 待办事项设置',
                'type': 'program', // 作业

              },
              {
                'id': '| 流程设置',
                'type': 'program', // 作业

              },
              {
                'id': '| 首页设置',
                'type': 'program', // 作业

              },
              {
                'id': '| 数据库设置',
                'type': 'program', // 作业
                //'programId': 'fxai002', // 对应作业ID

              },
              {
                'id': '常用查询条件设置',
                'type': 'program', // 作业
                'programId': 'fxai033', // 对应作业ID

              }
            ]
          },
          {
            'id': '权限设置',
            'type': 'program', // 作业
            'iconClass': 'anticon anticon-chrome',
            //'programId': 'fxad003', // 对应作业ID
            'child': [
              {
                'id': '管理字段设置',
                'type': 'program', // 作业
                'programId': 'fxai021', // 对应作业ID

              }, {
                'id': '菜单设置',
                'type': 'program', // 作业
                'programId': 'fxai022', // 对应作业ID

              },
              {
                'id': '角色设置',
                'type': 'program', // 作业
                'programId': 'fxai023', // 对应作业ID

              },
              {
                'id': '用户类型',
                'type': 'program', // 作业
                'programId': 'fxai024', // 对应作业ID

              },
              {
                'id': '用户设置',
                'type': 'program', // 作业
                'programId': 'fxai025', // 对应作业ID

              },
              {
                'id': '服务注册',
                'type': 'program', // 作业
                'programId': 'fxai009', // 对应作业ID

              }
            ]
          },
          {
            'id': '系统资源管理',
            'type': 'program', // 作业
            'iconClass': 'anticon anticon-chrome',
            // 'programId': 'fx-666', // 对应作业ID
            'child': [
              {
                'id': '| 排程设置',
                'type': 'program', // 作业
                'programId': 'fxai008', // 对应作业ID

              }, {
                'id': '| 排程执行状况查询',
                'type': 'program', // 作业
                'programId': 'fxaq008', // 对应作业ID

              }, {
                'id': '| 服务执行状况查询',
                'type': 'program', // 作业
                //'programId': 'fx-666', // 对应作业ID

              },
              {
                'id': '| 用户使用状况查询',
                'type': 'program', // 作业
                //'programId': 'fx-666', // 对应作业ID

              },
              {
                'id': '| 服务器资源状况查询',
                'type': 'program', // 作业
                //'programId': 'fx-666', // 对应作业ID

              },
              {
                'id': '| 系统日志查询',
                'type': 'program', // 作业
                //'programId': 'fx-666', // 对应作业ID

              }
            ]
          },
          {
            'id': '参数设置',
            'type': 'program', // 作业
            'iconClass': 'anticon anticon-chrome',
            // 'programId': 'fxgrid', // 对应作业ID
            'child': [
              {
                'id': '系统参数设置',
                'type': 'program', // 作业
                'programId': 'fxai011', // 对应作业ID

              }, {
                'id': '| 企业参数设置',
                'type': 'program', // 作业
                'programId': 'fxai012', // 对应作业ID

              },
              {
                'id': '组织参数设置',
                'type': 'program', // 作业
                'programId': 'fxai013', // 对应作业ID

              },
              {
                'id': 'excel导入模板设置',
                'type': 'program', // 作业
                'programId': 'fxai014', // 对应作业ID

              }
            ]
          }
        ]
      },
  {
    'id': '基础资料',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore',
    'child': [
      {
        'id': '基础资料',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '理由码设置',
            'type': 'program', // 作业
            'programId': 'fxbi005', // 对应作业ID

          }, {
            'id': '币别设置',
            'type': 'program', // 作业
            'programId': 'fxbi001', // 对应作业ID

          }, {
            'id': '税种设置',
            'type': 'program', // 作业
            'programId': 'fxbi002', // 对应作业ID

          },
          {
            'id': '单位设置',
            'type': 'program', // 作业
            'programId': 'fxbi003', // 对应作业ID

          },
          {
            'id': '| 单位转换设置',
            'type': 'program', // 作业
            'programId': 'fxbi002', // 对应作业ID

          },
          {
            'id': '| 服务分利设置',
            'type': 'program', // 作业
            'programId': 'fxgi002', // 对应作业ID

          },
          {
            'id': '支付方式设置',
            'type': 'program', // 作业
            'programId': 'fxbi006', // 对应作业ID

          },
          {
            'id': '| 支付方式组织设置',
            'type': 'program', // 作业
            //'programId': 'fxbi007', // 对应作业ID

          },
          {
            'id': '时区设置',
            'type': 'program', // 作业
            'programId': 'fxbi010', // 对应作业ID

          },
          {
            'id': '| 洲设置',
            'type': 'program', // 作业
            //'programId': 'fxbi002', // 对应作业ID

          },
          {
            'id': '月汇率',
            'type': 'program', // 作业
            'programId': 'fxbi013', // 对应作业ID

          },
          {
            'id': '日汇率',
            'type': 'program', // 作业
            'programId': 'fxbi014', // 对应作业ID

          },
          {
            'id': '车型设置',
            'type': 'program', // 作业
            'programId': 'fxbi030', // 对应作业ID

          },
          {
            'id': '车辆资料',
            'type': 'program', // 作业
            'programId': 'fxbi031', // 对应作业ID

          },
          {
            'id': '经销商服务区域设置',
            'type': 'program', // 作业
            'programId': 'fxbi027', // 对应作业ID

          },
          {
            'id': '配送员服务区域设置',
            'type': 'program', // 作业
            'programId': 'fxbi028', // 对应作业ID

          }
        ]
      },
      {
        'id': '地区信息设置',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fxad03', // 对应作业ID
        'child': [
          {
            'id': '| 国家设置',
            'type': 'program', // 作业
            //'programId': 'fxad03', // 对应作业ID

          }, {
            'id': '| 省设置',
            'type': 'program', // 作业
            //'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 城市设置',
            'type': 'program', // 作业
            //'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 县区设置',
            'type': 'program', // 作业
            //'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 街道设置',
            'type': 'program', // 作业
            //'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 小区设置',
            'type': 'program', // 作业
            'programId': 'fxbi012', // 对应作业ID

          },
          {
            'id': '行政区域',
            'type': 'program', // 作业
            'programId': 'fxbi011', // 对应作业ID

          }
        ]
      },
      {
        'id': '组织资料',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fx-666', // 对应作业ID
        'child': [
          {
            'id': '组织机构设置',
            'type': 'program', // 作业
            'programId': 'fxbi021', // 对应作业ID

          }, {
            'id': '组织结构建立',
            'type': 'program', // 作业
            'programId': 'fxbi022', // 对应作业ID

          },{
            'id': '| 组织机构结构设置',
            'type': 'program', // 作业
            //'programId': 'fx-666', // 对应作业ID

          },
          {
            'id': '管理区域设置',
            'type': 'program', // 作业
            'programId': 'fxdi009', // 对应作业ID

          },
          {
            'id': '部门设置',
            'type': 'program', // 作业
            'programId': 'fxbi023', // 对应作业ID

          },
          {
            'id': '员工岗位设置',
            'type': 'program', // 作业
            'programId': 'fxbi024', // 对应作业ID

          },
          {
            'id': '| 员工设置',
            'type': 'program', // 作业
            'programId': 'fxbi025', // 对应作业ID

          },
          {
            'id': '| 外部人员设置',
            'type': 'program', // 作业
            'programId': 'fxbi026', // 对应作业ID
          },
          {
            'id': '| 导购员设置',
            'type': 'program', // 作业
            //'programId': 'fx-666', // 对应作业ID

          }
        ]
      },
      {
        'id': '生命周期',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        // 'programId': 'fx-666', // 对应作业ID
        'child': [
          {
            'id': '| 生命周期限定',
            'type': 'program', // 作业
            'programId': 'fxbi052', // 对应作业ID
          }
        ]
      }
    ]
  },
  {
    'id': '商品管理',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore',
    'child': [
      {
        'id': '商品属性设置',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '商品品类设置',
            'type': 'program', // 作业
            'programId': 'fxci001', // 对应作业ID

          }, {
            'id': '| 商品特征类型设置',
            'type': 'program', // 作业

          }, {
            'id': '商品特征组设置',
            'type': 'program', // 作业
            'programId': 'fxci003', // 对应作业ID
          },
          {
            'id': '| 商品模板设置',
            'type': 'program', // 作业

          },
          {
            'id': '| 商品属性设置',
            'type': 'program', // 作业
            'programId': 'fxci004', // 对应作业ID
          },
          {
            'id': '| 商品成本',
            'type': 'program', // 作业
            'programId': 'fxci009', // 对应作业ID
          },
          {
            'id': '| 商品使用配件设置',
            'type': 'program', // 作业
            'programId': 'fxci010', // 对应作业ID
          }
        ]
      },
      {
        'id': '商品资料建立',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fxad03', // 对应作业ID
        'child': [
          {
            'id': '商品资料维护作业',
            'type': 'program', // 作业
             'programId': 'fxci005', // 对应作业ID

          }, {
            'id': '商品套餐维设置',
            'type': 'program', // 作业
            'programId': 'fxci007', // 对应作业ID
          },
          {
            'id': '商品集成对照表',
            'type': 'program', // 作业
            'programId': 'fxci011', // 对应作业ID
          },
          {
            'id': '单位换算',
            'type': 'program', // 作业
            'programId': 'fxci012', // 对应作业ID
          },
          {
            'id': '| 活动品维护作业',
            'type': 'program', // 作业

          }
        ]
      },
      {
        'id': '商品策略设置',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fx-666', // 对应作业ID
        'child': [
          {
            'id': '商品策略资料维护作业',
            'type': 'program', // 作业
			'programId': 'fxci006', // 对应作业ID

          }, {
            'id': '| 商品策略查询作业',
            'type': 'program', // 作业

          }
        ]
      }
    ]
  },
  {
    'id': '渠道管理',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore',
    'child': [
      {
        'id': '门店资料建立',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        // 'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '| 门店属性设置',
            'type': 'program', // 作业
            'programId': 'fxdi006' // 对应作业ID

          }, {
            'id': '| 门店设置',
            'type': 'program', // 作业
            'programId': 'fxdi007', // 对应作业ID

          }, {
            'id': '| 店铺设置',
            'type': 'program', // 作业
            //'programId': 'fxb-i01', // 对应作业ID

          },
          {
            'id': '销售片区设置',
            'type': 'program', // 作业
            'programId': 'fxdi008', // 对应作业ID

          }
        ]
      },
      {
        'id': '价格设置',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        // 'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '组织价格设置',
            'type': 'program', // 作业
            'programId': 'fxdd001', // 对应作业ID
          },
          {
            'id': '组织价格查询',
            'type': 'program', // 作业
            'programId': 'fxdq001', // 对应作业ID
          },
          {
            'id': '渠道价格设置',
            'type': 'program', // 作业
            'programId': 'fxdd002', // 对应作业ID
          },
          {
            'id': '渠道价格查询',
            'type': 'program', // 作业
            'programId': 'fxdq002', // 对应作业ID
          },
          {
            'id': '活动计划',
            'type': 'program', // 作业
            'programId': 'fxdi200', // 对应作业ID
          },
          {
            'id': '报价单',
            'type': 'program', // 作业
            'programId': 'fxdd003', // 对应作业ID
          },
          {
            'id': '促销设置',
            'type': 'program', // 作业
            'programId': 'fxdd005', // 对应作业ID
          },
          {
            'id': '客户促销',
            'type': 'program', // 作业
            'programId': 'fxdd006', // 对应作业ID
          },
          {
            'id': '促销设置-明一',
            'type': 'program', // 作业
            'programId': 'fxdd007', // 对应作业ID
          },
          {
            'id': '高级促销设置',
            'type': 'program', // 作业
            'programId': 'fxdd010', // 对应作业ID
          },
        ]
      },
      {
        'id': '渠道商资料建立',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        // 'programId': 'fxad03', // 对应作业ID
        'child': [
          {
            'id': '| 渠道商结算模板设置',
            'type': 'program', // 作业
            // 'programId': 'fxad03', // 对应作业ID

          }, {
            'id': '| 供应商分类设置',
            'type': 'program', // 作业
            // 'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 供应商属性',
            'type': 'program', // 作业
            // 'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 渠道设置',
            'type': 'program', // 作业
            'programId': 'fxdi001', // 对应作业ID

          },
          {
            'id': '| 地区渠道设置',
            'type': 'program', // 作业
            // 'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 渠道商属性设置',
            'type': 'program', // 作业
            'programId': 'fxdi002', // 对应作业ID

          },
          {
            'id': '| 渠道商集团设置',
            'type': 'program', // 作业
            // 'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 渠道商设置',
            'type': 'program', // 作业
            // 'programId': 'fxad03', // 对应作业ID

          },
          {
            'id': '| 渠道商组设置',
            'type': 'program', // 作业
            'programId': 'fxdi004', // 对应作业ID

          },
          {
            'id': '| 渠道商模板',
            'type': 'program', // 作业
            'programId': 'fxdi010', // 对应作业ID

          },
          {
            'id': '| 行业分类',
            'type': 'program', // 作业
            'programId': 'fxdi011', // 对应作业ID
          }
        ]
      },
      {
        'id': '合同',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        // 'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '结算周期设置',
            'type': 'program', // 作业
            'programId': 'fxdi100', // 对应作业ID
          },
          {
            'id': '项目建立',
            'type': 'program', // 作业
            'programId': 'fxdi300', // 对应作业ID
          }
        ]
      },
      {
        'id': '商机',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        // 'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '| 商机分类',
            'type': 'program', // 作业
            'programId': 'fxdi020', // 对应作业ID
          },
          {
            'id': '| 商机阶段',
            'type': 'program', // 作业
            'programId': 'fxdi021', // 对应作业ID
          },
          {
            'id': '拜访内容',
            'type': 'program', // 作业
            'programId': 'fxdi022', // 对应作业ID
          },
          {
            'id': '商机主题',
            'type': 'program', // 作业
            'programId': 'fxdi023', // 对应作业ID
          }
        ]
      },
      {
        'id': '售后服务',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        // 'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '| 常见故障维护',
            'type': 'program', // 作业
            'programId': 'fxdi050', // 对应作业ID
          },
          {
            'id': '| 服务项目设置',
            'type': 'program', // 作业
            'programId': 'fxdi052', // 对应作业ID
          },
          {
            'id': '客户商品档案设置',
            'type': 'program', // 作业
            'programId': 'fxdi055', // 对应作业ID
          },
          {
            'id': '评价指标设置',
            'type': 'program', // 作业
            'programId': 'fxdi053', // 对应作业ID
          },
          {
            'id': '配件库存设置',
            'type': 'program', // 作业
            'programId': 'fxdi054', // 对应作业ID
          }
        ]
      },
      {
        'id': '商城配置',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        // 'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '| 商城首页配置',
            'type': 'program', // 作业
            'programId': 'fxdi400', // 对应作业ID
          },
          {
            'id': '| 帮助中心',
            'type': 'program', // 作业
            'programId': 'fxdi401', // 对应作业ID
          },
          {
            'id': '| 新闻中心',
            'type': 'program', // 作业
            'programId': 'fxdi402', // 对应作业ID
          },
          {
            'id': '| 学习中心',
            'type': 'program', // 作业
            'programId': 'fxdi403', // 对应作业ID
          },
          {
            'id': '| 案例中心',
            'type': 'program', // 作业
            'programId': 'fxdi404', // 对应作业ID
          },
          {
            'id': '| 商品中心',
            'type': 'program', // 作业
            'programId': 'fxdi405', // 对应作业ID
          },
          {
            'id': '| 下载中心',
            'type': 'program', // 作业
            'programId': 'fxdi406', // 对应作业ID
          }
        ]
      }
    ]
  },
  {
    'id': '销售管理',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore'
  },
  {
    'id': '采购管理',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore'
  },
  {
    'id': '库存管理',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore',
    'child': [
      {
        'id': '仓库建立',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '| 库存特征设置',
            'type': 'program', // 作业
            'programId': 'fxhi005', // 对应作业ID
          },
        ]
        }
      ]
  },
  {
    'id': '结算管理',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore',
    // 'child': [
    //   {
    //     'id': 'fxb-i01',
    //     'type': 'program', // 作业
    //     'iconClass': 'anticon anticon-chrome',
    //     'programId': 'fxb-i01', // 对应作业ID
    //     'childs': [
    //       {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }, {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }, {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     'id': 'fxad03',
    //     'type': 'program', // 作业
    //     'iconClass': 'anticon anticon-chrome',
    //     'programId': 'fxad03', // 对应作业ID
    //     'childs': [
    //       {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }, {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     'id': 'fx-666',
    //     'type': 'program', // 作业
    //     'iconClass': 'anticon anticon-chrome',
    //     'programId': 'fx-666', // 对应作业ID
    //     'childs': [
    //       {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }, {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }, {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     'id': 'fxgrid',
    //     'type': 'program', // 作业
    //     'iconClass': 'anticon anticon-chrome',
    //     'programId': 'fxgrid', // 对应作业ID
    //     'childs': [
    //       {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }, {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   {
    //     'id': 'fxaq003',
    //     'type': 'program', // 作业
    //     'iconClass': 'anticon anticon-chrome',
    //     'programId': 'fxaq003', // 对应作业ID
    //     'childs': [
    //       {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }, {
    //         'id': '三级菜单',
    //         'type': 'program', // 作业
    //         'children': [
    //           {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }, {
    //             'id': '四级菜单',
    //             'type': 'program', // 作业
    //           }
    //         ]
    //       }
    //     ]
    //   }

    // ]
  },
  {
    'id': '集成',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore',
    'child': [
      {
        'id': '集成设置',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '集成平台账户',
            'type': 'program', // 作业
            'programId': 'fxii002', // 对应作业ID
          },
          {
            'id': '集成平台',
            'type': 'program', // 作业
            'programId': 'fxii003', // 对应作业ID
          },
          {
            'id': '集成接口设置',
            'type': 'program', // 作业
            'programId': 'fxii004', // 对应作业ID
          },
          {
            'id': '报文结构设置',
            'type': 'program', // 作业
            'programId': 'fxii006', // 对应作业ID
          }
        ]
        }
      ]
  },
  {
    'id': '费用与结算',
    'type': 'category', // 菜单
    'iconClass': 'anticon anticon-appstore',
    'child': [
      {
        'id': '费用',
        'type': 'program', // 作业
        'iconClass': 'anticon anticon-chrome',
        //'programId': 'fxb-i01', // 对应作业ID
        'child': [
          {
            'id': '渠道费用单',
            'type': 'program', // 作业
            'programId': 'fxgd002', // 对应作业ID
          },
        ]
        }
      ]
  },
  {
    'id': '| 储位设置',
    'type': 'program', // 作业
    'programId': 'fxhi002', // 对应作业ID
    },
];
