import { Component, OnInit, Input } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'edit-comcond',
  template: 
  `
  
  <div class="fx_modal_container" appModalDrag>
    <form dw-form class="fx_modal_form">
      <div dw-row class="dw-f-table dw-f-row-bottom-margin">
      <dw-table #dwTable [dwData]="dataList" [dwShowPagination]="false" [dwFrontPagination]="false" [dwScroll]="{ y: '240px' }" dwSize="middle" >
        <thead dw-thead>
          <tr >
            <th dw-th style="width: 50%;">
              <span translate>com-常用条件名称</span>
            </th>
            <th dw-th style="width: 30%;">
              <span translate>com-默认查询条件</span>
            </th>
            <th dw-th style="width: 20%;">
              <span translate>com-操作</span>
            </th>
          </tr>
        </thead>
        <tbody dw-tbody>
          <tr dw-tbody-tr *ngFor="let data of dataList; let idx = index" [ngClass]="{active: data.isSelected}" (click)="selectItem(idx)">
            <td dw-td style="width: 50%;">
              <input dw-input [(ngModel)]="data.label" name="label{{idx}}" >
            </td>
            <td dw-td style="width: 30%; text-align:center;">
              <label dw-checkbox [(ngModel)]="data.aan005c" name="check{{idx}}"  (ngModelChange)="changeChecked(idx)">
              </label>
            </td>
            <td dw-td style="width: 20%;">
              <span class="dw-link-txt">
              <a translate (click)="delComCond(idx)" ><i class="anticon anticon-delete" ></i></a>
              </span>
            </td>
          </tr>
        </tbody>
      </dw-table>
    </div>
      <div class="customize-footer">
        <button dw-button (click)="sortUp()">{{ 'com-排序上移' | translate}}</button>
        <button dw-button (click)="sortDown()">{{ 'com-排序下移' | translate}}</button>
        <button dw-button type="button" [dwType]="'default'" (click)="handleCancel()">{{ 'dw-cancel' | translate}}</button>
        <button dw-button [dwType]="'primary'" (click)="handleOk()">{{ 'dw-save' | translate}}</button>
      </div>
    </form>
    </div>
  
  `,
  styles: [
    `
    :host ::ng-deep .customize-footer {
      padding: 0;
      text-align: right;
      border-radius: 0 0 0px 0px;
      margin: 0;
    }
    .active {
      background: #b7e4ff
    }
    :host ::ng-deep .ant-layout-content {
      margin: -24px;
    }
    :host ::ng-deep .fx_modal_form {
      padding: 10px;
    }
    :host ::ng-deep .fx_modal_form tr th {
      padding: 0px 10px !important;
      height: 28px;
      font-size: 12px;
    }
    :host ::ng-deep .ant-table-placeholder {
      border-bottom: none;
      font-size: 12px;
    }
    :host ::ng-deep .customize-footer button {
      padding: 0 15px;
      transition: 1s all;
      -webkit-transition: 1s all;
      background: #69c0ff;
      border-color: #69c0ff;
      color: #fff;
      box-shadow: 0 1px 6px #bae7ff;
    }
    :host ::ng-deep .customize-footer button:last-child {
      color: #fff;
      border-color: #1890ff;
      background: #1890ff;
      box-shadow: 0px 1px 6px 0px #72c0ff;
    }
    .customize-footer button:hover {
      border-color: #3aa7ff;
      background: #3aa7ff;
    }
    :host ::ng-deep .customize-footer button:last-child:hover {
      color: #fff;
      border-color: #0b79de;
      background: #0b79de; 
    }
    `
  ]
})
export class editCondComponent implements OnInit  {
  public editForm: FormGroup;
  @Input() dataList: any;

  constructor(private modalSubject: DwModalRef, private fb: FormBuilder) {}

  ngOnInit(): void {}
//修改默认值
  changeChecked(xIdx): void{
    if (this.dataList[xIdx].aan005c) {
      for (let index = 0; index < this.dataList.length; index++) {
        if (xIdx != index) {
          this.dataList[index].aan005c = false;
        }
      }
    }
  }
//删除
  delComCond(xIdx): void{
    this.dataList.splice(xIdx, 1);
  }

  selectItem(xIdx): void {
    for (let index = 0; index < this.dataList.length; index++) {
      if (xIdx != index) {
        this.dataList[index].isSelected = false;
      } else {
        this.dataList[index].isSelected = true;
      }
    }
  }
  //上移
  sortUp(): void{
    var nowIndex= this.dataList.findIndex(function(obj) {
      return obj.isSelected == true;
    });
    if ((nowIndex == 0) || nowIndex == -1) { return;}
    var oldData = Object.assign({}, this.dataList[nowIndex -1]);
    this.dataList[nowIndex -1] = Object.assign({}, this.dataList[nowIndex]);
    this.dataList[nowIndex] = Object.assign({}, oldData);
  }
  //下移
  sortDown(): void{
    var nowIndex= this.dataList.findIndex(function(obj) {
      return obj.isSelected == true;
    });
    if ((nowIndex == (this.dataList.length-1)) || nowIndex == -1) { return;}
    var oldData = Object.assign({}, this.dataList[nowIndex +1]);
    this.dataList[nowIndex +1] = Object.assign({}, this.dataList[nowIndex]);
    this.dataList[nowIndex] = Object.assign({}, oldData);
  }

  handleOk(): void {
    this.modalSubject.triggerOk();
  }

  handleCancel(): void {
    this.modalSubject.triggerCancel();
  }
}