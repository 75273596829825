import { Injectable, ViewContainerRef, TemplateRef, ComponentRef, Type } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { FxModals2Component } from './fx-modals2.component';
import { ModalOptions, ModalButtonOptions } from './fx-modals2-type';
import { Observable } from 'rxjs';
import { DwHttpClient } from '../../../../../../projects/webdpt/framework/http';

@Injectable({
  providedIn: 'root'
})

export class FxModals2Service<T = any, R = any> {
  private modalRef: ComponentRef<FxModals2Component> | null;
  public overlayRef: OverlayRef;

  constructor(
    public overlay: Overlay,
    //public http: DwHttpClient
    //public viewContainerRef: ViewContainerRef
  ) { }

  //创建overlay
  public create(parameters: {
    title?: string | TemplateRef<{}>,
    fxIconType?: string,
    fxFooter?: TemplateRef<{}> | Array<ModalButtonOptions> | null,
    fxContent?: string | TemplateRef<{}> | Type<T>,
    fxWidth?: string,
    fxHeight?: string,
    isShowFooter?: boolean,
    fxComponentParams?: Partial<T>
  }): any {
    this.createModal()
    const getParam = Object.assign(this.modalRef.instance, parameters);
    
    return this.modalRef
  }
  //创建容器
  public createModal() {
    const config = new OverlayConfig();
    config.positionStrategy = this.overlay.position()
      .global() // 全局显示
      .centerHorizontally() // 水平居中
      .centerVertically(); // 垂直居中
    config.hasBackdrop = false;
    this.overlayRef = this.overlay.create(config);
    this.modalRef = this.overlayRef.attach(new ComponentPortal(FxModals2Component));
    //console.log(this.modalRef);
  }
  
  destroyModal(): void {
    if (this.modalRef) {
      this.overlayRef.dispose();
      this.modalRef = null;
    }
  }
  

    //监听入参
    private changeProps(options: ModalOptions): void {
      if (this.modalRef) {
        Object.assign(this.modalRef.instance, options); 
      }
    }
}
