import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prog001',
  template: `<h1>404</h1>`
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
