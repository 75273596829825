const angularValidators = {
  'required': '必填',
  'minlength': '最小字元長度：{{requiredLength}}，當前字元長度：{{actualLength}}',
  'maxlength': '最大字元長度：{{requiredLength}}，當前字元長度：{{actualLength}}',
  'min': '最小值：{{min}}，當前值：{{actual}}',
  'max': '最大值：{{max}}，當前值：{{actual}}',
  'requiredtrue': '必為真(true)',
  'email': '不符合電子郵件格式',
  'pattern': '格式不匹配{{requiredPattern}}',
  'nullValidator': '無操作驗證器'
};

const dwI18nProgView = {
  'dw-login-rememberMe': '記住我',
  'dw-login-forgetPassword': '忘記密碼',
  'dw-login-registered': '註冊',
  'dw-login-password': '密碼',
  'dw-login-account': '帳戶',
  'dw-login-signIn': '登入',
  'dw-login-tenant': '租戶',
  'dw-sys-menu-save': '保存',
  'dw-sys-menu-cancel': '取消',
  'dw-sys-menu-add': '新增',
  'dw-sys-menu-increase': '添加',
  'dw-sys-menu-edit': '編輯',
  'dw-sys-menu-delete': '刪除',
  'dw-sys-menu-addItem': '新增項目',
  'dw-sys-menu-externalLink': '外部連結',
  'dw-sys-menu-category': '目錄',
  'dw-sys-menu-program': '作業',
  'dw-sys-menu-report': '報表',
  'dw-sys-menu-menuPreview': '選單預覽',
  'dw-sys-menu-name': '顯示名稱',
  'dw-sys-menu-iconClass': '圖示樣式',
  'dw-sys-menu-defaultExpand': '預設展開',
  'dw-sys-menu-nodeEditing': '節點編輯',
  'dw-sys-menu-linkProgram': '連結作業',
  'dw-sys-menu-parameterSetting': '參數設定',
  'dw-sys-menu-parameterName': '參數名稱',
  'dw-sys-menu-parameterValue': '參數值',
  'dw-sys-menu-linkURL': '連結網址',
  'dw-sys-menu-openMode': '開啟方式',
  'dw-sys-menu-openMode-window': '另開視窗',
  'dw-sys-menu-openMode-iframe': '嵌入網頁',
  'dw-sys-menu-upload-mcc': '上傳互聯應用',
  'dw-sys-menu-upload-mcc-hint': '作業全部上傳互聯應用',
  'dw-sys-menu-msg-updateFailed': '更新失敗',
  'dw-sys-menu-msg-updated': '已更新',
  'dw-sys-menu-msg-confirmDelete': '是否刪除目錄及內含的相關連結作業?',
  'dw-sys-menu-msg-deleteFailed': '刪除失敗',
  'dw-sys-menu-msg-deleted': '已刪除',
  'dw-sys-menu-msg-notExist': '{{value1}}查無資料',
  'dw-sys-menu-msg-exist': '請勿重複設定',
  'dw-forget-mailCode': 'mail驗證碼',
  'dw-forget-mobileCode': '手機驗證碼',
  'dw-forget-enterEmailReceive': '請輸入您的E-mail,收取開通帳號mail',
  'dw-forget-enterCorrectEmail' : '請輸入正確Email',
  'dw-forget-emailNotExist': 'Email不存在',
  'dw-forget-enterCellphone': '請輸入您的手機號碼',
  'dw-forget-needsDigits': '手機號碼需為11個數字',
  'dw-forget-needAllNumbers': '手機號碼需全部是數字',
  'dw-forget-cellphoneNotExist': '手機號碼不存在',
  'dw-forget-getCode': '獲取驗證碼',
  'dw-forget-enterCode': '請輸驗證碼',
  'dw-forget-countDown': '{{current}} 秒倒數',
  'dw-forget-newPassword': '新密碼',
  'dw-forget-confirmNewPassword': '確認新密碼',
  'dw-forget-enterNewPassword': '請輸入新密碼',
  'dw-forget-enterNewPasswordAgain': '請再次輸入新密碼',
  'dw-forget-passwordMismatch': '密碼不相符',
  'dw-forget-sentSuccessfully': '發送成功',
  'dw-forget-confirmCodeMailbox': '請確認信箱驗證碼',
  'dw-forget-confirmCodeSMS': '請確認短信驗證碼',
  'dw-forget-logInAgain': '修改密碼後, 請使用新密碼重新登入',
  'dw-forget-updatedSuccessfully': '密碼更新成功,請使用新密碼登入'
};

export const dwI18nBasic = {
  ...angularValidators,
  ...dwI18nProgView,
  'app-title': '',
  'dw-request-url': '請求網址',
  'dw-request-body': '請求內容',
  'dw-response-status': '回應狀態',
  'dw-response-message': '回應訊息',
  'dw-http-error': '抱歉，出錯了',
  'dw-http-error-400': '錯誤的請求',
  'dw-http-error-401': '發送的請求缺乏憑證',
  'dw-http-error-403': '抱歉，您無權訪問該頁面',
  'dw-http-error-404': '抱歉，您訪問的頁面不存在',
  'dw-http-error-500': '抱歉，服務器出錯了',
  'dw-loadingData': '加載數據中',
  'dw-pleaseEnter': '請輸入搜尋字串',
  'dw-determine': '確定',
  'dw-cancel': '取消',
  'dw-save': '保存',
  'dw-select-modal-error-dataSource': '必需有資料來源',
  'dw-select-modal-error-tableIdField': '使用的 id 欄位不得為空值',
  'dw-select-modal-error-tableNameField': '使用的 name 欄位不得為空值',
  'dw-select-modal-error-tableColDefs': '表格欄位定義不得為空值',
  'dw-select-modal-error-tableColDefs-title': '表格欄位定義-[title]不得為空值',
  'dw-select-modal-error-tableColDefs-field': '表格欄位定義-[field]不得為空值'
};
