import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-hyperlink-cell',
  template: '<a (click)="clicked()" title={{value}}>{{value}}</a>'
})
export class HyperlinkComponent implements ICellRendererAngularComp {
  private params: any;
  value: any;

  agInit(params: any): any {
    this.params = params;
    this.value = params.getValue();

  }

clicked(): void {
  console.log('cell clicked!');
  if (
    this.params.colDef.cellRendererParams &&
    this.params.colDef.cellRendererParams.onClick) {
    this.params.colDef.cellRendererParams.onClick(this.value, this);
  }
}
  refresh(): boolean {
    return false;
  }

}
