import { Observable } from 'rxjs';
import { FxModals2Component } from './fx-modals2.component';

export abstract class FxModalRef<T = any, R = any> {
    abstract afterOpen: Observable<void>;
    abstract afterClose: Observable<R>;
  
    abstract open(): void;
    abstract close(result?: R): void;
    abstract destroy(result?: R): void;

    abstract getInstance(): FxModals2Component;
}
