// 多頁佈局預設開啟作業
export const tabRouteConfigJson = [
  {
    'id': 'home',
    'canClose': false,
    'defaultOpen': true,
    'canMultiOpen': false,
    'iconClass': 'anticon-home'
  }
];
