/**
 * todo
 * 1. 增加参数控制是否分页，默认分页
 * 2. 样式优化
 */

import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation,ElementRef, Injector  } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import { TranslateService } from '@ngx-translate/core';
import { GridApi } from 'ag-grid-community';
import { DwHttpClient } from '../../../../../../projects/webdpt/framework/http';
import { moreComCondComponent } from './more-comcond';
 
/**
 * 设计要求
 * icon 22px
 * title 16px
 * content 14px
 */
@Component({
  selector: 'fx-search-backend-pagination-modal-component',
  templateUrl: './fx-search-backend-pagination-modal.component.html',
  styleUrls: [
    './fx-search-backend-pagination-modal.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class FxSearchBackendPaginationModalComponent implements OnInit {
  @Input() title: string;
  @Input() dataSet: Array<any>;
  @Input() selected: Array<any>;
  @Input() mutilSelected: boolean;
  // ag-grid 栏位配置
  @Input() columnDefs: any;
  // 数据分页部分
  @Input() postParam: any;   // 设定开窗分页添加请求参数
  @Input() searchFn: any;      // 查询的方法
  @Input() isBackendPaging: any;   // 设定前端分页还是后端分页-true-后端分页，false-前端分页
  @Input() pageCount: number;     // 记录当前多少页
  @Input() rowCount: number;      // 记录当前资料总笔数
  @Input() searchlableList: Array<any>;      // 记录当前资料总笔数
  @Input() isCustomWindow: any;      // 是否自定义开窗

  public selectedItem: Array<any> = [];
  public isHeight = '325px';
  public isParm = true;
  public isFull = false;
  public isMoreConShow = false; // 进阶查询是否展开，true-展开，false-关闭，默认false关闭
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  @ViewChild('layout') layout: ElementRef;
  @ViewChild('moreComcond') private searchMore: moreComCondComponent; // 公共的查询组件

  COL_SINGLE_SELECT = {
    headerName: '',
    width: 55,
    headerCheckboxSelection: false, // 标题添加checkbox
    headerCheckboxSelectionFilteredOnly: true, // 全选只勾选过滤部分
    checkboxSelection: true
  };

  COL_MUTIL_SELECT = {
    headerName: '',
    width: 55,
    headerCheckboxSelection: true, // 标题添加checkbox
    headerCheckboxSelectionFilteredOnly: true, // 全选只勾选过滤部分
    checkboxSelection: true
  };

  // 数据集
  _displyData: Array<any> = [];
  // 搜索字串
  _searchText: string = '';
  // ag-grid 栏位配置
  _defaultColDef: any = {};
  _columnDefs: Array<any> = [];
  // ag-grid renderer 配置
  _components = {};
  // ag-grid API
  _gridApi: GridApi;
  // ag-grid-column API
  gridColumnApi: any;
  // 行选模式
  // _rowSelection: string = 'multiple';
  _rowSelection: string = 'single';
  // ag-grid 页码
  _pageSize: number = 10;
  _pageNumber: number = 1;
  _rowCount: number = 1;

  constructor(private modal: DwModalRef, private translateService: TranslateService, public http: DwHttpClient,
    private injector: Injector) {
    // super(injector); 
  }

  ngOnInit(): void {
    this._rowCount = this.rowCount;
    
    // @ts-ignore
    this._rowSelection = this.modal.dwComponentParams.mutilSelected ? 'multiple' : 'single';
    this._defaultColDef = {
      width: 100,
      editable: false
    };
    let colDefs = [{
      headerName: '',
      // field:'ischecked',
      width: 40,
      // @ts-ignore
      headerCheckboxSelection: this.modal.dwComponentParams.mutilSelected, // 标题添加checkbox
      headerCheckboxSelectionFilteredOnly: true, // 全选只勾选过滤部分
      checkboxSelection: true,
      suppressSizeToFit: true,
      cellClass: this._rowSelection == 'single'? 'fx_single_select' : '',
      cellRenderer: (params) => {
        if (params.colDef.cellClass == 'fx_single_select') {
          var span = document.createElement('span');
          span.className = 'ag-icon-checkbox-checked-inner';
          params.eGridCell.children[0].children[0].children[0].append(span);
        }
      }
    }];
    // @ts-ignore
    const pColDefs = this.modal.dwComponentParams.columnDefs;
    if (pColDefs && pColDefs.length > 0) {
      colDefs = colDefs.concat(pColDefs);
      colDefs[colDefs.length - 1].width = undefined;
    }
    this._columnDefs = colDefs;
    // // 首次查询没有资料则默认展开进阶查询提供搜索
    // if (this.dataSet.length == 0) {
    //   this.isMoreConShow = true;
    // }
    this.setGridData(this.dataSet);
    this.setDataSelected();
    
  }

  setDataSelected(): void {
    setTimeout(() => {
      // 设置已选择的数据为选中状态
      if (this.selected && this.selected.length) {
        // 获取key值
        const selectedKeys = Object.keys(this.selected[0]);
        if (selectedKeys.length === 2) {
          this._gridApi.forEachNode((node: any, index1: number) => {
            this.selected.forEach(function (value) {
              if (node.data[selectedKeys[0]] === value[selectedKeys[0]] && node.data[selectedKeys[1]] === value[selectedKeys[1]]) {
                node.setSelected(true);
              }
            });
          });
        } else {
          // if (selectedKeys.length === 1) {
          this._gridApi.forEachNode((node: any, index1: number) => {
            this.selected.forEach(function (value) {
              if (node.data[selectedKeys[0]] === value[selectedKeys[0]]) {
                node.setSelected(true);
              }
            });
          });
          // }
        }
      }
    }, 300);
  }
  setGridData(data: any): void {
    this._displyData = data;
    this._rowCount = this.rowCount;
  }

  search(): void {
    if (this.isCustomWindow == false) { // 公共开窗
      this.searchMore.getsearchParam();
      this._pageNumber = 1;
      this.searchFn(this.postParam.modalID, this.postParam.postParam, this.postParam.searchType, this.postParam.isChkAuth, this.postParam.no_translation, this._pageNumber, this._pageSize, this._searchText, '', this.searchMore.searchParam.condition).subscribe((config) => {
        if (config.data && config.data.datas && config.data.datas.length) {
          this.rowCount = config.data.rowCount;
          this.setGridData(config.data.datas);
          this.setDataSelected();
        } else {
          this.rowCount = 0;
          this.setGridData([]);          
        }
        this.isMoreConShow = false; // 查询完隐藏进阶查询
      });
    } else { // 自定义开窗
      this.setGridData(this.dataSet.filter(data => {
        const strData = JSON.stringify(data);
        return strData.includes(this._searchText);
      }));
      this.setDataSelected();
    }
    
    // this.setGridData(this.dataSet.filter(data => {
    //   const strData = JSON.stringify(data);
    //   return strData.includes(this._searchText);
    // }));
    // this._rowCount = this.rowCount;
    // this.setDataSelected();
  }

  // 清空查询参数按钮
  cleanSearchParam(): void {
    this._searchText = '';
    this.searchMore.searchVal.forEach(item => {
      item.value = '';
    });
  }

  // gridAPI赋值
  onGridReady(params: any): void {
    this._gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this._gridApi.sizeColumnsToFit();
  }

  // grid 选择事件
  selectionChanged(params: any): void {
    // todo
    // console.log(this._displyData);
  }

  debug(): void {
    // const selectedRows = this._gridApi.getSelectedRows();
    // console.warn(selectedRows);
    this._gridApi.sizeColumnsToFit();
  }

  onPageIndexChange(pageIndex: number): void {
    this._pageNumber = pageIndex;
    if (this._pageNumber > 0) {
      // this._gridApi.paginationGoToPage(this._pageNumber - 1);
      this.searchFn(this.postParam.modalID, this.postParam.postParam, this.postParam.searchType, this.postParam.isChkAuth, this.postParam.no_translation, this._pageNumber, this._pageSize, this._searchText, '', this.searchMore.searchParam.condition).subscribe((config) => {
        if (config.data && config.data.datas && config.data.datas.length) {
          this.rowCount = config.data.rowCount;
          this.setGridData(config.data.datas);
        } else {
          this.rowCount = 0;
          this.setGridData([]);
        }
        this.isMoreConShow = false; // 查询完隐藏进阶查询
      });
    }
  }

  onPageSizeChange(): void {
    // this._gridApi.paginationSetPageSize(this._pageSize);
    this._pageNumber = 1;
    this.searchFn(this.postParam.modalID, this.postParam.postParam, this.postParam.searchType, this.postParam.isChkAuth, this.postParam.no_translation, this._pageNumber, this._pageSize, '',  '', this.searchMore.searchParam.condition).subscribe((config) => {
      if (config.data && config.data.datas && config.data.datas.length) {
        this.rowCount = config.data.rowCount;
        this.setGridData(config.data.datas);
      } else {
        this.rowCount = 0;
        this.setGridData([]);
      }
      this.isMoreConShow = false; // 查询完隐藏进阶查询
    });
  }

  onClickOK(): void {
    this.selectedItem = this._gridApi.getSelectedRows();
    this.modal.triggerOk();
  }

  onClickCancel(): void {
    this.modal.triggerCancel();
  }
  fxCloseModal(): void {
    this.modal.triggerCancel();
  }
  // 最大化/还原
  fullModal(parm): void {
    //获取浏览器可见宽高
    let docWidth = window.outerWidth;
    let docHeight = window.outerHeight;
    let newModal = document.querySelector('.fx_modal_container');

    if(parm) {
      //存储第一次dwWidth值
      let oldW = this.modal['dwWidth'];
      // let oldH = this.modal;
      localStorage.setItem('width',oldW);
      let oldWidth = localStorage.getItem('width');

      //赋予新值
      this.layout.nativeElement.style.width = docWidth + 'px';
      this.isHeight = 'calc(100vh - 85px)';
      this.isFull = true;
      this.modal['dwStyle'].top = '0';
      //改写click传参值
      this.isParm = false;
    } else {
      this.layout.nativeElement.style.width = localStorage.getItem('width') + 'px';
      this.isHeight = '325px';
      this.isFull = false;
      this.modal['dwStyle'].top = '20px';

      //改写click传参值
      this.isParm = true;
    }
  }

  checkEdge(event) {
    this.edge = event;
    console.log('edge:', event);
  }
}

