import {Component, Input} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {observableToBeFn} from 'rxjs/internal/testing/TestScheduler';
import {GridApi} from 'ag-grid-community';

@Component({
  selector: 'app-operationbtn-cell',
  template: `
      <div>
          <a (click)="modify()" *ngIf="!editMode && isShowMod" title="修改"><i class="anticon anticon-edit"></i></a>
          <a (click)="delete()" *ngIf="!editMode && isShowDel" title="删除"><i class="anticon anticon-delete color-error"></i></a>
          <a (click)="save()" *ngIf="editMode" title="保存"><i class="anticon anticon-check"></i></a>
          <a (click)="cancel()" *ngIf="editMode" title="取消"><i class="anticon anticon-close color-error"></i></a>
      </div>`,
  styleUrls: [
    './operation-button-component.less'
  ]
})
export class OperationbtnComponent implements ICellRendererAngularComp {
  private params: any;
  value: any;
  @Input() isShowMod: boolean = true;
  @Input() isShowDel: boolean = true;
  @Input() disabelDefultSave: boolean = false;

  public editMode: boolean = false;

  agInit(params: any): any {
    this.params = params;
    this.value = params.getValue();
    this.disabelDefultSave = params.colDef.cellRendererParams.disabelDefultSave;
    // if (
    //   this.params.colDef.cellRendererParams &&
    //   this.params.colDef.cellRendererParams.attrbiue) {
    //   if (this.params.colDef.cellRendererParams.attrbiue.isShowMod != undefined) {
    //     this.isShowMod = this.params.colDef.cellRendererParams.attrbiue.isShowMod;
    //   }
    //   if (this.params.colDef.cellRendererParams.attrbiue.isShowDel != undefined) {
    //     this.isShowDel = this.params.colDef.cellRendererParams.attrbiue.isShowDel;
    //   }
    // }
  }

  modify(): void {

    if (!this.disabelDefultSave) {
      const renderersInOtherRows = this.params.api.getCellRendererInstances(this.params);

      if ( renderersInOtherRows && renderersInOtherRows.length > 0 ) {
        for ( let i = 0; i < renderersInOtherRows.length; i++) {
          const wrapper = renderersInOtherRows[i];
          // @ts-ignore
          if ( wrapper.getFrameworkComponentInstance && wrapper.getFrameworkComponentInstance() instanceof OperationbtnComponent ) {
            const foundRenderer = wrapper.getFrameworkComponentInstance() as OperationbtnComponent;
            if ( foundRenderer.editMode ) {
              foundRenderer.cancel();
            }
          }
        }
      }

      this.editMode = true;

      this.params.api.setFocusedCell(this.params.node.rowIndex, 'operation');
      this.params.api.startEditingCell({
        rowIndex: this.params.node.rowIndex,
        colKey: 'operation'
      });
    }

    console.log('modify!');
    if (
      this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.modify) {
      this.params.colDef.cellRendererParams.modify(this.params);
    }
  }

  delete(): void {
    console.log('delete!');
    if (
      this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.delete) {
      this.params.colDef.cellRendererParams.delete(this.params);
    }
  }

  public save(): void {
    this.editMode = false;
    this.params.api.stopEditing(false);

    console.log('delete!');
    if (
      this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.save) {
      this.params.colDef.cellRendererParams.save(this.params);
    }
  }

  public cancel(): void {
    this.editMode = false;
    this.params.api.stopEditing(true);

    console.log('delete!');
    if (
      this.params.colDef.cellRendererParams &&
      this.params.colDef.cellRendererParams.cancel) {
      this.params.colDef.cellRendererParams.cancel(this.params);
    }
  }

  refresh(): boolean {
    return true;
  }

}
