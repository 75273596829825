/**
 * 代码修改自
 * https://gist.github.com/adamrecsko/0f28f474eca63e0279455476cc11eca7#gistcomment-2103489
 */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'fxHighlight' })
export class FxHighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text) {
      let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      pattern = pattern.split(' ').filter((t) => {
        return t.length > 0;
      }).join('|');
      const regex = new RegExp(pattern, 'gi');

      return text.replace(regex, (match) => `<mark class="search-highlight">${match}</mark>`);
    } else {
      return text;
    }
  }
}
