import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, Optional, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateParser } from '@ngx-translate/core';
import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxAgGridEventHandlerService, IAgGridEditorEvent} from '../ag-grid/fx-ag-grid-event-handler.service';
import { FxBase } from './fxbase';
import { FxBaseModel } from './fx-base.model';
import { GridOptions, GridApi, ColumnApi, Column, RowNode, RowNodeTransaction} from 'ag-grid-community';
import {DwNewRouteKeyService} from '@webdpt/framework';

@Component({
    selector: 'fx-base',
    template: '',
    styles: [ `` ],
  })
  export class  FxBaseComponent implements FxBase {

    // 控管的按钮
    readonly button: {
        [key: string]: boolean;
    };

    // 控管的栏位
    readonly field: {
        [key: string]: boolean;
    };

    protected router: DwNewRouteKeyService;
    protected route: ActivatedRoute;
    protected http: FxDapHttpClient;
    protected agGridEventHandler: FxAgGridEventHandlerService;
    protected baseModel:  FxBaseModel;   // 只有patteren中可用

    constructor (public baseInjector: Injector, @Optional() baseModel?:  FxBaseModel) {
        this.router = this.baseInjector.get(DwNewRouteKeyService);
        this.route = this.baseInjector.get(ActivatedRoute);
        this.http = this.baseInjector.get(FxDapHttpClient);
        this.agGridEventHandler = this.baseInjector.get(FxAgGridEventHandlerService);
        this.baseModel = baseModel;
    }

    // 权限检查
    hasPermission(permission: string): boolean {
      if (permission) {
          if (typeof(permission) === 'string') {
              // return this.button[permission]; //ToDo 权限服务还没有
              return true;
        }
        return false;
        }
    }

    // 新增
    addButton(name: string, permission: boolean): void {
    }

    // 删除
    removeButton(name: string): void {
    }

    // 替换
    setButton(name: string, permission: boolean): void {
    }

    // 是否存在
    containsButton(name: string): boolean {
        return true;
    }

    // 新增
    addField(name: string, permission: boolean): void {
    }

    // 删除
    removeField(name: string): void {

    }

    // 替换
    setField(name: string, permission: boolean): void {

    }

    // 是否存在
    containsField(name: string): boolean {

        return true;
    }

    // 字段隐藏
    getHideField(gridColumnApi: ColumnApi): void {

    }

}
