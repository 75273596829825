import { Component, ElementRef, OnDestroy, Optional, Renderer2, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { DwInputNumberComponent, DwSelectComponent } from 'ng-quicksilver';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';  // Added by David Lee 25Feb2019
import {of, Subscription} from 'rxjs';   // Added by David Lee 25Feb2019

@Component({
  selector: 'fx-ag-select-cell-editor',
  template: `
    <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
         [dwTitle]="errorContent" [dwVisible]="errorVisible"
         *ngIf="errorVisible"></div>
    <ng-template #errorContent>
      <div *ngFor="let msg of validationMessages">{{ message }} {{ msg.key | translate:msg.params }}</div>
    </ng-template>
    <form [formGroup]="validateForm">
      <dw-form-control>
        <dw-input-group>
          <dw-select dw-input style="width: 100%"
                     [formControl]="formControl"
                     placeholder=""
                     [dwShowSearch]="dwShowSearch"
                     [dwAllowClear]="dwAllowClear"
                     [dwDisabled]="dwDisabled"
                     #cellInput (keydown)="onKeyDown($event)">
            <dw-option *ngFor="let item of values | async"
                       [dwValue]="item.value ? item.value : item"
                       [dwLabel]="item.label ? item.label : item"></dw-option>
          </dw-select>
        </dw-input-group>
      </dw-form-control>
    </form>
  `
})
export class FxAgSelectCellEditorComponent extends FxAgGridValidationEditorComponent
  implements ICellEditorAngularComp, OnDestroy {
  colId;
  values: any;
  dwShowSearch: boolean;
  dwAllowClear: boolean;
  dwDisabled: boolean;
  @ViewChild('cellInput') cellInput: DwSelectComponent;
  params: ICellEditorParams;  // Added by David Lee 25Feb2019
  valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  name: string;
  message: string;

  constructor(private fb: FormBuilder, @Optional() private eventHandler: FxAgGridEventHandlerService) {
    super();
  }

  onKeyDown(event): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38 
      || event.keyCode === 39 || event.keyCode === 40) {
        event.stopPropagation();
    }
  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.params = params;  // 把cellEditorParams参数赋给params Added By David Lee 18Feb2019
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    this.colId = params.column.colId;
    this.formControl = new FormControl(params.value, params.validators);
    this.validateForm = params.form;
    this.values = Array.isArray(params.values)
      ? of(params.values)
      : params.values;    this.dwShowSearch = !!params.dwShowSearch;
    this.dwAllowClear = !!params.dwAllowClear;
    this.dwDisabled = !!params.dwDisabled;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.name = this.params.node.id + '-' + params.column.colId;

    if ( this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);
    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }
    this.formControl.markAsDirty();

    // 监听赋值 Add David lee 25 Feb 2019
    this.valueSetSubscription = this.eventHandler.valueSet.subscribe(event => {
      if ( (event.colId === this.colId) && (event.gridId === params.context.id) && (!event.id || (event.id && event.id === params.node.id))) {
             this.formControl.setValue(event.value);
             this.message = event.message;
             this.formControl.markAsDirty();
      }
    });

    super.init();
  }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {

    const valueChange = (value: string): void => {

      this.formControl.setValue(value);
      this.formControl.markAsDirty({onlySelf: true});

      if (this.eventHandler) {
        this.eventHandler.onBeforeField({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }

      if (this.eventHandler) {
        this.eventHandler.onChangeField({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }

    };
    this.cellInput.registerOnChange( valueChange) ;  // Added by David Lee 注册OnChange事件


    const OnTouched = (): void => {

      if (this.eventHandler) {
        this.eventHandler.onAfterField({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }

    };
    this.cellInput.registerOnTouched(OnTouched);

  //    // 通过订阅消息回传onFocus事件
  //   this.cellInput.onChange = (e: any): void => {
  //   if (this.eventHandler) {
  //     this.eventHandler.onChangeField({
  //       value: this.validateForm.controls[this.columnId].value,
  //       rowIndex: this.params.node.rowIndex,
  //       columnId: this.columnId
  //     });
  //   }
  // };

      // // 通过订阅消息回传onClick事件
      // this.cellInput.onClick = (): void => {
      //   if (this.eventHandler) {
      //     this.eventHandler.onBeforeField({
      //       value: this.formControl.value,
      //       rowIndex: this.params.node.rowIndex,
      //       columnId: this.columnId
      //     });
      //   }
      // };
  }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {

    this.cellInput.focus();
    if (this.eventHandler) {
      this.eventHandler.onBeforeField({
        gridId: this.params.context.id,
        value: this.formControl.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
      });
    }
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    this.cellInput.blur();
    if (this.eventHandler) {
      this.eventHandler.onAfterField({
        gridId: this.params.context.id,
        value: this.formControl.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
      });
    }
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.formControl.value;
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
    // return false;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.name);
    // 当销毁时取消所有订阅
    if (this.valueSetSubscription) {
      this.valueSetSubscription.unsubscribe();
    }
  }


}
