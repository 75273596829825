import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject, BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FxHotkeyCommunicateService {

  private _hotkeyConfig: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public _dangerMsg: any;
  // private _hotkeyConfig: Subject<any> = new Subject<any>();

  // Observable string streams
  hotkeyUpdate$ = this._hotkeyConfig.asObservable();

  /**
   * 向其他组件发送信息
   * @param message 需要发送的信息
   * @returns {Observavle<any>}
   */
  public setHotkeyMap(hotkeys: any): void {
    this._dangerMsg = hotkeys;
    this._hotkeyConfig.next(hotkeys);
  }

  /**
   * 订阅其他组件发送来的消息
   * @returns {Observalue<any>}
   */
  public getHotkeyMap(): Observable<any> {
    return this._hotkeyConfig.asObservable();
  }

  constructor() {
  }
}
