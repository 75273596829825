import {Component, OnInit, Input, ViewChild, ElementRef, Renderer2, AfterViewInit} from '@angular/core';
import {observable, Observable} from 'rxjs';
import {FxDapHttpClient} from '../../../fx-http-client';
import {TranslateService} from '@ngx-translate/core';
import {DwRoutingMessageService, DwUserService} from '@webdpt/framework';
import {DwTreeNode,DwTreeComponent} from 'app/implementation/shared/tree/dw-tree-node';
import {GridApi} from '../../../../../../../node_modules/ag-grid-community';
import {FxModalService} from '../../fx-modal/fx-modal.service';
import {DwModalRef} from 'ng-quicksilver';
// import { DwTreeComponent } from 'app/implementation/shared/tree/dw-tree.component.js';

export enum USER_EDIT_ACTION {
  ADD = '1',
  REMOVE = '2'
}

@Component({
  selector: 'app-role-and-user-relationship-setting',
  templateUrl: './role-and-user-relationship-setting.component.html',
  styleUrls: ['./role-and-user-relationship-setting.component.less']
})
export class RoleAndUserRelationshipSettingComponent implements OnInit, AfterViewInit {

  @Input() role_id: string;
  @Input() role_name: string;
  @ViewChild('treeCom') treeCom: DwTreeComponent;

  // 搜索文本框
  searchValue: string = '';
  // 组织树
  nodes: Array<any> = [];
  // 选择组织节点
  currentNode: any = {};

  // 待选择用户搜索
  orgUserSearchValue: string = '';
  // 已选择用户搜索
  selectedUserSearchValue: string = '';
  // 组织下可选用户
  orgUsers: Array<any> = [];
  filteredOrgUsers: Array<any> = [];
  // 已选择的用户
  selectedUsers: Array<any> = [];
  filteredSelectedUsers: Array<any> = [];

  gridApi1: GridApi;
  gridApi2: GridApi;
  defaultColDef = {
    suppressSorting: true,
    suppressFilter: true,
    suppressMovable: true,
    editable: false
  };
  columnDefs1 = [];
  columnDefs2 = [];
  gridContext1 = {};
  gridContext2 = {};

  constructor(
    private modal: DwModalRef,
    public http: FxDapHttpClient,
    public dwMessage: DwRoutingMessageService,
    public fxModalService: FxModalService,
    private translateService: TranslateService,
    private userService: DwUserService,
    private el: ElementRef,
    private render: Renderer2
  ) {
    console.log(userService);
  }

  ngOnInit() {
    this.getOrgData(this.userService.userInfo['c_user']).subscribe(data => {
      // this.nodes = data.map(item => {
      //   return new DwTreeNode(item);
      // });
      let arr = [];
      data.forEach(item => {
        arr.push(new DwTreeNode(item));
      });
      this.nodes = arr;
    });

    this.columnDefs1 = [
      {
        headerName: '',
        width: 55,
        headerCheckboxSelection: true, // 标题添加checkbox
        headerCheckboxSelectionFilteredOnly: true, // 全选只勾选过滤部分
        checkboxSelection: true
      },
      {
        headerName: this.translateService.instant('abj001'),
        field: 'abj001',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abj001_n1'),
        field: 'abj001_n1',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abjldept'),
        field: 'abjldept',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abjlorg_n1'),
        field: 'abjlorg_n1',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abjlorg'),
        field: 'abjlorg',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abjlorg_n1'),
        field: 'abjlorg_n1',
        width: 100,
      }
    ];
    /**
     abk001: "10058"
     abk001_n1: "丁浩"
     abkldept: null
     abkldept_n1: null
     abklorg: "100001"
     abklorg_n1: "总部"
     */
    this.columnDefs2 = [
      {
        headerName: '',
        width: 55,
        headerCheckboxSelection: true, // 标题添加checkbox
        headerCheckboxSelectionFilteredOnly: true, // 全选只勾选过滤部分
        checkboxSelection: true
      },
      {
        headerName: this.translateService.instant('abk001'),
        field: 'abk001',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abk001_n1'),
        field: 'abk001_n1',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abkldept'),
        field: 'abkldept',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abkldept_n1'),
        field: 'abkldept_n1',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abklorg'),
        field: 'abklorg',
        width: 100,
      },
      {
        headerName: this.translateService.instant('abklorg_n1'),
        field: 'abklorg_n1',
        width: 100,
      }
    ];
  }

  ngAfterViewInit(): void {
    console.log(this.el);
    console.log(this.render);
    setTimeout(() => {
      try {
        const fmc = this.el.nativeElement.getElementsByClassName('fx_modal_container')[0];
        // fmc.parentNode.parentNode.parentNode.set
        this.render.setStyle(
          fmc,
          'background',
          '#fff'
        );
        this.render.setStyle(
          fmc.parentNode.parentNode.parentNode,
          'background',
          'rgba(0,0,0,0)'
        );
        this.render.setStyle(
          fmc.parentNode.parentNode.parentNode,
          'box-shadow',
          'none'
        );
        this.render.setStyle(
          fmc.parentNode.parentNode.parentNode.getElementsByClassName('ant-modal-close-x')[0],
          'display',
          'none'
        );
        this.render.setStyle(
          fmc.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode,
          'background',
          'rgba(0,0,0,0)'
        );
      } catch (e) {
        console.error(e);
      }
    }, 300);
  }

  // private dwEventOnDragStart($event: any): void {}
  // private dwEventOnDragEnter($event: any): void {}
  // private dwEventOnDragLeave($event: any): void {}
  // private dwEventOnDragOver($event: any): void {}
  // private dwEventOnDrop($event: any): void {}
  // private dwEventOnDragEnd($event: any): void {}
  // private dwEventExpandChange($event: any): void {}
  // private dwEventSearchValueChange($event: any): void {}
  public dwClick($event: any): void {
    console.log($event);
    if (this.currentNode && this.currentNode.key === $event.node.key) {
      return;
    }
    this.currentNode = $event.node;
    this.showUserInfo();
  }

  private showUserInfo() {
    this.showRoleUserInfo(this.role_id, this.currentNode.key).then(() => {
      this.showOrgUserInfo(this.currentNode.key);
    });
  }

  private getOrgData(userNo: string): Observable<any> {
    return Observable.create((observer) => {
      const entNo = this.userService.userInfo['c_ent'];
      this.http.post('A/IACommonService/getOrgTree', {param: {user_no: userNo, entNo: entNo, language: this.userService.userInfo['c_lang']}}).subscribe(data => {
        if (data.success && data.data) {
          observer.next(data.data);
        } else {
          observer.error(data);
        }
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });

  }

  private getOrgUserData(orgId): Observable<any> {
    return Observable.create((observer) => {
      this.http.post('A/IFxai023Service/getUserListByOrg', {param: {org_no: orgId}}).subscribe(data => {
        if (data.success && data.data) {
          observer.next(data.data);
        } else {
          observer.error(data);
        }
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  private getRoleUserData(roleId: string, orgId: string): Observable<any> {
    return Observable.create((observer) => {
      this.http.post('A/IFxai023Service/getUserListByRole', {param: {role_no: roleId, org_no: orgId}}).subscribe(data => {
        if (data.success && data.data) {
          observer.next(data.data);
        } else {
          observer.error(data);
        }
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  searchOrgUser() {
    this.filteredOrgUsers = ('' === this.orgUserSearchValue)
      ? this.orgUsers
      : this.orgUsers.filter(item => {
        return JSON.stringify(item).indexOf(this.orgUserSearchValue) > -1;
      });
  }

  searchSelectedUser() {
    this.filteredSelectedUsers = ('' === this.selectedUserSearchValue)
      ? this.selectedUsers
      : this.selectedUsers.filter(item => {
        return JSON.stringify(item).indexOf(this.selectedUserSearchValue) > -1;
      });
  }

  async showOrgUserInfo(orgId: string): Promise<void> {
    this.filteredOrgUsers = [];
    // this.filteredSelectedUsers = [];
    this.orgUsers = [];
    // this.selectedUsers = [];
    this.orgUserSearchValue = '';
    // this.selectedUserSearchValue = '';
    try {
      const orgUsers = await this.getOrgUserData(orgId).toPromise();
      this.orgUsers = orgUsers.filter(orgUser => {
        return this.selectedUsers.findIndex(selectedUser => {
          return orgUser.abj001 === selectedUser.abk001;
        }) < 0;
      });
    } catch (e) {
      console.error(e);
    }
    this.searchOrgUser();
  }

  async showRoleUserInfo(roleId: string, orgId: string): Promise<void> {
    // this.filteredOrgUsers = [];
    this.filteredSelectedUsers = [];
    // this.orgUsers = [];
    this.selectedUsers = [];
    // this.orgUserSearchValue = '';
    this.selectedUserSearchValue = '';
    try {
      this.selectedUsers = await this.getRoleUserData(roleId, orgId).toPromise();
    } catch (e) {
      console.error(e);
    }
    this.searchSelectedUser();
  }

  // grid多语系
  localTextFunc(key: string): any {
    // return this.translateService.instant(key);
  }

  onGrid1Ready(params: any): void {
    this.gridApi1 = params.api;
  }

  onGrid2Ready(params: any): void {
    this.gridApi2 = params.api;
  }

  addSelectedItem(): void {
    let selectRows = this.gridApi1.getSelectedRows().map(item => {
      return {
        user_no: item.abj001
      };
    });
    selectRows = selectRows.filter(item => {
      return this.selectedUsers.findIndex(user => user.abk001 === item.user_no) < 0;
    });
    if (selectRows.length > 0) {
      this.addUserByRole(selectRows, this.role_id, USER_EDIT_ACTION.ADD).subscribe(success => {
        console.log('success', success);
        this.dwMessage.info('修改成功');
        this.showUserInfo();
      }, error => {
        this.fxModalService.confirm(error);
        this.showUserInfo();
      });
    }

  }

  removeSelectedItem(): void {
    let selectRows = this.gridApi2.getSelectedRows().map(item => {
      return {
        user_no: item.abk001
      };
    });
    selectRows = selectRows.filter(item => {
      return this.orgUsers.findIndex(user => user.abj001 === item.user_no) < 0;
    });
    if (selectRows.length > 0) {
      this.addUserByRole(selectRows, this.role_id, USER_EDIT_ACTION.REMOVE).subscribe(success => {
        console.log('success', success);
        this.dwMessage.info('修改成功');
        this.showUserInfo();
      }, error => {
        this.fxModalService.confirm(error);
        this.showUserInfo();
      });
    }
  }

  addUserByRole(users: Array<any>, roleId: string, action: USER_EDIT_ACTION): Observable<any> {
    return Observable.create((observer) => {
      this.http.post('A/IFxai023Service/updUserListByRole', {
        param: {
          role_no: roleId,
          type: action,
          user_list: users
        }
      }).subscribe(data => {
        if (data.success) {
          observer.next(data);
        } else {
          observer.error(data);
        }
        observer.complete();
      }, error => {
        observer.error(error);
      });
    });
  }

  onClickContinue(): void {
    this.modal.triggerOk();
  }
}
