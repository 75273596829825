import { Component, OnInit, Input } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'save-comcond',
  template: 
  `
  
  <div class="fx_modal_container" appModalDrag>
    <form dw-form [formGroup]="addForm" (ngSubmit)="addData()" class="fx_modal_form">
      <dw-form-input
        formControlName="label"
        dwLabel="{{'com-常用条件名称' | translate}}"
        dwLabelSpan="6"
        dwInputSpan="18"
        [dwRequired]="true">
      </dw-form-input>
      <dw-form-item dw-row>
        <div dw-col [dwSpan]="6">
        </div>
        <dw-form-control dw-col [dwSpan]="18">
          <label dw-checkbox formControlName="aan005" >
            <span>{{ 'com-默认查询条件' | translate}}</span>
          </label>
        </dw-form-control>
      </dw-form-item>
      <div class="customize-footer">
        <button dw-button type="button" [dwType]="'default'" (click)="handleCancel()">{{ 'dw-cancel' | translate}}</button>
        <button dw-button [dwType]="'primary'" [disabled]="!addForm.valid">{{ 'dw-determine' | translate}}</button>
      </div>
    </form>
    </div>
  
  `,
  styles: [
    `
    :host ::ng-deep .customize-footer {
      // border-top: 1px solid #e9e9e9;
      padding: 0;
      text-align: right;
      border-radius: 0 0 0px 0px;
      margin: 0;
    }
    :host ::ng-deep .ant-form-item-label {
      line-height: 24px;
    }
    :host ::ng-deep .ant-form-item-label label {
      font-size: 13px;
    }
    :host ::ng-deep .ant-layout-content {
      margin: -24px;
    }
    :host ::ng-deep .fx_modal_form {
      padding: 10px;
    }
    :host ::ng-deep .fx_modal_form .customize-footer button {
      padding: 0 15px;
      transition: 1s all;
    -webkit-transition: 1s all;
    }
    :host ::ng-deep .fx_modal_form .customize-footer button:first-child {
      background: #69c0ff;
      border-color: #69c0ff;
      color: #fff;
      box-shadow: 0 1px 6px #bae7ff;
    }
    :host ::ng-deep .fx_modal_form .customize-footer button:last-child {
      color: #fff;
      border-color: #1890ff;
      background: #1890ff;
      box-shadow: 0px 1px 6px 0px #72c0ff;
    }
    .customize-footer button:hover {
      border-color: #3aa7ff;
      background: #3aa7ff;
    }
    :host ::ng-deep .customize-footer button:last-child:hover {
      color: #fff;
      border-color: #0b79de;
      background: #0b79de; 
    }
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary.disabled, 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary[disabled], 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary.disabled:hover, 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary[disabled]:hover, 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary.disabled:focus, 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary[disabled]:focus, 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary.disabled:active, 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary[disabled]:active, 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary.disabled.active, 
    :host ::ng-deep .fx_modal_form .customize-footer .ant-btn-primary[disabled].active {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      box-shadow: 0 1px 6px #d9d9d9;
    }
    `
  ]
})
export class saveCondComponent implements OnInit  {
  public addForm: FormGroup;

  constructor(private modalSubject: DwModalRef, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.addForm = this.fb.group({});
    this.addForm.addControl('label', new FormControl('', Validators.required));
    this.addForm.addControl('aan005', new FormControl(false));
  }

  addData(): void {
    console.log('保存',this.addForm);
    this.modalSubject.triggerOk();
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.modalSubject.triggerCancel();
  }
}