import {Component, Input, OnInit, Optional} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import { FxAgGridEventHandlerService } from '../fx-ag-grid-event-handler.service';

@Component({
  selector: 'app-fx-grid-operation-button-renderer',
  templateUrl: './fx-grid-operation-button-renderer.component.html',
  styleUrls: ['../fx-grid-operation-button-editor/fx-grid-operation-button-editor.component.less']
})
export class FxGridOperationButtonRendererComponent implements ICellRendererAngularComp {
  private params: any;
  value: any;
  @Input() isShowMod: boolean = true;
  @Input() isShowDel: boolean = true;
  @Input() isShowUp: boolean = false;
  @Input() isShowDown: boolean = false;
  @Input() disabelDefultSave: boolean = false;
  @Input() isShowDetail: boolean = false;

  constructor( @Optional() private eventHandler: FxAgGridEventHandlerService ) {

  }

  agInit(params: any): any {
    this.params = params;
    this.value = params.value;
    this.disabelDefultSave = params.disabelDefultSave;
    this.isShowMod = this.isFunc(params.isShowMod);
    this.isShowDel = this.isFunc(params.isShowDel);
    this.isShowUp = (params.isShowUp !== undefined) && params.isShowUp;
    this.isShowDown = (params.isShowDown !== undefined) && params.isShowDown;
    this.isShowDetail = (params.isShowDetail !== undefined) && params.isShowDetail;
  }

  // Added by David Lee
  isFunc(value: boolean|any): boolean {

    if (typeof value === 'boolean') {
        return value;
    }
    if (typeof value === 'function') {
        const isShowDelFunc = value;
        return isShowDelFunc();
    }
    return false;
  }

  modify(): void {

    if (this.eventHandler) {
      this.eventHandler.onClickModifyButton({
        gridId: this.params.context.id,
        value: this.params.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.params.column.colId,
        controlName: ''
      });
    }

    // if (!this.disabelDefultSave) {

    //  this.params.api.setFocusedCell(this.params.node.rowIndex, 'operation');
    //  this.params.api.startEditingCell({
    //    rowIndex: this.params.node.rowIndex,
    //    colKey: 'operation'
    //  });
    // }

    if (this.params.modify) {
      this.params.modify(this.params);
    }
  }

  delete(): void {

    // Added by David Lee 1Apr2019
    if (this.eventHandler) {
      this.eventHandler.onClickDeleteButton({
        gridId: this.params.context.id,
        value: this.params.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.params.column.colId,
        controlName: '',
        dataId: this.params.data.id || '-1'
      });
    }


    if (this.params.delete) {
      this.params.delete(this.params);
    }
  }

  up(): void {
    if (this.params.up) {
      this.params.up(this.params);
    }
  }
  down(): void {
    if (this.params.down) {
      this.params.down(this.params);
    }
  }
  detail(event: any): void {
    event.stopPropagation();
    if (this.eventHandler) {
      this.eventHandler.onClickDetailButton({
        gridId: this.params.context.id,
        value: this.params.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.params.column.colId,
        controlName: ''
      });
    }

    if (this.params.detail) {
      this.params.detail(this.params);
    }
  }

  refresh(): boolean {
    this.isShowDel = this.isFunc(this.params.isShowDel);
    return true;
  }

}
