import { NgModule } from '@angular/core';
import { DwLoginBlockComponent } from './login-block/login-block.component';
import { CommonModule } from '@angular/common';
import { NgQuicksilverModule } from 'ng-quicksilver';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DwIamPermissionInfoService } from '../../iam';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgQuicksilverModule,
    TranslateModule
  ],
  declarations: [DwLoginBlockComponent],
  exports: [DwLoginBlockComponent],
  providers: [DwIamPermissionInfoService]
})
export class DwLoginModule {}
