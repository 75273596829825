import { Component, OnDestroy, Optional, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular/src/interfaces';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslateParser } from '@ngx-translate/core';
import { DwDatePickerComponent, CandyDate } from 'ng-quicksilver';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';  // Added by David Lee 25Feb2019
import {Subscription} from 'rxjs';   // Added by David Lee 25Feb2019

@Component({
  selector: 'fx-cell-input-editor',
  template: `
    <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
         [dwTitle]="errorContent" [dwVisible]="errorVisible"></div>
    <ng-template #errorContent>
      <div *ngFor="let msg of validationMessages">{{message}} {{ msg.key | translate:msg.params }}</div>
    </ng-template>
    <form [formGroup]="validateForm">
      <dw-form-control>
        <dw-input-group>
          <dw-date-picker dwPlaceHolder=""
                          [dwFormat]="dateFormat"
                          [dwShowTime]="is_ShowTime"
                          [formControl]="formControl"
                          dwSize="default"
                          (keydown)="onKeyDown($event)"
                          #cellInput>  <!--对子组件元素的实例引用 Added By David Lee 18Feb2019  -->
          </dw-date-picker>
        </dw-input-group>
      </dw-form-control>
    </form>
  `
})
export class FxAgGridDateEditorComponent extends FxAgGridValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {

  dateFormat = this.fxUserService.getEntParamById('c_E031');
  colId;
  @ViewChild('cellInput') cellInput: DwDatePickerComponent;  // 获取子组件元素的实例引用 Added By David Lee 18Feb2019
  params: ICellEditorParams;  // 存放cellEditorParams   Added By David Lee 18Feb2019
  valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  flag = true;
  name: string;
  message: string;
  is_ShowTime:false;
  constructor(private fb: FormBuilder, @Optional() private eventHandler: FxAgGridEventHandlerService, private fxUserService: FxUserInfoService, private datePipe: DatePipe) {
    super();
  }
  onKeyDown(event): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38 
      || event.keyCode === 39 || event.keyCode === 40) {
        event.stopPropagation();
    }
  }
  agInit(params: ICellEditorParams | any): void {
    this.is_ShowTime = params.is_ShowTime||false;
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    this.colId = params.column.getColId();
    this.validateForm = params.form;
    this.dateFormat = params.dateFormat ? params.dateFormat : (this.dateFormat ? this.dateFormat : 'yyyy/MM/dd');
    // const currentDate =moment(moment(params.value, this.dateFormat).toDate()).isValid()?moment(params.value, this.dateFormat).toDate():null;    
    // const currentDate =moment(moment(params.value, this.dateFormat).toDate()).isValid()?new Date(this.datePipe.transform(params.value, this.dateFormat)):null;
    var currentDate = null;
    if (params.value !== 'Invalid Date' && params.value) {
      currentDate = new Date(this.datePipe.transform(params.value, this.dateFormat));
    } else {
      currentDate = null;
    }
    this.formControl = new FormControl(currentDate, params.validators);
    this.params = params;  // 把cellEditorParams参数赋给params Added By David Lee 18Feb2019
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.name = this.params.node.id + '-' + params.column.colId;

    if ( this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);
    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }
    this.formControl.markAsDirty();

    // 监听赋值 Add David lee 25 Feb 2019
    this.valueSetSubscription = this.eventHandler.valueSet.subscribe(event => {
      if ( (event.colId === this.colId) && (event.gridId === params.context.id) ) {
        this.formControl.setValue(event.value);
        this.message = event.message;
        this.formControl.markAsDirty();
      }
    });

    super.init();
  }

  getValue(): any {
    if(!this.formControl.value || this.formControl.value == 'Invalid Date') {
      return null;
    } else {
      // return  moment(this.formControl.value, this.dateFormat).format(this.dateFormat);
      const formValue = this.datePipe.transform(this.formControl.value, this.dateFormat);
      this.formControl.setValue(formValue);
      return formValue;
    }    
  }

  afterGuiAttached(): void {

    const valueChange = (value: string): void => {
      // if ( this.params.onChange !== undefined) {
      //  this.params.onChange(value);  // Added by David Lee 值发生变化时回调
      // }
      // 通过订阅消息回传onChangeField事件

      if(!this.formControl.value || this.formControl.value == 'Invalid Date') {
        value = null;
      }
      this.formControl.setValue(value);
      this.formControl.markAsDirty({onlySelf: true});

    };
    this.cellInput.registerOnChange( valueChange) ;  // Added by David Lee 注册OnChange事件


    this.flag = true;
    this.cellInput.onOpenChange = (): void => {
      if (this.eventHandler) {
        if ( this.flag === true) {
        this.eventHandler.onBeforeField({
          gridId: this.params.context.id,
          value: this.formControl.value,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
          });
          this.flag = false;
        }
      }
    };

    this.cellInput.onValueChange = (value: CandyDate): void => {
      if (this.eventHandler) {
        this.eventHandler.onChangeField({
          gridId: this.params.context.id,
         // value: this.formControl.value,
          value: value ? value.nativeDate : null,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
          });
      }
      if (this.is_ShowTime == false) {
        this.cellInput.closeOverlay();
      }

      if (this.eventHandler) {
        this.eventHandler.onAfterField({
          gridId: this.params.context.id,
          // value: this.formControl.value,
          value: value ? value.nativeDate : null,
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
          });
      }
    };


  }

  focusIn(): void {
    if (this.eventHandler) {
      this.eventHandler.onBeforeField({
        gridId: this.params.context.id,
        value: this.formControl.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
        });
    }
  }

  focusOut(): void {
    if (this.eventHandler) {
      this.eventHandler.onAfterField({
        gridId: this.params.context.id,
        value: this.formControl.value,
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
        });
    }
  }

  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.name);
    // 当销毁时取消所有订阅
    if (this.valueSetSubscription) {
      this.valueSetSubscription.unsubscribe();
    }
  }
}
