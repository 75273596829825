import {Observable} from 'rxjs';
import { CfcAgGridValidationRendererComponent } from 'app/implementation/shared/fx-grid/cell-component/cfc-validation-renderer';

export class CfcStatusRendererComponent extends CfcAgGridValidationRendererComponent {
  private eGui;
  public values;

  constructor() { super(); }

  public init(params: any): void {
    this.eGui = document.createElement('div');
    this.eGui.style.paddingLeft = '0px';
    // todo: 这种做法有问题，只有 Subject 订阅早于 grid init 才可以... 吧
    if (params.values as Observable<any>|Array<any>) {
      if (Array.isArray(params.values)) {
        var matchedItem = (params.values.find(item => item.value === params.value) || {});
        this.updateValue(matchedItem);
      } else {
        // 传入 Observable 对象，此时
        try {
          params.values.subscribe(items => {
            var matchedItem = (items.find(item => item.value === params.value) || {});
            this.updateValue(matchedItem);
          });
        } catch (e) {
          console.error(e);
        }
      }
    } else  {
      console.error('params values is error');
    }
  }

  updateValue(matchedItem: any): void {
    var colorSpan = document.createElement('span');
    colorSpan.className = 'status-color';
    colorSpan.style.backgroundColor = (matchedItem ? matchedItem.color : '')  || (matchedItem ? matchedItem.aaa004 : '');
    colorSpan.style.width = '5px';
    colorSpan.style.height = '5px';
    colorSpan.style.display = 'inline-block';
    colorSpan.style.borderRadius = '50%';
    colorSpan.style.verticalAlign =  'middle';
    colorSpan.style.marginRight = '10px';
    this.eGui.appendChild(colorSpan)
    var labelSpan = document.createElement('span');
    colorSpan.className = 'status-label';
    var lable = (matchedItem ? matchedItem.label : '');
    labelSpan.innerHTML = lable ? lable : '';
    this.eGui.appendChild(labelSpan)
  }
  public getGui() {
    return this.eGui;
  }
}
