
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject, of, from, Observer } from 'rxjs';
import { map, mergeAll, filter, distinctUntilChanged, reduce } from 'rxjs/operators';

import { APP_DEFAULT, LONIG_DEFAULT, DW_USING_FRSSO } from '../config/system.config';
import { DwUserService } from './user.service';
import { DwUserStorage } from './user-storage';
import { DwLoginEvent } from './interface';
import { DwSystemConfigService } from '../config/config.service';

export const DW_AUTH_TOKEN = new InjectionToken<DwAuthToken>('DW_AUTH_TOKEN');

@Injectable()
export class DwAuthService {

  loginSubject: BehaviorSubject<boolean>; // 廣播登入狀態
  loginSuccess = (this.userService.userInfo['isLoggedin'] && JSON.parse(this.userService.userInfo['isLoggedin'])) ? true : false;
  // 事件.
  private eventHandler: DwLoginEvent[] = [];
  frUrl: string;
  $reRoute = '';
  /**
   * 注入Angular Router及DW_AUTH_TOKEN做為保存token訊息用
   * param  router Angular Router
   * param authToken DW_AUTH_TOKEN
   */
  constructor(
    protected router: Router,
    protected userService: DwUserService,
    protected storage: DwUserStorage,
    protected http: HttpClient,
    @Inject(DW_AUTH_TOKEN) protected authToken: any,
    @Inject(APP_DEFAULT) protected defaultApp: string,
    @Inject(LONIG_DEFAULT) protected defaultLogin: string,
    @Inject(DW_USING_FRSSO) protected usingFrSSO: boolean,
    protected configService: DwSystemConfigService
  ) {
    this.loginSubject = new BehaviorSubject<boolean>(this.loginSuccess);
    this.configService.get('frUrl').subscribe(
      url => this.frUrl = url
    );
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.loginSubject.asObservable();
  }

  get isLoggedIn(): boolean {
    return this.loginSuccess;
  }

  //记录登录页
  getLoginUrl (url) {
    this.$reRoute = url;
  }

  /**
   * [記錄]登出、登入的前後事件.
   *
   * param {*} event: object.
   */
  public registerEvent(event: DwLoginEvent): void {
    // 如果已經存在了, 就不重複新增.
    if (this.eventHandler.indexOf(event) === -1) {
      this.eventHandler.push(event);
    }
  }

  /**
   * [移除]登出、登入的前後事件.
   *
   * param {DwLoginEvent} event: object.
   */
  public unregisterEvent(event: DwLoginEvent): void {
    const idx = this.eventHandler.indexOf(event);
    if (idx >= 0) {
      this.eventHandler.splice(idx, 1);
    }
  }


  /**
   * 是否已通過驗證.
   *
   * param  currentUrl 當下的URL，如果驗證不通過，將會導向至登入頁，登入完成後會再導回currentUrl
   * return  是否已驗證
   */
  public isAuthenticated(state: RouterStateSnapshot): boolean {

    const currentUrl = state.url;
    const isLoggedIn = (this.userService.userInfo['isLoggedin'] && JSON.parse(this.userService.userInfo['isLoggedin'])) ? true : false;

    // 未登入時轉跳登入頁
    if (!isLoggedIn) {
      this.router.navigate(
        [this.defaultLogin], // 如果使用this.defaultApp, 會造成無窮迴圈.
        {
          queryParams:
          {
            returnUrl: currentUrl ? currentUrl : this.defaultApp
          }
        });
    }
    return isLoggedIn;
  }


  /**
   * 登入.
   *
   * param userConfig 登入的資訊.
   * return 返回Observable.
   */
  public login(userConfig: any): Observable<any> {
    const subject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    // const subject: Subject<any> = new Subject(); // 訂閱時, 還未被 new 出來.
    const info = {
      success: true,
      description: '' // 登入失敗時, 顯示.
    };

    this.logining().subscribe(
      result => {
        if (!result) {
          info.success = false;
          info.description = '登入失敗';
          subject.next(info);
          // subject.complete(); // 統一交由下方的return 把值回寫, 如果這裡 complete 時, 會導致下方的 return 區段無法把值回寫.
          return;
        }

        const userData = {
          tenantId: userConfig.tenantId,
          userId: userConfig.userId,
          userName: userConfig.userName,
          token: 'abcdefg'
        };

        this.setLogined(userData);
        subject.next(info);
        // subject.complete();  // 統一交由下方的return 把值回寫.
        return;
    });

    return subject.asObservable().pipe(
      filter(obsData => obsData !== null), // 不廣播初始值
      distinctUntilChanged() // 有改變時才廣播
    );
  }

  /**
   * 登出並清除儲存紀錄(local, session).
   *
   */
  public logout(): void {
    this.logouting();

    // 登出前不擋, 一律進行登出.
    this.setLogouted();
    if(this.$reRoute != '') {
      this.router.navigateByUrl(this.$reRoute);
    } else {
      this.router.navigateByUrl(this.defaultLogin); // 如果使用this.defaultApp, 當在 / 進行登出時, 因為 url 一樣, 會無法跳轉.
    }
  }

  /**
   * 向後端服務發送請求時要帶入的HTTP headers.
   *
   */
  public getTokenHeaders(url?: string): any {
    if(url.indexOf("http://hufu")>=0  || url.indexOf("http://localhost:4040/sf")>=0)
    {
      return {};
    }
    this.authToken.token = this.userService.userInfo['token'] ? this.userService.userInfo['token'] : '';
    return {
      token: this.authToken.token ? this.authToken.token : ''
    };
  }

  /**
   * 登入成功後, 設定登入的資訊.
   *
   * param {*} loginInfo
   */
  public setLogined(loginInfo: any): void {
    this.userService.setUser({ id: 'isLoggedin', value: true });

    this.userService.setUserInfo(loginInfo);

    this.loginSuccess = true;
    this.loginSubject.next(this.loginSuccess); // 廣播登入狀態

    this.logined();
  }

  /**
   * 登出成功後, 清除登出的資訊.
   *
   */
  public setLogouted(): void {
    this.loginSuccess = false;
    this.loginSubject.next(this.loginSuccess); // 廣播登入狀態

    // 因為 [rememberLogin] 是被 [DwLoginBlockComponent] 寫入到 [localstorage], 所以不應該在這裡 read,
    // 而且也不該對 storage 進行clear(), 因為會清掉其他程式的暫存值.
    // if (this.storage.get('rememberLogin') === 'false') {
    //   this.storage.clear();
    // }

    this.userService.clearUser();
    this.authToken.token = '';

    this.logouted();
  }

  /**
   * [登入前]的事件觸發
   * 使用 reduce 是為了確保只取到一個結果值, 在 reduce 裡所操作的 Observable, 必定要 complete.
   * returns {Observable<boolean>}
   */
  public logining(): Observable<boolean> {
    this.userService.setUser({ id: 'isLoggedin', value: false });
    this.userService.setUser({ id: 'token', value: '' }); // 登入前清空token

    if (this.eventHandler.length === 0) {
      return Observable.create((observer: Observer<any>) => {
        observer.next(true);
        observer.complete(); // 在 create 裡, 需要 complete.
      });
    }

    const eventLists = from(this.eventHandler);
    const eventResults = eventLists.pipe(
      filter(item => ('logining' in item)), // 對 this.eventHandler 過濾 logining(), 如果沒有, 會以預設值 return true.
      map(item => {
        if ('logining' in item) {
          const ret = item.logining();
          // 如果有註冊 logining() 且為 Observable, 直接 return, 如果不是 Observable, 利用 of() 轉型為 Observable.
          return (ret instanceof Observable) ? ret : of(ret);
        }
      }),
      mergeAll(),
      reduce((acc, val): boolean => acc && val, true) // 第 2 個參數 true 是預設值, this.eventHandler 有幾個就要進來幾次, 值為 val, acc 為每次的結果值.
    );

    return Observable.create((observer: Observer<any>) => {
      eventResults.subscribe(result => {
        console.log('result>>>>', result);
        observer.next(result);
        observer.complete(); // 在 create 裡, 需要 complete.
      });
    });

  }

  /**
   * [登入後]的事件觸發.
   *
   */
  public logined(): void {
    this.eventHandler.forEach(item => {
      if (item['logined']) {
        item['logined']();
      }
    });
  }

  /**
   * [登出前]的事件觸發.
   *
   */
  public logouting(): void {
    this.eventHandler.forEach(item => {
      if (item['logouting']) {
        item['logouting']();
      }
    });
  }

  /**
   * [登出後]的事件觸發.
   *
   */
  public logouted(): void {
    this.eventHandler.forEach(item => {
      if (item['logouted']) {
        item['logouted']();
      }
    });

    // 登出後, 要清空, 不然會累積-因為在一般 @Component 裡沒有 ngOnDestroy 可以用.
    this.eventHandler = [];
  }

  /**
   * FineReport 的 SSO login.
   *
   * returns {void}
   */
  public fineReportlogin(): void {
    if (!this.usingFrSSO) {
      return;
    }

    const userToken = this.userService.userInfo['token'];
    const userId = this.userService.userInfo['c_user'];
    const tenantId = this.userService.userInfo['tenantId'];
    // fineReport SSO 登入時, fr_username 要用[租戶ID:用戶ID].
    const fr_username = tenantId + ':' + userId;
    const url = `${this.frUrl}?op=fs_load&cmd=sso&fr_username=${fr_username}&fr_password=${userToken}`;
    this.http.jsonp(url, 'callback').subscribe();
  }

  //获取客户端IP
  // public getClientIP() {
  //   // this.http.get(window.location.origin+'/api/test/').subscribe(data => {
  //     this.http.get('http://ip.taobao.com/outGetIpInfo?ip=myip&accessKey=alibaba-inc').subscribe((response: any) => {
  //       this.userService.setUser({id: 'localIp', value: response.data.ip});
  //     })
  //   //   this.http.get('http://api.ipify.org').subscribe(data => {
      
  //   //     let results = data['test'];
  //   //     console.log('=====', results, data)
  //   //   })
  //   // //获取客户端IP
  //   // this.http.post('I/IGetPubNetIPService/showIP', {param: {}}).subscribe((response: any )=> {
  //   //   this.userService.setUser({localIp: response.ip});
  //   // })
  // }


}

export interface DwAuthToken {
  token: string;
  expiredDate: any;
}

