import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fxFilter'
})
export class FilterPipe implements PipeTransform {

  transform(value: Array<any> | any, args?: any): any {
    if (!value || !args) {
      return value;
    } else {
      return value.filter(item => JSON.stringify(item).toLowerCase().includes(args.toLowerCase()));
    }
  }

}
