import { AfterContentInit, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { DwPaginationComponent } from 'ng-quicksilver';

import { tap } from 'rxjs/operators';
import { DwQueryInfo } from '../../document/model/query';
import { Observable } from 'rxjs/internal/Observable';
import { AgGridNg2 } from 'ag-grid-angular';

@Component({
  selector: 'dw-pagination-client-side-wrap',
  template: `
    <ng-content></ng-content>
  `
})
export class DwPaginationClientSideWrapperComponent implements AfterContentInit {
  @ContentChild(DwPaginationComponent) paginationComponent: DwPaginationComponent;

  @Input() dataSource: {
    getDataList(pageNumber: number, pageSize: number, queryInfo: DwQueryInfo): Observable<any>;
  };
  @Input() agGridComponent: AgGridNg2;
  @Input() pageNumber: number = 1;
  @Input() pageSize: number = 10;
  @Input() rowCount: number = 0;
  @Input() queryInfo: DwQueryInfo;
  @Output() dataSourceChange = new EventEmitter();

  pageable: boolean = true;

  ngAfterContentInit(): void {

    if (this.paginationComponent && this.dataSource) {

      this.reSendData();
      this.paginationComponent.dwTotal = this.rowCount;
      this.paginationComponent.dwPageIndex = this.pageNumber;
      this.paginationComponent.dwPageSize = this.pageSize;

      if (this.agGridComponent) {
        this.paginationComponent.dwPageIndexChange.subscribe((index: number) => {
            this.pageNumber = index;
            this.agGridComponent.api.paginationGoToPage(index - 1);
          }
        );
        this.paginationComponent.dwPageSizeChange.subscribe((size: number) => {
          this.pageSize = size;
          this.agGridComponent.api.paginationSetPageSize(size);
        });

      }
    }

  }

  reSendData(): void {

    this.dataSource.getDataList(this.pageNumber, this.pageSize, this.queryInfo).subscribe(
      (result) => {
        this.dataSourceChange.emit(result);
        this.paginationComponent.dwTotal = result.data.length;
      }
    );
  }

}
