import { Injectable, inject } from '@angular/core';
import { FxDapHttpErrorHandler } from './fx-http-error-handler';
import { DwSystemConfigService, DwUserService, DwHttpClient } from '@webdpt/framework';
import {ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { DwDapHttpErrorHandler, DwProgramInfoListJsonService,
  // DwHttpClientOptionsService, DwHttpRequestUrlService, DwLoadingMaskService,
  DW_APP_AUTH_TOKEN, DW_AUTH_TOKEN, DwRouterInfoService, IDwProgram } from '@webdpt/framework';
import { DwHttpClientOptionsService } from '../../../../../../projects/webdpt/framework/http/service/http-client-options.service';
import { DwHttpRequestUrlService } from '../../../../../../projects/webdpt/framework/http/service/http-request-url.service';
import { DwLoadingMaskService } from '../../../../../../projects/webdpt/framework/components/loading/service/loading-mask.service';
// import { DW_APP_AUTH_TOKEN } from '../../../config/system.config';
// import { DW_AUTH_TOKEN } from '../../../auth/auth.service';
// import { DwRouterInfoService } from '../../../router-info/router-info.service';
// import { IDwProgram } from '../../../program-info/interface/program.interface';

@Injectable()
export class FxDapHttpClient extends DwHttpClient {
  constructor(
    public dwHttpClientOptionsService: DwHttpClientOptionsService,
    public dwHttpRequestUrlService: DwHttpRequestUrlService,
    public dwLoadingMaskService: DwLoadingMaskService,
    private systemConfig: DwSystemConfigService,
    private userService: DwUserService,
    private dapHttpError: FxDapHttpErrorHandler,
    protected route: ActivatedRoute
    ) {
      super(dwHttpClientOptionsService, dwHttpRequestUrlService, dwLoadingMaskService);

    this.systemConfig.get('apiUrl').subscribe(url => this.api = url);

  }

  protected get defaultHeaders(): { [header: string]: string | string[] }  {
    const token = this.userService.getUser('token') ? this.userService.getUser('token') : '';
    // @ts-ignore
    let programCode = this.route.snapshot._routerState.url.split(';')[0].replace('/dwTabRouting/', '').split('/')[0];
    if (programCode === '/dwTabRouting') {
      programCode = 'dwTabRouting';
    }
    // return {
    //   'token': token
    // };

    // header 的值为：
    // defaultHeader + authService.userData
    return {
      'token': token,
      'program-code': programCode || 'fx-666',
      'ati-cookie': document.cookie || '',
      'local-ip': localStorage.getItem('local_cip') || '',
      'jdid': localStorage.getItem('jd_eid')||''
      
      //'content-type': 'application/json, text/plain'
    };
  }

  protected responseIntercept(body: any): any {
    if (body && body.response) {
      return body.response;
    } else {
      return body;
    }
  }

  protected errorHandler(event: any): void {
    console.error(event);
    if (event.error && event.error.status) {
      this.dapHttpError.handlerError(event);
    }
  }
}
