import { Component, OnDestroy, OnInit, Input, AfterViewInit, Output, ViewEncapsulation, DoCheck } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { FxDapHttpClient } from '../../fx-http-client';

@Component({
  selector: 'fx-detail-status',
  templateUrl: './fx-detail-status.component.html',
  styleUrls: ['./fx-detail-status.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FxDetailStatusComponent implements OnInit {
  @Input() optionVlues: Observable<any>;
  @Input() fxFieldId: string;
  @Input() fxFieldValue: string;
  @Input() fxForm: FormGroup;
  @Input() fxValidators: any;

  values: any;
  public formControl: FormControl;
  public validateForm: FormGroup;
  key;
  public value;
  label: string = '';
  status: string = 'default';
  color: string = 'transparent';
  timeInterval: any;
  constructor(private fb: FormBuilder, private http: FxDapHttpClient) {

  }

  ngOnInit(): any {
    this.values = this.optionVlues ? this.optionVlues : []; // 获取下拉框的值

    this.key = this.fxFieldId;
    this.value = this.fxFieldValue;
    // if (Array.isArray(this.values)) {
    //   this.updateValue(this.values);
    // } else {
    //   const observable: Observable<any> = this.values;
    //   observable.subscribe(data => {
    //     this.updateValue(data);
    //   });
    // }

    this.formControl = new FormControl(this, this.fxValidators);
    this.validateForm = this.fxForm;
    if (!this.fxForm) {
      this.validateForm = this.fb.group({});
    }
    this.validateForm.addControl(this.key, this.formControl);
    this.formControl.markAsDirty();
    // setInterval(()=>{}, 500);
    // this.updateValue();
    // this.timeInterval = setInterval(() => {
    //   this.updateValue(); 
    //   // if(this.value !== this.fxFieldValue)     {
    //   //   clearInterval(this.timeInterval);
    //   // }
    // }, 1000);


    this.fxForm.valueChanges.subscribe(newValue => {
      if (this.key && newValue[this.key])
      setTimeout(() => {
        this.updateValue();        
      }, 0);
    })
  }

  updateValue(): void {
    if (Array.isArray(this.values)) {
      this.patchValue(this.values);
    } else {
      const observable: Observable<any> = this.values;
      observable.subscribe(data => {
        this.patchValue(data);
      });
    }
  }

  patchValue(arr: Array<any>): void {
    const matchedItem = arr.find(item => item.value === this.fxFieldValue);
    if (matchedItem) {
      this.label = matchedItem.label || '';
      this.color = matchedItem.color || 'transparent';
    }
  }

  // refresh(params: any): boolean {
  //   this.value = params.value;
  //   if (Array.isArray(this.values)) {
  //     this.updateValue(this.values);
  //   } else {
  //     const observable: Observable<any> = this.values;
  //     observable.subscribe(data => {
  //       this.updateValue(data);
  //     });
  //   }
  //   return true;
  // }

  debug(): void {
    console.debug('-------- fx-form-cell debugging');
    console.debug(this.formControl);
  }
  // ngDoCheck(): void {
  //    this.fxFieldValue
  // }
  ngOnDestroy(): void {
    this.validateForm.removeControl(this.key);
  }
}
