import { Component, OnInit, Input, OnDestroy,ElementRef,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IDwMenu } from '../../../../../../projects/webdpt/framework/components/menu/interface/menu.interface';
import { DwMenuService } from '../../../../../../projects/webdpt/framework/components/menu/service/menu.service';
import { DwRecursiveMenuService } from '../../../../../../projects/webdpt/framework/components/menu/service/recursive-menu.service';
import { DwRouteInfoStrogeService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/storge/routeInfo-storage.service';
import { DwRouterInfoService } from '../../../../../../projects/webdpt/framework/router-info/router-info.service';
import {FxMenuService} from '../services/fx-menu.service';
// 引入动画
import {trigger,state,style,animate,transition} from '@angular/animations';


@Component({
  selector: 'menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.less'],
  animations: [
    trigger('menuAnimations', [
      
      //state('in', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ opacity: 0 }),
        style({ transform: 'translateX(10px)' }),
        animate('0.3s')
      ]),
      transition(':leave', [
        //style({ opacity: 0 }),
        animate('0.3s', style({ transform: 'translateX(10px)', opacity: 0 })),
        
      ])
    ]),
  ]
})
export class MenuListComponent implements OnInit {
  @Input() menuList: IDwMenu[] = [];
  menuListSubscription: Subscription;
  //mode:string;
  //selectable:Boolean = true;
  private _routeSelectMenu: boolean = false; // 路由影響選單選中節點(TabMenu是否和Menu連動),預設false
  private _programId: string;
  public flag:boolean = false;
  public activeIndex = 0; //记录当前的活动节点
  @ViewChild('menuList') menuListDom: ElementRef;


  @Input()
  set routeSelectMenu(routeSelectMenu:boolean) {
    this._routeSelectMenu = routeSelectMenu || false;
  }

  @Input()
  set programId(programId: string) {
    this._programId = programId;
  }
  //浮层菜单
  @Input() menuData:any;
  public hasChildren = false;
  public menuChild = [];
  public menuChildrens = [];


  onMouseLeave() {
    this.hasChildren = false;
  }
  constructor(
    // private menuService: DwMenuService,
    private menuService: FxMenuService,
    private recursiveMenuService: DwRecursiveMenuService,
    private routeInfoStrogeService: DwRouteInfoStrogeService,
    private activatedRoute: ActivatedRoute,
    private dwRouterInfoService: DwRouterInfoService,

  ) { }

  ngOnInit():void {
    console.log(this.recursiveMenuService, 'menuData');
    this.recursiveMenuService.routeSelectMenu = this._routeSelectMenu;
    //获取list
    this.menuService.getMenu().subscribe(
      menuList => {
        this.menuList = menuList;
        console.log(this.menuList,'new nemuList')
        console.log(this.programId)
      },
      error => {
        this.menuList = [];
        console.log(error);
      }
    ) //.unsubscribe()

  }

  //menu 展开
  onClickItem(menuItem: IDwMenu,index) {

    if (index != this.activeIndex) {
      this.activeIndex = index;
    }
    this.menuChild = [];

    let menuChilds = menuItem.child;
    for (let i = 0; i < menuChilds.length;i++) {
      if (menuChilds.length == 0) {
        this.recursiveMenuService.onClickItem(menuItem);
        this.hasChildren = false;
      } else {
        this.hasChildren = true;
        this.menuChild.push(menuChilds[i]);
        // setTimeout(() => {
        //   this.riMenuList()
        // }, 2000);


      }

    }

    if (menuChilds.length == 0) {
      this.recursiveMenuService.onClickItem(menuItem);
      this.hasChildren = false;
    }
  }

  //关闭菜单
  onClickClose() {
    this.hasChildren = false;
  }

  public riMenuList() {
    if (this.hasChildren) {
      console.log(this.menuListDom, 'listBody')
    }
  }
}
