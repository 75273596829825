import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateParser } from '@ngx-translate/core';
import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxAgGridEventHandlerService, IAgGridEditorEvent} from '../../ag-grid/fx-ag-grid-event-handler.service';
import { FxBaseComponent } from '../../fxbase/fx-base.component';
import { FxMasterService } from './fx-masterservice';
import { FxDetailService } from './fx-detailservice';
import { GridOptions, GridApi, ColumnApi, Column, RowNode, RowNodeTransaction} from 'ag-grid-community';

@Component({
    selector: 'fx-master-detail-base',
    template: '',
    styles: [ `` ],
  })
  export class  FxMasterDetailBaseComponent extends FxBaseComponent {


    private masterService:  FxMasterService;  // 列表服务
    private detailService:  FxDetailService;   // 详情服务

    constructor (public baseInjector: Injector, masterService:  FxMasterService, detailService ?:  FxDetailService) {
        super(baseInjector);
        this.masterService = masterService;
        this.detailService = detailService;
    }

    // 设定Grid 栏位是否显示
    getGridDisplayField(gridColumnApi: ColumnApi, programNo: any): void {
    let data: any  = [];
    // 表格单头列显示控制
    if (gridColumnApi) {
      this.masterService.getDisplayField(programNo).subscribe(response => {
        console.log('getDisplayField response:', response);
        if (response.data.length > 0) {
          // data = response.data;
          // data.forEach(obj => {
          //   gridColumnApi.setColumnVisible(obj.aaf002, true); // 动态设置操作列显示隐藏
          // });
          const showColumns = response.data.map(item => item.aaf002);
          gridColumnApi.setColumnsVisible(showColumns, true);
        }
        // else {
        //   // 通过 masterGridColumnApi 来设定，设定之前先Ag-Grid 显示判断
        //   // 未做控制或请求出错，所有Ag-grid 列均显示（ToDo: 此处存在数据安全隐患）
        //   const columns: Column[] = gridColumnApi.getAllGridColumns();
        //   columns.forEach(column => {
        //     if (!column.isVisible()) {
        //         gridColumnApi.setColumnVisible(column, true); // 动态设置操作列显示隐藏
        //     }
        //   });
        // }
      });
    }
  }

}
