import { Component, Inject, OnInit, Input, ViewEncapsulation, TemplateRef,Output,EventEmitter, ElementRef, ViewContainerRef, OnDestroy} from '@angular/core';
import { DwLanguageService } from '../../../../../../projects/webdpt/framework/components/language';
import { DwUserService } from '../../../../../../projects/webdpt/framework/auth/user.service';
import { DwAuthService } from '../../../../../../projects/webdpt/framework/auth/auth.service';
import { Logo_Path, APP_DEFAULT, DW_USING_TAB } from '../../../../../../projects/webdpt/framework/config/system.config';
//引入菜单列表
// import { MenuListComponent } from 'app/implementation/shared/layout/menu-list/menu-list.component';
import { ShowMenuService } from '../services/show-menu.service';
import{MinLogoService} from '../services/min-logo.service';
import { DwHttpClient } from '../../../../../../projects/webdpt/framework/http';
import { Observable, BehaviorSubject, ConnectableObservable } from 'rxjs';
import { DwNotificationService } from 'ng-quicksilver';
import { timeout } from 'rxjs/operators';
import { IDwRouteInfo } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/interface/route-info.interface';
import { getMenuName } from '../../services/common-method';
import { DwRouteInfoService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/dw-route-info.service';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';

/**
 * 基本側邊欄佈局
 * 使用範例：
 * <fx-layout-basic-side></fx-layout-basic-side>
 * <fx-layout-basic-side [collapsedWidth]="0" [breakpoint]="'lg'"></fx-layout-basic-side>
 *
 * layoutType 視覺佈局類型：'tabMenu'多頁面佈局樣式
 * width 側邊欄寬度，預設200
 * dwCollapsed 當前收起狀態，預設false
 * collapsedWidth 收縮寬度：預設72;設置為 0 會出現特殊 trigger
 * dwBreakpoint 觸發響應式佈局的斷點：'xs', 'sm', 'md', 'lg', 'xl'
 * dwTheme Menu樣式：'dark', light'
 * isHeaderFixed 是否固定頭部
 * hasFooter 是否有底部布局
 * dwSelectable 選單選中節點,預設true
 * routeSelectMenu 路由影響選單選中節點(TabMenu是否和Menu連動),預設false
 */

@Component({
  selector: 'fx-layout-basic-side',
  templateUrl: './basic-side.component.html',
  styleUrls: ['./basic-side.less'],
  // tslint:disable-next-line:use-view-encapsulation
  encapsulation: ViewEncapsulation.None, // 樣式不封裝
  providers: [
  
  ]
})

export class FxLayoutBasicSideComponent implements OnInit, OnDestroy {
  dwLayoutType: string;
  layoutClass = [];
  headerClass = [];
  dwWidth = 200;
  dwCollapsedWidth = 72;
  dwBreakpoint = '';
  language: string = ''; // 語言別

  isCollapsed = false; // 當前收起狀態
  userName = this.userService.userInfo['c_user_n1'];
  theme: string; // Menu Theme
  dwIsHeaderFixed = true;
  dwHasFooter = false;
  isReverseArrow = false;
  dwLogoBigPath: string;  // 公司logo路径-大的
  dwLogoSmallPath: string; // 公司logo路径-小的

  private _dwSelectable: boolean = true;
  private _routeSelectMenu: boolean = false;
  public isHide = false;
  public drawLeft = {
    "left": 0
  }
  public dwLogoPath: string = '';
  public total_number=0;//当前用户消息提醒总条数
  public messageList = []; //消息提醒明细
  public template_notification ; //消息提醒明细
  public messageID = []; //记录消息ID
  public messageInterval;
  public isShowRemind={
    isNeedToRemind:false
  } ;
  public c_E111=300000;
    //左侧logo处理
    @Output() isMin = new EventEmitter();
    isMinCheck = false;
  
  //右边固定栏位
  visible = false;
  visible_message = false;
  leftActive = false;
  sliderdom = false;
  isClosed = false;

  // 側邊欄
  @Input()
  siderTemplate: TemplateRef<any>;

  @Input()
  siderAfterMenuTemplate: TemplateRef<any>;

  @Input()
  headerTemplate: TemplateRef<any>;

  @Input()
  headerLeftTemplate: TemplateRef<any>;

  @Input()
  headerRightTemplate: TemplateRef<any>;

  @Input()
  headerRightActionTemplate: TemplateRef<any>;

  @Input()
  headerRightUserTemplate: TemplateRef<any>;

  @Input()
  footerTemplate: TemplateRef<any>;

  @Input()
  set isHeaderFixed(isHeaderFixed: boolean) {
    this.dwIsHeaderFixed = isHeaderFixed;
  }

  @Input()
  set hasFooter(hasFooter: boolean) {
    this.dwHasFooter = hasFooter;
  }

  @Input()
  set width(width: number) {
    this.dwWidth = width;
  }

  @Input()
  set dwCollapsed(dwCollapsed: boolean) {
    this.isCollapsed = dwCollapsed;
  }

  @Input()
  set collapsedWidth(collapsedWidth: number) {
    this.dwCollapsedWidth = collapsedWidth;
  }

  @Input()
  set breakpoint(breakpoint: string) {
    this.dwBreakpoint = breakpoint;
  }

  @Input()
  set dwTheme(dwTheme: string) {
    this.theme = dwTheme || 'dark';
  }

  @Input()
  set dwSelectable(dwSelectable: boolean) {
    this._dwSelectable = dwSelectable;
  }

  get dwSelectable(): boolean {
    return this._dwSelectable;
  }

  @Input()
  set routeSelectMenu(routeSelectMenu: boolean) {
    this._routeSelectMenu = routeSelectMenu;
  }

  get routeSelectMenu(): boolean {
    return this._routeSelectMenu;
  }

  constructor(
    private notification: DwNotificationService,
    private userService: DwUserService,
    private authService: DwAuthService,
    private showmenu: ShowMenuService, //收缩菜单
    private languageService: DwLanguageService,
    private dwRouteInfoService: DwRouteInfoService,

    @Inject(APP_DEFAULT) public logoUrl: string,
    
    @Inject(DW_USING_TAB) private _usingTab: boolean,
    public minLogo:MinLogoService,
    public http: DwHttpClient,
    public elementRef: ElementRef,
    private viewContainer: ViewContainerRef,

  ) {
    this.userName = this.userService.userInfo['c_user_n1'];
    //
    showmenu.emiter.subscribe((str:string)=>{
      if (str == 'toggle') {
        this.toggleSidebar()
      }
    })

  }
  toggleSidebar() {
    this.isHide = !this.isHide;
  }
  open(): void {
    this.visible = !this.visible;
    this.leftActive = !this.leftActive;
    // const getModal = document.getElementsByClassName('cdk-overlay-container');
    // getModal[0].__proto__.style.zIndex = "1000"
    //getModal[0].style["zIndex"] = "1000";
    //let getDwarModal = document.getElementsByClassName("ant-drawer-mask");
    //getDwarModal[0].style["left"] = "0px";
    console.log(this.viewContainer,'getModal')
    this.notification.remove();
  }
  closeSlider() {
    this.notification.remove();
    //console.log(this.sliderdom)
    if(this.sliderdom == true) {
      this.isClosed = true;
      this.minLogo.eventEmit.emit('true')
    } else {
      this.isClosed = false;
      this.minLogo.eventEmit.emit('false')
    }
  }

  


  ngOnInit(): void {
    this.setLayoutType();
    this.http.post('A/IACommonService/pub_upd_aat', { param: ['c_E111'], aatent: this.userService.userInfo['c_ent']}).subscribe(
      (sysDatas: any) => {
        if (sysDatas.success) {
          this.c_E111 = Number(sysDatas.data[0].aat002);
        } 
      }
    );
    console.log(this.routeSelectMenu, 'dwSelectable')

    // 避免Logo連結干擾Tab Menu 路由
    if (this._usingTab) {
      this.logoUrl = null;
    }

    // 取得最新語言別
    this.languageService.language$.subscribe(
      lang => {
        this.language = lang;
      }
    );

    //获取存储的主题色
    this.getTheme();

    // 获取logo图片
    this.getAllOrgParam().subscribe(data => {
      if (data.length) {
        // 公司logo-大
        const dwLogoBigPathIdx = data.findIndex(item => {
          return item.aau001 === 'c_O012';
        });

        // 公司logo-小
        const dwLogoSmallPathIdx = data.findIndex(item => {
          return item.aau001 === 'c_O013';
        });

        if (dwLogoBigPathIdx != -1) {
          this.dwLogoBigPath = data[dwLogoBigPathIdx].aau002;  // 公司logo-大
        }
        if (dwLogoSmallPathIdx != -1) {
          this.dwLogoSmallPath = data[dwLogoSmallPathIdx].aau002; // 公司logo-小
        }
        console.log('logo-big', this.dwLogoBigPath, 'logo-small', this.dwLogoSmallPath);
        this.dwLogoPath = this.dwLogoBigPath ? this.dwLogoBigPath : this.dwLogoPath;
      }
      if (!this.dwLogoBigPath || !this.dwLogoSmallPath) {
        this.getAllEntParam().subscribe(data => {
          if (data.length) {
            // 公司logo-大
            const dwLogoBigPathIdx = data.findIndex(item => {
              return item.aat001 === 'c_E024';
            });
    
            // 公司logo-小
            const dwLogoSmallPathIdx = data.findIndex(item => {
              return item.aat001 === 'c_E069';
            });
    
            if (dwLogoBigPathIdx != -1 && !this.dwLogoBigPath) {
              this.dwLogoBigPath = data[dwLogoBigPathIdx].aat002;  // 公司logo-大
            }
            if (dwLogoSmallPathIdx != -1 && !this.dwLogoSmallPath) {
              this.dwLogoSmallPath = data[dwLogoSmallPathIdx].aat002; // 公司logo-小
            }
            console.log('logo-big', this.dwLogoBigPath, 'logo-small', this.dwLogoSmallPath);
            this.dwLogoPath = this.dwLogoBigPath ? this.dwLogoBigPath : this.dwLogoPath;
          }
        });
      }
    });
    //  订单提醒start
   
   console.log("订单提醒start:")
   this.http.post('A/IMessageRemindService/needMessageRemind', { param: {} },{uiOptions: <any>{'loadMaskCfg': {spinning: false}}}).subscribe(
    response => {
      if (response.data.need_flag == 'Y') {
        // this.isShowRemind=response.data;
        this.isShowRemind.isNeedToRemind=true;
        this.clock(this.isShowRemind); 
        this.messageInterval = setInterval(() => {
          this.clock(this.isShowRemind); 
        }, this.c_E111 ); //如果查询成功，定时5分钟订单提醒
        // }, 10000 ); //如果查询成功，定时5分钟订单提醒 测试
      }else{
        this.isShowRemind.isNeedToRemind=false;
      }
    });
  }
  public clock(isShowRemind) {
    this.notification.config({
      dwPlacement: 'bottomRight',
      dwBottom: '0',
      dwDuration:0,
      // icon: '<i class="anticon anticon-info-circle-o"></i>',
      dwOnClose: function(params) {
        console.log("关闭内容"+params);
        // console.log("关闭内容"+params.messageId, this.messageID);
      },
      // onClick: function(params) {
      //   console.log("点击内容"+params);
      // }
    })
    this.http.post('A/IMessageRemindService/pollMessageRemindInfo', { param: {} },{uiOptions: <any>{'loadMaskCfg': {spinning: false}}}).subscribe(
      response => {
        if (response.success && response.data) {
          this.total_number = response.data.total_number;
          console.log(this.total_number);
          // 滚动提示 1
          if (response.data.scroll_list && response.data.scroll_list.length > 0) {
            response.data.scroll_list.forEach(obj => {
              this.messageID.push(obj.acwdocno);
              this.notification
                .blank(
                  '任务提醒',
                  obj.acw007, 
                )
            })
          }
          // 消息弹窗提示 - 有弹窗并且可以超链接  8
          if (response.data.modal_list && response.data.modal_list.length > 0) {
            this.messageList =response.data.modal_list;
          }
          // 语音提示 4
          if (response.data.voice_list && response.data.voice_list.length > 0) {
            const voiceList = response.data.voice_list[0].acw008;
            var music = new Audio();
            music.src = voiceList;//这里替换成一个有效的音频文件地址以方便测试
            music.loop = true;
            music.play();
          }
        }
        
      });
  }
  open_message(template: TemplateRef<{}>): void {
    this.notification.remove();
    this.notification.template(template);
    // this.messageID++;
    this.messageID.push('all_message');
    // this.notification.close = function () {
    //     console.log('gubv');
    // }
  }
  goto_program(item): void {
    // this.notification.remove();
    this.markMessageRemindInfo(item);
    if (item.acw005) {
      var routeInfo: IDwRouteInfo = {
        id: item.acw005,
        menuId: getMenuName(item.acw005),
        name: '',
        routerLink: "/"+item.acw005,
        queryParams: {
          xState: 'browse',
          toInfo: false,
          search_param:item.acw006 ? item.acw006 : '' //跳转作业的常用查询条件
        }
      };
      this.dwRouteInfoService.create(routeInfo);
    }
  }

  //更新提醒状态
  markMessageRemindInfo(item): void {
    this.http.post('A/IMessageRemindService/markMessageRemindInfo', { param: {acwdocno: item.acwdocno, acwlock: item.acwlock} },{uiOptions: <any>{'loadMaskCfg': {spinning: false}}}).subscribe(
      response => {
        if (!response.success) {
          console.log(response.message);
        }
      });
  }
   //  订单提醒end


  // 登出
  // onLoggedout(): void {
  //   this.authService.logout();
  // }

  /**
   * 視覺佈局類型
   */
  private setLayoutType(): void {
    // 多頁面佈局樣式
    if (this._usingTab) {
      this.dwLayoutType = 'tabMenu';
      const str = this.dwLayoutType.toLocaleLowerCase();
      this.layoutClass = ['dw-layout-basic-side-' + str];
      this.headerClass = ['dw-header-basic-side-' + str];
    }
  }


  //主题更改
  /**
   * 使用localStorage 存储主题的名称
   * @param theme
   */
  saveTheme(theme): void {
    localStorage.setItem(`theme`, theme);
  }

  /**
   * 获取主题名称并设置到body
   */
  getTheme(): void {
    let theme = localStorage.getItem(`theme`); // 获取主题名称
    if (!theme) {
      theme = `blue`; // 本地存储中没有theme的话 使用blue主题
    }
    const body = document.getElementsByTagName('body')[0];
    body.setAttribute('data-theme-style', theme); // 设置data-theme-style 属性
    this.theme = theme; // 用于界面显示
  }

  /**
   * 点击按钮 触发改变主题的方法
   */
  colorCluters = ['blue', 'purple', 'orange','green','yellow','pink'];

  changeTheme(color): void {
    const body = document.getElementsByTagName('body')[0];
    this.saveTheme(color); // 保存
    this.getTheme(); // 更新获取
    this.notification.remove();
  }

  // 获取集团参数
  public getAllEntParam(): Observable<any> {
    return Observable.create(observer => {
      this.http.post('A/IACommonService/pub_upd_aat', { param: [''], aatent: this.userService.userInfo['c_ent'] }).subscribe(
        (sysDatas: any) => {
          if (sysDatas.success) {
            observer.next(sysDatas.data);
          } else {
            observer.next([]);
          }          
          observer.complete();
        }
      );
    });
  }
  // 获取组织参数
  public getAllOrgParam(): Observable<any> {
    return Observable.create(observer => {
      this.http.post('A/IACommonService/pub_upd_aau', { param: [''], aauent: this.userService.userInfo['c_ent'], aaulorg: this.userService.userInfo['c_org'] }).subscribe(
        (sysDatas: any) => {
          if (sysDatas.success) {
            observer.next(sysDatas.data);
          } else {
            observer.next([]);
          }          
          observer.complete();
        }
      );
    });
  }
  // 设置菜单是否展开--false不展开
  setCollapset(): void {
    this.notification.remove();
    this.isCollapsed = !this.isCollapsed;
    // 当菜单收缩起来的时候使用小的logo图片
    if (this.isCollapsed === true) {
      this.dwLogoPath = this.dwLogoSmallPath ? this.dwLogoSmallPath : this.dwLogoPath;
    } else {
      this.dwLogoPath = this.dwLogoBigPath ? this.dwLogoBigPath : this.dwLogoPath;
    }
  }

  //页面销毁动作
  ngOnDestroy(): void {
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
    }
    // this.destroy$.next();
  }

}
