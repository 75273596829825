/**
 * 废弃，暂使用FxAgFormCellRendererComponent
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {FxAgGridValidationEditorComponent} from '../fx-ag-grid-validation-editor';

@Component({
  selector: 'app-fx-form-cell',
  templateUrl: './fx-form-cell.component.html',
  styleUrls: ['./fx-form-cell.component.less']
})
export class FxFormCellComponent extends FxAgGridValidationEditorComponent implements  ICellRendererAngularComp, OnDestroy {
  public formControl: FormControl;
  key;
  public value;
  columnName: string;
  private rowId: number;

  constructor(private fb: FormBuilder ) {
    super();
  }

  agInit(params: any): any {
    // console.debug('-------- fx-form-cell debugging');
    this.columnName = params.column.colDef.headerName;
    // this.key = params.context.createKey(params.columnApi, params.column);
    this.key = 'ag_' + params.node.id + '_' + params.colDef.field;
    this.value = params.getValue();
    // this.rowId = params.node.id;


    this.formControl = new FormControl(this, params.validators);
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }
    this.validateForm.addControl(this.key, this.formControl);
    this.formControl.markAsDirty();

    super.init();
  }

  refresh(params: any): boolean {
    // this.formGroup = params.context.formGroup.controls[this.rowId].get(this.key);
    //
    // // this could also be done in GridComponent.createFormControls, but the cell component could be doing something with
    // // the value, so it feels more natural that the control value be set here
    // // this.formGroup.at(this.key).patchValue(this.value);
    // this.formGroup.patchValue(this.value);
    return true;
  }

  debug(): void {
    console.debug('-------- fx-form-cell debugging');
    console.debug(this.formControl);
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.key);
  }
}
