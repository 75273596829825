import {Component, Input, OnInit, Optional} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-cfc-operation-button-editor',
  templateUrl: './cfc-operation-button-editor.component.html',
  styleUrls: ['./cfc-operation-button-editor.component.less']
})
export class CfcOperationButtonEditorComponent  implements ICellRendererAngularComp {
  private params: any;
  value: any;
  @Input() disabelDefultSave: boolean = false;

  public editMode: boolean = false;
  formGroup: FormGroup;  // Added by David Lee 1Mar2019

  constructor(private fb: FormBuilder ) {

  }

  agInit(params: any): any {
    this.params = params;  // 传入参数
    this.formGroup = params.context.serviceObj.rowCachedFormGroup;

    this.disabelDefultSave = params.disabelDefultSave;
  }

  public save(): void {

    if (this.params.onClickSaveButton) {
      this.params.onClickSaveButton({
        gridId: '',
        value: '',
        rowIndex: '',
        id: '',
        colId: '',
        controlName: ''
      });
    }

    if (this.params.save) {
      this.params.save(this.params);
    }
  }

  public cancel(): void {
    // this.params.api.stopEditing(true);

    if (this.params.onClickCancelButton) {
      this.params.onClickCancelButton({
        gridId: '',
        value: '',
        rowIndex: '',
        id: '',
        colId: '',
        controlName: ''
      });
    }

    if (this.params.cancel) {
      this.params.cancel(this.params);
    }
  }

  refresh(): boolean {
    return true;
  }

  onKeyDown(event: any): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
      || event.keyCode === 39 || event.keyCode === 40) {
      event.stopPropagation();
    }
  }
}
