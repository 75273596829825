import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FxHighlightPipe} from './fx-highlight.pipe';
import {AppendNamePipe} from './append-name.pipe';
import { FilterPipe } from './filter.pipe';
import {DwNumberPipe} from './dw-number.pipe';
import {fxButtonEditFilterPipe} from './fx-button-edit-filter.pipe';

@NgModule({
  declarations: [
    FxHighlightPipe,
    AppendNamePipe,
    FilterPipe,
    DwNumberPipe,
    fxButtonEditFilterPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FxHighlightPipe,
    AppendNamePipe,
    FilterPipe,
    DwNumberPipe,
    fxButtonEditFilterPipe
  ]
})
export class PipesModule {

}
