/**
 * todo
 * 1. 增加参数控制是否分页，默认分页
 * 2. 样式优化
 */

import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import { TranslateService } from '@ngx-translate/core';
import { GridApi } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import {DwModalService} from 'ng-quicksilver';
import {FxConfirmModalComponent} from '../fx-confirm-modal.component';
// import { DwRoutingMessageService } from '@webdpt/framework';



/**
 * 设计要求
 * icon 22px
 * title 16px
 * content 14px
 */
@Component({
  selector: 'fx-characteristic-modal-component',
  templateUrl: './fx-characteristic-modal.component.html',
  styleUrls: [
    './fx-characteristic-modal.component.less'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FxCharacteristicModalComponent implements OnInit {
  @Input() title: string;
  // @Input() dataSet: Array<any>;
  @Input() selected: Array<any>;
  @Input() mutilSelected: boolean;
  @Input() postParam: any;
  @Input() dataInfo: any;
  // ag-grid 栏位配置
  @Input() columnDefs: any;

  public selectedItem: Array<any> = [];
  public isHeight = '325px';
  public isParm = true;
  public isFull = false;
  public characterTypeList: Array<any> = [];    // 商品特征类型
  public characterCombineList: Array<any> = []; // 商品特征组
  public isReady = false;
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  @ViewChild('layout') layout: ElementRef;



  constructor(private modal: DwModalRef,
    private translateService: TranslateService,
    public dwModalService: DwModalService,
    // public dwMessage: DwRoutingMessageService,
    private http: HttpClient) {
  }

  ngOnInit(): void {
    this.getCharacteristicList();
  }


  // 获取商品特征组
  getCharacteristicList(): void {
    console.log(this.dataInfo)
    this.http.post('C/ICCommonService/getCharInfoB2C', { 'param': this.postParam }).subscribe(response => {
      this.isReady = true;
      if (response['success']) {
        // 特征值
        if (response['data']) {           
          this.characterTypeList = response['data'];           
        } else {
          this.characterTypeList = [];          
        }
        // 特征组合
        if (response['data2'] && response['data2'].length) {
          this.characterCombineList = response['data2'];
          this.setCombins(this.characterTypeList, this.characterCombineList);
          // 查看是否有默认选中的特征有则选中否则默认选中第一个
          if (this.selected && this.selected.length) {
            var selIdx = this.characterCombineList.findIndex(obj => {
              return obj.character_no == this.selected[0].character_no;
            });
            if (selIdx === -1) {
              selIdx = 0;
            }
          } else {// 默认选中第一个特征组          
            var selIdx = 0;
          }
          this.characterCombineList[selIdx].type_value_list.forEach((res, resIdx) => {
            this.characterTypeList[resIdx].char_value_list.forEach(origin => {
              if (origin.cae003 === res.char_value_list[0].cae003) {
                origin.selected = true;
              }
            });
          });
          this.setDisabledChr();     // 设置特征值状态  
        }

      } else {
        this.characterTypeList = [];
        this.characterCombineList = [];
        const modal = this.dwModalService.create({
          dwTitle: null,
          dwStyle: {top: '20px'},
          dwMaskClosable: false, // 點擊遮罩是否允許關閉
          dwWrapClassName: 'fx_md_modal',
          dwContent: FxConfirmModalComponent,
          dwClosable: false,
          dwFooter: [
            {
              label: '取消',
              shape: 'default',
              onClick: (data: any): any => {
                modal.destroy();
              }
            },
            {
              label: '确定',
              type: 'primary',
              onClick: (data: any): any => {
                modal.close({});
              }
            }
          ],
          dwComponentParams: {
            title: "",
            message: response['message'],
            moreInfo: "",
            program_name: "",
            program_no: "",
            isRemind: ""
          }
        });   
      }
    });
  }

  // 获取所有特征值所对应的所有特征组
  setCombins(originArr, resultArr) {
    originArr.forEach(origin => {
      origin.char_value_list.forEach(originItem => {
        originItem.result = resultArr.filter(result => {

          var idx = result.type_value_list.findIndex(resultItem => {
            return resultItem.char_type == origin.char_type && resultItem.char_value_list[0].cae003 === originItem.cae003;
          })
          if (idx !== -1) {
            return true;
          } else {
            return false;
          }
        });
      });
    });
    console.log(originArr);
  }

  // 更新特征值状态
  setDisabledChr(): void {
    // 获取选中的特征值
    var selList = [];
    this.characterTypeList.forEach(item => {
      var idx = item.char_value_list.findIndex(obj => {
        return obj.selected;
      });
      if (idx !== -1) {
        item.character_select = item.char_value_list[idx];
        selList.push(item);
      }
    });
    // 判断选中的特征值是否可与当前特征值组合
    this.characterTypeList.forEach(origin => {
      origin.char_value_list.forEach(originItem => {
        var resultIdx = originItem.result.findIndex(item => {

          var selListExceptCur = selList.filter(sel => {
            return sel.char_type !== origin.char_type;
          });
          var count = 0;
          selListExceptCur.forEach(sel => {
            var idx = item.type_value_list.findIndex(obj => {
              return obj.char_type === sel.char_type && obj.char_value_list[0].cae003 === sel.character_select.cae003;
            });
            if (idx !== -1) {
              count += 1;
            }
          });
          if (count === selListExceptCur.length) {
            return true;
          }
        });
        if (resultIdx !== -1) {
          originItem.disabled = false;
        } else {
          originItem.disabled = true;
        }
      })
    });
  }

  // 选中商品特征
  selectFeatrueAndFixing(outIndex, inIndex) {
    if (this.characterTypeList[outIndex].char_value_list[inIndex].disabled == true) {
      return;
    }
    // 判断特征是否有选中的特征值
    var selIndex = this.characterTypeList[outIndex].char_value_list.findIndex(item => {
      return item.selected === true;
    });
    if (selIndex !== -1) {// 已存在选中的特征值
      // 判断选中的特征值是否是自己
      if (selIndex === inIndex) {  // 是
        this.characterTypeList[outIndex].char_value_list[inIndex].selected = false;
      } else { // 否
        this.characterTypeList[outIndex].char_value_list.forEach(x => {
          x.selected = false;
        });
        this.characterTypeList[outIndex].char_value_list[inIndex].selected = true;
      }
    } else {// 不存在选中的特征值
      this.characterTypeList[outIndex].char_value_list[inIndex].selected = true;
    }
    this.setDisabledChr();
  }


  // 判断是否所有可选特征都已选中
  checkSelectedChr(): Boolean {
    var selObj = [];
    var count = 0;
    this.characterTypeList.forEach((item, outIdx) => {
      item.char_value_list.forEach((obj, inIdx) => {
        if (obj.selected) {
          selObj[Number(outIdx)] = obj.cae003;
          count++;
        }
      });
    });

    if (this.characterCombineList.length) {
      if (this.characterCombineList[0].type_value_list.length === count) {
        // 特征类型全都选中的时候获取对应的特征组名称
        var idx = this.characterCombineList.findIndex(item => {
          var num = 0;
          item.type_value_list.forEach((obj, inIdx) => {
            if (obj.char_value_list[0].cae003 == selObj[inIdx]) {
              num += 1;
            }
          });
          if (num == this.characterCombineList[0].type_value_list.length) {
            return true;
          } else {
            return false;
          }
        });
        if (idx !== -1) {
          this.selectedItem[0] = this.characterCombineList[idx];
        }
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

  };

  onClickOK(): void {
    if (this.checkSelectedChr() === false && this.isReady) {
      this.selectedItem = []; 
      const modal = this.dwModalService.create({
        dwTitle: null,
        dwStyle: {top: '20px'},
        //dwBodyStyle: { maxHeight: 'calc(100vh - 50px)', overflowY: 'auto' },
        dwMaskClosable: false, // 點擊遮罩是否允許關閉
        dwWrapClassName: 'fx_md_modal',
        dwContent: FxConfirmModalComponent,
        dwClosable: false,
        dwFooter: [
          {
            label: '取消',
            shape: 'default',
            onClick: (data: any): any => {
              modal.destroy();
            }
          },
          {
            label: '确定',
            type: 'primary',
            onClick: (data: any): any => {
              modal.close({});
            }
          }
        ],
        // dwGetContainer: () => {
        //   let a = document.createElement('div');
        //   a.className = 'aaa';
        //   return a;
        // },
        dwComponentParams: {
          title: "",
          message: this.translateService.instant('no-feature-data'),
          moreInfo: "",
          program_name: "",
          program_no: "",
          isRemind: ""
        }
      });             
    } else {
      this.modal.triggerOk();
    }    
   
  }

  onClickCancel(): void {
    this.modal.triggerCancel();
  }
  fxCloseModal(): void {
    this.modal.triggerCancel();
  }

  // 最大化/还原
  fullModal(parm): void {
    //获取浏览器可见宽高
    let docWidth = window.outerWidth;
    let docHeight = window.outerHeight;
    let newModal = document.querySelector('.fx_modal_container');

    if (parm) {
      //存储第一次dwWidth值
      let oldW = this.modal['dwWidth'];
      // let oldH = this.modal;
      localStorage.setItem('width', oldW);
      let oldWidth = localStorage.getItem('width');

      //赋予新值
      this.layout.nativeElement.style.width = docWidth + 'px';
      this.isHeight = 'calc(100vh - 85px)';
      this.isFull = true;
      this.modal['dwStyle'].top = '0';
      //改写click传参值
      this.isParm = false;
    } else {
      this.layout.nativeElement.style.width = localStorage.getItem('width') + 'px';
      this.isHeight = '325px';
      this.isFull = false;
      this.modal['dwStyle'].top = '20px';

      //改写click传参值
      this.isParm = true;
    }
  }

  checkEdge(event) {
    this.edge = event;
    console.log('edge:', event);
  }

}

