import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxDapModule } from '../fx-http-client';
// import { localStorageProviders } from '@ngx-pwa/local-storage';

@NgModule({
  providers: [
    // localStorageProviders({ prefix: 'ocss' })
  ],
  declarations: [],
  imports: [
    CommonModule, FxDapModule
  ]
})
export class FxStorageModule { }
