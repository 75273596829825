import { Injectable } from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';

// @Injectable({
//   providedIn: 'root'
// })
/**
 * 将 formGroup 的 invalid 数据展平放进 map
 * 如 FormGroup {
 *   controls: {
 *     name: FormControl { invalid: false },
 *     password: FormControl { invalid: true },
 *     details: FormArray {
 *       controls: [
 *         0 FormGroup { invalid: true},
 *         1 FormGroup { invalid: false}
 *       ]
 *     }
 *   }
 * }
 * 验证结果为 Map {
 *   'password': {errors},
 *   'details@1': {errors}
 * }
 */
export class FormStatusAnalyzeFlatService {
  // 组合 ID 的单词连接符
  private readonly SEPARATOR: string = '@';
  // 是否记录 formGroup 对象的 errors 属性
  private readonly _redFormGroupErrors: boolean = false;
  private _formGroup: FormGroup | FormArray;
  private _cache;

  constructor(options?: {separator?: string, redFormGroupErrors?: boolean}) {
    if (options && options.separator) {
      this.SEPARATOR = options.separator;
    }
    if (options && options.redFormGroupErrors) {
      this._redFormGroupErrors = options.redFormGroupErrors;
    }
  }

  getInvalidData(formGroup: FormGroup | FormArray): any {
    this._formGroup = formGroup;
    this._cache = new Map();
    this.analyze('', this._formGroup);
    return this._cache;
  }

  private analyze(preString: string, formGroup: FormGroup|FormArray): void {
    if (!formGroup.invalid) {
      return;
    }
    if (Array.isArray(formGroup.controls)) {
      formGroup.controls.forEach((control, index) => {
        if (control.invalid) {
          if ((control as FormGroup).controls) {
            const _preString = preString + index + this.SEPARATOR;
            this.analyze(_preString, control as FormGroup);
          } else {
            this._cache.set(preString + index, control);
          }
        }
      });
    } else {
      // 如果在 formGroup 上设置验证器，可以按 { nameSpace: { err: xxx} } 记录，在 grid 里， nameSpace 用来代替 columnId
      if (this._redFormGroupErrors && formGroup.errors) {
        try {
          Object.keys(formGroup.errors).forEach(nameSpace => {
            this._cache.set(preString + nameSpace, {
              status: 'INVALID',
              errors: formGroup.errors[nameSpace]
            });
          });
        } catch (e) {
          console.error('form-status-analyze-flat-service, can\'t get formGroup\'s err: ', e);
        }
      }
      Object.keys(formGroup.controls).forEach(id => {
        if (formGroup.controls[id].invalid) {
          if (formGroup.controls[id].controls) {
            const _preString = preString + id + this.SEPARATOR;
            this.analyze(_preString, formGroup.controls[id]);
          } else {
            this._cache.set(preString + id, formGroup.controls[id]);
          }
        }
      });
    }
  }
}
