import { Route, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FxTabRoutingComponent } from './dw-tab-routing.component';
import { DwTabRouteReuseService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/dw-tab-route-reuse.service';
import { DwNewRouteKeyService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/new-route-key.service';
import { DwIframeItemSubjectService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/iframe-item-subject.service';
import { DwReuseStrategy } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/dw-reuse-strategy';
// import {MODULE_ROUTES} from '../../../routes';
import { DwTabRouteConfigService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/tab-route-config.service';
import { NgQuicksilverModule } from 'ng-quicksilver';
import { DwMenusModule } from '../../../../../../projects/webdpt/framework/components/menu/menus.module';
import { DwIframeModule } from '../../../../../../projects/webdpt/framework/components/dw-iframe/dw-iframe.module';
import { DwDivMaskModule } from '../../../../../../projects/webdpt/framework/components/dw-div-mask/dw-div-mask.module';
import { TranslateModule } from '@ngx-translate/core';
import { DwMenuService } from '../../../../../../projects/webdpt/framework/components/menu/service/menu.service';
import { DW_USING_TAB } from '../../../../../../projects/webdpt/framework/config/system.config';
import { DwDefaultRouteReuseStrategy } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/dw-default-route-reuse.service';


// import { DwRouteInfoService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/index';
import { DwRouteInfoStrogeService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/storge/routeInfo-storage.service';
import {FxDapModule} from '../../fx-http-client';
import { ChangePasswordComponent } from 'app/implementation/shared/layout/dw-tab-routing/change-password/change-password.component';
import { DwFormItemsModule } from '../../../../../../projects/webdpt/framework/components/form-items/form-items.module';
import { DwRoutingMessageModule } from '../../../../../../projects/webdpt/framework/components/message/routing-message.module';

import { DwRouteInfoService } from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/dw-route-info.service';
import { FxTabHeadComponent } from './dw-tab-head.component';

// import {FrameworkUIModule} from '../framework.ui.module';

// const routes: Routes = [
//   {
//     path: '',
//     component: DwTabRoutingComponent,
//     pathMatch: 'prefix',
//     children: [
//       ...MODULE_ROUTES
//     ]
//   }
// ];

export function dwAttachTabbedRoutes(routes: Routes): Route {
  return {
    path: 'dwTabRouting',
    component: FxTabRoutingComponent,
    children: routes
  };
}

export const usingRouteReuseStrategy = (usingTab: boolean, dwRouterReuseService: DwTabRouteReuseService): any => {
  return usingTab ? new DwReuseStrategy(dwRouterReuseService) : new DwDefaultRouteReuseStrategy();
};

@NgModule({
  imports: [
    CommonModule,
    // ,
    RouterModule,
    NgQuicksilverModule,
    TranslateModule,
    DwMenusModule,
    DwIframeModule,
    DwDivMaskModule,
    FormsModule,
    DwFormItemsModule,
    // FrameworkUIModule,
    ReactiveFormsModule,
    DwRoutingMessageModule
    // projects/webdpt/framework/components/message/routing-message.service.ts
  ],
  declarations: [
    FxTabRoutingComponent,
    ChangePasswordComponent,
    FxTabHeadComponent
  ],
  entryComponents: [ChangePasswordComponent],
  exports: [RouterModule]
})
export class FxTabRoutingModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: FxTabRoutingModule,
      providers: [
        // 作業啟動模式-頁籤
        DwTabRouteReuseService,
        {provide: RouteReuseStrategy, useFactory: usingRouteReuseStrategy, deps: [DW_USING_TAB, DwTabRouteReuseService]},
        DwNewRouteKeyService,
        DwTabRouteConfigService,
        DwIframeItemSubjectService,
        DwMenuService,
        DwRouteInfoService,
        DwRouteInfoStrogeService
      ]
    };
  }
}

