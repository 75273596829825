import {Injectable, ErrorHandler} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import { DwMessageService } from 'ng-quicksilver';
import { TranslateService } from '@ngx-translate/core';
import {Router} from '@angular/router';


@Injectable()
export class FxDapHttpErrorHandler {

  constructor(
    private route: Router,
    private message: DwMessageService,    
    private translateService: TranslateService,) {

  }

  handlerError(error: HttpErrorResponse): void {
    const msg = error.error;
    // if msg.errorType === 'Unexpected' 跳转到小怪物页面
    if (msg.errorType !== 'System') {
      return;
    }

    // if (msg.status === 400) {
    //   if (msg.errorMessage.indexOf('Method 不允許匿名') !== -1) {
    //     this.route.navigate(['/login']);
    //   }
    // }
    if (msg.status === 400) {
      // this.route.navigate(['/login']);
      // 打印底层异常，400 不需要应用处理
      console.error(msg.errorMessage);
    } else if (msg.status === 401) {
      // 401 禁止匿名访问
      const currentHref = window.location.href;
      if (currentHref && currentHref.indexOf('/dwTabRouting') != -1) {
        const arr = currentHref.split('/dwTabRouting');
        window.location.href = arr[0] + '/login?reload=true';
        this.message.info(msg.errorMessage + '，' + this.translateService.instant('comm-relogin'));
        // alert(msg.errorMessage + '请刷新系统重新登录！');
      }
    } else if (msg.status === 409) {
      console.error('您已经保存过此页的数据，请打开列表页进行检查');
    }

  }
}
