import { Pipe, PipeTransform } from '@angular/core';

/**
 * 显示扩展说明
 * 效果:
 * <p>PCI-0001(2.5cm 文件夹)</p>
 * 示例:
 * <p>{{ myForm.get('id').value | appendName: myForm.get('name').value }}</p>
 **/
@Pipe({
  name: 'appendName'
})
export class AppendNamePipe implements PipeTransform {
  transform(id: string, name?: string): any {
    return id +
      (name ? ' (' + name + ')' : '');
  }

}
