import { Observable  } from 'rxjs';
// import { timer } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { Directive } from '@angular/core';
import { AsyncValidatorFn, AsyncValidator, NG_ASYNC_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { Fxai001S01MasterService } from '../../programs/a/fxai001/fxai001-s01/fxai001-s01-master.service';

@Directive({
  selector: '[fxAsyncValidator][formControlName],[fxAsyncValidator][formControl],[fxAsyncValidator][ngModel]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: FxAsyncValidatorDirective, multi: true}]
})
export class FxAsyncValidatorDirective implements AsyncValidator {
  constructor(private userService: Fxai001S01MasterService) {  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
     return FxAsyncValidatorDirective.FxAsyncValidator(this.userService)(control);
  }

  static FxAsyncValidator(userService: Fxai001S01MasterService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        const debounceTime = 500; // milliseconds
        // return Observable.timer(debounceTime).switchMap(() => {
            const param = {aaa001: control.value };
            return userService.checkPrimaryKey(param).pipe(map(
                response => {
                    return (response && response.success === true) ? null : {'response': true};
                }
            ));
        // });
    };
  }

}
