import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { DW_LANG_LOADER } from '../../../config/system.config';


@Injectable()
export class DwLanguageLoaderService {

  constructor(
    @Inject(DW_LANG_LOADER) private langLoader: any[], // 翻譯檔載入器
    private http: HttpClient,
    private translateService: TranslateService
  ) { }

  /**
   * 取翻譯檔
   * @param lang 語系
   */
  getTranslation(lang: string): void {
    this.langLoader.forEach(
      (services) => {
       // const obs: Observable<any> = services.getTranslation(lang);
        // 執行翻譯檔載入器
        services.getTranslation(lang).subscribe(
          langData => {
            this.translateService.setTranslation(lang, langData, true);
          },
          error => {
            console.log(error);
          }
        );
      }
    );
  }
}
