import { ValidatorFn, AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';
import { Directive, forwardRef } from '@angular/core';

@Directive({
  selector: '[fxValidates]',
  exportAs: 'fxValidates',
  providers: [{
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FxValidatesDirective),
      multi: true
  }]
})
 export  class FxValidatesDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any; } {
      const value: string = c.value || '';
      if (!value.startsWith('159')) {
          return {
              mobile: {
                  msg: '手机号必须是159开头',
                  actualValue: value
              }
          };
      }
      return null;
  }

   static  fxValidateRex(type: string, validateRex: RegExp): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      // 获取当前控件的内容
      const str = control.value;
      // 设置我们自定义的严重类型
      const res = {};
      res[type] = { str };
      // 如果验证通过则返回 null 否则返回一个对象（包含我们自定义的属性）
      if ( validateRex.test(str) ) {
        return null;
      } else {
        return res;
      }
    };
  }

}


