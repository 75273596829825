import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxModals2Component } from './fx-modals2.component';
import {OverlayModule, OverlayContainer, FullscreenOverlayContainer} from "@angular/cdk/overlay";
import { FxModals2Service } from './fx-modals2.service';
import {DwDocument, DwDocumentModule, FrameworkUIModule} from '@webdpt/framework';
import { AngularDraggableModule } from 'angular2-draggable';


@NgModule({
  declarations: [FxModals2Component],
  imports: [
    CommonModule,
    OverlayModule,
    FrameworkUIModule,
    DwDocumentModule,
    AngularDraggableModule
  ],
  entryComponents: [
    FxModals2Component
  ],
  providers: [FxModals2Service]
})
export class FxModals2Module { }
