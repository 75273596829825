import { Component, OnInit, Input, ViewEncapsulation, TemplateRef, ComponentRef, Type, ComponentFactoryResolver, Injector, Inject, ViewContainerRef, ViewChild } from '@angular/core';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { DwRoutingMessageService, DwUserService } from '@webdpt/framework';
import { ModalButtonOptions } from './fx-modals2-type';
import { FxModalRef } from './fx-modal2-ref.class';
import { DOCUMENT } from '@angular/common';
import { isPromise } from '@angular/compiler/src/util';

@Component({
  selector: 'app-fx-modals2',
  templateUrl: './fx-modals2.component.html',
  styleUrls: ['./fx-modals2.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FxModals2Component<T = any, R = any> implements OnInit {
  [x: string]: any;
  @Input() title: string | TemplateRef<{}>;
  @Input() fxWidth: string;
  @Input() fxIconType: string = 'question-circle';
  @Input() fxFooter: TemplateRef<{}> | Array<ModalButtonOptions> | null;
  @Input() fxContent: string | TemplateRef<{}> | Type<T>;
  @Input() fxComponentParams: T;
  @Input() fxTop: string = '30px';
  @Input() fxHeight: string;
  @Input() fxCancelText: string | null;
  @Input() isShowFooter: boolean = true;
  public iconFull: string = 'arrows-alt';
  @Input() fxGetContainer: HTMLElement | OverlayRef | (() => HTMLElement | OverlayRef) = () => this.overlay.create();
  private container: HTMLElement | OverlayRef;
  private contentComponentRef: ComponentRef<any>;
  public overlayRef: OverlayRef;
  public isParm = true;
  public isFull = false;
  public bodyHeight;


  @ViewChild('bodyContainer', { read: ViewContainerRef }) bodyContainer: ViewContainerRef;
  @ViewChild('block') block;
  @ViewChild('myBounds') bodyElemnt;

  constructor(
    private cfr: ComponentFactoryResolver,
    private viewContainer: ViewContainerRef,
    private overlay: Overlay,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {
    //console.log(document.querySelector('.cdk-global-overlay-wrapper'),'conponemt自页面')
    this.isHeight(this.fxHeight || 'md');
    if (this.isComponent(this.fxContent)) {
      this.createDynamicComponent(this.fxContent as Type<T>); // Create component along without View
    }
    if (this.isModalButtons(this.nzFooter)) {
      // 设置按钮配置项
      this.nzFooter = this.formatModalButtons(this.nzFooter as Array<ModalButtonOptions<T>>);
    }
  }
  ngAfterViewInit(): void {
    // If using Component, it is the time to attach View while bodyContainer is ready
    if (this.contentComponentRef) {
      this.bodyContainer.insert(this.contentComponentRef.hostView);
    }
  }

  private createDynamicComponent(component: Type<T>): void {
    const factory = this.cfr.resolveComponentFactory(component);
    const childInjector = Injector.create({
      providers: [{ provide: FxModalRef, useValue: this }],
      parent: this.viewContainer.parentInjector
    });
    this.contentComponentRef = factory.create(childInjector);
    if (this.fxComponentParams) {
      Object.assign(this.contentComponentRef.instance, this.fxComponentParams);
    }
    // Do the first change detection immediately (or we do detection at ngAfterViewInit, multi-changes error will be thrown)
    this.contentComponentRef.changeDetectorRef.detectChanges();
  }

  setOverlayRef(overlayRef: OverlayRef): void {
    this.overlayRef = overlayRef;
  }

  //删除
  public closeBtn() {
    const getModal = this.bodyElemnt.nativeElement.parentNode.parentNode.parentNode;
    getModal.parentNode.removeChild(getModal);
  }

  //按钮
  public onButtonClick(button: ModalButtonOptions): void {
    const result = this.getButtonCallableProp(button, "onClick"); // Call onClick directly
    console.log(button,result,'result')
    if (isPromise(result)) {
      button.loading = true;
      result.then(() => (button.loading = false)).catch(() => (button.loading = false));
    }
  }

  public getButtonCallableProp(options: ModalButtonOptions, prop: string): {} {
    const value = options[prop];
    const args: any[] = [];
    if (this.contentComponentRef) {
      args.push(this.contentComponentRef.instance);
    }
    return typeof value === 'function' ? value.apply(options, args) : value;
  }
  private formatModalButtons(buttons: Array<ModalButtonOptions<T>>): Array<ModalButtonOptions<T>> {
    return buttons.map(button => {
      return {
        ...{
          type: 'button',
          show: true,
          disabled: false
        },
        ...button
      };
    });
  }


  //component返回值
  public isComponent(value: {}): boolean {
    return value instanceof Type;
  }
  //字符串
  public isNonEmptyString(value: {}): boolean {
    return typeof value === 'string' && value !== '';
  }
  //内嵌模板
  public isTemplateRef(value: {}): boolean {
    return value instanceof TemplateRef;
  }
  //弹出层宽度
  public isWidth(value: string) {
    if (value == 'sm') {
      //存储临时变量
      localStorage.setItem('wSize', 'sm');
      return '400px';
    } else if (value == 'md') {
      //存储临时变量
      localStorage.setItem('wSize', 'md');
      //this.fxHeight = 'calc(100vh - 155px)';
      return '1100px';
    } else if (value == 'lg') {
      //存储临时变量
      localStorage.setItem('wSize', 'lg');
      //this.fxHeight = 'calc(100vh - 95px)';
      return window.innerWidth + 'px';
    }
  }

    //弹出层高度
    public isHeight(value:string) {
      if (value == 'sm') {
        this.bodyHight = '300px';
        return '300px';
      } else if (value == 'md') {
        this.bodyHight = 'calc(70vh - 50px)';
        return 'calc(70vh - 50px)';
      } else if (value == 'lg') {
        this.bodyHight = 'calc(90vh - 50px)';
        return 'calc(90vh - 50px)';
      } else {
        this.bodyHight = value;
        return value;
      }
    }

  //取消按钮
  public onClickOkCancel(type: 'cancel'): void {
    this.closeBtn();
  }
  //数组按钮
  public isModalButtons(value: string | TemplateRef<{}> | Array<ModalButtonOptions<T>> | null): boolean {
    return Array.isArray(value) && value.length > 0;
  }
  getContentComponentRef(): ComponentRef<T> {
    return this.contentComponentRef;
  }

  getContentComponent(): T {
    return this.contentComponentRef && this.contentComponentRef.instance;
  }
  get cancelText(): string {
    return this.nzCancelText || this.locale.cancelText!;
  }

  checkEdge(event) {
    this.edge = event;
  }

  // 最大化/还原
  public fullModal(parm): void {
    this.block.resetPosition()
    //获取浏览器可见宽高
    let docHeight = window.innerHeight;
    let currrntModal = document.querySelector('.fx_modal_main');

    if (parm) {
      //赋予新值
      this.fxWidth = window.innerWidth + 'px';
      this.fxTop = '0px';
      // this.fxHeight = 'calc(100vh - 95px)';
      this.isHeight('calc(100vh - 50px)');
      this.iconFull = 'shrink';
      this.isFull = true;
      //改写click传参值
      this.isParm = false;

    } else {
      let getOld = localStorage.getItem('wSize');
      console.log(getOld, currrntModal, 'getOld')
      this.fxWidth = getOld;
      this.fxTop = '30px';
      // this.fxHeight = 'calc(100vh - 200px)';
      this.isHeight(this.fxHeight);
      this.iconFull = 'arrows-alt';
      this.isFull = false;

      //改写click传参值
      this.isParm = true;
    }
  }
}
