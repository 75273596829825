import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxGridLiteComponent } from './fx-grid-lite/fx-grid-lite.component';
import { CellComponentModule } from './cell-component/cell-component.module';
import {SharedModule} from '../shared.module';
import {FxGridRowEditService} from './fx-grid-row-edit.service';
import { FxGridToolHeaderComponent } from './fx-grid-tool-header/fx-grid-tool-header.component';
import {FxToolPanelModule} from '../components/fx-tool-panel/fx-tool-panel.module';
import {FxStorageModule} from '../fx-storage/fx-storage.module';
// import { LocalStorageModule } from '@ngx-pwa/local-storage';

@NgModule({
  declarations: [FxGridLiteComponent, FxGridToolHeaderComponent],
  imports: [
    CommonModule,
    CellComponentModule,
    FxToolPanelModule,
    FxStorageModule
  ],
  providers: [FxGridRowEditService],
  entryComponents: [
    FxGridToolHeaderComponent
  ]
})
export class FxGridModule { }
