import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FxDapHttpClient } from '../../fx-http-client';
import { ActivatedRoute } from '@angular/router';
import { DwRoutingMessageService } from '@webdpt/framework';
import { UploadFile, UploadXHRArgs } from 'ng-quicksilver';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';


@Component({
  selector: 'fx-import-self',
  templateUrl: './fx-import-self.component.html',
  styleUrls: ['./fx-import-self.component.less']
})
export class FxImportSelfComponent implements OnInit {
  @Input() importSelfParams: any; // 导入传参
  @Input() templateCode: any;    // 记录上传的模板编号
  @Output() afterImport = new EventEmitter();      // 按钮资料
  public fileList: UploadFile[] = [];


  constructor(
    public http: FxDapHttpClient,
    private translateService: TranslateService,
    protected route: ActivatedRoute,
    public dwMessage: DwRoutingMessageService,
    public fxModalService: FxModalService
  ) {
  }

  ngOnInit() {

  }


  // 导入文件
  importFile = (item: UploadXHRArgs) => {

    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    formData.append('attach', item.file as any);
    this.http.post('A/IAttachFileService/uploadFile', formData).subscribe(response => {
      if (response.successValue) {
        item.onSuccess!(response.data, item.file!, '');
        var url = response.data[0].url;
        var param = {
          url: url, // 上传文件的url--必填
          api:  '', 
          param:  '',
          params: this.importSelfParams ? this.importSelfParams : '',  // (自定义模板必填,为自定义导入的参数)
          templateCode: this.templateCode,  // （标准模板必填）
          importToDb: 'N'
        };
        this.http.post('A/IStandardImportExcelService/importFileToDB', {param: param}).subscribe(response => {
          this.fxModalService.batch({
            batchResult: response.collector.batchResult,
            errorInfos: response.collector.errorInfos,
            okFn: (data: any): void => {
              this.afterImport.emit(response);
            },
            cancelFn: (): void => {
              this.afterImport.emit(response);
            }
          });
        });
      } else {
        this.dwMessage.errorNew(response.message, response.isRemind, response.programNo, response.programName);
      }
    });
  }

  // 点击导入按钮
  clickImportBtn(data?: any): void {
    document.getElementById("fx_import_self_upload").click();
  };

}
