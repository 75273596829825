import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject, ConnectableObservable } from 'rxjs';
import { filter, distinctUntilChanged, publish, publishLast } from 'rxjs/operators';
// import { DwUserService, DwUserStorage } from '@webdpt/framework';
import { HttpClient } from '@angular/common/http';
import { isUndefined } from 'util';
import { DwUserService } from '../../../../../projects/webdpt/framework/auth/user.service';
import { DwUserStorage } from '../../../../../projects/webdpt/framework/auth/user-storage';
import { DwHttpClient } from '../../../../../projects/webdpt/framework/http';

@Injectable({
  providedIn: 'root'
})
export class FxUserInfoService extends DwUserService {
  public ent: number;
  public org: string;
  public positiveMin: number = 0.00000001;// 正数最小值
  public negativeMin: number = -0.00000001;// 负数最小值
  private authedPrograms: Array<string> = [];

  private sysEnumsSubject: {
    [key: string]: Observable<any>
  } = {};
  private programParamSubject: {
    [key: string]: Observable<Map<string, string>>
  } = {};

  constructor(
    protected userStorage: DwUserStorage,
    protected http: HttpClient) {
    super(userStorage);
  }
  /**
   * 不登陆的时候取得ent
   * return 返回Observable
   */
  public readEnt(ent: number): Observable<number> {
    const subject1: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    this.ent = ent;
    // const subject: Subject<any> = new Subject(); // 訂閱時, 還未被 new 出來.
        //this.setEntInfo(this.ent);
        subject1.next(this.ent);
    return subject1.asObservable().pipe(
      filter(obsData => obsData !== null), // 不廣播初始值
      distinctUntilChanged() // 有改變時才廣播
    );
  }
  /**
   * 取得使用者資訊
   * return 返回Observable
   */
  public read(userId: string, ent: number): Observable<object> {
    this.ent = ent;
    const subject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    // const subject: Subject<any> = new Subject(); // 訂閱時, 還未被 new 出來.
    const info = {
      success: true,
      description: '', // 取值失敗時, 顯示.
      userInfo: {} // 取得的回傳值.
    };

    this.http.post('A/ILoginService/getUserInfo', { param: { userId: userId, ent: ent } }).subscribe(
      (userDatas: any) => {
        if (!userDatas.success) {
          this.setAPILog([{
            azz001: "A/ILoginService/getUserInfo", //API
            azz002: JSON.stringify({ userId: userId, ent: ent }), //入参
            azz003:JSON.stringify( userDatas), //返参
            azznote: "" //备注
          }]);
        }
        console.log('===参数userDatas',userId,ent, userDatas);
        const userResult = userDatas.data[0];
        info.userInfo = userResult;
        this.org = userResult.c_org;
        this.setUserInfo(userResult);
        this.getAllEntParam();
        this.getAllSysParam();
        this.getAllOrgParam();
        this.getAllUnitParam();
        this.getAllCurrencyParam();
        subject.next(info);
      }, error => {        
        if (error.error && error.error.status && error.error.status === 401) {
          alert(error.error.errorMessage + '请刷新系统重新登录！');
        }
      }
    );

    return subject.asObservable().pipe(
      filter(obsData => obsData !== null), // 不廣播初始值
      distinctUntilChanged() // 有改變時才廣播
    );
  }
  // 集团参数
  public getAllEntParam(): void {
    this.http.post('A/IACommonService/pub_upd_aat', { param: [''], aatent: this.ent }).subscribe(
      (sysDatas: any) => {
        console.log('===集团参数',this.ent,sysDatas);
        if (sysDatas.success) {
          this.setUser({ id: 'overAllSys', value: sysDatas.data });
        } else {
          this.setAPILog([{
            azz001: "A/IACommonService/pub_upd_aat", //API
            azz002: JSON.stringify({ param: [''], aatent: this.ent }), //入参
            azz003:JSON.stringify( sysDatas), //返参
            azznote: "" //备注
          }]);
          this.setUser({ id: 'overAllSys', value: [] });
        }
      }
    );
  }
  // 系统参数
  public getAllSysParam(): void {
    this.http.post('A/IACommonService/pub_upd_aas', { param: [''] }).subscribe(
      (sysDatas: any) => {
        console.log('===系统参数',sysDatas);
        if (sysDatas.success) {
          // this.allSccList = sysDatas.data;
          this.setUser({ id: 'overAllScc', value: sysDatas.data });
        } else {
          this.setAPILog([{
            azz001: "A/IACommonService/pub_upd_aas", //API
            azz002: JSON.stringify(['']), //入参
            azz003:JSON.stringify(sysDatas), //返参
            azznote: "" //备注
          }]);
          this.setUser({ id: 'overAllScc', value: [] });
        }
      }
    );
  }
  // 组织参数参数
  public getAllOrgParam(): void {
    this.http.post('A/IACommonService/pub_upd_aau', { param: [''], aaulorg: this.org, aauent: '' }).subscribe(
      (orgDatas: any) => {
        console.log('===组织参数参数', this.org,orgDatas);
        if (orgDatas.success) {
          // this.allSccList = sysDatas.data;
          this.setUser({ id: 'overAllOrg', value: orgDatas.data });
        } else {
          this.setAPILog([{
            azz001: "A/IACommonService/pub_upd_aau", //API
            azz002: JSON.stringify({param:[''], aaulorg: this.org, aauent: ''}), //入参
            azz003:JSON.stringify(orgDatas), //返参
            azznote: "" //备注
          }]);
          this.setUser({ id: 'overAllOrg', value: [] });
        }
      }
    );
  }

  // 单位资料
  public getAllUnitParam(): void {
    this.http.post('B/IBCommonService/getUnitList', { "param": {}}).subscribe(
      (unitDatas: any) => {
        console.log('===单位资料',unitDatas);
        if (unitDatas.success) {
          this.setUser({ id: 'overAllUnit', value: unitDatas.data.datas });
        } else {
          this.setAPILog([{
            azz001: "/B/IBCommonService/getUnitList", //API
            azz002: JSON.stringify({ "param": {} }), //入参
            azz003:JSON.stringify(unitDatas), //返参
            azznote: "" //备注
          }]);
          this.setUser({ id: 'overAllUnit', value: [] });
        }
      }
    );
  }

  // 币种资料
  public getAllCurrencyParam(): void {
    this.http.post('B/IBCommonService/getCurrencyList', { "param": {} }).subscribe(
      (currencyDatas: any) => {
        console.log('===币种资料',currencyDatas);
        if (currencyDatas.success) {
          this.setUser({ id: 'overAllCurrency', value: currencyDatas.data });
        } else {
          this.setAPILog([{
            azz001: "/B/IBCommonService/getCurrencyList", //API
            azz002: JSON.stringify({ "param": {} }), //入参
            azz003:JSON.stringify(currencyDatas), //返参
            azznote: "" //备注
          }]);
          this.setUser({ id: 'overAllCurrency', value: [] });
        }
      }
    );
  }

  public getEntParam(aat001: Array<any>): Array<any> {
    // console.log(this.overAllParam);
    if (aat001.length === 0 || (!this.overAllParam)) { return; }
    const sysList = [];
    for (let i = 0; i < aat001.length; i++) {
      sysList.push(this.overAllParam.overAllSys.filter(obj => obj.aat001 === aat001[i]) || '');
    }
    return sysList;
  }
  public getEntParamById(aat001: string): string {
    if (this.getEntParam([aat001])[0].length !== 0) {
      return this.getEntParam([aat001])[0][0].aat002;
    } else {
      return "";
    }
  }
  public getSysParam(aas001: Array<any>): Array<any> {
    if (aas001.length === 0 || (!this.overAllParam)) { return; }
    const sccList = [];
    for (let i = 0; i < aas001.length; i++) {
      sccList.push(this.overAllParam.overAllScc.filter(obj => obj.aas001 === aas001[i]) || '');
    }
    return sccList;
  }
  public getSysParamById(key: string): string {

    if (this.getSysParam([key])[0].length !== 0) {
      return this.getSysParam([key])[0][0].aas002;
    } else {
      return "";
    }
  }
  public getOrgParam(aau001: Array<any>): Array<any> {
    if (aau001.length === 0 || (!this.overAllParam)) { return; }
    const orgList = [];
    for (let i = 0; i < aau001.length; i++) {
      orgList.push(this.overAllParam.overAllOrg.filter(obj => obj.aau001 === aau001[i]) || '');
    }
    return orgList;
  }
  public getOrgParamById(key: string): string {
    if (this.getOrgParam([key])[0].length !== 0) {
      return this.getOrgParam([key])[0][0].aau002;
    } else {
      return "";
    }
  }
  public getUnitParam(bacunit: Array<any>): Array<any> {
    if ((!this.overAllParam)) { return; }
    var unitList = [];
    if (bacunit.length === 0) {
      unitList = this.overAllParam.overAllUnit;
    } else {
      for (let i = 0; i < bacunit.length; i++) {
        unitList.push(this.overAllParam.overAllUnit.filter(obj => obj.bacunit === bacunit[i]) || '');
      }
    }

    return unitList;
  }
  public getUnitParamById(key: string): Array<any> {
    return this.getUnitParam([key])[0][0].bacunit;
  }
  public getCurrencyParam(baacurr: Array<any>): Array<any> {
    if ((!this.overAllParam)) { return; }
    var currencyList = [];
    if (currencyList.length === 0) {
      currencyList = this.overAllParam.overAllCurrency;
    } else {
      for (let i = 0; i < baacurr.length; i++) {
        currencyList.push(this.overAllParam.overAllCurrency.filter(obj => obj.baacurr === baacurr[i]) || '');
      }
    }

    return currencyList;
  }
  public getCurrencyParamById(key: string): Array<any> {
    return this.getCurrencyParam([key])[0][0].baacurr;
  }
  // 查询枚举值
  private searchAAA(key: string): Observable<Array<any>> {
    return Observable.create(observer => {
      this.http.post<any>('A/IACommonService/pub_get_aaa', { param: { 'is_enum': 'Y', 'aaa001': key } }).subscribe(response => {
        if (response.success && response.data.length > 0) {
          const resultdata = response.data;
          observer.next(resultdata);
        } else {
          this.setAPILog([{
            azz001: "A/IACommonService/pub_get_aaa", //API
            azz002: JSON.stringify({ 'is_enum': 'Y', 'aaa001': key  }), //入参
            azz003:JSON.stringify(response), //返参
            azznote: "" //备注
          }]);
          observer.next([]);
        }
        observer.complete();
      }, error => {
        observer.next([]);
        observer.complete();
      });
    });
  }
  // 获取系统枚举值
  public getSysEnumById$(key: string): Observable<any> {
    if (!this.sysEnumsSubject.hasOwnProperty(key)) {
      const getSysEnumSubject = (this.searchAAA(key)).pipe(publishLast());
      (getSysEnumSubject as ConnectableObservable<any>).connect();
      this.sysEnumsSubject[key] = getSysEnumSubject;
    }
    return this.sysEnumsSubject[key];
  }

  // 查询作业参数
  private searchProgramParam(key: string): Observable<Array<any>> {
    return Observable.create(observer => {
      this.http.post<any>('A/IACommonService/getProgramParam', { param: { 'program_no': key } }).subscribe(response => {
        console.log('===查询作业参数', key,response);
        if (response.success && response.data.length > 0) {
          const resultdata = new Map(response.data.map(item => [item.aai002, item.aai003]));
          observer.next(resultdata);
        } else {
          this.setAPILog([{
            azz001: "A/IACommonService/getProgramParam", //API
            azz002: JSON.stringify({ 'program_no': key }), //入参
            azz003:JSON.stringify(response), //返参
            azznote: "" //备注
          }]);
          observer.next(new Map());
        }
        observer.complete();
      }, error => {
        observer.next(new Map());
        observer.complete();
      });
    });
  }

  // 获取作业参数
  public getProgramParamById$(key: string): Observable<any> {
    console.log('getProgramParamById$')
    if (!this.programParamSubject.hasOwnProperty(key)) {
      const getPPSubject = (this.searchProgramParam(key)).pipe(publishLast());
      (getPPSubject as ConnectableObservable<any>).connect();
      this.programParamSubject[key] = getPPSubject;
    }
    return this.programParamSubject[key];
  }

  // 移除作业参数
  removeProgramParamSubject(key?: string): void{
    if (key) {
      if (this.programParamSubject[key]) {
        delete  this.programParamSubject[key];
      }
    } else {
      this.programParamSubject = {};
    }
  }

  public clearUserInfo(): boolean {
    // this.userStorage.remove('DwUserInfo');
    this.userInfo = {};
    // this.userStorage.remove('DwSysInfo');
    this.overAllParam = {};
    this.removeProgramParamSubject();
    return true;
  }

  setAuthedProgram(programs: Array<string>): boolean {
    this.authedPrograms = programs;
    return true;
  }
  checkProgramAuthed(programId: string): boolean {
    return isUndefined(this.authedPrograms.find(item => item === programId));
  }

  /**
   * setAPILog
   */
  public setAPILog(param) {
    this.http.post('A/IACommonService/requestServiceLogging', { param: param }).subscribe(
      (resultdata: any) => {
       console.log('系统类参数日志记录', resultdata);
      }
    );
  }
}
