import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgQuicksilverModule } from 'ng-quicksilver';
import { FxLayoutBasicSideComponent } from './basic-side/basic-side.component';
import { FxLayoutDefaultComponent } from './layout-default/layout-default.component';
import { RouterModule } from '@angular/router';
import { DwMenusModule } from '../../../../../projects/webdpt/framework/components/menu/menus.module';
import { DwLanguageModule } from '../../../../../projects/webdpt/framework/components/language/language.module';
import { MenuListComponent } from './menu-list/menu-list.component';

import { FxTabRoutingModule } from './dw-tab-routing/dw-tab-routing.module';
import { ShowMenuService } from './services/show-menu.service';
import {FxUserInfoService} from '../../auth/fx-auth/fx-user-info.service';
import {MinLogoService} from './services/min-logo.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgQuicksilverModule,
    DwMenusModule,
    DwLanguageModule,
    FxTabRoutingModule,
    FormsModule
  ],
  declarations: [
    FxLayoutBasicSideComponent,
    FxLayoutDefaultComponent,
    MenuListComponent
  ],
  exports: [
    FxLayoutBasicSideComponent,
    FxLayoutDefaultComponent
  ],
  providers: [
    ShowMenuService,
    // FxUserInfoService,
    MinLogoService,
  ]
})
export class FxLayoutModule {}
