/*****
 * 单头按钮的封装
 *  */
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DwUserService, DwRoutingMessageService } from '@webdpt/framework';
import { DwModalService } from 'ng-quicksilver';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';

@Component({
  selector: 'app-grid-button',
  templateUrl: './grid-button.component.html',
  styleUrls: ['./grid-button.component.less']
})
export class GridButtonComponent implements OnInit {
  
  @Input() btnData: any[] = []; //按钮资料
  @Output() onButton = new EventEmitter<boolean>();
  @ViewChild('btn') btnW: ElementRef; //绑定按钮栏位的节点
  @ViewChild('btns') btns:ElementRef;
  public currentStyles = {}; //浮层按钮的样式
  public showBtn = false; //更多按钮显示层
  public btnMore:any[] = []; // 更多按钮
  public showMoreBtn = false;


  constructor(
    public dwMessage: DwRoutingMessageService,
    public http: HttpClient,
    private userService: DwUserService,
    public dwModalService: DwModalService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() { 
    
  }
  ngAfterViewInit() {
    this.reizesWidth();
    
  }

  //宽度适应
  reizesWidth() {
      let sum = 0;
      let allW = this.btnW.nativeElement.clientWidth;
      let btnChild = this.btnW.nativeElement.children; 
      //console.log(btnChild)
      //console.log(this.btnData)

      let btnsWidth = [];
      for (let i = 0; i < btnChild.length;i++) {
        let w = btnChild[i].clientWidth + 6;
        sum += w;
        btnsWidth.push(w)
      }
      sum += 50;
    if (sum > allW && allW != 0) {
        for (let index = this.btnData.length - 1; index >= 0;index--) {
          //console.log(index, this.btnData[index],'index')
          this.btnMore.splice(0,0,this.btnData[index]);
          this.btnData.splice(index, 1)
          sum -= btnsWidth[index]
          if(sum < allW) {
            break;
          }
        }
        setTimeout(() => {
          this.showMoreBtn = true;
        }, 0);
      }
      
    //console.log(this.btnData)
    //console.log(this.btnMore)
  }

  //更多操作
  public clikMoreBtn(event: any): void {
    this.showBtn = !this.showBtn;
  }

  //对应的按钮操作
  clickFixedBtn(name: any): void {
    this.onButton.emit(name);
  }
}
