/**
 * 处理历程封装
 * paramInfo {program_no: '', process_no: '', document_no: ''  } // 作业编号 签核流程编号 数据单号
 */
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
@Component({
  selector: 'app-process-info',
  template: `<div dw-row [dwJustify]="'start'" class="fx_listInfo fx_timeline">
              <dw-timeline dwMode="custom">
                <dw-timeline-item *ngFor="let obj of processList" dwPosition="left">
                  <p class="fx_timeline_title">{{ obj.acu002 + (obj.acu002_n1 ? ('(' + obj.acu002_n1 + ')') : '') }}</p>
                  <div class="fx_timeline_list" [ngClass]="{'fx_timeline_list_nowarp': i <= 3}" *ngFor="let val of obj.detailList;let i = index">					
                    <p> <strong>{{val.label}}</strong> {{val.value}}</p>
                  </div>
                </dw-timeline-item>
              </dw-timeline>
             </div>`,
  styles: [` 
  .fx_listInfo.fx_timeline {
    padding: 20px 0 0 40px !important;
  } 

  .fx_timeline_title {
    font-weight: 600;
    color: #1890ff;
  }

  .fx_timeline_list_nowarp {
    display: inline-block;
    width: 25%;
  }
  `]
})
export class ProcessInfoComponent implements OnInit {

  public _paramInfo: any;
  public processList: any;
  @Input() set paramInfo(paramInfo: any) {
    this._paramInfo = paramInfo;
  }
  get paramInfo(): any { return this._paramInfo; }

  public morelog_datas: any;
  showInfo = false;

  constructor(
    public translateService: TranslateService,
    public http: FxDapHttpClient) {
  }

  ngOnInit(): void {
    this.dataInit(); //获取初始数据
  }


  change(value: boolean): void {
    console.log(value);
  }

  /**
   * dataInit获取历程信息
   */
  public dataInit(): void {
    // 签核流程编号 数据单号
    if (!this._paramInfo.process_no || !this._paramInfo.document_no) {
      return;
    }
    this.http.post<any>('A/IOaCommonService/inquiryProcess', { param: this._paramInfo }).subscribe(result => {
      this.processList = result.data;
      this.processList.forEach(obj => {
        obj.detailList = [
          { label: this.translateService.instant('comm-处理人') + ':', value: obj.acu003 + (obj.acu003_n1 ? ('(' + obj.acu003_n1 + ')') : '') },
          { label: this.translateService.instant('comm-处理状态') + ':', value: obj.acu004_n1 },
          { label: this.translateService.instant('comm-建立日期') + ':', value: obj.acuddate },
          { label: this.translateService.instant('comm-处理日期') + ':', value: obj.acu005 },
          { label: this.translateService.instant('comm-处理意见') + ':', value: obj.acu006 },
        ];
      });
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['infoOption'] !== undefined) {
      this.dataInit();
    }
  }

} 