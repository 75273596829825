import {Component, EventEmitter, forwardRef, Input, Optional, Output} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DwOptionComponent} from 'ng-quicksilver';
import {AbstractDwFormItemComponent} from '../../form-field/abstract-dw-form-item-component';
import {TranslateParser} from '@ngx-translate/core';
import {toBoolean} from '../../../utils/convert';
import {forkJoin, Observable} from 'rxjs';
import * as _ from 'lodash';

// import { DwAreaSelectionService } from './area-selection.service';


@Component({
  selector: 'dw-form-area-selection',
  templateUrl: './area-selection.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DwAreaSelectionComponent),
      multi: true
    }
  ]
})
export class DwAreaSelectionComponent extends AbstractDwFormItemComponent {
  @Input() dwPlaceHolder: string;
  @Input() dwSize: string = 'default';
  public provinceName;
  public cityName;
  public countyDistrictName;
  public streetName;
  public residentialQuarterName;
  public v0;
  public v1;
  public v2;
  public v3;
  public v4;

  public validProperty = 'AddressVerification'; // 传递验证成功或失败的属性值(校驗)
  public validError = 'addressError'; // 傳遞驗證的錯誤訊息.
  public addressErrMsg = { // 地址層級的選單(match: 匹配失敗. empty: required 失敗.).
    province: {match: '省别校验失败', empty: '请选择省别'},
    city: {match: '城市校验失败', empty: '请选择城市'},
    countyDistrict: {match: '县区校验失败', empty: '请选择县区'},
    street: {match: '街道校验失败', empty: '请选择街道'},
    residentialQuarter: {match: '小区校验失败', empty: '请选择小区'}
  };

  // model 的複本暫存器(匹配校驗時會改變, 在此可能會產生不同步)
  public inputData = {}; // 用來取對應的開窗清單, 因為在尚未 click 選單前, 不能清 model, model 變動時需同步.
  public isOpen = false; // 動態-開窗選單是否開啟.

  public beChoosed = 0; // 頁籤的位置.
  public itemLists = { // 地址開窗選單暫存器.
    province: [], // 省.
    city: [], // 城市.
    countyDistrict: [], // 縣區.
    street: [], // 街道.
    residentialQuarter: [] // 小區.
  };

  constructor(@Optional() protected _fg: ControlContainer,
              @Optional() protected _ts: TranslateParser,
  ) {
    super(_fg, _ts);
  }

  afterContentInit(): void {
  }

  onInit(): void {
    this.variableInit();
    // console.log(this.addCommunity);
    // console.log(this.province);
  }

  // 变量初始化
  variableInit(): void {
  }

  // public getProvince(_countryId): Observable<any> {
  //   return this.AddressService.getDisplayField({});
  // }
  // ============ 取得資料 ====================//
  // 取得資料-省别.
  // public getProvince(_countryId): void {
  //   return this.AddressService.getProvince({
  //     countryId: _countryId
  //   });
  // }

  // // 取得資料-城市別.
  // public getCity(_countryId, _province): void {
  //   return this.AddressService.getCity({
  //     countryId: _countryId,
  //     provinceId: _province
  //   });
  // }

  // // 取得資料-縣區.
  // public getCountyDistrict(_countryId, _province, _city): void {
  //   return this.AddressService.getCountyDistrict({
  //     countryId: _countryId,
  //     provinceId: _province,
  //     cityId: _city
  //   });
  // }

  // // 取得資料-街道.
  // public getStreet(_countryId, _province, _city, _countyDistrict): void {
  //   return this.AddressService.getStreet({
  //     countryId: _countryId,
  //     provinceId: _province,
  //     cityId: _city,
  //     countyDistrictId: _countyDistrict
  //   });
  // }

  // // 取得資料-小區.
  // public getResidentialQuarter(_countryId, _province, _city, _countyDistrict, _street): void {
  //   return this.AddressService.getResidentialQuarter({
  //     countryId: _countryId,
  //     provinceId: _province,
  //     cityId: _city,
  //     countyDistrictId: _countyDistrict,
  //     streetId: _street
  //   });
  // }

  // 一次全取開窗清單並填入開窗選單.
  // public getItemLists(): void {
  //   var tasks$ = [];
  //   tasks$.push(this.getProvince(this.countryId));
  //   tasks$.push(this.getCity(this.countryId, this.inputData.province));
  //   tasks$.push(this.getCountyDistrict(this.countryId, this.inputData.province, this.inputData.city));
  //   tasks$.push(this.getStreet(this.countryId, this.inputData.province, this.inputData.city, this.inputData.countyDistrict));
  //   tasks$.push(this.getResidentialQuarter(this.countryId, this.inputData.province, this.inputData.city, this.inputData.countyDistrict, this.inputData.street));
  //   forkJoin(...tasks$).subscribe(ret => {
  //     this.clearItemLists(0);
  //     var i;
  //     // 清單-省別.
  //     for (i = 0; i < ret[0].length; i++) {
  //       if (_.isEmpty(ret[0][i].province) || _.isEmpty(ret[0][i].province_name)) {
  //         continue;
  //       }

  //       this.itemLists.province.push({
  //         id: ret[0][i].province,
  //         name: ret[0][i].province_name
  //       });
  //     }

  //     // 清單-城市別.
  //     for (i = 0; i < ret[1].length; i++) {
  //       if (_.isEmpty(ret[1][i].city) || _.isEmpty(ret[1][i].city_name)) {
  //         continue;
  //       }
  //       this.itemLists.city.push({
  //         id: ret[1][i].city,
  //         name: ret[1][i].city_name
  //       });
  //     }

  //     // 清單-縣區別.
  //     for (i = 0; i < ret[2].length; i++) {
  //       if (_.isEmpty(ret[2][i].county_district) || _.isEmpty(ret[2][i].county_district_name)) {
  //         continue;
  //       }

  //       this.itemLists.countyDistrict.push({
  //         id: ret[2][i].county_district,
  //         name: ret[2][i].county_district_name
  //       });
  //     }

  //     // 清單-街道別.
  //     for (i = 0; i < ret[3].length; i++) {
  //       if (_.isEmpty(ret[3][i].street) || _.isEmpty(ret[3][i].street_name)) {
  //         continue;
  //       }

  //       this.itemLists.street.push({
  //         id: ret[3][i].street,
  //         name: ret[3][i].street_name
  //       });
  //     }

  //     // 清單-小區別.
  //     for (i = 0; i < ret[4].length; i++) {
  //       if (_.isEmpty(ret[4][i].residential_quarter) || _.isEmpty(ret[4][i].residential_quarter_name)) {
  //         continue;
  //       }

  //       this.itemLists.residentialQuarter.push({
  //         id: ret[4][i].residential_quarter,
  //         name: ret[4][i].residential_quarter_name
  //       });
  //     }
  //   });

  // }

  // 依指定條件, 清空對應層級的開窗選單(this.itemLists).
  public clearItemLists(_mode: number): void {
    switch (_mode) {
      case 0:
        this.itemLists.province.splice(0, this.itemLists.province.length);
      case 1:
        this.itemLists.city.splice(0, this.itemLists.city.length);
      case 2:
        this.itemLists.countyDistrict.splice(0, this.itemLists.countyDistrict.length);
      case 3:
        this.itemLists.street.splice(0, this.itemLists.street.length);
      case 4:
        this.itemLists.residentialQuarter.splice(0, this.itemLists.residentialQuarter.length);
    }
  }


}

