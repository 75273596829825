import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fx-grid-lite',
  templateUrl: './fx-grid-lite.component.html',
  styleUrls: ['./fx-grid-lite.component.less']
})
export class FxGridLiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
