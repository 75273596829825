import { DwHttpClient } from '../../../http/client';
import { DW_APP_AUTH_TOKEN } from '../../../config/system.config';
import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DwIamHttpErrorHandler } from './iam-http-error-handler';
import { DwSystemConfigService } from '../../../config/config.service';
import { DwHttpClientOptionsService } from '../../../http/service/http-client-options.service';
import { DwLoadingMaskService } from '../../../components/loading/service/loading-mask.service';
import { DW_AUTH_TOKEN } from '../../../auth/auth.service';
import { DwHttpRequestUrlService } from '../../../http/service/http-request-url.service';

@Injectable()
export class DwIamHttpClient extends DwHttpClient {

  constructor(
    public dwHttpClientOptionsService: DwHttpClientOptionsService,
    public dwHttpRequestUrlService: DwHttpRequestUrlService,
    public dwLoadingMaskService: DwLoadingMaskService,
    public configService: DwSystemConfigService,
    @Inject(DW_AUTH_TOKEN) private authToken: any,
    private iamHttpError: DwIamHttpErrorHandler,
    @Inject(DW_APP_AUTH_TOKEN) private dwAppAuthToken: string
  ) {
    super(dwHttpClientOptionsService, dwHttpRequestUrlService, dwLoadingMaskService);

    this.getApiUrl().subscribe(
      (url: string) => {
        this.api = url;
      }
    );
  }

  public getApiUrl(): Observable<string> {
    return this.configService.get('iamUrl');
  }

  protected get defaultHeaders(): { [header: string]: string | string[] } {
    const token = (this.authToken.token) ? this.authToken.token : '';

    return {
      'digi-middleware-auth-app': this.dwAppAuthToken,
      'digi-middleware-auth-user': token
    };
  }

  protected errorHandler(event: HttpErrorResponse): void {
    if (event.error && event.error.message) {
      this.iamHttpError.handlerError(event);
    }
  }
}
