import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, from, of } from 'rxjs';
import { map, mergeAll, reduce } from 'rxjs/operators';

import { IDwSsoLogin } from './interface/sso.interface';
import { DW_SSO_LOGIN, LONIG_DEFAULT, APP_DEFAULT, DW_USING_TAB } from '../../config/system.config';
import { DwLanguageService } from '../language/service/language.service';


@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.less']
})
export class DwSsoLoginComponent implements OnInit {

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private languageService: DwLanguageService,
    @Inject(LONIG_DEFAULT) private defaultLogin: string,
    @Inject(APP_DEFAULT) protected defaultApp: string,
    @Inject(DW_SSO_LOGIN) private issoLogins: IDwSsoLogin[],
    @Inject(DW_USING_TAB) private _usingTab: boolean
  ) {
  }

  /**
   * 使用 reduce 是為了確保只取到一個結果值, 在 reduce 裡所操作的 Observable, 必定要 complete.
   * reduce: 將源 observalbe 的值歸併為單個值，當源 observable 完成時將這個值發出。
   */
  ngOnInit(): void {
    // 取得路由參數
    this.activateRoute.queryParamMap.subscribe(
      (params) => {
        const eventLists = from(this.issoLogins);
        const eventResults = eventLists.pipe(
          map(item => {
            const ret = item.ssoLogin(params);
            if (ret instanceof Observable) {
              return ret;
            }
            return of(ret);
          }),
          mergeAll(),
          reduce((acc, val): boolean => acc && val, true) // 第 2 個參數 true 是預設值, this.eventHandler 有幾個就要進來幾次, 值為 val, acc 為每次的結果值.
        );


        eventResults.subscribe(result => {
          if (!result) {
            this.loginedForwardUrl(this.defaultLogin);
          } else {
            let routerLink = params.get('routerLink') || '';
            const dwLang = params.get('dwLang') || '';

            if (dwLang) {
              this.languageService.setUp(dwLang);
            }

            if (routerLink === '') {
              routerLink = this.defaultApp;
            } else {
              const qryString = this._getUrlQueryParams(params);
              // 導頁前往指定頁面時, 需帶其餘的 url query parameters.
              if (qryString) {
                routerLink += '?' + qryString;
              }
            }

            console.log('routerLink>>>', routerLink);
            this.loginedForwardUrl(routerLink);
          }
        });

      }
    );

  }

  /**
   * todo 目前寫在 2 個地方, 應該集中到 dw-tab-routing 去.
   * [登入後]的要導頁的 url.
   *
   * param {string} returnUrl: 導頁的 url.
   */
  public loginedForwardUrl(returnUrl: string): void {
    if (!this._usingTab) {
      this.router.navigateByUrl(returnUrl);
      return;
    }

    if (!returnUrl || returnUrl === '/') {
      this.router.navigateByUrl('/dwTabRouting');
      return;
    }

    if (returnUrl.search('/dwTabRouting') === -1) {
      if (returnUrl.substr(0, 1) !== '/') {
        returnUrl = '/' + returnUrl;
      }

      returnUrl = '/dwTabRouting' + returnUrl;
      this.router.navigateByUrl(returnUrl);
      return;
    }

    this.router.navigateByUrl(returnUrl);
  }


  /**
   * 取出網址列的 url query parameters
   *
   * param {*} params
   * returns {string}
   */
  private _getUrlQueryParams(params: any): string {
    let qryString = '';
    const qryParams = {};

    params.keys.forEach((val) => {
      // 這2個值為指定用途, 不可當做導頁後的 url query parameters.
      if (val !== 'routerLink' && val !== 'userToken') {
        qryParams[val] = params.get(val);
      }
    });

    if (Object.keys(qryParams).length === 0) {
      return qryString;
    }

    // 將其餘的 url query parameters 組成標準參數串.
    qryString = Object.keys(qryParams).map((_key) => {
      return encodeURIComponent(_key) + '=' + encodeURIComponent(qryParams[_key]);
    }).join('&');

    return qryString;
  }
}
