import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FxAgGridValidationEditorComponent} from '../../../ag-grid/fx-ag-grid-validation-editor';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ICellEditorParams} from 'ag-grid-community';

@Component({
  selector: 'app-cfc-checkbox-editor',
  templateUrl: './cfc-checkbox-editor.component.html',
  styleUrls: ['./cfc-checkbox-editor.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CfcCheckboxEditorComponent extends FxAgGridValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {

  formGroup: FormGroup;
  key;
  columnName: string;
  private rowId: number;
  // 禁用属性表达式
  disabledExpr: Function;
  dwTrueVal: string = 'Y';
  dwFalseVal: string = 'N';
  message: string;
  params: any;

  @ViewChild('cellInput') cellInput: ElementRef;
  // @ViewChild('cellInput') cellInput: DwCheckboxComponent;

  constructor(private fb: FormBuilder) {
    super();
  }

  onKeyDown(event): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
      || event.keyCode === 39 || event.keyCode === 40) {
      event.stopPropagation();
    }
  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.columnName = params.column.colDef.headerName;
    this.key = params.column.colId;
    this.rowId = params.node.id;
    this.params = params;
    const rowFromGroup = params.context.serviceObj.rowCachedFormGroup;
    this.formGroup = rowFromGroup;

    // this.disabledExpr = params.disabledExpr || ((params: any) => { return false; });
    this.disabledExpr = params.disabledExpr
      ? ((param: any): boolean => (params.disabledExpr(param) ? true : false))
      : ((param: any): boolean => false);

    if (params.dwTrueVal && params.dwFalseVal) {
      this.dwTrueVal = params.dwTrueVal;
      this.dwFalseVal = params.dwFalseVal;
    }
    this.formGroup.get(this.key).setValue(params.value === this.dwTrueVal || params.value === true);

    super.init();
  }
  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {

  }



  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    // this.cellInput.blur();
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return (this.formGroup.get(this.key).value === true || this.formGroup.get(this.key).value === this.dwTrueVal)?this.dwTrueVal:this.dwFalseVal;
  }

  onChange(event): any{
    console.log('==', event, this.getValue())
    if (this.params && this.params.onChange) {
      this.params.onChange({
        gridId: '',
        value: this.getValue(),
        rowIndex: '',
        id: '',
        colId: '',
        controlName: ''
      });
    }
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void { }


}
