import { Injectable } from '@angular/core';

import { IDwMenu } from '../interface/menu.interface';
import { DwProgramExecuteService } from '../../../program-info/program-execute.service';
import { DwSystemConfigService } from '../../../config/config.service';
import { DwUserService } from '../../../auth';
@Injectable()
export class DwMenuExecuteService {

  constructor(
    private systemConfig: DwSystemConfigService,
    private dwProgramExecuteService: DwProgramExecuteService,
    private fxUserService: DwUserService,
  ) {
  }

  // 點選Menu
  menuClick(menuItem: IDwMenu): void {
    let programId = '';
    let ent = this.fxUserService.userInfo['c_ent'];
    if ('programId' in menuItem) {
      programId = menuItem.programId;
    }
    if (programId == 'fxed020') {
      menuItem.type = 'externalUrl';
      this.systemConfig.get('eshopUrl').subscribe(url => menuItem.url = url+"?ent="+ent);
      sessionStorage.setItem('DwFxed020UserInfo', sessionStorage.getItem('DwUserInfo'));
      sessionStorage.setItem('DwFxed020EntInfo', ent);
    }
    switch (menuItem.type) {
      case 'category':
        this.dwProgramExecuteService.byMenu(menuItem.id, programId);
        break;
      case 'program':
        // 執行作業
        if(menuItem.programIdQuery){//如果当前点击作业c_P006有值则跳转对应作业
          const param ={
            programId: programId
          }
          this.dwProgramExecuteService.byMenu(menuItem.id, menuItem.programIdQuery,param);
        }else{
          this.dwProgramExecuteService.byMenu(menuItem.id, programId);
        }
        break;
      case 'fineReport':
        this.dwProgramExecuteService.byMenu(menuItem.id, programId);
        break;
      case 'externalUrl':
        if (menuItem.openMode === 'iframe') {
          this.dwProgramExecuteService.byMenu(menuItem.id, programId);
        } else {
          if (menuItem.url !== '') {
            // 另開外部網頁
            window.open(menuItem.url);
          }
        }

        break;
      default:
    }
  }
}
