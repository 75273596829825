import { Component, OnInit } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';


/**
 * 自定义 operation column header
 * 作业用法:
 *   headerComponent: 'fxGridToolHeaderComponent',
 *
 */
@Component({
  selector: 'app-fx-grid-tool-header',
  templateUrl: './fx-grid-tool-header.component.html',
  styleUrls: ['./fx-grid-tool-header.component.less']
})
export class FxGridToolHeaderComponent implements IHeaderAngularComp {
  private params: any;
  public availableFields: any;
  public fxGridStateControlService;

  agInit(params: any): void {
    this.params = params;
    this.availableFields = params.context.availableFields;
    this.fxGridStateControlService = params.context.fxGridStateControlService;
    // TODO 未解决问题
    // 如果通过 headerComponentParams 传入 fxGridStateControlService，
    // headerComponent 的 serviceObj 和 parentComp 的 ServiceObj 是不同实例
    // gridOption.context 就不会有这个问题
  }

  public saveColumnState(event: any): any {
    this.fxGridStateControlService.saveColumnState(event);
  }

  public resetGridColumnState(event: any): any {
    this.fxGridStateControlService.resetGridColumnState(event);
  }
}
