import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Renderer2, Input, AfterViewInit, OnChanges, SimpleChange } from '@angular/core';
import { UEditorComponent } from 'ngx-ueditor';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { DwNewRouteKeyService, DwRoutingMessageService } from '@webdpt/framework';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { TranslateService } from '@ngx-translate/core';
import { concat, Observable } from 'rxjs';
// import produce from 'immer';

@Component({
  selector: 'fx-edit-cell-table',
  templateUrl: './fx-edit-cell-table.component.html',
  styleUrls: ['./fx-edit-cell-table.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class FxEditCellTableComponent implements OnInit {
  @Input() config: any;
  public head = []; //表头
  public body = []; //表身
  public month = []; //月份
  public colspan: any;
  //count = false;  //隐藏配置项
  public footer: any; //合计项
  public footers = [];

  constructor(
    protected http: FxDapHttpClient,
    private translateService: TranslateService,
    public router: DwNewRouteKeyService,
    public route: ActivatedRoute,
    public dwMessage: DwRoutingMessageService,
    public fxUserService: FxUserInfoService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  ngOnInit() {

  }
  async ngOnChanges(changes: SimpleChange) {

    if (!this.config) {
      return
    }
    this.head = this.config.tHead;

    this.body = this.config.tBody;
    this.month = this.config.month;

    this.config.tHead.find(
      (val, index) => {
        if (val.isEdit != undefined) {
          return this.colspan = index
        }
      });
    console.log(this.colspan, 'this.colspan')
    this.footer = this.config;
    const rowMonths = this.head.filter(val => val.isEdit != undefined);
    let getObj = this.footer.tHead.map((element, index) => {
      let temp = {};
      //过滤合并项
      if (index === 0) {
        temp['colspan'] = this.colspan;
        temp['field'] = this.translateService.instant("comm-合计") + '：'
      } else {
        for (const key in rowMonths) {
          if (element.field == rowMonths[key].field) {
            temp['date'] = element.field;
          }
        }
        temp['colspan'] = '';
        temp['field'] = '';
      }
      return temp

    });

    getObj.splice(1, this.colspan - 1);
    const newGetObj = getObj.filter(item => { return item != undefined })
    this.footers = newGetObj;

    //判断参数传入
    if (this.config.type === 'param') {
      let total = 0;
      //横计算
      for (let i = 0; i < this.body.length; i++) {
        let sum = 0;
        for (let key in this.config.tMonth) {
          if (this.body[i][this.config.tMonth[key]]) {
            sum += Number(this.body[i][this.config.tMonth[key]])
          }
        }
        this.body[i]['num'] = sum;
      }
      //竖计算
      for (let i = 0; i < this.config.tMonth.length; i++) {
        let sum = 0;
        for (let j = 0; j < this.body.length; j++) {
          if (this.body[j][this.config.tMonth[i]]) {
            sum += Number(this.body[j][this.config.tMonth[i]])
          }
        }

        for (let k = 0; k < this.footers.length; k++) {
          if (this.config.tMonth[i] === this.footers[k].date) {
            this.footers[k].field = sum;
          }
        }
      }
      //合计
      for (let i = 1; i < this.footers.length - 1; i++) {
        total += Number(this.footers[i].field)
      }
      this.footers[this.footers.length - 1].field = total;
    }
  }

  getCount(key, tr, date) {
    let getHdate = '';
    let sunH = 0;
    let rowSum = 0;
    let total = 0;
    const rowDate = this.head.filter(val => val.isEdit != undefined);

    //横向
    for (let i = 0; i < rowDate.length; i++) {
      rowSum += Number(this.body[tr][rowDate[i].field])
    }
    this.body[tr]['num'] = rowSum;

    //竖循环
    for (let i = 0; i < this.body.length; i++) {
      if (this.body[i][date] != undefined) {
        sunH += Number(this.body[i][date])
      }
    }

    this.footers[key - this.colspan + 1].field = sunH;

    //合计
    for (let i = 1; i < this.footers.length - 1; i++) {
      total += Number(this.footers[i].field)
    }
    this.footers[this.footers.length - 1].field = total;
  }


  getData() {
    return {
      data: this.body,
      sum: this.footers
    }
  }
}
