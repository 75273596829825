import { Observable } from 'rxjs';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';

export class FxBaseModel {

   // getDisplayField
   public getDisplayButton( programNo: any): Observable<any> {
      return this.http.post('A/IACommonService/getDisplayField', {param: {programNo: programNo}}); // 此函数需要调整
    }

    constructor(public http: FxDapHttpClient) {
    }

    public getHideField(programNo: any ): Observable<any> {
      return this.http.post(' A/IACommonService/getHideField', {param: {programNo: programNo}});
    }
}
