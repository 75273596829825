import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DwIframeFinereportComponent } from './finereport.component';
import { DwBaseIframeModule } from '../base-iframe/dw-base-iframe.module';

const COMPONENTS = [
  DwIframeFinereportComponent
];


@NgModule({
  imports: [
    CommonModule,
    DwBaseIframeModule
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    DwIframeFinereportComponent
  ]
})
export class DwIframeFinereportModule { }
