import { FormGroup, AbstractControl, FormControl} from '@angular/forms';
import { DwDataTable } from './data-table';
import { DatePipe } from '@angular/common';

export const DELETE = 'd';
export const CREATE = 'c';
export const UPDATE = 'u';

export class DwDataRow extends FormGroup {
  childs: { [key: string]: DwDataRow[] };
  modifyState: string;
  constructor(datasource: any) {
    const controls: { [key: string]: AbstractControl } = {};
    Object.keys(datasource).forEach(controlName => {
      controls[controlName] = new FormControl(datasource[controlName]);
    });

    super(controls);

    this.modifyState = '';
    this.childs = {};
  }

  get $state(): string {
    return this.modifyState;
  }

  addChild (name: string, table: DwDataRow[]): void {
    this.childs[name] = table;
  }

  addChildRow(name: string, row: DwDataRow): void {
    if (!this.childs[name]) {
      this.childs[name] = [];
    }

    this.childs[name].push(row);
  }

  markAsDirty(): void {
    if (this.modifyState === '') {
      this.markAsUpdate();
    }
    super.markAsDirty();
  }

  markAsPristine(): void {
    if (this.modifyState === UPDATE) { this.modifyState = ''; }
    super.markAsPristine();
  }

  markAsDelete(): void {
    this.modifyState = DELETE;
  }

  markAsCreate(): void {
    this.modifyState = CREATE;
  }

  markAsUpdate(): void {
    this.modifyState = UPDATE;
  }

  get active(): boolean {
    return (this.modifyState !== DELETE);
  }

  getRawValue(): any {
    const raw = super.getRawValue();
    raw['$state'] = this.modifyState;
    if ('child' in raw) {
      delete raw.child;
    }

    Object.keys(raw).forEach(
      rawKey => {
        if (raw[rawKey] instanceof Date) {
          raw[rawKey] = (new DatePipe('zh_tw')).transform(raw[rawKey], 'yyyy/MM/dd HH:mm:ss');
        }
      }
    );

    return raw;
  }
}
