import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-cfc-validation-editor',
  templateUrl: './cfc-validation-editor.component.html',
  styleUrls: ['./cfc-validation-editor.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CfcValidationEditorComponent  {
  rowFormGroup: FormGroup;
  formControlName: string;
  columnName: string;
  rowId: number;

  editType = '';
  validationMessages: Array<{key: string, params: any}> = [];
  message: string;
  _errorVisible: boolean = false;
  overlayStyle = {
    color: 'red'
  };
  @HostListener('mouseover') onMouseOver(): void {
    this.errorVisible = true;
  }
  @HostListener('mouseout') onMouseOut(): void {
    this.errorVisible = false;
  }
  set errorVisible(visible: boolean) {
    this._errorVisible = visible;
  }
  get errorVisible(): boolean {
    return this.validationMessages.length > 0 && this._errorVisible;
  }
  init(): void {
    // if (this.formControl) {
    //   // todo 改写法
    //   // this.formControl.statusChanges.subscribe(data => this.updateValidateStatus(data));
    // }
    if (this.rowFormGroup && this.formControlName) {
      this.rowFormGroup.get(this.formControlName).statusChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.updateValidateStatus(data));
    }
  }
  private updateValidateStatus(status: any): void {
    const errors = this.rowFormGroup.get(this.formControlName).errors || {};
    const errorKeys = Object.keys(errors).map(key => ({ key: key, params: errors[key] }));
    this.validationMessages = errorKeys;
  }

  isFullRowEdit(): boolean {
    return this.editType === 'fullRow';
  }

  public destroy$ = new Subject();
  destroy(): void {
    console.log('destroy in cfc-base');
    this.destroy$.next();
  }
}
