import { Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation, Optional } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { FxAgGridEventHandlerService } from './fx-ag-grid-event-handler.service';
import { Subscription } from 'rxjs';
import { DwCheckboxComponent } from 'ng-quicksilver';
import { FxAgGridValidationEditorComponent } from './fx-ag-grid-validation-editor';

@Component({
  selector: 'fx-checkbox-cell-editor',
  template: `
    <div dw-tooltip dwPlacement="topLeft" dwOverlayClassName="ag-invalid-cell-overlay"
         [dwTitle]="errorContent" [dwVisible]="errorVisible"
         *ngIf="errorVisible"></div>
    <ng-template #errorContent>
      <div *ngFor="let msg of validationMessages">{{message}} {{ msg.key | translate:msg.params }}</div>
    </ng-template>
    <form [formGroup]="validateForm">
      <dw-form-control>
        <dw-input-group>
          <label class="edit-checkbox" [formControl]="formControl" dw-checkbox [(dwDisabled)]="isDisabled" [(ngModel)] = "value"
          #cellInput (keydown)="onKeyDown($event)"></label>
        </dw-input-group>
      </dw-form-control>
    </form>
  `,
  styles: [`
    .edit-checkbox {
      padding-left: 11px;
    }
    // input[type="checkbox"]:checked::before {
    //   background: url('assets/img/showcase/checkboxed.svg') no-repeat -1px -1px;
    // }
    // .edit-checkbox.ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    //   background: url('assets/img/showcase/checkboxed.svg') no-repeat -1px -1px !important;
    // }
  `],
  encapsulation: ViewEncapsulation.None
})
export class FxAgCheckboxEditorComponent extends FxAgGridValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {
  modalService;
  name: string;
  colId;
  value: boolean = false;
  isDisabled: boolean = false;
  dwTrueVal: string = 'Y';
  dwFalseVal: string = 'N';
  params: ICellEditorParams;  // Added by David Lee 25Feb2019
  valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  validateForm: FormGroup;  // Added by David Lee 1Mar2019
  message: string;

  // @ViewChild('cellInput') cellInput: ElementRef;
  @ViewChild('cellInput') cellInput: DwCheckboxComponent;

  constructor(private fb: FormBuilder, @Optional() private eventHandler: FxAgGridEventHandlerService) {
    super();
  }

  onKeyDown(event): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
      || event.keyCode === 39 || event.keyCode === 40) {
      event.stopPropagation();
    }
  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    this.colId = params.column.colId;
    this.params = params;
    if (params.dwTrueVal) {
      this.dwTrueVal = params.dwTrueVal;
    }
    if (params.dwFalseVal) {
      this.dwFalseVal = params.dwFalseVal;
    }
    if (params.value === this.dwTrueVal) { this.value = true; }
    if (params.value === this.dwFalseVal) { this.value = false; }
    if (params.dwDisabled) {
      this.isDisabled = params.dwDisabled;
    }
    this.formControl = new FormControl(this.value, params.validators);
    this.validateForm = params.form;
    if (!params.form) {
      this.validateForm = this.fb.group({});
    }

    this.name = this.params.node.id + '-' + params.column.colId;

    if (this.validateForm.contains(this.name)) {
      this.validateForm.setControl(this.name, this.formControl);
    } else {
      this.validateForm.addControl(this.name, this.formControl);
    }
    this.formControl.markAsDirty();

    // 监听赋值 Add David lee 25 Feb 2019
    this.valueSetSubscription = this.eventHandler.valueSet.subscribe(event => {
      if ((event.colId === this.colId) && (event.gridId === params.context.id)) {
        this.formControl.setValue(event.value);
        this.message = event.message;
        this.formControl.markAsDirty();
      }
    });

    // 通过订阅消息回传onBlur事件
    this.cellInput.onBlur = (): void => {
      if (this.eventHandler) {
        this.eventHandler.onAfterField({
          gridId: this.params.context.id,
          value: this.getValue(),
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }
    };

    super.init();
  }
  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    if (this.cellInput && !this.isFullRowEdit()) {
      // this.cellInput.nativeElement.focus();
      // this.cellInput.nativeElement.select();
      this.cellInput.focus();
    }


    const valueChange = (value: boolean): boolean => {

      this.formControl.setValue(value);
      // 通过订阅消息回传onChange事件

      if (this.eventHandler) {
        this.eventHandler.onBeforeField({
          gridId: this.params.context.id,
          value: this.getValue(),
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }

      this.formControl.setValue(value);
      this.formControl.markAsDirty({ onlySelf: true });

      if (this.eventHandler) {
        this.eventHandler.onChangeField({
          gridId: this.params.context.id,
          value: this.getValue(),
          rowIndex: this.params.node.rowIndex,
          id: this.params.node.id,
          colId: this.colId,
          controlName: this.name
        });
      }


      return true;

    };
    this.cellInput.registerOnChange(valueChange);  // Added by David Lee 注册OnChange事件


  }



  // changechecked(): void {
  //  console.log('cell change!');
  //  if (this.params != undefined) {
  //      console.log('wdew', this.params)
  //    this.params.onChange(this.params, this.getValue());
  //  }
  // }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
    this.cellInput.focus();
    if (this.eventHandler) {
      this.eventHandler.onBeforeField({
        gridId: this.params.context.id,
        value: this.getValue(),
        rowIndex: this.params.node.rowIndex,
        id: this.params.node.id,
        colId: this.colId,
        controlName: this.name
      });
    }
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    this.cellInput.blur();
    // if (this.eventHandler) {
    //  this.eventHandler.onAfterField({
    //    gridId: this.params.context.id,
    //    value: this.getValue(),
    //    rowIndex: this.params.node.rowIndex,
    //    id: this.params.node.id,
    //    colId: this.colId,
    //    controlName: this.name
    //  });
    // }
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    let resultVal = 'Y';
    if (this.formControl.value ===true || this.formControl.value === this.dwTrueVal) {
      // this.formControl.setValue(this.dwTrueVal);
      resultVal = this.dwTrueVal;
    } else {
      // this.formControl.setValue(this.dwFalseVal);
      resultVal = this.dwFalseVal;
    }
    return resultVal;
  }

  // Set the final value to the grid, the result of the editing
  setValue(param: any): void {

    if (param === this.dwTrueVal) {
      this.formControl.setValue(true);
    } else {
      // this.formControl.setValue(this.dwFalseVal);
      this.formControl.setValue(false);
    }
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.validateForm.removeControl(this.name);
    // 当销毁时取消所有订阅
    if (this.valueSetSubscription) {
      this.valueSetSubscription.unsubscribe();
    }
  }


}
