import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DwSystemConfigService } from '../../config/config.service';


/**
 * Http API 攔截器
 *
 * @export
 */
@Injectable()
export class DwHttpApiInterceptor implements HttpInterceptor {
  isExternalURL:boolean;
  isNoSchemeURL:boolean
  isPathAbsolute:boolean;
  apiUrl: string;
  constructor(
    private configService: DwSystemConfigService
  ) {
    this.configService.get('apiUrl').subscribe(
      url => {
        this.apiUrl = url;
        //规定apiUrl后端配置URL为http://或https://开头，代表带子路径的开发环境或为外部URL
        this.isExternalURL = (url.startsWith('http://') || url.startsWith('https://'));
        //不带协议的绝对URL
        this.isNoSchemeURL = (url.startsWith('//'));
        //后端采用绝对路径配置方式配置 begins with "/" but not "//" 规定表示apiUrl后端配置URL为相对路径例如: /restful/service/
        this.isPathAbsolute=(url.startsWith('/')&&!(url.startsWith('//')));
      });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;

     /* David Li 30Aug2023 分销URL(https://datatracker.ietf.org/doc/html/rfc3986#section-3.3)
     * 1. Absolute URI: 场景为ExternalURL: http://、https://、double slash ("//") 
     * 2. relative-reference: path-abempty、path-absolute  
     */

    //绝对URL
    const isAbsoluteURL:boolean = (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//'));


     //相对URL之path-absolute: begins with "/" but not "//"
    const isRelativeURL = (url.startsWith('/')&&!(url.startsWith('//')));


    //资源文件从前端加载
    const isAssets:boolean= url.startsWith('assets/');

  
    //非绝对URL
    if (!isAbsoluteURL) {
    //相对URL
      //非资源文件
      if(!isAssets) {
      //相对URL

        //带子路径的开发环境
        if(this.isExternalURL){ 

          //后端配置URL+相对URL
          req = req.clone({
            url: this.apiUrl + url
          });

          // //从baseURI截取URL+相对URL
          // let baseURI:string =  document.baseURI;

          // if(baseURI.charAt(baseURI.length-1) === "/") {
          //   baseURI = baseURI.slice(0, -1);
          // }

          // //path-absolute
          // if(isRelativeURL){
          //   req = req.clone({
          //     url: baseURI + url
          //   });
          //  }else{
          //  //非path-absolute 
          //   req = req.clone({
          //     url: baseURI +'/'+ url
          //   });  
          //  }
        }else{
        //非资源文件&&不带子路径的相对URL&&typeof　this.apiUrl === 'undefined'

          //不带协议的绝对URL
          if(this.isNoSchemeURL){
            //后端配置URL+相对URL
            req = req.clone({
              url: this.apiUrl + url
            });
          }else{

              //后端采用相对路径配置方式配置
              if(this.isPathAbsolute){
                //非相对URL之path-absolute: begins with "/" but not "//"&&非资源文件
                if(!isRelativeURL){
                  //从baseURI截取URL+相对URL
                  let baseURI:string =  document.baseURI;

                  if(baseURI.charAt(baseURI.length-1) === "/") {
                    baseURI = baseURI.slice(0, -1);
                  }

                  req = req.clone({
                    url: baseURI+this.apiUrl + url
                  });
                }else{
                  //相对URL之path-absolute: begins with "/" but not "//"&&非资源文件
                  let baseURI:string =  document.baseURI;

                  if(baseURI.charAt(baseURI.length-1) === "/") {
                    baseURI = baseURI.slice(0, -1);
                  }

                  req = req.clone({
                    url: baseURI + url
                  });

                }
              }else{

                req = req.clone({
                  url: this.apiUrl + url
                });

              }
          }
        }

      }else{
      //资源文件
         
        //从baseURI+相对URL
        let baseURI:string =  document.baseURI;

        req = req.clone({
          url: baseURI + url
        });

      }

    }else{
    //绝对URL
    
      req = req.clone({
        url: url
      });

    }
    
    return next.handle(req).pipe(
      map(
        (event: any) => {

          // 攔截響應：轉換成API的格式
          if (event instanceof HttpResponse) {
            const resp: HttpResponse<any> = event;
            event = event.clone({ body: isAbsoluteURL ? resp.body : resp.body.response });
          }

          return event;
        }
      )
    );
  }
}
