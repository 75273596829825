import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormGroup} from '@angular/forms';
import { DOWN_ARROW, ENTER, ESCAPE, TAB, SPACE, UP_ARROW } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-cfc-readonly-text-editor',
  templateUrl: './cfc-readonly-text-editor.component.html',
  styleUrls: ['./cfc-readonly-text-editor.component.less']
})
export class CfcReadonlyTextEditorComponent {

  formGroup: FormGroup;
  disabledExpr: Function;
  key;
  // private value;
  columnName: string;
  activeId: any; //鼠标移入位置
  private rowId: number;
  @ViewChild('readyDiv') readyDiv: ElementRef;
  params: any;

  agInit(params: any) {
    this.params = params;
    this.columnName = params.column.colDef.headerName;
    // this.key = params.context.createKey(params.columnApi, params.column);
    this.key = params.column.colId;
    // this.value = params.value;
    this.rowId = params.node.id;

    // const gridFormArray = params?.context.gridFormArray;
    // const rowFromGroup = gridFormArray.at(params.rowIndex);

    // const rowFromGroup = params.context.rowCachedFormGroup;

    const rowFromGroup = params.context.serviceObj.rowCachedFormGroup;
    // const cellFormControl = rowFromGroup.get(this.key);
    this.formGroup = rowFromGroup;
  }

  getValue(): any {
    // return this.value;
    return this.formGroup.get(this.key).value;
  }

  onKeyDown(event: any): void {
    if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
      || event.keyCode === 39 || event.keyCode === 40) {
      event.stopPropagation();
    }
    if (event.keyCode === ENTER || event.keyCode === TAB) {
      // this.readyDiv.nativeElement.blur();
      // this.params.api.tabToNextCell();
      // this.formGroup.get(this.key).value;
      // if (this.activeId && this.activeId !== this.rowFormGroup.get(this.formControlName).value && this.matOptionShow && !(this.editorParams['param'](this.rowFormGroup))['disableCheck'] && !this.isBackendPaging) {
      //   this.rowFormGroup.get(this.formControlName).patchValue(this.activeId);
      // }
      // this.cellInput.nativeElement.onchange();
    }
  }

  //获取焦点
  focusIn(event?: any): void {
    console.log(event, 'tabToNextCell');   
    this.readyDiv.nativeElement.focus(); 
    // return true;
    // 
    // this.params.api.tabToNextCell();
    // this.readyDiv.nativeElement.blur();
  }

  //离开焦点
  focusOut(event?: any): void {
    console.log(event);
    this.readyDiv.nativeElement.blur();
  }

  

}
