import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FxBatchModalComponent} from './fx-batch-modal.component';
import {FxConfirmModalComponent} from './fx-confirm-modal.component';
import {FxModalService} from './fx-modal.service';
import {ModuleWithProviders} from '@angular/core';
import {DwDocument, DwDocumentModule, FrameworkUIModule} from '@webdpt/framework';
import {SharedModule} from '../../shared.module';
import {FxSearchModalComponent} from './fx-search-modal.component';
import {FxSearchBackendPaginationModalComponent} from './fx-search-backend-pagination-modal.component';
import {moreComCondComponent} from './more-comcond';
import {HyperlinkComponent} from '../../ag-grid/hyperlink.component';
import {OperationbtnComponent} from '../../ag-grid/operationbtn.component';
// import {CheckboxRendererComponent} from '../../ag-grid/checkbox-renderer.component';
// import {CheckboxEditorComponent} from '../../ag-grid/checkbox-editor.component';
import {AgGridModule} from 'ag-grid-angular';
import { updateBPriceComponent } from 'app/implementation/shared/components/fx-modal/updateBPrice/updateBPrice.component';
import { FxIconPickerModalComponent } from './fx-icon-picker-modal/fx-icon-picker-modal.component';
import {FxDapModule} from '../../fx-http-client';
import {PipesModule} from '../pipe/pipes.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { FxCharacteristicModalComponent } from './fx-characteristic-modal/fx-characteristic-modal.component';
import { FxModalDragDirective } from '../../services/fx-modal-drag.directive';
import { FxOptionsModalComponent } from './fx-options-modal/fx-options-modal.component';

@NgModule({
  imports: [
    CommonModule,
    // SharedModule,
    FrameworkUIModule,
    FxDapModule,
    DwDocumentModule,
    PipesModule,
    AgGridModule.withComponents([HyperlinkComponent, OperationbtnComponent]),
    AngularDraggableModule
  ],
  exports: [
    FxBatchModalComponent,
    FxConfirmModalComponent,
    FxSearchModalComponent,
    FxSearchBackendPaginationModalComponent,
    moreComCondComponent,
    FxCharacteristicModalComponent,
    updateBPriceComponent,
    FxModalDragDirective,
    FxOptionsModalComponent
  ],
  declarations: [
    FxBatchModalComponent,
    FxConfirmModalComponent,
    FxSearchModalComponent,
    FxSearchBackendPaginationModalComponent,
    moreComCondComponent,
    FxIconPickerModalComponent,
    FxCharacteristicModalComponent,
    updateBPriceComponent,
    FxModalDragDirective,
    FxOptionsModalComponent
  ],
  entryComponents: [
    FxBatchModalComponent,
    FxConfirmModalComponent,
    FxSearchModalComponent,
    FxSearchBackendPaginationModalComponent,   
    FxIconPickerModalComponent,
    FxCharacteristicModalComponent,
    updateBPriceComponent,
    // FxModalDragDirective
    FxOptionsModalComponent
  ],
  providers: [
    FxModalService,
    FxSearchBackendPaginationModalComponent
  ]
})
export class FxModalModule {
  static forRoot(components: any): ModuleWithProviders {
    return {
      ngModule: FxModalModule,
      providers: [
        FxModalService,
        FxSearchBackendPaginationModalComponent
      ]
    };
  }
}
