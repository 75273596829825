import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DwLanguageI18nRepository {

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * 全域多語言翻譯
   */
  basic(lang: string): Observable<any> {
    return this.http.get('assets/i18n/' + lang + '/basic.json');
  }
}
