import {Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation, Optional} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {IAfterGuiAttachedParams, ICellEditorParams} from 'ag-grid-community';
import {FxAgGridEventHandlerService} from './fx-ag-grid-event-handler.service';
import {Subscription} from 'rxjs';
import {DwCheckboxComponent} from 'ng-quicksilver';
import {FxAgGridValidationEditorComponent} from './fx-ag-grid-validation-editor';

@Component({
  selector: 'fx-ag-color-renderer',
  template: `

        <dw-input-group>
          <span [ngStyle]="{'width': '20px', 'height': '20px', 'display': 'inline-block', 'vertical-align': 'middle', 'margin-right': '5px', 'background-color': value}"></span>
					<span [ngClass]="{'cell-overlay' : errorVisible}">{{value}}</span>
				</dw-input-group>
  `
})

export class FxAgColorRendererNewComponent extends FxAgGridValidationEditorComponent implements ICellRendererAngularComp, OnDestroy {
  private params: ICellRendererParams;
  value: boolean = false;
  name: string;

  constructor() {
    super();
    // console.warn('创建FormCellRenderer:');
  }

  agInit(params: any): any {

    this.params = params;

    this.value = this.params.getValue();
    super.init();
    
  }

  refresh(): boolean {
    // console.warn('刷新FormCellRenderer:', this.name);
    return false;
  }


  ngOnDestroy(): void {
    // console.warn('销毁FormCellRenderer:', this.name);
  }
}
