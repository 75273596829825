import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgQuicksilverModule } from 'ng-quicksilver';
import { FrameworkUIModule } from '@webdpt/framework';
import { FormsModule } from '@angular/forms';

import { GridButtonComponent } from './grid-button.component';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgQuicksilverModule,
    FrameworkUIModule,
    FormsModule
  ],
  declarations: [GridButtonComponent],
  exports: [GridButtonComponent]
})
export class GirdBtnModule { }
