import {Observable} from 'rxjs';
import { CfcAgGridValidationRendererComponent } from 'app/implementation/shared/fx-grid/cell-component/cfc-validation-renderer';

export class CfcColorRendererComponent extends CfcAgGridValidationRendererComponent {
  private eGui;
  public values;

  constructor() { super(); }

  public init(params: any): void {
    // <span [ngStyle]="{'width': '20px', 'height': '20px', 'display': 'inline-block', 'vertical-align': 'middle', 'margin-right': '5px', 'background-color': value}"></span>
		// 			<span [ngClass]="{'cell-overlay' : errorVisible}">{{value}}</span>
    this.eGui = document.createElement('div');
    var colorSpan = document.createElement('span');
    colorSpan.style.backgroundColor = params.value;
    colorSpan.style.width = '20px';
    colorSpan.style.height = '20px';
    colorSpan.style.display = 'inline-block';
    colorSpan.style.verticalAlign =  'middle';
    colorSpan.style.marginRight = '5px';
    this.eGui.appendChild(colorSpan)
    var labelSpan = document.createElement('span');
    labelSpan.innerHTML = params.value ? params.value : '';
    this.eGui.appendChild(labelSpan);
    if (params.vstatus) {
      const _valueStatus = ((typeof params.vstatus === 'function'
        ? params.vstatus(params)
        : params.vstatus) || {}).status as string || '';
      if (_valueStatus.toLowerCase() === 'invalid') {
        this.eGui.style.background = 'red';
      } else if (_valueStatus.toLowerCase() === 'pending') {
        this.eGui.style.background = 'blue';
      }
    }
   
  }

  public getGui() {
    return this.eGui;
  }
}
