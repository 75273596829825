import {
  Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Inject, ViewChildren, OnDestroy, AfterViewInit,Output,EventEmitter
} from '@angular/core';
import {Router, RouterOutlet, NavigationEnd, NavigationStart, NavigationExtras} from '@angular/router';
import {DOCUMENT, Location} from '@angular/common';
import {Observable, Subscription, of} from 'rxjs';
import {filter, distinct, first} from 'rxjs/operators';
import * as crypto from 'crypto-js';

import {DW_USING_TAB, DW_TAB_ROUTE_CONFIG_JSON, DW_MENU_JSON} from '../../../../../../projects/webdpt/framework/config/system.config';
import {IDwIframeItem} from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/interface/tab-routing.interface';
import {IDwRouteInfo} from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/interface/route-info.interface';
import {DwRouteInfoService} from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/dw-route-info.service';
import {ListChangedEventArgs} from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/interface/list-changed-event-args.interface';
import {DwIframeItemSubjectService} from '../../../../../../projects/webdpt/framework/components/dw-tab-routing/service/iframe-item-subject.service';
import {DwMenuItemNameComponent} from '../../../../../../projects/webdpt/framework/components/menu/menu-item-name/menu-item-name.component';
import {IDwMenuConfigMap, IDwMenu} from '../../../../../../projects/webdpt/framework/components/menu/interface/menu.interface';
import {DwRecursiveMenuService} from '../../../../../../projects/webdpt/framework/components/menu/service/recursive-menu.service';
import {DwOperationInfoService} from '../../../../../../projects/webdpt/framework/program-info/operation-info.service';
import {IDwProgram} from '../../../../../../projects/webdpt/framework/program-info/interface/program.interface';
// import { DwBaseIframeComponent } from '../dw-iframe/base-iframe/dw-base-iframe.component';
import {DwAuthService} from '../../../../../../projects/webdpt/framework/auth/auth.service';
// import {DwUserService} from '../../../../../../projects/webdpt/framework/auth/user.service';
import {ShowMenuService} from '../services/show-menu.service';
// import {FxUserInfoService} from '../../../auth/fx-auth/fx-user-info.service';
import {FxDapHttpClient} from '../../fx-http-client/http/fx-http-client';
import {HttpClient} from '@angular/common/http';

// import {DwUserService} from '@webdpt/framework';
import {DwRoutingMessageService} from '../../../../../../projects/webdpt/framework/components/message/routing-message.service';
import {FxModalService} from '../../components/fx-modal/fx-modal.service';
//导入收缩logo状态
import { MinLogoService } from '../services/min-logo.service';
import { DwModalService, DwMessageService } from 'ng-quicksilver';
import { ChangePasswordComponent } from 'app/implementation/shared/layout/dw-tab-routing/change-password/change-password.component';
import { DwViewLangLoaderService } from '../../../../../../projects/webdpt/framework/components/language/service/view-lang-loader.service';
import { DwLanguageService } from '../../../../../../projects/webdpt/framework/components/language/service/language.service';
// 路由文件
import { programData } from 'app/implementation/program-info/model/program.model'
import { DwHttpClient } from '../../../../../../projects/webdpt/framework/http/client';
import { DwUserService } from '@webdpt/framework';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { getMenuName } from 'app/implementation/shared/services/common-method';
// import {cti} from 'https://cdn.lanjia.org.cn/sg/main.js?v=1';

class UserInfo {
  userId: string = '';
  userName: string = '';
  orgId: string = '';
  orgName: string = '';

  constructor(value: any,) {
    Object.assign(this, value);
  }
}

@Component({
  selector: 'app-fx-tab-routing',
  templateUrl: './dw-tab-routing.component.html',
  styleUrls: ['./dw-tab-routing.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class FxTabRoutingComponent implements OnInit, OnDestroy, AfterViewInit {
  nowUrl = '';
  iframeItems: IDwIframeItem[] = [];
  reloadItems: IDwIframeItem[] = [];
  tabs: IDwRouteInfo[] = [];
  selectedIndex: number = 0; // 用程式動態切換tab用
  subscription: Subscription;
  tabCreating: boolean = false;
  outletDivOutSide: HTMLElement;
  outletDiv: HTMLElement;
  @ViewChild('tabContent')
  tabContent: ElementRef;
  @ViewChild(RouterOutlet)
  @ViewChildren(DwMenuItemNameComponent) menuItemNameComponents: DwMenuItemNameComponent[];
  // @ViewChildren(DwBaseIframeComponent) iframeComponents: DwBaseIframeComponent[];
  route$: Subscription;
  menuConfigMap: IDwMenuConfigMap;
  public outlet: RouterOutlet;
  // userInfo = this.userService.getUser('userInfo');
  userInfo: UserInfo;
  orgList: Array<{
    orgId: string,
    orgName: string
  }> = [];
  public isOpen = true;
  public full = true;

  //搜索框状态
  public isActive = false;
  searchVal:string = '';
  public works = [];
  public menuData:any;
  public program_names: string[] = [];
  public names = [];
  public program_name = [];
  public programData = JSON.parse(JSON.stringify(programData));
  //收缩左侧菜单是迷你logo显示状态
  public isMinShow = false;
  public dwLogoPath: string;
  // public dwMessage: DwRoutingMessageService;
  translateService: any;
  isAdd: any;
  isSave: boolean;
  isModify: any;
  MasterService: any;
  masterGridApi: any;
  public isMask = true;
  isShowCti = false;
  isTBLogin = true; //是否是淘宝登录进入
  public callerPanelShow = false; // 是否显示400话务条


  public remainingDays; // 剩余天数
  public remainingDaysShow = false;

  constructor(
    private router: Router,
    private location: Location,
    private userService: DwUserService, // 用户信息
    // private fxUserService: FxUserInfoService,
    private http: DwHttpClient,
    private authService: DwAuthService, // 登陆
    private showmenu: ShowMenuService, // 收缩菜单
    private iframeItemSubjectService: DwIframeItemSubjectService,
    private dwRouteInfoService: DwRouteInfoService,
    private recursiveMenuService: DwRecursiveMenuService,
    private dwOperationInfoService: DwOperationInfoService,
    private fxModalService: FxModalService,
    public dwMessage: DwRoutingMessageService,
    @Inject(DOCUMENT) private doc: any,
    @Inject(DW_USING_TAB) private _usingTab: boolean,
    @Inject(DW_TAB_ROUTE_CONFIG_JSON) private tabRouteConfigJson: any[],
    public el: ElementRef,
    public minLogo: MinLogoService,
    public dwModalService: DwModalService,
    private fxUserService: FxUserInfoService,
    private DwMessage: DwMessageService,
    private viewLangLoaderService: DwViewLangLoaderService,
    private languageService: DwLanguageService,
    // public cti: cti
    // public FilterPipe
  ) {

    this.iframeItemSubjectService.clear();
    this.iframeItems = [];
    this.dwRouteInfoService.clear();
    this.nowUrl = '';
    this.tabs = [];
    // if (!this._usingTab) {
    //   this.router.navigateByUrl('');
    // }
    this.dwRouteInfoService.onListChange(function (listChangedEventArgs: ListChangedEventArgs): void {
      // console.log(listChangedEventArgs);
    });
    this.isTBLogin = JSON.parse(sessionStorage.getItem('loginInfo')) && JSON.parse(sessionStorage.getItem('loginInfo')).tbtype == '1' ? false : true;
    // console.log(this.userInfo, 'this.userInfo');

    this.minLogo.eventEmit.subscribe( value => {
      if(value == "true") {
          this.isMinShow = true;
          this.dwLogoPath = this.userService.getEntParamById('c_E069');
      } else {
          this.isMinShow = false;
      }
    });
    // this.getCtiPromiess().subscribe(result => {
    //   this.isShowCti = result;
    //   if (this.isShowCti) {
    //     document.getElementById('ctitoolbar').style.display = 'block';
    //     document.getElementById('fx_wrapper_layout').style.paddingTop = '50px';
    //     var headerEle = document.getElementById('tab_header');
    //     headerEle.style.top = '35px';
    //     headerEle.style.height = '23px';
    //     document.getElementById('fx_drawer_Ribtn_basic').style.paddingTop = '27px';

    //   }
    // })
  }

  closeTab(tab: IDwRouteInfo): void {
    this.dwRouteInfoService.remove(tab);
    // sessionStorage.removeItem(tab.id);
    //删除作业缓存
    Object.keys(sessionStorage).forEach(keyItem => {
      if (keyItem.slice(0,7) == tab.id) {
        sessionStorage.removeItem(keyItem);
      }
    });
    this.selectedIndex = this.dwRouteInfoService.routeSelectedIndex;
    // 移除關閉的ifrmeItem
    const idx = this.iframeItems.findIndex((i: any) => {
      return i.item.routerLink === tab.routerLink;
    });
    if (idx >= 0) {
      this.iframeItems.splice(idx, 1);
    }
    // 移除作业参数
    const closeRepeatTabs = this.tabs.filter(tabItem => {
      return tabItem.id == tab.id;
    });
    if (closeRepeatTabs.length == 0) {
      this.fxUserService.removeProgramParamSubject(tab.id);
    }
  }

  // // 切換tab
  tabClick(tab: IDwRouteInfo): void {
    if (this.location.path() !== tab.routerLink) {
      this.hideIframe();
    }
    this.dwRouteInfoService.routeChange(tab);
  }

  // 切換tab後
  tabSelected(tab: IDwRouteInfo): void {
    this.dwRouteInfoService.routeChanged(tab);
    this.recursiveMenuService.onSelect(tab.menuId);
    // 临时解决方案-解决切换tab页签grid表头和内容不对齐问题
    var agHeaderContainer = document.getElementsByClassName('ag-header-container');
    for (var i = 0; i < agHeaderContainer.length; i++) {
      agHeaderContainer[i].setAttribute('style', 'left: 0 !important');
    }
  }

  selectedIndexChange($idx: any): void {
    this.dwRouteInfoService.routeSelectedIndexChange();
    this.selectedIndex = this.dwRouteInfoService.routeSelectedIndex;
  }

  /** 切換前先隱藏,避免報表網頁因為新tab內容寬高不同,自動resize,造成回來之後會因為resize而觸發reload*/
  hideIframe(): void {
    this.iframeItems.forEach(
      (iframeItem: IDwIframeItem) => {
        iframeItem.isDisplay = false;
      });
  }

  // 登陆信息
  onLoggedout() {
    // 登出方法应该在 authService 中定义
    // 但现在 DwAuthService 和 FxAuthService 之间有奇怪的引用关系暂未厘清
    // 现在先放这儿
    this.userService.clearUserInfo();
    this.userService.clearUser();
    sessionStorage.clear();
    // 登出清空多语言
    if (this.languageService.currentLanguage && this.viewLangLoaderService.translated[this.languageService.currentLanguage]) {
    this.viewLangLoaderService.translated[this.languageService.currentLanguage] = {};
    }
    this.authService.logout();
  }
  //  // 修改密码
   onChangePassword(): void {
    const modal = this.dwModalService.create({
      dwTitle: "修改密码",
      dwStyle: { top: '40px' },
      dwWidth: 400,
      dwWrapClassName: 'fx_xs_modal',
      dwMaskClosable: true, // 點擊遮罩是否允許關閉
       dwContent: ChangePasswordComponent,
      dwComponentParams: {
      },
      dwFooter: [
        {
          label: "取消",
          shape: 'default',
          onClick: (data: any): void => {
            modal.destroy();
          }
        },
        {
          label: "确定",
          type: 'primary',
          onClick: (data: any): void => {
            console.log(data.myform.value);
            var result = data.myform.value;
            if (!result.new_password ||!result.old_password || !result.sure_password ) {
              //界面栏位不可存在空值！
              this.dwMessage.error(this.translateService.instant('com-empty-field'));
              // this.dwMessage.errorNew(this.translateService.instant('nullofall'), this.translateService.instant('rephdate_notnull'), "", "");
              return;
            }
            let abj004 = crypto.MD5(result.old_password);
            let newAbj004 = crypto.MD5(result.new_password);
            let confirmAbj004 = crypto.MD5(result.sure_password);
            this.http.post('A/IFxai025Service/modifyPsd', {param: {
              abj001 :result.userId ? result.userId : this.userInfo.userId,// 用户编号
              abj004:abj004+'',//  原密码
              newAbj004:newAbj004+'',//   新密码
              confirmAbj004:confirmAbj004+''//  确认新密码
            }}).subscribe(
              response => {
                // response.success && response.data
                //   ? observer.next(response.data)
                //   : observer.next([]);
                // observer.complete();
                console.log(response,'modifyPsd')
                if(response['success']){
                  modal.close({});
                  this.onLoggedout();

                }else{
               // alert(response['message']);
                  this.dwMessage.error(response['message']);
                  return;
                }

              }, error => {

                // observer.next([]);
                // observer.complete();
              }
            );


          }
        }
      ],
    });
   }

  // 清除缓存
  onCleanStorage() {
    window.location.reload();
  }

  //全屏显示
  fullScreen() {
    let screenBox = this.el.nativeElement.ownerDocument.documentElement;
    let exitBox = this.el.nativeElement.ownerDocument;
    if (this.full) {
      if (screenBox.requestFullscreen) {
        screenBox.requestFullscreen();
      } else if (screenBox.mozRequestFullScreen) {
        screenBox.mozRequestFullScreen();
      } else if (screenBox.webkitRequestFullscreen) {
        screenBox.webkitRequestFullscreen();
      } else if (screenBox.msRequestFullscreen) {
        screenBox.msRequestFullscreen();
      }
      this.full = false;
    } else {
      //退出全屏
      if (exitBox.exitFullscreen) {
        exitBox.exitFullscreen();
      } else if (exitBox.mozCancelFullScreen) {
        exitBox.mozCancelFullScreen();
      } else if (exitBox.webkitCancelFullScreen) {
        exitBox.webkitCancelFullScreen();
      } else if (exitBox.msExitFullscreen) {
        exitBox.msExitFullscreen();
      }
      this.full = true;
    }
  }

  //搜索栏
  fxSearch() {
    if (this.searchVal == '') {
      this.isActive = !this.isActive;
    } else {
      this.isActive = true;
      return;
    }
  }

  // 切换菜单
  // clickMenu() {
  //   this.showmenu.toggleSidebar();
  // }

  // toggleSidebar() {
  //   this.isOpen = !this.isOpen;
  // }

  ngOnInit(): void {
    this.userInfo = new UserInfo({
      userId: this.userService.userInfo['c_user'],
      userName: this.userService.userInfo['c_user_n1'],
      orgId: this.userService.userInfo['c_org'],
      orgName: this.userService.userInfo['c_org_n1']
    });

    this.http.post('T/IOMSMangeService/remainingDays', {
      param: {}
    }).subscribe((response: any) => {
      if(response.data && response.data.remainingDays !== '' && response.data.remainingDays !== null){
        this.remainingDays = "剩余天数：" + response.data.remainingDays;
        this.remainingDaysShow = true;
      } else {
        this.remainingDaysShow = false;
      }
    })

    // 初始遮罩
    const body = this.doc.querySelector('body');
    let divElem = this.doc.querySelector('.tabRoutingMask');
    if (!divElem) {
      divElem = this.doc.createElement('div');
      const att = this.doc.createAttribute('class');       // Create a "class" attribute
      att.value = 'tabRoutingMask';
      divElem.setAttributeNode(att);
      body.appendChild(divElem);
      divElem.style.display = 'none';
    }

    // 訂閱 dwRouteInfoService >> _tabs變動
    this.subscription = this.dwRouteInfoService.routeInfos$
      .subscribe(routes => {
        this.tabs = routes;
          this.isMask = false;
          this.selectedIndex = this.dwRouteInfoService.routeSelectedIndex;
          if (this.tabs[this.selectedIndex] && this.location.path() !== this.tabs[this.selectedIndex].routerLink) {
            this.hideIframe();
          }
          if (!this.tabs.length) {
            this.router.navigateByUrl('/dwTabRouting');
          }

      });
    // 訂閱 dwRouteInfoService >> tabCreating 遮罩開啟
    this.dwRouteInfoService.tabCreating$.subscribe(val => {
      val = this.isMask;
      this.tabCreating = val;
      divElem.style.display = (!!val ? 'block' : 'none');
    });

    // #########管理外部連結iframe div display BEGIN#########
    // 重整時帶入
    // if (this.dwRouteInfoService.iframeItems.length) {
    //   console.log('iframeItems from storge');
    //   this.iframeItems = this.dwRouteInfoService.iframeItems;
    //   setTimeout(() => {
    //     nowUrl = this.location.path();
    //     this.iframeComponents.forEach((comp) => {
    //       if (nowUrl.search(comp.item.routerLink) !== -1) {
    //         comp._elementRef.nativeElement.style.display = 'block';
    //       } else {
    //         comp._elementRef.nativeElement.style.display = 'none';
    //       }
    //     });
    //   }, 0);

    // }

    this.nowUrl = '';
    this.route$ = this.router.events.pipe(
      filter(evt => (evt instanceof NavigationEnd || evt instanceof NavigationStart)),
      distinct(value => value)
    ).subscribe((res: any) => {
      if (res instanceof NavigationEnd) {
        this.nowUrl = res.url;
        // reload iframeItem
        if (this.reloadItems.length) {
          const idx = this.reloadItems.findIndex((_reloadItem: IDwIframeItem) => {
            return _reloadItem.item.routerLink === this.nowUrl;
          });

          if (idx !== -1) {
            this.tabIframeItemsAdd(this.reloadItems[idx].item);
            this.reloadItems.splice(idx, 1);
          }
        }

        this.tabIframeComponentsDisplay(); // 開啟當前iframeComponent
      }

      // 離開時檢查此dwIframeComponent是否要reload
      if (res instanceof NavigationStart && this.tabRouteConfigJson) {
        const nowTab = this.tabs[this.selectedIndex];

        if (nowTab && nowTab.hasOwnProperty('id')) {
          for (let i = 0; i < this.tabRouteConfigJson.length; i++) {
            if (nowTab.id === this.tabRouteConfigJson[i].id || (nowTab.routerLink.search(this.tabRouteConfigJson[i].id) !== -1)) {
              if (this.tabRouteConfigJson[i].hasOwnProperty('reload') && this.tabRouteConfigJson[i].reload) {
                const idx = this.iframeItems.findIndex((_iframeItem: IDwIframeItem) => {
                  return _iframeItem.item.routerLink === nowTab.routerLink;
                });

                if (idx !== -1) {
                  const reloadItem: IDwIframeItem = {
                    isDisplay: this.iframeItems[idx].isDisplay,
                    item: Object.assign({}, this.iframeItems[idx].item)
                  };
                  this.reloadItems.push(reloadItem);
                  // 先移
                  this.iframeItems.splice(idx, 1);
                  // 之後再加回iframeItems,就會reload
                }
              }
              break;
            }
          }
        }
      }
    });

    // 從DwIframeGeneralComponent DwIframeFinereportComponent傳來資料
    // TODO：[多頁佈局首頁內嵌iframe非同步混亂]
    //       item$廣播、路由轉換為非同步，先後順序不一定。
    //       避免focus tab不是報表，但首頁是報表，重新整理時首頁先init就廣播出報表url同時路由也轉成focus tab作業，誤以為報表url是此作業的。
    // 暫解：首頁內嵌iframe時，1.提供programId='home'做辨識 2.作業資訊指定type
    this.iframeItemSubjectService.item$.pipe(distinct(value => value)).subscribe((outUrlObj) => {
      if (outUrlObj.hasOwnProperty('url')) {
        const item = Object.assign({}, outUrlObj);

        if (item.hasOwnProperty('programId')) {
          // 取作業routerLink
          this.dwOperationInfoService.operationInfo$(item.programId).pipe(
            first()
          ).subscribe(
            (operationInfo: IDwProgram) => {
              // 作業內嵌iframe時，要在作業資訊定義type為'fineReport'或'externalUrl'
              if (operationInfo.type === 'fineReport' || operationInfo.type === 'externalUrl') {
                item.routerLink = operationInfo.routerLink;
                this.tabIframeItemsAddCheck(item);
              }
            }
          );
        } else {
          item.routerLink = this.nowUrl;
          this.tabIframeItemsAddCheck(item);
        }
      }
    });
    // #########管理外部連結iframe div display END#########
    this.dwRouteInfoService.tabContent = this.tabContent; // 變更tab時,管理顯示隱藏,避免看到前tab殘影
    this.tabContent.nativeElement.style.display = 'none'; // 避免一開始看到首頁
    // 開啟初始TAB
    this.dwRouteInfoService.createDefaultTab().then((val) => {
      this.tabContent.nativeElement.style.display = 'block';
      // 自貼連結給nowProgramId後,顯示tab名稱
      if (this.menuItemNameComponents) {
        this.menuItemNameComponents.forEach((com, index, array) => {
          if (index === this.selectedIndex) {
            com.menuId = this.tabs[this.selectedIndex].menuId;
            com.programId = this.tabs[this.selectedIndex].id;
            com.ngOnInit();
          }
        });
      }
    });

    this.getOrgList().subscribe(data => {
      this.orgList = data;
    });

      //获取菜单
      this.getMenuData().subscribe(val => {
        // console.log(val, 'onChange');
        this.names = [];
        this.program_name = [];
        val.forEach((item) => {
          item['child'].forEach((obj) => {
            obj['child'].forEach((i) => {
              this.program_name.push(i.name);
              this.names.push({
                id: i.id,
                name: i.name,
                programIdQuery: i.id_program
              });
            });
          });
        });
        console.log('菜单初始化------>');
        //菜单去重start
        let arr2 = this.names.filter((item, index) => {
          let temArr = []
          this.names.forEach(item2 => temArr.push(item2.name))
          return temArr.indexOf(item.name) == index
        })
        // console.log('菜单去重start------>',arr2);
        this.names =arr2;
        //菜单去重end

        // 存储菜单方便作业后期跳转对应作业动态取得名称-modify by quwj
        if (this.names) {
          sessionStorage.setItem('fxMenuDataJson', JSON.stringify(this.names));
        }
      })

      // 400话务条
      if (this.fxUserService.userInfo['abj014']) {
        this.callerPanelShow = true;
        var script = document.createElement('script');
        
        // script.src = 'https://cc.canature.com/admin/sdk/index/callcenter/?extension=' + this.fxUserService.userInfo['abj014']  + '&popscreen_callback=callerBack&control_container=callerPanel';   
        script.src = 'https://demo.doscs.com/admin/sdk/index/callcenter/?extension=' + this.fxUserService.userInfo['abj014']  + '&popscreen_callback=callerBack&control_container=callerPanel';    
        // 如果有话务条fx_wrapper的样式要调整
        var fxWrapper = this.doc.querySelector('.fx_wrapper');
        if (fxWrapper) {
          fxWrapper.style['padding-top'] = '77px';
        }
        // 如果有话务条tabHeader的样式要调整
        var tabHeader = this.doc.querySelector('#tab_header');
        if (tabHeader) {          
          tabHeader.style['top'] = '37px';
        }
        // 如果有话务条侧边栏的设置按钮的样式要调整
        var tabHeader = this.doc.querySelector('.fx_riBtn .fx_drawer_Ribtn');
        if (tabHeader) {          
          tabHeader.style['top'] = '37px';
        }        
        document.head.appendChild(script);
        // 监听电话呼入弹屏
        setInterval(() => {
          const event = sessionStorage.getItem('400CallerBack');
          if (event) {
            sessionStorage.setItem('400CallerBack', '');          
            this.callerBack(JSON.parse(event));
          }
        }, 500)
      }

  }

  // myCallIn(param): void {
  //   var eleDiv = document.getElementById('cti_btn');
  //   if (eleDiv && (eleDiv as any).value) {
  //     var phone = (eleDiv as any).value;
  //     this.http.post('A/IACommonService/matchDefInfo', {param:{ phone: phone} }).subscribe((response: any) => {
  //         if (response.success && response.data) {
  //           if (response.data.length == 1) {
  //             var routeInfo: IDwRouteInfo = {
  //               id: "fxdi055",
  //               menuId: getMenuName("fxdi055"),
  //               name: '',
  //               routerLink: "/fxdi055",
  //               queryParams: {
  //                 xState: 'browse',
  //                 xPage: 'Detail',
  //                 def001: response.data[0].def001,
  //                 phone: phone
  //               }
  //             };
  //             this.dwRouteInfoService.create(routeInfo);
  //           } else if (response.data.length > 1) {
  //             var routeInfo: IDwRouteInfo = {
  //               id: "fxdi055",
  //               menuId: getMenuName("fxdi055"),
  //               name: '',
  //               routerLink: "/fxdi055",
  //               queryParams: {
  //                 xState: 'browse',
  //                 xPage: 'List',
  //                 data: response.data.map(item => item.def001)
  //               }
  //             };
  //             this.dwRouteInfoService.create(routeInfo);
  //           } else {
  //             var routeInfo: IDwRouteInfo = {
  //               id: "fxdi055",
  //               menuId: getMenuName("fxdi055"),
  //               name: '',
  //               routerLink: "/fxdi055",
  //               queryParams: {
  //                 xState: 'add',
  //                 xPage: 'Detail',
  //                 phone: phone
  //               }
  //             };
  //             this.dwRouteInfoService.create(routeInfo);
  //           }
  //         } else {
  //           this.dwMessage.error(this.translateService.instant('com-phone-error'));
  //         }
  //       }, error => {
  //         this.dwMessage.error(this.translateService.instant('com-phone-error'));
  //       });
  //   } else {
  //     this.dwMessage.error(this.translateService.instant('com-phone-get'));
  //   }

  // }

  // 400话务条
  callerBack(event): void {
    if (event && event.Callerid) {
      /* 
        pahone: 收货人手机
        routerLink: 跳转路由
        xState: 详情页状态
        def001: 档案编号
        xPage: 根据之前客户商品档案fxdi055参数定义的跳转列表页接参数需要的变量
      */
      var phone = event.Callerid, routerLink = '', xState = '', def001 = '', xPage = '';
      // 调用客户商品档案fxdi055列表服务查询收货人手机号def009为当前呼入手机号的数据，大于1笔跳转列表页，等于一笔跳转详情页，零跳转新增页面
      const queryInfo = {
        condition: {
          items: [{
            name: 'def009',
            operator: 'EQUAL',
            table_no: 'def_f',
            type: '3',
            value: [phone]
          }],
          joinOperator: 'AND',
        },
        gcomConds: '',
        glikesel: '',
        orderfields: [],
        pageNumber: 1,
        pageSize: 20,
        pageable: true
      }
      this.http.post('D/IFxdi055Service/getList', {queryInfo: queryInfo }).subscribe((response: any) => {
        if (response.data && response.data.datas && response.data.datas.length) {
          // if (response.data.datas.length == 1)  {// 只有一笔资料跳转详情页
          //   routerLink = '/fxdi055/fxdi055-s02';
          //   xState = 'browse';
          //   def001 = response.data.datas[0].def001;
          // } else {// 多笔资料跳转列表页
            routerLink = '/fxdi055';
            xState = '';
            xPage = 'List';
            def001 = '';
          // }
        } else {// 没有资料跳转新增页
          routerLink = '/fxdi055/fxdi055-s02';
          xState = 'add';
          def001 = '';
        }
        var routeInfo: IDwRouteInfo = {
          id: "fxdi055",
          menuId: getMenuName("fxdi055"),
          name: '',
          routerLink: routerLink,
          queryParams: {
            xState: xState, 
            def001: def001,
            xPage: xPage,
            phone: phone
          }
        };
        this.dwRouteInfoService.create(routeInfo);
      });
    } else {
      this.dwMessage.error(this.translateService.instant('com-phone-get'));
    }
  } 

  ngAfterViewInit(): void {
    // var script = document.createElement("script");  //创建一个script标签
    // script.src = 'https://cdn.lanjia.org.cn/sg/dist/cti.api.js?v=1';
    // var script2 = document.createElement("script");  //创建一个script标签
    // script2.src = 'https://cdn.lanjia.org.cn/sg/main.js?v=4';
    // document.getElementsByTagName('body')[0].appendChild(script);
    // document.getElementsByTagName('body')[0].appendChild(script2);

    // 管理回復捲軸位置用
    // 如果要包在div裏,請在div加入class="for-tab-query-class"
    if (this.doc.querySelector('.for-tab-query-class')) {
      this.outletDiv = this.doc.querySelector('.for-tab-query-class');
    } else {
      this.outletDiv = this.doc.documentElement || this.doc.body;
    }
    this.dwRouteInfoService.outletDiv = this.outletDiv;
    // this.outlet.activateEvents.subscribe((component: Component) => {
    //   // gets passed the new child component instance
    //   console.log(component);
    // }, () => { }, () => { console.log('cc complete'); });
    // this.outlet.deactivateEvents.subscribe(() => {
    //   // the child component has been destroyed
    //   console.log('component destroyed');
    // });
    // if (this.outlet.isActivated) {
    //   // will be false if there is no child route active
    //   console.log('outlet active');
    // }
  }

  ngOnDestroy(): void {
    // prevent memory leak when component is destroyed
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.route$) {
      this.route$.unsubscribe();
    }
    // if (this.iframeItemSubjectService.subscrition) {
    //   this.iframeItemSubjectService.subscrition.unsubscribe();
    // }
    this.iframeItemSubjectService.clear();
    this.iframeItems = [];
    this.dwRouteInfoService.clear();
    this.nowUrl = '';
    this.tabs = [];
  }

  // 新增iframe前的檢查
  private tabIframeItemsAddCheck(item: any): void {
    const newItem = Object.assign({}, item);

    const idx = this.iframeItems.findIndex((_iframeItem: IDwIframeItem) => {
      return _iframeItem.item.routerLink === newItem.routerLink;
    });

    if (idx === -1 && !!newItem.routerLink && newItem.routerLink !== '/dwTabRouting') {
      this.tabIframeItemsAdd(newItem);
      this.tabIframeComponentsDisplay(); // 開啟當前iframeComponent
    }
  }

  // 新增iframe
  private tabIframeItemsAdd(item: any): void {
    const iframeItem: IDwIframeItem = {
      isDisplay: false, // 預設不顯示
      item: Object.assign({}, item)
    };
    this.iframeItems.push(iframeItem);
  }

  // 開啟當前iframeComponent
  private tabIframeComponentsDisplay(): void {
    this.iframeItems.forEach(
      (iframeItem: IDwIframeItem) => {
        let display = false;

        // 搜尋''或'/'的結果會是0，在此防止誤判
        if (iframeItem.item.routerLink === '' || iframeItem.item.routerLink === '/' || iframeItem.item.routerLink === '/dwTabRouting') {
          if (this.nowUrl === '' || this.nowUrl === '/' || this.nowUrl === '/dwTabRouting') {
            display = true;
          }
        } else if (this.nowUrl.indexOf(iframeItem.item.routerLink) !== -1) {
          display = true;
        }

        iframeItem.isDisplay = display;
      }
    );
  }

  // add by Canon
  getOrgList(): Observable<any> {
    // return of([])
    return Observable.create(observer => {
      const entNo = this.userService.userInfo['c_ent'];
      console.log('........',this.userService.userInfo['c_lang']);
      var param = {user_no: this.userInfo.userId, is_need_tree: 'N', entNo: entNo, language: this.userService.userInfo['c_lang']}
      console.log('........',param)
      this.http.post('A/IACommonService/getOrgTree', {param:param }).subscribe(
        (response: any) => {
          const result = (response.success && response.data)
            ? response.data.map(item => ({orgId: item.id, orgName: item.name}))
            : [];
          observer.next(result);
        }, error => {
          observer.next([]);
        }, () => {
          observer.complete();
        });
    });
  }

  async switchOrg(org: any): Promise<any> {
    // 检查是否是同组织
    if (this.userInfo.orgId === org.orgId) {
      return;
    }
    // 防呆提示
    try {
      const ok = await this.fxModalService.confirm$({message: '切换组织将关闭所有分页'}).toPromise();
    } catch (e) {
      return;
    }
    // 清空页签
    // this.dwRouteInfoService.clear();
    const tabs2Close = this.tabs.filter(tab => tab.id !== 'home');
    tabs2Close.forEach(tab => {
      this.dwRouteInfoService.remove(tab);
    });
    // this.dwOperationInfoServ
    this.selectedIndex = this.dwRouteInfoService.routeSelectedIndex;
    this.iframeItems = [];
    // 切换组织
    this.userInfo.orgId = org.orgId;
    this.userInfo.orgName = org.orgName;
    // 更新 token
    try {
      const newTokenRes: any = await this.http.post('A/ILoginService/switchOrg', {param: {org_no: this.userInfo.orgId, org_name: this.userInfo.orgName}}).toPromise();
      // sessionStorage.setItem('DwUserInfo', JSON.stringify({token: newTokenRes.token}));
      this.userService.setUserInfo({
        token: newTokenRes.token,
        c_org: this.userInfo.orgId,
        c_org_n1: this.userInfo.orgName
      });
      // this.userService.userInfo['token'] = newTokenRes.token;
      // this.userService.userInfo['c_org'] = this.userInfo.orgId;
      // this.userService.userInfo['c_org_n1'] = this.userInfo.orgName;
    } catch (e) {
      console.error('组织切换失败！', e);
    }
    // 刷新页面 << not need
    // window.location.reload();
  }


  isShow(e) {
    console.log(e,'isShow')
  }


  private getMenuData(): Observable<any> {
    return Observable.create(observer => {
      this.http.post('A/IACommonService/getMenuTreeByUser', {param: {}}).subscribe(
        response => {
          response['success'] && response['data']
            ? observer.next(response['data'])
            : observer.next([]);
          observer.complete();
          console.log(response,'getMenuData')
        }, error => {
          observer.next([]);
          observer.complete();
        }
      );
    });
  }


  onChange() {
    const aaa = this.names.filter(tab => JSON.stringify(tab.name).toLowerCase().includes(this.searchVal.toLowerCase()));
      if (aaa) {
        this.program_names = [];
        aaa.forEach((item) => {
          this.program_names.push(item.name);
        })
      } else {
        this.program_names = this.program_name;
      }
  }

  public program_nameClick(name: any): void {
    console.log('作业搜索跳转——————》', name);
    const aaa = this.names.filter(tab => tab.name === name);
    var programId = '';//programIdQuery为自定义查询作业，id为作业本身
    var queryParams = {};
    if(aaa[0]['programIdQuery']){
      programId = aaa[0]['programIdQuery'];
      queryParams ={
        programId: aaa[0]['id']
      }
    }else{
      programId = aaa[0]['id'];
    }
    var routerLink = "/" + programId;
    if (this.programData && this.programData.length) {
      const menuIdx = this.programData.findIndex( menu => {
        return menu.id == programId;
      });
      if (menuIdx !== -1) {
        routerLink = this.programData[menuIdx].routerLink;
      }
    }
    const routeInfo: IDwRouteInfo = {
      id: programId,
      menuId: name,
      name: '',
      routerLink: routerLink,
      queryParams: queryParams
    };
    // 根据作业id找到对应的路由routerLink

    this.dwRouteInfoService.create(routeInfo);
    // })

  }

  keyDownSearch(event): void {
    if (event.keyCode === 13) {
      this.program_nameClick(this.searchVal);
    }
  }

  //获取电话呼叫权限
  // getCtiPromiess(): Observable<any> {
  //   // return of([])
  //   return Observable.create(observer => {
  //     this.http.post('A/IACommonService/showButtonDisplay', {param:{ program_no: 'fxdi055'} }).subscribe(
  //       (response: any) => {
  //         var result = false;
  //         if (response.success && response.data) {
  //           result = response.data.filter(item => {return item.id == 'btnCti'}).length > 0;
  //         }
  //         observer.next(result);
  //       }, error => {
  //         observer.next(false);
  //       }, () => {
  //         observer.complete();
  //       });
  //   });
  // }

}
