import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationInfoComponent } from './operation-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgQuicksilverModule } from 'ng-quicksilver';
import { FrameworkUIModule } from '@webdpt/framework';
import { ImgUploadComponent } from './img-upload.Component';
import { FileUploadComponent } from './file-upload.Component';
import { ProcessInfoComponent } from './process-info.component';

@NgModule({
  imports: [ //导入该模块常用指令
    CommonModule, 
    FrameworkUIModule,
    TranslateModule,
    NgQuicksilverModule
  ],
  declarations: [OperationInfoComponent, ImgUploadComponent, FileUploadComponent, ProcessInfoComponent], //声明工具性的管道、指令和组件类
  // providers: [ //在当前模块的注入器中可用的一组可注入对象。
  //   OperationInfoComponent
  // ],
  exports: [ // 导出工具性的管道、指令和组件类 //通过重新导出 OperationInfoComponent，任何导入了这个 CommonComponentModule 的其它模块都可以访问OperationInfoComponent中的组件
    OperationInfoComponent, ImgUploadComponent, FileUploadComponent, ProcessInfoComponent
  ],
})

export class CommonComponentModule {
  static forRoot(providers: Provider[] = []): ModuleWithProviders {
    return {
      ngModule: CommonComponentModule,
      providers: [
        // OperationInfoComponent,
        ...providers
      ]
    };
  }
}
