export const programPageData = [
    {
        'programId': 'fxai001',
        'page': [
            {
                'id': ' fxia001',
                'routerLink': '/fxai001'
            }
        ]
    }
];
