import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MODULE_ROUTES } from '../routes';
import { DwLayoutDefaultComponent, DwSsoLoginComponent } from '@webdpt/framework';
import { dwAttachTabbedRoutes } from '@webdpt/framework';
import { FxLayoutDefaultComponent } from '../implementation/shared/layout/layout-default/layout-default.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'prefix',
  //   // component: FxLayoutDefaultComponent,
  //   children: [
  //     dwAttachTabbedRoutes(MODULE_ROUTES),
  //     ...MODULE_ROUTES
  //   ]
  // },
  {
    path: '',
    pathMatch: 'prefix',
    component: FxLayoutDefaultComponent,
    children: [
      dwAttachTabbedRoutes(MODULE_ROUTES),
      ...MODULE_ROUTES
    ]
  },

  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: './auth/login.module#LoginModule'
  },
  {
    path: 'forget',
    pathMatch: 'full',
    loadChildren: './auth/forget/forget.module#ForgetModule'
  },
  {
    path: 'demo',
    pathMatch: 'full',
    loadChildren: './demo/demo.module#DemoModule'
  },
  {
    path: 'index',
    pathMatch: 'prefix',
    loadChildren: './index/index.module#IndexModule'
  },
  {
    path: 'sso-login',
    pathMatch: 'full',
    component: DwSsoLoginComponent
  },
  {
    path: 'index',
    pathMatch: 'prefix', 
    loadChildren: './index/index.module#IndexModule'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImplementationRoutingModule { }
