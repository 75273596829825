import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgQuicksilverModule } from 'ng-quicksilver';
import { FrameworkUIModule } from '@webdpt/framework';
import { SearchMoreComponent } from './search-more.component';
import { saveCondComponent } from './modal/save-comcond';
import { editCondComponent } from './modal/edit-comcond';
import { FormsModule } from '@angular/forms';
//动画模块
//import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SetOrderByComponent } from 'app/implementation/shared/search-condition/modal/set-orderby';
import { OrderByMasterService } from 'app/implementation/shared/search-condition/modal/set-orderby.service';
import { FxGridModule } from 'app/implementation/shared/fx-grid/fx-grid.module';
import { textModalComponent } from './modal/text-modal';

@NgModule({
  imports: [ //导入该模块常用指令
    CommonModule, 
    FormsModule,
    FrameworkUIModule,
    TranslateModule,
    NgQuicksilverModule,
    FxGridModule,
    //BrowserModule,
    //BrowserAnimationsModule
  ],
  declarations: [ saveCondComponent, editCondComponent, SearchMoreComponent, SetOrderByComponent, textModalComponent], //声明工具性的管道、指令和组件类
  entryComponents: [saveCondComponent, editCondComponent, SetOrderByComponent, textModalComponent], //定义此 NgModule 中要编译的组件集，这样它们才可以动态加载到视图中
  providers: [OrderByMasterService], //
  exports: [ // 导出工具性的管道、指令和组件类 //通过重新导出 OperationInfoComponent，任何导入了这个 CommonComponentModule 的其它模块都可以访问OperationInfoComponent中的组件
    SearchMoreComponent
  ],
})

export class SearchConditionModule {
  static forRoot(providers: Provider[] = []): ModuleWithProviders {
    return {
      ngModule: SearchConditionModule,
      providers: [
        ...providers
      ]
    };
  }
}
