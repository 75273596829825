import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DwUserService} from '@webdpt/framework';
import {FxDapHttpClient} from './http/fx-http-client';
import {FxDapHttpErrorHandler} from './http/fx-http-error-handler';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    FxDapHttpClient,
    FxDapHttpErrorHandler
  ]
})

export class FxDapModule {
  static forRoot(providers: Provider[] = []): ModuleWithProviders {
    return {
      ngModule: FxDapModule,
      providers: [
        FxDapHttpErrorHandler,
        FxDapHttpClient,

        { // 用戶
          provide: DwUserService
        },
        // DwIamLoggingService,
        // {
        //   provide: DwLoggingService,
        //   useExisting: DwIamLoggingService
        // },
        ...providers
      ]
    };
  }
}
