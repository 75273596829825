const angularValidators = {
  'required': 'Required',
  'minlength': 'Minimal character length: {{requiredLength}}, current character length: {{actualLength}}',
  'maxlength': 'Maximum character length: {{requiredLength}}, current character length: {{actualLength}}',
  'min': 'Minimum: {{min}}, current value: {{actual}}',
  'max': 'Maximum: {{max}}, current value: {{actual}}',
  'required true': 'must be true',
  'email': 'Not in compliance with the email format.',
  'pattern': 'Format does not match {{requiredPattern}}',
  'nullValidator': 'No Operation Validator'
};

const dwI18nProgView = {
  'dw-login-rememberMe': 'Remember me',
  'dw-login-forgetPassword': 'Forget password',
  'dw-login-registered': 'Registered',
  'dw-login-password': 'password',
  'dw-login-account': 'Account',
  'dw-login-signIn': 'Login',
  'dw-login-tenant': 'Tenant',
  'dw-sys-menu-save': 'Save',
  'dw-sys-menu-cancel': 'Cancel',
  'dw-sys-menu-add': 'Add',
  'dw-sys-menu-increase': 'Increase',
  'dw-sys-menu-edit': 'Edit',
  'dw-sys-menu-delete': 'Delete',
  'dw-sys-menu-addItem': 'Add Item',
  'dw-sys-menu-externalLink': 'External Link',
  'dw-sys-menu-category': 'Category',
  'dw-sys-menu-program': 'Program',
  'dw-sys-menu-report': 'Report',
  'dw-sys-menu-menuPreview': 'Menu Preview',
  'dw-sys-menu-name': 'Name',
  'dw-sys-menu-iconClass': 'Icon Style Class',
  'dw-sys-menu-defaultExpand': 'Default Expand',
  'dw-sys-menu-nodeEditing': 'Node Editing',
  'dw-sys-menu-linkProgram': 'Link Program',
  'dw-sys-menu-parameterSetting': 'Parameter Setting',
  'dw-sys-menu-parameterName': 'Parameter Name',
  'dw-sys-menu-parameterValue': 'Parameter Value',
  'dw-sys-menu-linkURL': 'Link URL',
  'dw-sys-menu-openMode': 'Open Mode',
  'dw-sys-menu-openMode-window': 'New Window',
  'dw-sys-menu-openMode-iframe': 'Embed Webpage',
  'dw-sys-menu-upload-mcc': 'Upload Middleware Control Center',
  'dw-sys-menu-upload-mcc-hint': 'Upload All Programs To Middleware Control Center',
  'dw-sys-menu-msg-updateFailed': 'Update Failed',
  'dw-sys-menu-msg-updated': 'Updated',
  'dw-sys-menu-msg-confirmDelete': 'Do you want to delete?',
  'dw-sys-menu-msg-deleteFailed': 'Delete Failed',
  'dw-sys-menu-msg-deleted': 'Deleted',
  'dw-sys-menu-msg-notExist': '{{value1}} could not find data',
  'dw-sys-menu-msg-exist': 'Do not repeat setting',
  'dw-forget-mailCode': 'Mail Verification Code',
  'dw-forget-mobileCode': 'Mobile Verification Code',
  'dw-forget-enterEmailReceive': 'Please enter Email,Receive an active account mail',
  'dw-forget-enterCorrectEmail' : 'Please enter correct email',
  'dw-forget-emailNotExist': 'Email does not exist',
  'dw-forget-enterCellphone': 'Please enter cellphone number',
  'dw-forget-needsDigits': 'The cellphone number needs to be 11 digits',
  'dw-forget-needAllNumbers': 'The cellphone numbers need to be all numbers',
  'dw-forget-cellphoneNotExist': 'The cellphone number does not exist',
  'dw-forget-getCode': 'Get code',
  'dw-forget-enterCode': 'Please enter verification code',
  'dw-forget-countDown': '{{current}} seconds',
  'dw-forget-newPassword': 'New Password',
  'dw-forget-confirmNewPassword': 'Confirm the new password',
  'dw-forget-enterNewPassword': 'Please enter a new password',
  'dw-forget-enterNewPasswordAgain': 'Please enter new password again',
  'dw-forget-passwordMismatch': 'Password mismatch',
  'dw-forget-sentSuccessfully': 'Sent successfully',
  'dw-forget-confirmCodeMailbox': 'Please confirm the verification code in the mailbox.',
  'dw-forget-confirmCodeSMS': 'Please confirm the verification code in the SMS.',
  'dw-forget-logInAgain': 'After changing your password, please log in again with your new password.',
  'dw-forget-updatedSuccessfully': 'The password is updated successfully. Please log in with your new password.'
};

export const dwI18nBasic = {
  ...angularValidators,
  ...dwI18nProgView,
  'app-title': '',
  'dw-request-url': 'Request URL',
  'dw-request-body': 'Request content',
  'dw-response-status': 'Response status',
  'dw-response-message': 'Response message',
  'dw-http-error': 'Sorry, something went wrong.',
  'dw-http-error-400': 'Bad Request.',
  'dw-http-error-401': 'Request for sending lacks credentials.',
  'dw-http-error-403': 'Sorry, you don\'t have access to this page.',
  'dw-http-error-404': 'Sorry, the page you visited does not exist.',
  'dw-http-error-500': 'Sorry, the server is wrong.',
  'dw-loadingData': 'Loading Data',
  'dw-pleaseEnter': 'input search text',
  'dw-determine': 'Ok',
  'dw-cancel': 'Cancel',
  'dw-save': 'Save',
  'dw-select-modal-error-dataSource': 'Must have a dataSource',
  'dw-select-modal-error-tableIdField': 'The tableIdField must not be null',
  'dw-select-modal-error-tableNameField': 'The tableNameField must not be null',
  'dw-select-modal-error-tableColDefs': 'The tableColDefs must not be null',
  'dw-select-modal-error-tableColDefs-title': 'tableColDefs-The title must not be null',
  'dw-select-modal-error-tableColDefs-field': 'tableColDefs-The field must not be null',
};
