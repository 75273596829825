import { Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FxAgGridValidationEditorComponent} from '../fx-ag-grid-validation-editor';
import {Observable, of} from 'rxjs';
import {ICellRendererAngularComp} from '../../../../../../node_modules/ag-grid-angular';
import {FxDapHttpClient} from '../../fx-http-client';

@Component({
  selector: 'fx-ag-grid-status-cell-renderer',
  templateUrl: './fx-ag-grid-status-cell-renderer.component.html',
  styleUrls: ['./fx-ag-grid-status-cell-renderer.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FxAgGridStatusCellRendererNewComponent  extends FxAgGridValidationEditorComponent implements  ICellRendererAngularComp, OnDestroy {
  values: any;
  key;
  public value;
  label: string = '';
  status: string = 'default';
  color: string = 'transparent';
  columnName: string;
  private rowId: number;

  constructor( private http: FxDapHttpClient) {
    super();
  }

  agInit(params: any): any {
    this.values = params.values ? params.values : [];
    // console.debug('-------- fx-form-cell debugging');
    this.columnName = params.column.colDef.headerName;
    // this.key = params.context.createKey(params.columnApi, params.column);
    this.key = 'ag_' + params.node.id + '_' + params.colDef.field;
    this.value = params.getValue();
    if (Array.isArray(this.values)) {
      this.updateValue(this.values);
    } else {
      const observable: Observable<any> = this.values;
      observable.subscribe(data => {
        this.updateValue(data);
      });
    }
    super.init();
  }

  updateValue(arr: Array<any>): void {
    const matchedItem = arr.find(item => item.value === this.value);
    this.label = matchedItem ? matchedItem.label : '';
    this.color = (matchedItem ? matchedItem.color : '')  || (matchedItem ? matchedItem.aaa004 : '');
  }

  refresh(params: any): boolean {
    // this.formGroup = params.context.formGroup.controls[this.rowId].get(this.key);
    //
    // // this could also be done in GridComponent.createFormControls, but the cell component could be doing something with
    // // the value, so it feels more natural that the control value be set here
    // // this.formGroup.at(this.key).patchValue(this.value);
    // this.formGroup.patchValue(this.value);
    this.value = params.value;
    if (Array.isArray(this.values)) {
      this.updateValue(this.values);
    } else {
      const observable: Observable<any> = this.values;
      observable.subscribe(data => {
        this.updateValue(data);
      });
    }
    return true;
  }

  debug(): void {
    console.debug('-------- fx-form-cell debugging');
  }

  ngOnDestroy(): void {
  }
}
