/**
 * todo
 * 1. 增加参数控制是否分页，默认分页
 * 2. 样式优化
 */

import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation, ElementRef, Injectable } from '@angular/core';
// import { DwModalRef } from 'ng-quicksilver';
import { DwRoutingMessageService } from '@webdpt/framework';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { GridApi } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { DwModalService } from 'ng-quicksilver';
import { ActivatedRoute } from '@angular/router';

// @Component({
//   selector: 'fx-automatic-component',
//   templateUrl: './fx-automatic.component.html',
//   styleUrls: [
//     './fx-automatic.component.less'
//   ],
//   encapsulation: ViewEncapsulation.None,
// })
@Injectable()
export class FxAutomaticService {
  public programId: string; // 作业编号
  public isReady = false;
  public flag:boolean = true;


  constructor(
    // private modal: DwModalRef,
    private translateService: TranslateService,
    public dwModalService: DwModalService,
    public dwMessage: DwRoutingMessageService,
    protected route: ActivatedRoute,
    public fxModalService: FxModalService,
    private http: HttpClient) {
  }

  // 获取自动编码规则
  public getAutoRules(params): Observable<any> {
    // params = {
    //   abm002: 'fxdd054',
    //   abm003: 'dem_f',
    //   abm004: 'demdocno',
    // }
    return Observable.create(observer => {
      params.zdbm = '1';
      var openModelParam = {
        modalID: 'qry_abm001_01',     // (必传) 开窗的编号
        postParam: [params],             // (必传) 查询参数，注意是数组
        selected: [],
        searchType: 'all', // 标准校验的标志位传参
        // isRequired: true,
      };
      this.fxModalService.searchFx(openModelParam).subscribe(data => {
        if (data.length == 0) {
          this.dwMessage.warning(this.translateService.instant('comm-暂无编码规则，请先录入编码规则！'));
          observer.next([]);
          observer.complete();
        } else if (data.length == 1) {
          observer.next(data);
          observer.complete();
        } else if (data.length > 1) {
          if (this.flag) {
            this.flag = false;
            openModelParam.searchType = 'a';
            this.fxModalService.searchFx(openModelParam).subscribe(openData => {
              if (openData && openData.length) {
                observer.next(openData);
                observer.complete();
              } else {
                this.dwMessage.warning(this.translateService.instant('common-请选择自动编码'));               
                observer.error(false);
              }
            }, error => {
              // observer.next(data);
              // observer.complete();
              this.flag = true;
              observer.error(false);
            });
          }
        }
      }, error => {
        observer.next([]);
        observer.complete();
          // observer.error(false);
      })
    });
  }




}

