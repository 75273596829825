import { AfterContentInit, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { DwPaginationComponent } from 'ng-quicksilver';

import { tap } from 'rxjs/operators';
import { DwQueryInfo } from '../../document/model/query';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'dw-pagination-server-side-wrap',
  template: `
    <ng-content></ng-content>
  `
})
export class DwPaginationServerSideWrapperComponent implements AfterContentInit {
  @ContentChild(DwPaginationComponent) paginationComponent: DwPaginationComponent;

  @Input() dataSource: {
    getDataList(pageNumber: number, pageSize: number, queryInfo: DwQueryInfo): Observable<any>;
  };
  @Input() pageNumber: number = 1;
  @Input() pageSize: number = 10;
  @Input() queryInfo: DwQueryInfo;
  @Output() dataSourceChange = new EventEmitter();

  pageable: boolean = true;

  ngAfterContentInit(): void {
    if (this.paginationComponent && this.dataSource) {

      this.reSendData();

      if (this.queryInfo.pageable) {
        this.paginationComponent.dwPageIndexChange.subscribe((index: number) => {
            this.pageNumber = index;
            this.reSendData();
          }
        );
        this.paginationComponent.dwPageSizeChange.subscribe((size: number) => {
          this.pageSize = size;
          this.reSendData();
        });
      }
    }
  }

  reSendData(): void {

    this.dataSource.getDataList(this.pageNumber, this.pageSize, this.queryInfo).pipe(
      tap((result) => {
        this.parsePaginationInfo(result);
      })
    ).subscribe(
      (result) => {
        this.dataSourceChange.emit(result);
      }
    );
    this.pageable = this.queryInfo.pageable;

  }

  parsePaginationInfo(result: any): void {
    if (this.paginationComponent && this.pageable) {
      this.paginationComponent.dwTotal = result.rowCount;
      this.paginationComponent.dwPageIndex = result.currentPage;
      this.paginationComponent.dwPageSize = result.pageSize;
    }
  }
}
