import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * 路由
 */
@Injectable()
export class DwRouterInfoService {

  constructor(
  ) { }

  /**
   * 路由資訊中的作業編號
   */
  public routerProgramId(activatedRoute: ActivatedRoute): string {
    let routerProgramId = null;
    const firstChild = activatedRoute.firstChild;
    const value = activatedRoute.data['value'];

    if (value['dwRouteData']) {
      if (value['dwRouteData'].hasOwnProperty('programId')) {
        routerProgramId = value['dwRouteData'].programId;
      }
    }

    if (!routerProgramId) {
      if (firstChild) {
        routerProgramId = this.routerProgramId(firstChild);
      }
    }

    return routerProgramId;
  }
}
