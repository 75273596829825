import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dw-layout-default',
  templateUrl: './layout-default.component.html'
  // styleUrls: ['./layout-default.component.css']
})
export class DwLayoutDefaultComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
