import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FxHotkeyHelpService {

  private _keyMap: any = {};

  public getKeyMap(): any {
    return this._keyMap;
  }

  constructor() { }
}
