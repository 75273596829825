import { DwHttpClient } from '../../../http/client';
import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { DwDapHttpErrorHandler } from './dap-http-error-handler';
import { DwSystemConfigService } from '../../../config/config.service';
import { DwHttpClientOptionsService } from '../../../http/service/http-client-options.service';
import { DwLoadingMaskService } from '../../../components/loading/service/loading-mask.service';
import { DW_APP_AUTH_TOKEN } from '../../../config/system.config';
import { DW_AUTH_TOKEN } from '../../../auth/auth.service';
import { DwRouterInfoService } from '../../../router-info/router-info.service';
import { IDwProgram } from '../../../program-info/interface/program.interface';
import { DwProgramInfoListJsonService } from '../../../program-info/program-info-list-json.service';
import { DwHttpRequestUrlService } from '../../../http/service/http-request-url.service';

@Injectable()
export class DwDapHttpClient extends DwHttpClient {
  constructor(
    public dwHttpClientOptionsService: DwHttpClientOptionsService,
    public dwHttpRequestUrlService: DwHttpRequestUrlService,
    public dwLoadingMaskService: DwLoadingMaskService,
    public configService: DwSystemConfigService,
    @Inject(DW_APP_AUTH_TOKEN) private dwAppAuthToken: string,
    @Inject(DW_AUTH_TOKEN) private authToken: any,
    private dapHttpError: DwDapHttpErrorHandler,
    private activatedRoute: ActivatedRoute,
    private dwRouterInfoService: DwRouterInfoService,
    private programInfoListJsonService: DwProgramInfoListJsonService
  ) {
    super(dwHttpClientOptionsService, dwHttpRequestUrlService, dwLoadingMaskService);

    this.getApiUrl().subscribe(
      (url: string) => {
        this.api = url;
      }
    );
  }

  public getApiUrl(): Observable<string> {
    return this.configService.get('apiUrl');
  }

  protected get defaultHeaders(): { [header: string]: string | string[] } {
    const token = (this.authToken.token) ? this.authToken.token : '';

    let programId = this.dwRouterInfoService.routerProgramId(this.activatedRoute);
    let moduleId = '';

    if (programId === null) {
      programId = 'default';
      moduleId = 'default';
    } else {
      const program: IDwProgram = this.programInfoListJsonService.programListJsonMap[programId];

      if (program) {
        moduleId = program.module;
      } else {
        // TODO：報表不在作業清單，找不到模組，API傳入模組'default'必須也讓programId = 'default'，才能通過請求
        programId = 'default';
        moduleId = 'default';
      }
    }

    return {
      'digi-middleware-auth-app': this.dwAppAuthToken,
      'token': token,
      'Module-Name': moduleId, // 模組編號
      'Program-Code': programId, // 作業編號
      'ati-cookie': document.cookie
    };
  }

  protected responseIntercept(body: any): any {
    if (body && body.response) {
      return body.response;
    } else {
      return body;
    }
  }

  protected errorHandler(event: any): void {
    if (event.error && event.error.status) {
      this.dapHttpError.handlerError(event);
    }
  }
}
