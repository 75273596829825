import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DwAuthService } from '../../../auth/auth.service';


@Injectable()
export class DwViewLangLoaderService {
  private lastProgramId = ''; // 最後翻譯的作業編號
  public translated: any = {}; // 已翻譯清單

  constructor(
    private http: HttpClient,
    private authService: DwAuthService
  ) {
  }

  /**
   * 畫面翻譯檔載入器,不限已登入
   * @param lang 語系
   * @param [programId] 作業編號
   * @returns translation 畫面翻譯檔
   */
  getTranslation(lang: string, programId?: string): Observable<any> {
    // 語言別改變時會取語全部的系文檔，此時只要翻譯最後一個作業，其他的則是透過路由轉換時再翻譯
    if (!programId) {
      programId = this.lastProgramId;
    } else {
      this.lastProgramId = programId;
    }

    const isLoggedIn = this.authService.isLoggedIn; // 是否已登入
    const subject: Subject<any> = new Subject<any>();
    const transId = lang + '/' + programId;

    // 沒翻譯過才需要重新取檔案
    if (programId && this.translated[lang] === undefined || programId && this.translated[lang][programId] === undefined ) {
    // if (programId) {
      if (programId == "dw-forget" || programId == "dw-login" ||programId == "fx-address-picker") {
        const languageLoaderObs = this.http.get('assets/i18n/' + transId + '.json').pipe(
          map(
            (response: object) => {
              const langData = response;
              this.translated[lang] ?
                this.translated[lang][programId] = programId :
                this.translated[lang] = new Array, this.translated[lang][programId] = programId;
              return langData;
            }
          )
        );
        const subscription = languageLoaderObs.subscribe(
          langData => {
            subject.next(langData);
            subject.complete();
            subscription.unsubscribe();
          },
          error => {
            subject.complete();
            subscription.unsubscribe();
          }
        );
      } else {
        if (isLoggedIn) {
          const languageLoaderObs = this.http.post('A/IACommonService/getLanguageJsonInfo', { param: { program_no:  programId} }).pipe(
            map(
              (response: any) => {
                const langData = JSON.parse(response.data);
                if (response.success&&langData) {
                this.translated[lang] ?
                  this.translated[lang][programId] = programId :
                  this.translated[lang] = new Array, this.translated[lang][programId] = programId;
                }
                return langData;
              }
            )
          );
          const subscription = languageLoaderObs.subscribe(
            langData => {
              subject.next(langData);
              subject.complete();
              subscription.unsubscribe();
            },
            error => {
              subject.complete();
              subscription.unsubscribe();
            }
          );
        } else {
          subject.complete();
        }
      }
    } else {
      subject.next('languageLoaderObs is exixt');
      subject.complete();
    }

    return subject.asObservable();
  }
}
